import { InputNumber } from 'antd';
import { useField } from 'formik';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Title = styled.p`
  color: #009842;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
  line-height: 28px;
  margin-right: 12px !important;
`;

const Wrapper = styled.div<{ hide: boolean }>`
  align-self: flex-end;
  display: flex;
  opacity: ${(props) => (props.hide ? 0 : 1)};
`;

interface EditWorkingHoursProps {
  name: string;
}

const EditWorkingHours: FunctionComponent<EditWorkingHoursProps> = ({
  name,
}) => {
  const fieldName = `${name}.actualWorkingHours`;
  const [field, meta] = useField(fieldName);
  const status = meta.error;
  return (
    <Wrapper
      hide={
        field.value === undefined || field.value === null || field.value === 0
      }
    >
      <Title>gelst. AH</Title>
      <InputNumber
        status={status !== undefined ? 'error' : undefined}
        style={{ width: 66, marginLeft: 0, border: 'none' }}
        value={field.value}
        readOnly={true}
        decimalSeparator=','
      />
    </Wrapper>
  );
};

export default EditWorkingHours;
