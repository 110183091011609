import { Fragment, useState } from 'react';

import { Button } from 'antd';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import React from 'react';

interface RemoveButtonProps {
  disabled?: boolean;
  hidden?: boolean;
  callback: () => Promise<void>;
}

export default function SaveButton(props: RemoveButtonProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const action = async () => {
    try {
      setLoading(true);
      await props.callback();
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        hidden={!!props.hidden}
        disabled={!!props.disabled}
        type='link'
        className='add float-right'
        loading={loading}
        onClick={() => !loading && action()}
      >
        <CheckIcon />
        Speichern
      </Button>
    </Fragment>
  );
}
