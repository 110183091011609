import { Col, Row } from 'antd';
import { useField } from 'formik';
import { chain, reduce } from 'lodash';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ClockIcon } from '../../../assets/icons/calender.svg';
import { Job, Tour } from '../../../context/Route';
import EditWorkingHours from './EditWorkingHours';
import StatusSelect from './StatusSelect';
import TourJobTask from './TourJobTask';
import WorkingHours from './WorkingHours';
import renderError from './renderError';
import { CollapseButton } from '../../common/CollapseButton';

const Wrapper = styled.div`
  margin: 0 0 24px 0;
`;

export const JobDetails = styled.div`
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  height: 68px;
  justify-content: space-between;
  margin: 12px 0;
  padding: 20px 56px 20px 20px;
  position: relative;
  width: 100%;
  z-index: 0;
  .ant-row {
    width: 100%;
  }
`;

export const LocationName = styled.p`
  color: #000;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  overflow: hidden;
  padding: 0 0 1px;
  white-space: nowrap;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
`;

export const Address = styled.p`
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
`;

export const Date = styled.p`
  color: #009842;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
  line-height: 28px;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  svg {
    fill: #009842;
    margin: 5px;
    height: 18px;
    width: 18px;
    position: relative;
    top: -2px;
    p,
    path {
      fill: #009842 !important;
    }
  }
`;

const Tasks = styled.div``;

const ErrorDisplay = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  min-height: 1rem;
  max-height: 1rem;
  height: 1rem;
  opacity: ${({ show }) => (show ? '1' : '0')};
  overflow: hidden;
  font-size: 0.8rem;
  color: red;
  transition: opacity 0.2s ease-in-out;
  margin: 0;
  padding: 0.2rem;
  width: 100%;
`;

interface TourJobProps {
  tour: Tour;
  job: Job;
  collapsed: boolean;
  onCollapse: (job: Job, value: boolean) => void;
}

function renderAdress(job?: Job) {
  if (!job) return 'Job gelöscht';
  if (!job.address) return 'Adresse gelöscht';
  return `${job.address.street}, ${job.address.postalCode} ${job.address.city}`;
}

function renderLocation(job?: Job) {
  if (!job) return 'Job gelöscht';
  if (!job.location) return 'Standort gelöscht';
  return `[${job.location.tag}] ${job.location.name}`;
}

function locationUrl(job?: Job) {
  if (!job) return '';
  if (!job.location) return '';
  return `/administration/locations/${job.location._id || ''}?p=1`;
}

function renderDate(job?: Job) {
  if (!job) return '';
  if (!job.planedAt) return '';
  if (typeof job.planedAt === 'string') {
    return DateTime.fromISO(job.planedAt).toLocaleString();
  }
  return job.planedAt.toLocaleString('de-DE', {});
}

const TourJob: FunctionComponent<TourJobProps> = ({
  collapsed,
  onCollapse,
  job,
  tour,
}) => {
  const fieldName = `jobs.${job._id}`;
  const [field, meta, helpers] = useField(fieldName);

  useEffect(() => {
    // sum up actualWorkingHours from tasks
    // use null if no actualWorkingHours is set by tasks
    let j = reduce(
      field.value.tasks,
      (acc2, task) => {
        if (!task.actualWorkingHours) return acc2;
        return acc2 + (task.actualWorkingHours ?? 0);
      },
      null
    );

    // use planHours as fallback if actualWorkingHours is not set by taskss
    if (j === null) {
      j = field.value.planHours;
    }

    // check if actualWorkingHours is already set
    if (field.value.actualWorkingHours === j) return;

    // set actualWorkingHours
    helpers.setValue({
      ...field.value,
      actualWorkingHours: j,
    });
  }, [field.value]);

  return (
    <Wrapper key={job._id}>
      <ErrorDisplay show={meta.error !== undefined}>
        {renderError(meta.error)}
      </ErrorDisplay>
      <JobDetails>
        <CollapseButton
          data-collapsed={collapsed}
          collapsed={collapsed}
          onClick={() => onCollapse(job, !collapsed)}
        />
        <Row gutter={[36, 24]}>
          <Col xs={24} md={16} lg={8} xxl={6}>
            <NavLink to={locationUrl(job)} target='_blank'>
              <LocationName>{renderLocation(job)}</LocationName>
              <Address>{renderAdress(job)}</Address>
            </NavLink>
          </Col>
          <Col xs={24} md={8} lg={8} xxl={2}>
            <Date>
              <ClockIcon />
              {renderDate(job)}
            </Date>
          </Col>
          <Col xs={24} lg={8} xxl={7}>
            <StatusSelect name={fieldName} />
          </Col>
          <Col xs={24} md={8} xxl={3}>
            <WorkingHours name={fieldName} />
          </Col>
          <Col xs={24} md={8} xxl={3}></Col>
          <Col xs={24} md={8} xxl={3}>
            <EditWorkingHours name={fieldName} />
          </Col>
        </Row>
      </JobDetails>
      <Tasks>
        {!collapsed
          ? chain(field.value.tasks)
              .map((a, task) => {
                return (
                  <TourJobTask
                    key={task}
                    job={job}
                    tour={tour}
                    task={task.toString()}
                  />
                );
              })
              .value()
          : false}
      </Tasks>
    </Wrapper>
  );
};

export default TourJob;
