import './Partner.scss';

import React, { Component } from 'react';

import { AuthConsumer } from '../../context/AuthContext';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import DirectInput from '../Form/DirectInput';
import { EMAIL_REGEXP } from '../regex';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import { Redirect } from 'react-router-dom';
import { Request } from '../../api/Request';
import { set } from 'lodash';

class PartnerNew extends Component {
  state = {
    redirect: null,
    error: null,
    isLoaded: false,
    valid: false,
    item: {},
    location: null,
    editing: false,
  };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.send = this.send.bind(this);
  }

  capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  async save(k, value) {
    const data = set(this.state.item, k, value);

    this.setState({
      item: data,
      valid: this.isValid(),
    });
  }

  send() {
    Request.post('partners', this.state.item).then(
      (result) => {
        this.setState({
          isLoaded: true,
          redirect: result._id,
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error,
        });
      }
    );
  }

  isValid() {
    return (
      true &&
      this.state.item &&
      this.state.item.name &&
      this.state.item.name.length > 2
    );
    // && (this.state.item.address && this.state.item.address.location)
  }

  render() {
    const { item } = this.state;
    return (
      <AuthConsumer>
        {() => (
          <div className='partner container-inner'>
            {this.state.redirect ? (
              <Redirect
                to={`/administration/partners/${this.state.redirect}`}
              />
            ) : (
              ''
            )}
            <InPageNavigation to='/administration/partners' />
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col-6 col'>
                <h1 className='page-title'>Partner hinzufügen</h1>
              </div>
              <div className='col-6 col actions'>
                {/* { this.state.valid ? ( */}
                <div
                  className='float-right'
                  disabled={!this.state.valid}
                  onClick={this.send}
                >
                  <CheckIcon className='save' />
                  Hinzufügen
                </div>
                {/* ) : '' } */}
              </div>
            </div>
            <FormConsumer>
              {({ isEditing }) => (
                <div
                  className={`page-content row ${isEditing ? 'editing' : ''}`}
                >
                  <form className='col col-12 col-xs-6 col-md-6 col-lg-4 col-xl-4'>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Stammdaten</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Name'
                          required={true}
                          value={item.name}
                          validate={(value) =>
                            value !== undefined &&
                            value !== null &&
                            value.length > 2
                          }
                          callback={(value) => this.save('name', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='E-Mail'
                          value={item.email}
                          type='email'
                          validate={(value) =>
                            !value ||
                            (value && EMAIL_REGEXP.exec(value) !== null)
                          }
                          callback={(value) => this.save('email', value)}
                        />
                      </div>
                    </div>
                  </form>
                  {/* <div className="col col-12 col-xs-6 col-md-6 col-lg-8 col-xl-8">
                  <div className="row table-divider">
                    <span>Adresse</span>
                  </div>
                  <div className="row input-row">
                    <MapInput value={item.address} callback={ (value) => this.save('address', value) }/>
                    </div>
                </div> */}
                </div>
              )}
            </FormConsumer>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default PartnerNew;
