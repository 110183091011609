import '../Tours.scss';
import '../Filter.scss';

import React, { FunctionComponent } from 'react';

import classNames from 'classnames';
import numeral from 'numeral';

interface MetaTagProps {
  item: any;
  text: string;
  unit: string;
  s?: any;
  i?: any;
  a?: any;
  sollKey?: string;
  istKey?: string;
  alert?: (value?: number) => boolean;
}

const shortCurrency = (value: number, n?: string) => {
  const f = value < 10000 ? n || '0,0' : '0.0a';
  return numeral(value).format(f);
};

const MetaTag: FunctionComponent<MetaTagProps> = ({
  item,
  text,
  unit,
  s,
  i,
  a,
  sollKey,
  istKey,
  alert,
}) => {
  const sollValue = s ? s : item[sollKey ?? ''];
  const istValue = i ? i : item[istKey ?? ''];

  const soll = sollValue ? shortCurrency(sollValue) : '-';
  const ist = istValue ? shortCurrency(istValue) : '-';
  const classes = classNames({
    // 'meta-alert': alert && alert(sollValue, istValue),
  });
  return (
    <li className={classes}>
      <span
        style={{
          display: 'inline-block',
          minWidth: '24px',
          width: '24px',
          textAlign: 'left',
        }}
      >
        {text}:
      </span>
      <span
        style={{
          display: 'inline-block',
          minWidth: '50px',
          width: '50px',
          textAlign: 'right',
          color: alert && alert(sollValue) ? '#ff8a8a' : '#000',
        }}
      >
        {soll}
        {soll !== '-' ? unit : ''}
      </span>
      <span
        style={{
          display: 'inline-block',
          minWidth: '14px',
          width: '14px',
          textAlign: 'center',
        }}
      >
        |
      </span>
      <span
        style={{
          display: 'inline-block',
          minWidth: '50px',
          width: '50px',
          textAlign: 'left',
          color: alert && alert(istValue) ? '#ff8a8a' : '#000',
        }}
      >
        {ist}
        {ist !== '-' ? unit : ''}
        {a}
      </span>
    </li>
  );
};

export default MetaTag;
