import './Filter.scss';

import {
  Comparator,
  FILTER_OPERATOR_ALL,
  FILTER_OPERATOR_DEFAULT,
  FILTER_OPERATOR_EMPTY,
  FILTER_OPERATOR_EQ_BOOL,
  FilterComparator,
  comparatorFromString,
} from './Filter';
import React, { Component, Fragment } from 'react';

import CheckInput from '../Form/CheckInput';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import Modal from 'react-modal';
import { TabledHeaderFieldWithFilter } from '../Planing/Steps/createLocationTableHeader';
import { withWidth } from '../helper/modalCustomStyles';
import { uniq } from 'lodash';

const customStyles = withWidth('95%', '860px');

Modal.setAppElement('#root');

type Mode = 'EXPR' | 'SELECTION';

interface Filter {
  value: string;
  comparator: string;
}

interface FilterOptComparator {
  value: string;
  comparator?: string;
}

const testWithoutPartial = (f: { value?: string; comparator?: string }) => {
  const isEmptyOperator = f.comparator === FILTER_OPERATOR_EMPTY[0].comparator;
  const isInfinityOperator = f.comparator === Comparator['<I'];
  return (
    f &&
    f.comparator &&
    f.comparator.length > 0 &&
    ((f.value && f.value.length > 0) || isEmptyOperator || isInfinityOperator)
  );
};

const testWithPartial = (f: { value?: string; comparator?: string }) => {
  return (
    f &&
    ((f.comparator && f.comparator.length > 0) ||
      (f.value && f.value.length > 0))
  );
};

interface FilterModalProps {
  filters: Filter[];
  field?: TabledHeaderFieldWithFilter;
  values?: string[];
  onSubmitFilters?: (filters: Filter[]) => Promise<void>;
}

interface FilterModalState {
  mode: Mode;
  filters?: FilterOptComparator[];
  values?: string[];
  isOpen: boolean;
}

class FilterModal extends Component<FilterModalProps, FilterModalState> {
  state: FilterModalState = {
    mode: 'EXPR',
    filters: [],
    isOpen: false,
  };

  constructor(props: FilterModalProps) {
    super(props);
    this.state.values = this.removeDublicatesFromValues(props.values) || [];
    this.state.filters = this.addEmptyFilter(this.props.filters);
  }

  /**
   * Props changed
   * @param prevProps
   */
  componentDidUpdate(prevProps: FilterModalProps) {
    let p = {};
    if (this.props.filters !== prevProps.filters) {
      p = { ...p, value: this.addEmptyFilter(this.props.filters) };
    }
    if (this.props.values !== prevProps.values) {
      p = { ...p, values: this.removeDublicatesFromValues(this.props.values) };
    }
    if (Object.keys(p).length > 0) {
      this.setState(p);
    }
  }

  /**
   * adds an emtpy filter to an array of filters
   * @param filters
   */
  addEmptyFilter(filters: Filter[] = []): FilterOptComparator[] {
    return [
      ...filters,
      {
        value: '',
        comparator: undefined,
      },
    ];
  }

  filtersWithEmptyFilter(allowPartial = false): FilterOptComparator[] {
    const cleanedFilters = this.cleanedFilters(allowPartial);
    return this.addEmptyFilter(cleanedFilters);
  }

  /**
   * return a array of unique strings
   * @param values
   */
  removeDublicatesFromValues(values: string[] = []) {
    return uniq(values);
  }

  handleFilterChange(
    index: number,
    value: string | undefined,
    comparator: Comparator | undefined
  ) {
    const filters = this.state.filters || [];

    if (filters && filters[index] && !filters[index]) {
      const defaultOperator = this.getFirstOperator(this.props.field!);
      filters[index] = {
        value: '',
        comparator: defaultOperator.comparator as string,
      };
    }

    // set the default comparator if no comparator is set already or the comparator will be set
    if (
      comparator === undefined &&
      filters[index] &&
      !filters[index].comparator
    ) {
      // console.log(`no operator set for ${index}`);
      const defaultComparator = this.getFirstOperator(this.props.field!);
      filters[index].comparator = defaultComparator.comparator as string;
    } else if (comparator !== undefined) {
      filters[index]!.comparator = comparator as string;
    }

    // set the default value if no value is set already
    if (
      value === undefined &&
      filters &&
      filters[index] &&
      !filters[index].value
    ) {
      // console.log(`no value set for ${index}`);
      filters[index].value = '';
    } else if (value !== undefined) {
      filters[index].value = value.trim();
    }

    this.setState({ filters: this.filtersWithEmptyFilter(true) });
  }

  /**
   * Render dropdown
   * @param filter
   * @param index
   */
  renderComparatorSelect(
    filter: FilterOptComparator | undefined,
    index: number
  ) {
    const filterComparators =
      this.props.field?.filterComparators || FILTER_OPERATOR_ALL;
    return (
      <select
        disabled={this.state.mode !== 'EXPR'}
        value={filter?.comparator}
        onChange={(e) => {
          const c = comparatorFromString(e.target.value);
          this.handleFilterChange(index, filter?.value, c);
        }}
        name='name'
        size={1}
      >
        <option value={undefined}>Eine Option auswählen</option>
        {filterComparators.map((opt: FilterComparator, index: number) => (
          <option key={index} value={opt.comparator as string}>
            {opt.title}
          </option>
        ))}
      </select>
    );
  }

  /**
   * Render text input
   * @param filter
   * @param index
   */
  renderValueInput(filter: FilterOptComparator | undefined, index: number) {
    return (
      <input
        disabled={this.state.mode !== 'EXPR'}
        value={filter?.value}
        type='text'
        name='name'
        onChange={(e) => {
          this.handleFilterChange(
            index,
            e.target.value,
            comparatorFromString(filter?.comparator)
          );
        }}
      />
    );
  }

  /**
   * Render boolean input
   * @param filter
   * @param index
   */
  renderBooleanInput(filter: FilterOptComparator | undefined, index: number) {
    return (
      <select
        disabled={this.state.mode !== 'EXPR'}
        value={filter?.value}
        onChange={(e) => {
          this.handleFilterChange(
            index,
            e.target.value,
            comparatorFromString(filter?.comparator)
          );
        }}
        name='name'
        size={1}
      >
        <option value={undefined}>Eine Option auswählen</option>
        <option key={index} value={'selected'}>
          Ausgewählt
        </option>
      </select>
    );
  }

  /**
   * Render empty
   * @param filter
   * @param index
   */
  renderEmptyInput() {
    return (
      <span></span>
      // <select
      //   disabled={this.state.mode !== 'EXPR'}
      //   value={filter?.value}
      //   onChange={(e) => {
      //     this.handleFilterChange(
      //       index,
      //       e.target.value,
      //       comparatorFromString(filter?.comparator)
      //     );
      //   }}
      //   name='name'
      //   size={1}
      // >
      //   <option value={undefined}>Eine Option auswählen</option>
      //   <option key={index} value={FILTER_OPERATOR_EQ_BOOL[0].comparator}>
      //     Ausgewählt
      //   </option>
      // </select>
    );
  }

  /**
   *
   * @param field
   */
  getFirstOperator(field: TabledHeaderFieldWithFilter): FilterComparator {
    const filterOptions = field.filterComparators;
    if (!filterOptions || filterOptions.length === 0) {
      return FILTER_OPERATOR_DEFAULT;
    } else {
      return field.filterComparators![0];
    }
  }

  /**
   * Set filters to one empty filter
   */
  reset() {
    this.setState({
      mode: 'EXPR',
      filters: [
        {
          value: '',
          comparator: undefined,
        },
      ],
    });
  }

  async submit() {
    let filters: {
      value: string;
      comparator: string;
    }[] = [];
    if (this.state.mode === 'EXPR') {
      filters = this.cleanedFilters();
    } else if (this.state.mode === 'SELECTION') {
      filters = this.equalFilters();
    }
    if (this.props.onSubmitFilters) await this.props.onSubmitFilters(filters);
    this.setState({
      isOpen: false,
    });
  }

  /**
   * The modal is valid if all filters are valid, except the last filter which is the empty new filter.
   */
  isValid() {
    const cleanedFilters = this.cleanedFilters();
    const rawFilters = this.state.filters || [];
    return (
      rawFilters.length > 0 && cleanedFilters.length === rawFilters.length - 1
    );
  }

  cleanFilters(filters: FilterOptComparator[], allowPartial = false) {
    return filters.filter((f) =>
      allowPartial ? testWithPartial(f) : testWithoutPartial(f)
    ) as any;
  }

  /**
   * Returns a list with all valid filters
   * @param allowPartial allow filters with value or comparator
   */
  cleanedFilters(allowPartial = false): Filter[] {
    if (!this.state.filters) return [];
    return this.cleanFilters(this.state.filters, allowPartial);
  }

  equalFilters(): Filter[] {
    if (!this.state.filters) return [];
    return this.state.filters.filter(
      (f) =>
        f &&
        f.comparator &&
        f.comparator.length > 0 &&
        f.comparator === this.props.field?.equalComparator &&
        f.value &&
        f.value.length > 0
    ) as any;
  }

  renderFilter(filter: FilterOptComparator, index: number) {
    return (
      <div key={index} className='row'>
        <div className='col-6'>
          {this.renderComparatorSelect(filter, index)}
        </div>
        <div className='col-6'>
          {filter.comparator !== FILTER_OPERATOR_EQ_BOOL[0].comparator &&
            filter.comparator !== FILTER_OPERATOR_EQ_BOOL[1].comparator &&
            filter.comparator !== FILTER_OPERATOR_EMPTY[0].comparator &&
            filter.comparator !== Comparator['<I'] &&
            this.renderValueInput(filter, index)}
          {(filter.comparator === FILTER_OPERATOR_EQ_BOOL[0].comparator ||
            filter.comparator === FILTER_OPERATOR_EQ_BOOL[1].comparator) &&
            filter.comparator !== Comparator['<I'] &&
            this.renderBooleanInput(filter, index)}
          {(filter.comparator === FILTER_OPERATOR_EMPTY[0].comparator ||
            filter.comparator === Comparator['<I']) &&
            this.renderEmptyInput()}
        </div>
        <div className='col-12'>
          <div className='separator'>Oder</div>
        </div>
      </div>
    );
  }

  getValueForSelectionFilter(value: string) {
    const filter = this.state.filters?.find((f) => f.value === value);
    if (!filter) return false;
    if (filter.comparator === this.props.field?.equalComparator) return true;
    if (filter.comparator === this.props.field?.notEqualComparator)
      return false;
  }

  renderSelectionFilter(value: string, index: number) {
    return (
      <div key={index} className='col-6'>
        <CheckInput
          title={value}
          disabled={this.state.mode !== 'SELECTION'}
          value={this.getValueForSelectionFilter(value)}
          callback={(checked: boolean) => {
            this.handleFilterChange(
              index,
              value,
              checked
                ? this.props.field?.equalComparator
                : this.props.field?.notEqualComparator
            );
          }}
        />
      </div>
    );
  }

  handleModeChange(mode: Mode) {
    let filters: FilterOptComparator[] = [];

    if (mode === 'EXPR') {
      // mode changed to expression, so set an empty filter
      filters = [
        {
          value: '',
          comparator: undefined,
        },
      ];
    } else if (mode === 'SELECTION') {
      // mode changed to selection, so create a filter for each value
      filters = (this.state.values || []).map((value) => {
        return { value, comparator: this.props.field?.notEqualComparator };
      });
    }

    this.setState({ mode, filters });
  }

  renderInner() {
    return (
      <div className='modal table-filter-modal' tabIndex={-1} role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Filter für <b>{this.props.field?.title}</b>
              </h5>
            </div>
            <div className='modal-body'>
              <div>
                {/* <div className='row'>
                  <div className='col-12' style={{ textAlign: 'center' }}>
                    <input
                      type='radio'
                      id='mc'
                      name='Art'
                      value='EXPR'
                      checked={this.state.mode === 'EXPR'}
                      onChange={() => this.handleModeChange('EXPR')}
                    />
                    <label htmlFor='mc'>Regel</label>
                  </div>
                </div> */}
                <div
                  style={{
                    maxHeight: '120px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {this.state.mode === 'EXPR' &&
                    this.state.filters?.map((f, index) =>
                      this.renderFilter(f, index)
                    )}
                </div>
              </div>
              {/* <hr />
              <div>
                <div className='row'>
                  <div className='col-12' style={{ textAlign: 'center' }}>
                    <input
                      type='radio'
                      id='mc'
                      name='Art'
                      value='SELECTION'
                      disabled={!this.props.field?.suggestion}
                      checked={this.state.mode === 'SELECTION'}
                      onChange={() => this.handleModeChange('SELECTION')}
                    />
                    <label htmlFor='mc'>Auswahl</label>
                  </div>
                </div>
                {this.state.mode === 'SELECTION' && (
                  <div
                    className='row'
                    style={{
                      maxHeight: '120px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    {this.state.values?.map((v, index) =>
                      this.renderSelectionFilter(v, index)
                    )}
                  </div>
                )}
              </div> */}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline-danger'
                onClick={() => this.reset()}
              >
                Löschen
              </button>
              <div>
                <button
                  type='button'
                  className='btn btn-outline-secondary'
                  style={{ marginRight: '16px' }}
                  data-dismiss='modal'
                  onClick={() =>
                    this.setState({
                      isOpen: false,
                    })
                  }
                >
                  Schließen
                </button>
                <button
                  disabled={!this.isValid()}
                  type='button'
                  className='btn btn-outline-primary'
                  onClick={() => this.submit()}
                >
                  Anwenden
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <span
          onClick={() =>
            this.setState({
              isOpen: true,
            })
          }
        >
          <FilterIcon />
        </span>
        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={() => this.setState({ isOpen: false })}
          style={customStyles}
          contentLabel=''
        >
          {this.renderInner()}
        </Modal>
      </Fragment>
    );
  }
}

export default FilterModal;
