import './EditableInput.scss';

import React, { Component, Fragment } from 'react';

import { ReactComponent as CancelIcon } from '../../assets/icons/no.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { FormConsumer } from '../../context/FormContext';
import NumberFormat from 'react-number-format';
import PasswordStrengthBar from 'react-password-strength-bar';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { v4 as uuid } from 'uuid';

const zxcvbnConfig = {
  minLength: 6,
};

class EditableInput extends Component {
  elementID = uuid();

  state = {
    editing: false,
    value: '',
    oldValue: '',
    valid: true,
    readOnly: false,
    highlight: false,
  };

  constructor(props) {
    super(props);
    if (!isNil(props.value)) this.state.value = props.value;
    if (props.readOnly) this.state.readOnly = props.readOnly;
    if (props.highlight) this.state.highlight = props.highlight;
    this.handleChange = this.handleChange.bind(this);
    this.startEdit = this.startEdit.bind(this);
    this.stopEdit = this.stopEdit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      value: isNil(newProps.value) ? '' : newProps.value,
      oldValue: isNil(newProps.value) ? '' : newProps.value,
      readOnly: newProps.readOnly || false,
      highlight: newProps.highlight || false,
    });
  }

  handleChange(event) {
    const valid = this.props.validate
      ? this.props.validate(event.target.value) === true
      : true;
    this.setState({ value: event.target.value, valid });
  }

  handleChangeNumberFormat(event) {
    const valid = this.props.validate
      ? this.props.validate(event.floatValue) === true
      : true;
    this.setState({ value: event.floatValue, valid });
  }

  startEdit() {
    if (this.props.type === 'password') {
      this.setState({ editing: true, valid: false, value: '' });
    } else {
      const valid = this.props.validate
        ? this.props.validate(this.state.value) === true
        : true;
      this.setState({ editing: true, valid });
    }
    // setTimeout(() => this.nameInput.focus(), 25);
  }

  stopEdit(save) {
    if (save && this.state.valid) {
      this.setState({ oldValue: this.state.value, editing: false });
      this.props.callback(this.state.value);
    } else {
      this.setState({ value: this.state.oldValue, editing: false });
    }
  }

  _getInputCurrency(state) {
    const { value, editing } = state;
    return (
      <NumberFormat
        ref={(input) => {
          this.nameInput = input;
        }}
        id={`editable-input-${this.elementID}`}
        thousandSeparator='.'
        decimalSeparator=','
        value={value}
        placeholder={this.props.defaultValue}
        disabled={!editing}
        prefix=''
        suffix='€'
        onValueChange={(values) => this.handleChangeNumberFormat(values)}
      />
    );
  }

  _getInputPercentage(state) {
    const { value, editing } = state;
    return (
      <NumberFormat
        ref={(input) => {
          this.nameInput = input;
        }}
        id={`editable-input-${this.elementID}`}
        thousandSeparator='.'
        decimalSeparator=','
        value={value}
        placeholder={this.props.defaultValue}
        disabled={!editing}
        prefix=''
        suffix='%'
        decimalScale={0}
        onValueChange={(values) => this.handleChangeNumberFormat(values)}
      />
    );
  }

  _getInputMonths(state) {
    const { value, editing } = state;
    return (
      <NumberFormat
        ref={(input) => {
          this.nameInput = input;
        }}
        id={`editable-input-${this.elementID}`}
        thousandSeparator='.'
        decimalSeparator=','
        value={value}
        placeholder={this.props.defaultValue}
        disabled={!editing}
        prefix=''
        suffix=' Monate'
        decimalScale={0}
        onValueChange={(values) => this.handleChangeNumberFormat(values)}
      />
    );
  }

  _getInputHours(state) {
    const { value, editing } = state;
    return (
      <NumberFormat
        ref={(input) => {
          this.nameInput = input;
        }}
        id={`editable-input-${this.elementID}`}
        thousandSeparator='.'
        decimalSeparator=','
        value={value}
        placeholder={this.props.defaultValue}
        disabled={!editing}
        prefix=''
        suffix=' Stunden'
        decimalScale={2}
        onValueChange={(values) => this.handleChangeNumberFormat(values)}
      />
    );
  }

  _getInputNumber(state) {
    const { value, editing } = state;
    return (
      <NumberFormat
        ref={(input) => {
          this.nameInput = input;
        }}
        id={`editable-input-${this.elementID}`}
        thousandSeparator='.'
        decimalSeparator=','
        value={value}
        placeholder={this.props.defaultValue}
        disabled={!editing}
        prefix=''
        suffix=''
        decimalScale={0}
        onValueChange={(values) => this.handleChangeNumberFormat(values)}
      />
    );
  }

  _getInputDefault(state) {
    const { value, editing } = state;
    return (
      <input
        ref={(input) => {
          this.nameInput = input;
        }}
        id={`editable-input-${this.elementID}`}
        placeholder={this.props.defaultValue}
        disabled={!editing}
        type={this.props.type || 'text'}
        name='name'
        value={value}
        onChange={this.handleChange}
      />
    );
  }

  renderPass() {
    const { type } = this.props;
    const { value, editing } = this.state;
    if (type === 'password') {
      return (
        editing && (
          <PasswordStrengthBar
            style={{ maxWidth: '50%', left: '50%', opacity: 1 }}
            minLength={zxcvbnConfig.minLength}
            scoreWords={['schwach', 'schwach', 'ok', 'gut', 'stark']}
            shortScoreWord='zu kurz'
            password={value}
          />
        )
      );
    }
  }

  render() {
    const { title, type } = this.props;
    const { valid, editing, readOnly, highlight } = this.state;
    const cn = classNames('editable-input', {
      editing: editing,
      readOnly: readOnly,
      highlight: highlight,
    });
    return (
      <FormConsumer>
        {({ setEditing }) => (
          <Fragment>
            <div className={cn}>
              <label htmlFor={`editable-input-${this.elementID}`}>
                {title}
              </label>
              {type === 'currency' ? this._getInputCurrency(this.state) : null}
              {type === 'percentage'
                ? this._getInputPercentage(this.state)
                : null}
              {type === 'months' ? this._getInputMonths(this.state) : null}
              {type === 'hours' ? this._getInputHours(this.state) : null}
              {type === 'number' ? this._getInputNumber(this.state) : null}
              {type !== 'currency' &&
              type !== 'percentage' &&
              type !== 'months' &&
              type !== 'number' &&
              type !== 'hours'
                ? this._getInputDefault(this.state)
                : null}
              {readOnly !== true ? (
                <div className='actions'>
                  {!editing ? (
                    <EditIcon
                      className='edit'
                      onClick={() => {
                        setEditing(true);
                        this.startEdit();
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {editing ? (
                    <CheckIcon
                      className='save'
                      disabled={!valid}
                      onClick={() => {
                        setEditing(false);
                        this.stopEdit(true);
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {editing ? (
                    <CancelIcon
                      className='cancel'
                      onClick={() => {
                        setEditing(false);
                        this.stopEdit(false);
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            {this.renderPass()}
          </Fragment>
        )}
      </FormConsumer>
    );
  }
}

export default EditableInput;
