import React, { FunctionComponent } from 'react';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';

import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';

const { Dragger } = Upload;

interface ImportStepMasterfileProps {
  onMasterfile: (file: File) => void;
}

export const ImportStepMasterfile: FunctionComponent<
  ImportStepMasterfileProps
> = ({ onMasterfile }) => {
  const props: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    customRequest: () => {
      // console.log("customRequest");
    },
    onChange(info: UploadChangeParam<UploadFile<any>>) {
      if (info && info.file) onMasterfile(info.file.originFileObj as File);
    },
  };

  return (
    <div className='row'>
      <div className='col col-12'>
        <Dragger {...props}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            Klicken oder ziehen Sie eine Datei zum Hochladen in diesen Bereich.
          </p>
          <p className='ant-upload-hint'>SafePlan Masterfile im xlsx Format</p>
        </Dragger>
      </div>
    </div>
  );
};
