import React, { FunctionComponent, useState } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import {
  HomeAccommodationJob,
  HotelJob,
  Job,
  JOB_TYPE,
  NewJob,
} from '../../../context/Route';
import showLocationSelector from '../../LocationSelector';
import { DateTime } from 'luxon';
import { ClipLoader } from 'react-spinners';
import { message } from 'antd';
import { addJob } from './utils/add-job';

interface AddJobProps {
  before?: Job | HotelJob | HomeAccommodationJob;
  onChanged?: () => void;
}

const AddJob: FunctionComponent<AddJobProps> = ({ before, onChanged }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    if (!before || !before.planedAt) {
      return;
    }

    try {
      const l =
        typeof before.planedAt === 'string'
          ? DateTime.fromISO(before.planedAt)
          : DateTime.fromJSDate(before.planedAt!);

      const planningWeek = l.startOf('week');

      const location = await showLocationSelector(planningWeek.toJSDate());
      if (location) {
        const job: NewJob = {
          sort: before.sort,
          type: JOB_TYPE.Location,
          planedAt: l.plus({ minutes: 10 }).toISO(),
          location,
        };

        await addJob(job, before.tour!);

        onChanged && onChanged();
      }
    } catch (error: any) {
      message.error('Fehler beim Hinzufügen des Jobs');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <ClipLoader size={'1rem'} color={'#009842'} loading={true} />;
  }

  return <AddIcon onClick={() => handleClick()} />;
};

export default AddJob;
