import React, { FunctionComponent } from 'react';
import {
  PlanningStepLocationsOptions,
  getRemainingOrderValueFromString,
  getTitleFromRemainingOrderValue,
  RemainingOrderValue,
} from '../PlanningStepLocations';
import Filter from '../../../Tours/Filter';
import { get } from 'lodash';

interface RemainingOrderValueFilterProps {
  options: PlanningStepLocationsOptions;
  onChange: (options: PlanningStepLocationsOptions) => void;
  disabled?: boolean;
  direction?: 'up' | 'down';
}

const RemainingOrderValueFilter: FunctionComponent<
  RemainingOrderValueFilterProps
> = ({ options, onChange, disabled, direction }) => {
  const value = get(options, 'remainingOrderValue', RemainingOrderValue.Ignore);
  return (
    <Filter
      size='12rem'
      disabled={disabled}
      name='remainingOrderValue'
      options={options}
      multiple={false}
      required={true}
      direction={direction}
      values={[
        {
          key: getRemainingOrderValueFromString(RemainingOrderValue.Ignore),
          value: RemainingOrderValue.Ignore,
          text: getTitleFromRemainingOrderValue(RemainingOrderValue.Ignore),
        },
        {
          key: getRemainingOrderValueFromString(
            RemainingOrderValue.PayAttention
          ),
          value: RemainingOrderValue.PayAttention,
          text: getTitleFromRemainingOrderValue(
            RemainingOrderValue.PayAttention
          ),
        },
        {
          key: getRemainingOrderValueFromString(RemainingOrderValue.Only),
          value: RemainingOrderValue.Only,
          text: getTitleFromRemainingOrderValue(RemainingOrderValue.Only),
        },
      ]}
      onChange={onChange}
    >
      {getTitleFromRemainingOrderValue(value)}
    </Filter>
  );
};

export default RemainingOrderValueFilter;
