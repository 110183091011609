import React from 'react';
import classNames from 'classnames';

export default function RelSettingLineHeader() {
  return (
    <div
      className={classNames('row', 'import-settings-row')}
      style={{
        padding: '16px 0',
        margin: '9px 0',
        background: '#ffffff',
        borderRadius: ' 3px',
      }}
    >
      <div className='col col-3'>Zelle</div>
      <div className='col col-3'>Quelle</div>
      <div className='col col-3'>Feld</div>
    </div>
  );
}
