import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import './Map.scss';

import MapGL, {
  FlyToInterpolator,
  Marker,
  NavigationControl,
} from 'react-map-gl';
import React, { Component } from 'react';
import { differenceWith, filter } from 'lodash';

import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { JOB_TYPE } from '../../context/Route';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import Pin from './Pin';
import { PlanningContext } from '../../context/PlanningContext';
import WebMercatorViewport from 'viewport-mercator-project';
import directionsStyle from './directions_style';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import { PlanningConsumer } from '../../context/PlanningContext';
import classNames from 'classnames';

const MC = styled.span`
  font-size: 1rem;
  position: absolute;
  padding: 0.25rem;
  border-width: 0.25rem;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: inherit;
  left: 2rem;
  background: #ffffffaa;
  aspect-ratio: 1;
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
`;

const fallbackCoordinates = [10.447683333333, 51.163375];
const fallbackViewport = {
  latitude: fallbackCoordinates[1],
  longitude: fallbackCoordinates[0],
  zoom: 5,
  bearing: 0,
  pitch: 0,
};

const navStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '10px',
};

class Trip extends Component {
  routeInfo = {
    distance: null,
    duration: null,
  };

  constructor(props) {
    super(props);

    const { marker } = this.props;

    const filteredMarker = filter(marker, (m) => m.longitude && m.latitude);

    this.state = {
      viewport: this.calculateViewport(filteredMarker, fallbackViewport),
      marker: marker || [],
    };

    this._onMapLoad = this._onMapLoad.bind(this);
    this._renderMarker = this._renderMarker.bind(this);

    this._map = React.createRef();

    this.directions = new MapboxDirections({
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      profile: 'mapbox/driving',
      unit: 'metric',
      interactive: false,
      styles: directionsStyle,
    });

    // if (updateContext !== false) {
    //   this.directions.on("route", this._onDirectionRoute.bind(this));
    // }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // console.log("UNSAFE_componentWillReceiveProps");

    const { marker } = newProps;

    if (
      differenceWith(marker, this.state.marker, (a, b) => {
        return (
          a.index === b.index &&
          a.longitude === b.longitude &&
          a.latitude === b.latitude
        );
      }).length > 0
    ) {
      // // console.log("_setWaypointForDirections");
      const filteredMarker = filter(marker, (m) => m.longitude && m.latitude);
      this._setWaypointForDirections(filteredMarker);

      this.setState({
        marker,
        viewport: this.calculateViewport(filteredMarker, fallbackViewport),
      });
    }
  }

  componentDidMount() {
    const map = this.getMap();
    if (map) map.addControl(this.directions, 'bottom-left');
  }

  getMap() {
    return this._map && this._map.current ? this._map.current.getMap() : null;
  }

  getMapSize() {
    return {
      width: this._map.current._width,
      height: this._map.current._height,
    };
  }

  getRouteInfo() {
    return this.routeInfo;
  }

  calculateViewport(marker, currentViewport) {
    try {
      const bounds = new mapboxgl.LngLatBounds();
      const map = this.getMap();

      if (map && marker && marker.length > 0) {
        marker.forEach((m) => bounds.extend([m.longitude, m.latitude]));
      } else {
        return currentViewport;
      }

      const mapSize = this.getMapSize();

      const { longitude, latitude, zoom } = new WebMercatorViewport({
        ...currentViewport,
        ...mapSize,
      }).fitBounds(
        [
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()],
        ],
        {
          padding: 88,
        }
      );

      return {
        ...currentViewport,
        longitude,
        latitude,
        zoom,
        transitionDuration: 1200,
        transitionInterpolator: new FlyToInterpolator(),
      };
    } catch (error) {
      console.error(error);
      return currentViewport;
    }
  }

  _onDirectionRoute(e) {
    const { onDidCalculateRoute } = this.props;
    const routes = e.route;
    this.context && this.context.setInfo(routes[0]);
    onDidCalculateRoute && onDidCalculateRoute(routes[0]);
  }

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  _setWaypointForDirections(marker) {
    this.directions.removeRoutes();
    if (marker && marker.length > 0 && marker.length < 50) {
      this.directions.setOrigin([marker[0].longitude, marker[0].latitude]);
      this.directions.setDestination([marker[0].longitude, marker[0].latitude]);
      for (let i = 1; i < marker.length; i++) {
        this.directions.addWaypoint(i - 1, [
          marker[i].longitude,
          marker[i].latitude,
        ]);
      }
    }
  }

  _onMapLoad() {
    // console.log("_onMapLoad");

    const { marker } = this.state;

    const filteredMarker = filter(marker, (m) => m.longitude && m.latitude);
    this._setWaypointForDirections(filteredMarker);

    this.setState({
      viewport: this.calculateViewport(filteredMarker, fallbackViewport),
    });
  }

  _getMarkerContent(_marker) {
    // console.log(_marker.type);
    if (_marker.type === JOB_TYPE.Home) return <HomeIcon />;
    if (_marker.type === JOB_TYPE.HomeAccommodation) return <HomeIcon />;
    return <MC>{_marker.index}</MC>;
  }

  _renderMarker(_marker, highlight) {
    if (!_marker.longitude || !_marker.latitude)
      return <div key={_marker.index} />;

    const hasHightlight = highlight.length > 0;
    const highlighted = hasHightlight && _marker._id === highlight;
    const c =
      (hasHightlight && highlighted) || !hasHightlight ? 'active' : 'disabled';

    return (
      <Marker
        key={_marker.index}
        longitude={_marker.longitude}
        latitude={_marker.latitude}
        offsetTop={-30}
        offsetLeft={-15}
        className={classNames('marker', {
          highlight: highlighted,
        })}
      >
        <Pin id={_marker._id} color={c}>
          {this._getMarkerContent(_marker)}
        </Pin>
      </Marker>
    );
  }

  render() {
    const { viewport, marker } = this.state;
    const { style } = this.props;
    return (
      <React.Fragment>
        <MapGL
          reuseMaps={false}
          ref={this._map}
          {...viewport}
          width={style && style.width ? style.width : '100%'}
          height={style && style.height ? style.height : '100%'}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          mapStyle='mapbox://styles/notionug/cju5ioxp50sjc1fqm0z88bzyq?optimize=true'
          onViewportChange={this._updateViewport}
          onLoad={this._onMapLoad}
        >
          <PlanningConsumer>
            {({ highlight }) =>
              marker.map((m) => this._renderMarker(m, highlight))
            }
          </PlanningConsumer>
          <div className='nav' style={navStyle}>
            <NavigationControl onViewportChange={this._updateViewport} />
          </div>
        </MapGL>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    const map = this.getMap();
    if (map) {
      map.removeControl(this.directions);
      this.directions = null;
      this._map = null;
    }
  }
}

Trip.contextType = PlanningContext;

export default Trip;
