import './RelSettings.scss';

import { Field, Line } from './fieldsDefault';

import { Button } from 'antd';
import React from 'react';
import RelSettingLineHeader from './RelSettingLineHeader';
import { LoadingTrigger } from './RelSettings';
import { SortableList } from './SortableList';

interface RelSettingSectionProps {
  loading: LoadingTrigger;
  lines: Line[];
  fields: { [group: string]: Field[] };
  text: string;
  title: string;
  setLinesAndValidate: (lines: Line[]) => void;
  setLines: (lines: Line[]) => void;
}

export default function RelSettingSection({
  loading,
  lines,
  fields,
  title,
  text,
  setLinesAndValidate,
}: RelSettingSectionProps) {
  const getEmptyLine = () => {
    return {
      _id: `new-${new Date().getTime()}`,
      target: '',
      source: '',
    };
  };

  const addLine = () => {
    setLinesAndValidate([...lines, getEmptyLine()]);
  };

  const sortLines = () => {
    const sorted = lines.sort((a, b) => {
      if (a.target.length === 1 && b.target.length > 1) {
        return -1;
      }
      if (b.target.length === 1 && a.target.length > 1) {
        return 1;
      }
      return a.target.localeCompare(b.target);
    });

    setLinesAndValidate(sorted);
  };

  return (
    <>
      <h3>{title}</h3>
      <p className='info'>{text}</p>
      <Button onClick={() => sortLines()}>Sortieren</Button>
      <RelSettingLineHeader />
      {lines && lines.length > 0 && (
        <SortableList
          onChange={(index, l) => {
            if (l) {
              // replace line at index with new line
              setLinesAndValidate([
                ...lines.slice(0, index),
                l,
                ...lines.slice(index + 1),
              ]);
            } else {
              setLinesAndValidate([
                ...lines.slice(0, index),
                ...lines.slice(index + 1),
              ]);
            }
          }}
          loading={loading > LoadingTrigger.not}
          lines={lines}
          fields={fields}
        />
      )}
      <Button onClick={() => addLine()}>Zeile einfügen</Button>
    </>
  );
}
