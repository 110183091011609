import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-circle.svg';
import { ReactComponent as CheckDateIcon } from '../../../../assets/icons/date.svg';
import { ReactComponent as FailedIcon } from '../../../../assets/icons/fail-circle.svg';
import { ReactComponent as FailedDateIcon } from '../../../../assets/icons/date-cross.svg';

import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';

import { ReactComponent as DateIcon } from '../../../../assets/icons/calender.svg';
import { ReactComponent as DateWarningIcon } from '../../../../assets/icons/calender.svg';

export enum Status {
  neutral = 'neutral',
  success = 'success',
  warning = 'warning',
  failed = 'failed',
}

const getColorForStatus = (status: Status | undefined, bg: boolean) => {
  switch (status) {
    case Status.success:
      return `rgba(0, 152, 66, ${bg ? 0.15 : 1})`;
    case Status.warning:
      return `rgba(255, 152, 0, ${bg ? 0.15 : 1})`;
    case Status.failed:
      return `rgba(255, 0, 0, ${bg ? 0.15 : 1})`;
    default:
      return `rgba(152, 152, 152, ${bg ? 0.15 : 1})`;
  }
};

const getIconForStatus = (status: Status | undefined, dateMode: boolean) => {
  if (status === Status.success && dateMode) {
    return <CheckDateIcon />;
  }

  if (status === Status.success && !dateMode) {
    return <CheckIcon />;
  }

  if (status === Status.failed && dateMode) {
    return <FailedDateIcon />;
  }

  if (status === Status.failed && !dateMode) {
    return <FailedIcon />;
  }

  if (status === Status.warning && dateMode) {
    return <DateWarningIcon />;
  }

  if (status === Status.warning && !dateMode) {
    return <WarningIcon />;
  }

  if (dateMode) {
    return <DateIcon />;
  }

  return false;
};

interface StatusActionProps {
  state: Status | undefined;
  children: React.ReactNode;
  icon?: React.ReactNode;
  mode?: 'date' | undefined;
  onClick?: () => void;
  noBackground?: boolean;
  loading?: boolean;
  // onChange?: (state: Status) => void;
}

const Wrapper = styled.div<{
  disabled?: boolean;
  status: Status | undefined;
  noBackground?: boolean;
}>`
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.noBackground ? 'transparent' : getColorForStatus(props.status, true)};
  opacity: 1;
  color: ${(props) => getColorForStatus(props.status, false)};
  font-size: 12px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  user-select: none;
  display: flex;
  align-items: center;
  vertical-align: middle;
  // width: max-content;
  svg {
    opacity: 1;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    g,
    p,
    path {
      fill: ${(props) => getColorForStatus(props.status, false)};
    }
  }
`;

const StatusAction: FunctionComponent<StatusActionProps> = ({
  state,
  mode,
  children,
  icon,
  onClick,
  noBackground,
  loading,
}) => {
  return (
    <Wrapper
      noBackground={noBackground}
      disabled={loading || onClick === undefined}
      status={state}
      onClick={onClick}
    >
      {icon === undefined && getIconForStatus(state, mode === 'date')}
      {!loading && icon}
      {loading ? <div>Bitte warten...</div> : children}
    </Wrapper>
  );
};

export default StatusAction;
