import { Input, Modal } from 'antd';

import React from 'react';

const { confirm } = Modal;
const { TextArea } = Input;

export default function showModalInput(
  initalValue: string | undefined,
  title: string
): Promise<string | undefined> {
  let value = initalValue;
  return new Promise<string | undefined>((resolve) => {
    confirm({
      title,
      okText: 'Speichern',
      cancelText: 'Schließen',
      content: (
        <TextArea
          defaultValue={value}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            value = e.target.value;
          }}
          rows={4}
        />
      ),
      onOk() {
        resolve(value);
      },
      onCancel() {
        resolve(undefined);
      },
    });
  });
}
