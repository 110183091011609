import { get, isNil, isNumber } from 'lodash';

import { DateTime } from 'luxon';
import { EMAIL_REGEXP } from '../regex';

function validateOperatingExpenses(value) {
  return !isNil(value) && isNumber(value) && value >= 0;
}

function validatePrice(value) {
  const r = !isNil(value) && isNumber(value) && value >= 0;
  return r;
}

function validateInterval(value) {
  return !isNil(value) && isNumber(value) && value > 0;
}

function validateDue(value, allowPast = true) {
  if (isNil(value)) return false;
  const d =
    typeof value === 'string'
      ? DateTime.fromFormat(value, 'yyyy-MM-dd')
      : DateTime.fromJSDate(value).setLocale('de');
  if (!d.isValid) return false;
  if (!allowPast && d < DateTime.local()) return false;
  return true;
}

function validateCheckDepth(value) {
  return !isNil(value) && isNumber(value) && value >= 0;
}

function _validateTask(item, key, func, required = true) {
  const value = get(item, key);
  const n = isNil(value);
  if (required) {
    return !n && func(value);
  } else {
    return n || (!n && func(value));
  }
}

function validateTask(task, item) {
  const key = task.key;
  const isActive = get(item, `defaultTasks.${key}`);
  let result = true;
  if (isActive) {
    result &= _validateTask(
      item,
      `operatingExpenses.${key}`,
      validateOperatingExpenses,
      true
    );
    result &= _validateTask(item, `price.${key}`, validatePrice, true);
    result &= _validateTask(
      item,
      `interval.${key}`,
      validateInterval,
      task.extended
    );
    result &= _validateTask(item, `due.${key}`, validateDue, false);
    result &= _validateTask(
      item,
      `checkDepth.${key}`,
      validateCheckDepth,
      !!task.checkDepth
    );
  }
  return result;
}

function validateEMail(value) {
  return !isNil(value) && EMAIL_REGEXP.exec(value) !== null;
}

export {
  validateEMail,
  validateTask,
  validateOperatingExpenses,
  validatePrice,
  validateInterval,
  validateDue,
  validateCheckDepth,
};
