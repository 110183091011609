import { useField } from 'formik';
import React, { FunctionComponent } from 'react';
import { Tour } from '../../../context/Route';
import { get, isNumber } from 'lodash';
import classNames from 'classnames';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface ComparisonFieldProps {
  title: string;
  name: string;
  compare?: string;
  tour: Tour;
  suffix?: string;
  readOnly?: boolean;
  dangerForHigher?: boolean;
  dangerForLower?: boolean;
  istText?: string;
}

const ComparisonField: FunctionComponent<ComparisonFieldProps> = ({
  name,
  title,
  tour,
  compare,
  suffix,
  readOnly,
  dangerForHigher,
  dangerForLower,
  istText,
}) => {
  const [field, meta, helpers] = useField(name);

  const estimated = compare ? get(tour, compare) : 0;
  const hideEstimated = false;

  const value = field.value;

  let diff = 0;

  if (value !== 0 && estimated !== 0) {
    diff =
      (estimated && isNumber(estimated) && Number(estimated) > 0
        ? Math.round((100 / estimated) * value * 100) / 100
        : 0) - 100;
  }

  const classesForDiff = classNames('comaparison-input', {
    danger: (dangerForHigher && diff > 0) || (dangerForLower && diff < 0),
    success: (dangerForLower && diff > 0) || (dangerForHigher && diff < 0),
  });

  return (
    <>
      <div className='row table-divider'>
        <div className='col'>
          <span>{title}</span>
        </div>
      </div>
      <div className='row table-row'>
        <div className='col'>
          {' '}
          <div className={classesForDiff}>
            <div className='row'>
              <div className='col col-12 col-md-4 pr-0'>
                {!hideEstimated && (
                  <>
                    <NumberFormat
                      thousandSeparator='.'
                      decimalSeparator=','
                      value={estimated ? estimated : 0}
                      prefix=''
                      suffix={suffix ? ` ${suffix}` : undefined}
                      decimalScale={2}
                      disabled
                    />
                    <label>Plan</label>
                  </>
                )}
              </div>
              <div className='col col-12 col-md-4 pl-0 pr-2'>
                <NumberFormat
                  thousandSeparator='.'
                  decimalSeparator=','
                  value={value}
                  prefix=''
                  suffix={suffix ? ` ${suffix}` : undefined}
                  decimalScale={2}
                  disabled={readOnly}
                  onValueChange={async (values) => {
                    await helpers.setValue(values.floatValue, true);
                    await helpers.setTouched(true, true);
                  }}
                />
                <label>
                  {istText || 'Ist'}
                  {readOnly !== true && meta.touched && (
                    <span
                      onClick={async () => {
                        await helpers.setValue(estimated, true);
                        await helpers.setTouched(false, true);
                      }}
                    >
                      (überschrieben)
                    </span>
                  )}
                </label>
              </div>
              <div className='col col-12 col-md-4 pl-0 diff-col'>
                {!hideEstimated && (
                  <>
                    <NumberFormat
                      thousandSeparator='.'
                      decimalSeparator=','
                      value={diff}
                      prefix=''
                      suffix=' %'
                      decimalScale={2}
                      disabled
                    />
                    <label>Differenz</label>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparisonField;
