import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface StatusActionProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const Wrapper = styled.div<{
  disabled?: boolean;
}>`
  padding: 10px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.disabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0)'};
  opacity: 1;
  color: ${(props) =>
    props.disabled ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 1)'};
  font-size: 12px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  user-select: none;
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: max-content;
  svg {
    opacity: 1;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    g,
    p,
    path {
      fill: ${(props) =>
        props.disabled ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 1)'};
    }
  }
  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
  }
`;

const Action: FunctionComponent<StatusActionProps> = ({
  children,
  onClick,
}) => {
  return (
    <Wrapper disabled={onClick === undefined} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

export default Action;
