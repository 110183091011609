import { ReactComponent as CheckBoldIcon } from '../../../assets/icons/check-bold.svg';
import { DateTime } from 'luxon';
import LocationBlockedInfo from './LocationBlockedInfo';
import React from 'react';
import { TableCellProps } from 'react-virtualized';
import { Tooltip } from 'antd';
import { reduce } from 'lodash';
import shortCurrency from '../../../utils/shortCurrency';

export function isBlocked(blocked: any, user: any, location: any) {
  if (!blocked || !blocked.locations) return false;
  if (!user) return true;
  if (!location) return true;
  const locations = Object.keys(blocked.locations);
  const b = reduce(
    locations,
    (prev: boolean, curr: string) => {
      const { _id } = location;
      const m = curr.includes(_id);
      if (m) {
        return prev || blocked.locations[location._id] !== user.id;
      } else {
        return prev;
      }
    },
    false
  );
  return b;
}

export function isItemDisabled(blocked: any, user: any, location: any) {
  const addr = !(
    location.address &&
    location.address.street &&
    location.address.postalCode &&
    location.address.city
  );
  const planed =
    (location.allNextJobDate && location.allNextJobDate.length > 0) ||
    (location.nextJob && location.nextJob.all);
  const hasBudget =
    location.contractValueSum ||
    location.jobValueSum < location.contractValueSum;
  return (planed && !hasBudget) || addr || isBlocked(blocked, user, location);
}

export function isItemDisabledInfo(blocked: any, user: any, location: any) {
  if (isBlocked(blocked, user, location)) {
    return <LocationBlockedInfo userId={blocked?.locations[location._id]} />;
  }

  if (
    !(
      location.address &&
      location.address.street &&
      location.address.postalCode &&
      location.address.city
    )
  ) {
    return <span>Adresse ungültig</span>;
  }

  if (
    (location.allNextJobDate && location.allNextJobDate.length > 0) ||
    (location.nextJob && location.nextJob.all)
  ) {
    return <span>Standort geplant</span>;
  }

  if (
    !(
      location.contractValueSum ||
      location.jobValueSum < location.contractValueSum
    )
  ) {
    return <span>Auftrgsbudget leer</span>;
  }

  return <span></span>;
}

export function selectionCellRenderer(
  { rowData }: TableCellProps,
  isItemSelected: (id: string) => boolean,
  isItemDisabled?: (id: string) => boolean,
  isItemDisabledInfo?: (item: any) => React.ReactNode,
  handleSelectionChanged?: (item: any, value: boolean) => void
) {
  const { _id } = rowData;
  const value = isItemSelected(_id);
  const disabled = isItemDisabled && isItemDisabled(rowData);
  return (
    <div className={`table-selection ${value || false ? 'check' : ''}`}>
      <input
        type='checkbox'
        id={`table-selection-${_id}`}
        checked={value || false}
        disabled={disabled}
        onChange={(event) => {
          !disabled &&
            handleSelectionChanged &&
            handleSelectionChanged([rowData], event.target.checked);
        }}
      />
      {disabled && isItemDisabledInfo && (
        <Tooltip placement='right' title={isItemDisabledInfo(rowData)}>
          <label
            className={`check ${disabled ? 'disabled' : ''}`}
            htmlFor={`table-selection-${_id}`}
          >
            <CheckBoldIcon />
          </label>
        </Tooltip>
      )}
      {!disabled && (
        <label
          className={`check ${disabled ? 'disabled' : ''}`}
          htmlFor={`table-selection-${_id}`}
        >
          <CheckBoldIcon />
        </label>
      )}
    </div>
  );
}

export function extensionRowCellRenderer(
  rowData: any,
  isItemSelected: (id: string) => boolean,
  isItemDisabled?: (id: string) => boolean,
  isItemDisabledInfo?: (item: any) => React.ReactNode,
  handleSelectionChanged?: (item: any, value: boolean) => void
) {
  const { _id } = rowData;
  const value = isItemSelected(_id);
  const disabled = isItemDisabled && isItemDisabled(rowData);
  const isAlreadyPlaned = !!rowData.job;
  // console.log(rowData);
  return (
    <div className={`table-selection ${value || false ? 'check' : ''}`}>
      <input
        type='checkbox'
        id={`table-selection-${_id}`}
        checked={value || false}
        disabled={disabled || isAlreadyPlaned}
        onChange={(event) => {
          !disabled &&
            handleSelectionChanged &&
            handleSelectionChanged([rowData], event.target.checked);
        }}
      />
      {disabled && isItemDisabledInfo && (
        <Tooltip placement='right' title={isItemDisabledInfo(rowData)}>
          <label
            className={`check ${disabled ? 'disabled' : ''}`}
            htmlFor={`table-selection-${_id}`}
          >
            <CheckBoldIcon />
          </label>
        </Tooltip>
      )}
      {!disabled && !isAlreadyPlaned && (
        <label
          className={`check ${disabled ? 'disabled' : ''}`}
          htmlFor={`table-selection-${_id}`}
        >
          <CheckBoldIcon />
        </label>
      )}
    </div>
  );
}

export function extensionRowRender(
  item: any,
  index: any,
  columns: any,
  isItemSelected: (id: string) => boolean,
  isItemDisabled?: (id: string) => boolean,
  isItemDisabledInfo?: (item: any) => React.ReactNode,
  handleSelectionChanged?: (item: any, value: boolean) => void
) {
  return (
    <div key={index} className='table-row-inner-extension'>
      <div
        className='table-row-inner-extension-f1'
        style={{
          ...columns[0].props.style,
          textAlign: 'center',
          color: '#009842',
          fontWeight: 'bold',
          fontSize: '18px',
          paddingLeft: '24px',
        }}
      >
        <div className='table-row-inner-extension-f1-l'></div>
        {extensionRowCellRenderer(
          item,
          isItemSelected,
          isItemDisabled,
          isItemDisabledInfo,
          handleSelectionChanged
        )}
      </div>
      <div
        style={{
          ...columns[1].props.style,
          color: '#009842',
          fontWeight: 'bold',
        }}
      >
        {item.fixedDateFromAdditionalTasks ? 'Termin-Job' : 'Job'}
      </div>
      <div style={columns[2].props.style}>
        {DateTime.fromISO(item.due).toLocal().toLocaleString({
          month: '2-digit',
          day: '2-digit',
          weekday: 'short',
        })}
        {item.dueFixed && '| '}
        {item.dueFixed && DateTime.fromISO(item.due).toFormat('HH:mm')}
      </div>
      <div style={columns[3].props.style}>{item.comment}</div>
      <div style={columns[4].props.style}></div>
      <div style={columns[5].props.style}></div>
      <div style={columns[6].props.style}></div>
      <div style={columns[7].props.style}></div>
      <div
        style={{
          ...columns[8].props.style,
          color: '#cda223',
        }}
      >
        {shortCurrency(item.estimatedPrice as number, '0')} €
      </div>
      <div
        style={{
          ...columns[9].props.style,
          color: '#a1a1a1',
        }}
      >
        {shortCurrency(item.planHours as number, '0.00')}h
      </div>
      <div
        style={{
          ...columns[10].props.style,
          paddingLeft: '4px',
        }}
      >
        <div className='tasks row'>
          {Object.keys(item.type)
            .filter((k) => k !== '_id' && item.type[k])
            .map((k) => (
              <span key={k} className='task task-done col col-2'>
                {k}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
}
