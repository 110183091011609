import React, { useState } from 'react';

import { ClipLoader } from 'react-spinners';
import Popconfirm from 'antd/es/popconfirm';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';

interface TourItemDeleteProps {
  text: string;
  value: {
    _id: string;
  };
  onDelete: (id: string) => void;
}

export default function TourItemDelete(props: TourItemDeleteProps) {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Popconfirm
      className='route-step-action'
      placement='bottomRight'
      title='Sind Sie sich sicher?'
      onConfirm={() => {
        setLoading(true);
        props.onDelete(props.value._id);
      }}
      okText='Ja'
      cancelText='Nein'
    >
      {loading && (
        <>
          <div
            style={{
              paddingTop: '2px',
              paddingRight: '12px',
            }}
          >
            <ClipLoader size={24} color={'#009842'} loading={true} />
          </div>
          {props.text ? 'löschen...' : ''}
        </>
      )}
      {!loading && (
        <>
          <TrashIcon className='edit-icon' />
          {props.text}
        </>
      )}
    </Popconfirm>
  );
}
