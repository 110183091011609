import React, { Component } from 'react';

export default class PrintLayout extends Component {
  mainStyle = {};

  render() {
    return (
      <React.Fragment>
        <main role='main' style={this.mainStyle}>
          <div className='container-flex'>{this.props.children}</div>
        </main>
      </React.Fragment>
    );
  }
}
