import React, { FunctionComponent } from 'react';
import { Request } from '../../api/Request';
import { Button, message } from 'antd';

interface ReleaseProps {}

const Release: FunctionComponent<ReleaseProps> = ({}) => {
  const doRelease = async () => {
    await Request.post('/planning/release', {});
    message.success('Standorte und Techniker entsperrt!');
  };

  return (
    <div className='rel container-inner'>
      <div className='row page-content'>
        <div className='col col-12 mb-12'>
          <Button onClick={() => doRelease()}>
            Standorte und Techniker entsperren
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Release;
