import { isEmpty, reduce } from 'lodash';
import * as Yup from 'yup';
import { Job } from '../../../context/Route';

export const STATUS = [
  'not-completed-technician-sick',
  'not-made-in-time',
  'other',
  'completed',
  'not-completed',
  'not-possible',
  'technician-sick',
  'mixed',
];

function validateValues(schema: Yup.ObjectSchema) {
  return Yup.lazy((value: any) => {
    if (!isEmpty(value) && typeof value === 'object') {
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: schema,
        }),
        {}
      );
      return Yup.object().shape(newEntries);
    }
    return Yup.mixed().notRequired();
  });
}

const taskSchema = Yup.object().shape({
  status: Yup.string()
    .oneOf(STATUS, 'Bitte wählen Sie einen Status aus')
    .required('Bitte wählen Sie einen Status aus'),
  other: Yup.string().when('status', {
    is: 'other',
    then: Yup.string()
      .required('Bitte geben Sie einen Grund an')
      .min(1, 'Bitte geben Sie einen Grund an'),
    otherwise: Yup.string().nullable(), // Optional if status is not "other"
  }),
  reason: Yup.string().when('status', {
    is: (status) => status === 'not-possible',
    then: Yup.string().required('Bitte geben Sie einen Grund an'),
    otherwise: Yup.string().nullable(), // Optional when status other then 'not-possible'
  }),
  actualWorkingHours: Yup.number()
    .nullable()
    .min(0.1, 'Arbeitsstunden müssen größer als 0 sein')
    .nullable(),
});

const jobSchema = Yup.object().shape({
  status: Yup.string()
    .oneOf(STATUS, 'Bitte wählen Sie einen Status aus')
    .required('Bitte wählen Sie einen Status aus'),
  other: Yup.string()
    .when('status', {
      is: 'other',
      then: Yup.string()
        .required('Bitte geben Sie einen Grund an')
        .min(1, 'Bitte geben Sie einen Grund an'),
      otherwise: Yup.string().nullable(), // Optional if status is not "other"
    })
    .when('status', {
      is: 'mixed',
      then: Yup.string().nullable(), // No validation if status is "mixed"
    }),
  reason: Yup.string()
    .when('status', {
      is: (status) => status === 'not-possible',
      then: Yup.string().required('Bitte geben Sie einen Grund an'),
      otherwise: Yup.string().nullable(), // Optional when status other then 'not-possible'
    })
    .when('status', {
      is: 'mixed',
      then: Yup.string().nullable(), // No validation if status is "mixed"
    }),
  tasks: validateValues(taskSchema),
  actualWorkingHours: Yup.mixed().test(
    'all-tasks-have-working-hours',
    'Für alle Aufgaben müssen Arbeitsstunden angegeben werden',
    function () {
      const { tasks } = this.parent;
      if (tasks && typeof tasks === 'object') {
        const taskValues = Object.values(tasks); // Get all tasks as an array of values
        const anyTaskHasWorkingHours = taskValues.some(
          (task: any) => task.actualWorkingHours >= 0
        );
        if (anyTaskHasWorkingHours) {
          // Ensure all tasks have actualWorkingHours > 0
          return taskValues.every((task: any) => task.actualWorkingHours >= 0);
        }
      }
      return true; // Valid if no task has actualWorkingHours
    }
  ),
});

const validationSchema = Yup.object().shape({
  jobs: validateValues(jobSchema),
});

export default validationSchema;
