import { isNumber, reduce } from 'lodash';

export function sumUmsatz(locations: { duePrice: number | string }[]) {
  const value = reduce(
    locations,
    (sum, location) =>
      sum + (isNumber(location.duePrice) ? location.duePrice : 0),
    0
  );
  return Math.ceil(100 * value) / 100;
}

export function sumHours(locations: { operatingExpense: number | string }[]) {
  const value = reduce(
    locations,
    function (sum, location) {
      return (
        sum +
        (isNumber(location.operatingExpense) ? location.operatingExpense : 0)
      );
    },
    0
  );
  return Math.ceil(100 * value) / 100;
}
