import React, { Fragment } from 'react';

import { Location } from '../../../context/Route';
import numeral from 'numeral';
import { sumUmsatz } from '../helper/sum';

// switch between locales
numeral.locale('de');

function shortCurrency(value: number, smallFormat: string) {
  const f = value < 10000 ? smallFormat || '0,0' : '0.0a';
  return numeral(value).format(f);
}

export default function SummerySalesSum(props: {
  locations: Location[];
  loading?: boolean;
}) {
  const a: string[] = [];
  if (props.loading) {
    a.push('berechne...');
  } else if (props.locations && props.locations.length > 0) {
    const hours = sumUmsatz(props.locations);
    const d = `${shortCurrency(hours as number, '0')}€`;
    a.push(d);
  } else {
    a.push(`0€`);
  }
  return (
    <Fragment>
      <span>Umsatz:</span>
      <span>{a.join(' ')}</span>
    </Fragment>
  );
}
