import React, { Fragment, useEffect, useState } from 'react';

import AddContractModal from './AddContractModal';
import { DateTime } from 'luxon';
import EditableInput from '../Form/EditableInput';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as YesIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as NoIcon } from '../../assets/icons/fail-circle.svg';
import { Popconfirm } from 'antd';
import { Request } from '../../api/Request';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { Switch } from 'antd';
import styled from 'styled-components';
import { map } from 'lodash';
import { validateDue } from './validation';

interface ForceDuesProps {
  location: string;
  tasks: any[];
  readonly?: boolean;
  _v: number;
}

type ForcedDues = Record<string, string>;

interface ContractData {
  price: number;
  auto: boolean;
  _id: string;
  changedAt: string;
}

const Actions = styled.div`
  display: flex;
  float: right;
  > :not(:last-child) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

const AW = styled.span`
  display: inline-block;
  line-height: 32px;
  font-size: 11px;
  font-weight: bold;
  color: #a1a1a1;
  padding: 4px 22px;
`;

const TaskName = styled.span`
  display: inline-block;
  line-height: 32px;
  font-size: 11px;
  font-weight: bold;
  color: #a1a1a1;
  padding: 4px 22px;
  text-transform: uppercase;
`;

const SetButton = styled.button`
  border: 0;
  outline: 0;
  background: transparent;
  margin: 0 40px;
  padding: 8px 0;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 12px 3px 0;
    fill: rgb(0, 152, 66);
    color: rgb(0, 152, 66);
  }
`;

const Release = styled.button`
  border: 0;
  outline: 0;
  background: transparent;
  margin: 0 40px;
  padding: 8px 0;
  color: rgb(0, 152, 66);
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 12px 3px 0;
    fill: rgb(0, 152, 66);
    color: rgb(0, 152, 66);
  }
`;

function prepareContract(contract: ContractData): ContractData {
  return {
    price: contract.price,
    auto: contract.auto,
    _id: contract._id,
    changedAt: DateTime.fromISO(contract.changedAt).toFormat('yyyy-LL-dd'),
  };
}

export default function ForceDues(props: ForceDuesProps) {
  const [state, setState] = useState<ForcedDues>();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Request.get('locations', props.location, 'force-dues', {})
      .then((res: ForcedDues) => {
        setState({
          ...res,
        });
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [props.location]);

  const save = async (task: string, date: string | null) => {
    await Request.put(
      'locations',
      props.location,
      {
        date,
      },
      `force-dues/${task}`
    );
    // reload page
    window.location.reload();
  };

  const [showAll, setShowAll] = useState<boolean>(false);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error occured.{JSON.stringify(error)}</div>;
  }

  const renderLine = (task: any, date: string | undefined | null) => {
    if (!task) return null;
    const d = date ? DateTime.fromISO(date).toFormat('yyyy-LL-dd') : null;
    return (
      <div className='row tasks-row'>
        <div className='col col-5 col-md-5 col-xl-2'>
          <TaskName>{task.key}</TaskName>
        </div>
        <div className='col col-12 col-md-6 col-xl-2'>
          {task.editable || task.importFields.due ? (
            <EditableInput
              readOnly={props.readonly}
              title={`Fälligkeit`}
              type='date'
              value={d}
              validate={(value: number | string | undefined) => {
                return (
                  !value || value.toString().trim() === '' || validateDue(value)
                );
              }}
              callback={(value: number | undefined | string) => {
                if (!value || value.toString().trim() === '') {
                  save(task.key, null);
                } else {
                  save(task.key, value.toString());
                }
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className='row mb-3'>
        <div className='col col-12'>
          <div className='row table-divider'>
            <div className='col'>
              <span>Festgesetzte Fälligkeiten</span>
            </div>
            <div className='col'>
              <div className='float-right'>
                <Switch
                  checked={showAll}
                  title='Bearbeiten'
                  onChange={(checked) => setShowAll(checked)}
                />
                <span
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  Alle anzeigen
                </span>
              </div>
            </div>
          </div>
          {!showAll && state && Object.keys(state).length === 0 ? (
            <div className='row tasks-row'>
              <div className='col col-5 col-md-5 col-xl-2'>
                <AW>Es wurden keinen Fälligkeiten festgesetzt.</AW>
              </div>
            </div>
          ) : (
            false
          )}

          {showAll && props.tasks && state ? (
            <Fragment>
              {props.tasks.map((task) => renderLine(task, state[task.key]))}
            </Fragment>
          ) : (
            false
          )}
          {!showAll && state && props.tasks && Object.keys(state).length > 0 ? (
            <Fragment>
              {map(state, (v, k) =>
                renderLine(
                  props.tasks.find((t) => t.key === k),
                  v
                )
              )}
            </Fragment>
          ) : (
            false
          )}
        </div>
      </div>
    </Fragment>
  );
}
