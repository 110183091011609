import React, { FunctionComponent, useState } from 'react';
import { ReactComponent as FixedIcon } from '../../../assets/icons/date.svg';
import { Job } from '../../../context/Route';
import StatusAction, {
  Status,
} from '../../common/CardList/Actions/StatusAction';
import { setFixed } from './utils/set-fixed';

interface FixedProps {
  job: Job;
  readonly?: boolean;
  onChanged?: () => void;
}

const Fixed: FunctionComponent<FixedProps> = ({ job, onChanged }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await setFixed(job, !job.fixed);
    onChanged && onChanged();
    setLoading(false);
  };

  return (
    <StatusAction
      loading={loading}
      noBackground={!job.fixed}
      state={job.fixed ? Status.success : Status.neutral}
      icon={<FixedIcon />}
      onClick={handleClick}
    >
      {!job.fixed && <span>Termin fixieren</span>}
      {job.fixed && <span>Termin fix</span>}
    </StatusAction>
  );
};

export default Fixed;
