import React from 'react';

export default function LocationsSelected(props: {
  locations: Location[];
  loading?: boolean;
}) {
  const a: string[] = [];
  if (props.locations && props.locations.length === 1) {
    a.push('ein Standort ausgewählt');
  } else if (props.locations && props.locations.length > 1) {
    a.push(`${props.locations.length} Standorte ausgewählt`);
  } else {
    a.push(`kein Standort ausgewählt`);
  }
  return <span style={{ display: 'block' }}>{a.join(' ')}</span>;
}
