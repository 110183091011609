import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface CardListProps {
  loading?: boolean;
  children: React.ReactNode;
}

const Wrapper = styled.div``;

const CardList: FunctionComponent<CardListProps> = ({ children, loading }) => {
  if (loading) {
    return <div>Loading...</div>;
  }
  return <Wrapper>{children}</Wrapper>;
};

export default CardList;
