import './User.scss';

import React, { Component } from 'react';

import { AuthConsumer } from '../../context/AuthContext';
import { EMAIL_REGEXP } from '../regex';
import EditableInput from '../Form/EditableInput';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import { Redirect } from 'react-router-dom';
import { Request } from '../../api/Request';
import SelectInput from '../Form/SelectInput';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import zxcvbn from 'zxcvbn';

const GROUPS = [
  { value: 'sales', text: 'Vertrieb' },
  { value: 'hotel', text: 'Hotel Planer' },
  { value: 'planer', text: 'Tour Planer' },
  { value: 'admin', text: 'Administrator' },
  { value: 'super', text: 'Super-Administrator' },
];

const zxcvbnConfig = {
  minLength: 9,
};

class UserEdit extends Component {
  state = {
    redirectList: false,
    error: null,
    isLoaded: false,
    item: {},
    editing: false,
  };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { id } = this.props.match.params;
    Request.get('users', id).then(
      (result) => {
        this.setState({
          isLoaded: true,
          item: result,
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error,
          redirectList: error.status === 404,
        });
      }
    );
  }

  remove() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Sind Sie sich sicher?')) {
      Request.delete('users', this.state.item.id).then(
        () => {
          this.setState({ redirectList: true });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
            redirectList: error.status === 404,
          });
        }
      );
    }
  }

  async save(k, value) {
    try {
      const data = {};
      data[k] = value;
      await Request.put(
        'users',
        this.state.item.id || this.state.item._id,
        data
      );
      this.loadData();
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  calcPasswordScore(value) {
    let result = null;
    let score = 0;
    if (value && value.length >= zxcvbnConfig.minLength) {
      result = zxcvbn(value);
      ({ score } = result);
    }
    return score;
  }

  render() {
    const { item } = this.state;
    return (
      <AuthConsumer>
        {({ user }) => (
          <div className='user container-inner'>
            {this.state.redirectList ? (
              <Redirect to='/administration/users' />
            ) : (
              ''
            )}
            {this.props.match.params.id !== 'me' ? (
              <InPageNavigation to='/administration/users' item={item} />
            ) : (
              ''
            )}
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col col-6'>
                <h1 className='page-title'>{item.name}</h1>
              </div>
              <div className='col col-6'>
                {user &&
                user.id !== item.id &&
                this.props.match.params.id !== 'me' ? (
                  <p className='delete float-right' onClick={this.remove}>
                    <TrashIcon />
                    Benutzer löschen
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <FormConsumer>
              {({ isEditing }) => (
                <div
                  className={`page-content row ${isEditing ? 'editing' : ''}`}
                >
                  <form className='col col-12 col-xs-6 col-md-8 col-lg-6 col-xl-6'>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Stammdaten</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <EditableInput
                          title='Name'
                          value={item.name}
                          validate={(value) =>
                            value !== undefined &&
                            value !== null &&
                            value.length > 2
                          }
                          callback={(value) => this.save('name', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <EditableInput
                          title='Kürzel'
                          value={item.initialsOverwrite}
                          defaultValue={item.defautlInitials}
                          validate={(value) =>
                            value === undefined ||
                            (value !== undefined && value.length === 4)
                          }
                          callback={(value) =>
                            this.save('initialsOverwrite', value)
                          }
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <EditableInput
                          title='E-Mail'
                          value={item.email}
                          type='email'
                          validate={(value) =>
                            value && EMAIL_REGEXP.exec(value) !== null
                          }
                          callback={(value) => this.save('email', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Login</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <EditableInput
                          title='Benutzer'
                          value={item.username}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col col-12'>
                        <EditableInput
                          title='Passwort'
                          type='password'
                          validate={(value) =>
                            this.calcPasswordScore(value) >= 3
                          }
                          callback={(value) => this.save('password', value)}
                        />
                      </div>
                      {/* <div className='col col-6'></div>
                      <div className='col col-6'>
                        {isEditing && (
                          <PasswordStrengthBar
                            minLength={zxcvbnConfig.minLength}
                            scoreWords={[
                              'schwach',
                              'schwach',
                              'ok',
                              'gut',
                              'stark',
                            ]}
                            shortScoreWord='zu kurz'
                            password={item.password}
                          />
                        )}
                      </div> */}
                    </div>
                  </form>
                  <form className='col col-12 col-xs-6 col-md-8 col-lg-6 col-xl-6'>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Rechte</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <SelectInput
                          title='Gruppe'
                          value={item.group}
                          options={GROUPS}
                          readOnly={
                            this.props.match.params.id === 'me' ||
                            user.id === item.id
                          }
                          callback={(value) => this.save('group', value)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </FormConsumer>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default UserEdit;
