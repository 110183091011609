import React, { FunctionComponent, useMemo } from 'react';
import {
  HomeAccommodationJob,
  HotelJob,
  Job,
  Tour,
} from '../../../context/Route';
import CardList from '../../common/CardList/CardList';
import TourStopListHomeItem from './TourStopListHomeItem';
import TourStopListHotelItem from './TourStopListHotelItem';
import TourStopListLocationItem from './TourStopListLocationItem';
import { groupBy, map } from 'lodash';
import ColorCardListGroup from '../../common/CardList/ColorCardListGroup';
import { DateTime } from 'luxon';

interface TourStopListProps {
  tour?: Tour;
  editing?: boolean;
  onChanged?: () => void;
}

const TourStopList: FunctionComponent<TourStopListProps> = ({
  tour,
  editing,
  onChanged,
}) => {
  const items = useMemo(() => {
    return groupBy(tour?.jobs?.sort((j) => j.sort) ?? [], (item) => {
      const d =
        typeof item.planedAt === 'string'
          ? DateTime.fromISO(item.planedAt)
          : DateTime.fromJSDate(item.planedAt ?? new Date());

      return d.weekday;
    });
  }, [tour]);

  const loading = useMemo(() => {
    return Object.keys(items).length === 0;
  }, [items]);

  const getNext = (
    day: (Job | HotelJob | HomeAccommodationJob)[],
    index: number
  ) => {
    // if index plus one is greater than the length of the array, return undefined
    if (index + 1 >= day.length) return undefined;
    //
    if (index + 1 < 0) return undefined;
    // return the next job
    return day[index + 1];
  };

  const startAtHome = useMemo(() => {
    if (!tour) return undefined;

    return {
      sort: -1,
      isHome: true,
      address: tour?.technician.address,
      name: 'Heimat',
      fixed: true,
    } as HomeAccommodationJob;
  }, []);

  return (
    <CardList loading={loading || !tour}>
      {/* {startAtHome && (
        <TourStopListHomeItem
          job={startAtHome}
          editing={editing}
          next={getNext(Object.values(items)[0], -1)}
        />
      )} */}
      {Object.values(items).map((day, index) => (
        <ColorCardListGroup key={index} index={index}>
          {map(day, (job, index) => {
            if (job.isHome)
              return (
                <TourStopListHomeItem
                  key={index}
                  job={job as HomeAccommodationJob}
                  editing={editing}
                  next={getNext(day, index)}
                  onChanged={onChanged}
                />
              );

            if (job.isHotel)
              return (
                <TourStopListHotelItem
                  key={index}
                  job={job as HotelJob}
                  editing={editing}
                  next={getNext(day, index)}
                  onChanged={onChanged}
                />
              );

            return (
              <TourStopListLocationItem
                key={index}
                tour={tour!}
                job={job}
                next={getNext(day, index)}
                editing={editing}
                onChanged={onChanged}
              />
            );
          })}
        </ColorCardListGroup>
      ))}
    </CardList>
  );
};

export default TourStopList;
