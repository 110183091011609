import { FunctionComponent, useEffect, useState } from 'react';
import { Popconfirm, notification } from 'antd';

import { AuthConsumer } from '../../context/AuthContext';
import { ReactComponent as CheckedIcon } from '../../assets/icons/check-circle.svg';
import React from 'react';
import { Request } from '../../api/Request';
import { forEach, get, reduce } from 'lodash';
import {
  ListLoadingStatus,
  renderListLoadingModal,
} from '../SharesActions/renderLoadingModal';
import styled from 'styled-components';
import { Job, Tour } from '../../context/Route';
import canShare from './canShare';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { BehaviorSubject, OperatorFunction, scan } from 'rxjs';
import Promise from 'bluebird';

interface ToursToolsProps {
  tours: Tour[];
}

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 3px;
  overflow: hidden;
  margin: 0 0 16px 0;
  position: relative;
  padding: 20px 26px 18px 26px;
`;

const Action = styled.button`
  border: 0;
  box-shadow: none;
  background: #fff;
  margin-right: 2rem;
  display: inline-block;
  color: #000;
  svg {
    height: 20px;
    width: 20px;
    margin: 0 12px 1px 6px;
  }
  &:hover {
    color: #009842;
    svg {
      fill: #009842;
    }
  }
`;

type A = {
  [tag: number]: ListLoadingStatus;
};

type PartialA = Partial<A>;

function assign(): OperatorFunction<Partial<A>, Partial<A>> {
  return scan((oldValue: Partial<A>, newValue: Partial<A>) => {
    // return { ...oldValue, ...newValue };
    return { ...oldValue, ...newValue };
  });
}

class LoadingService {
  private carBhvSubj = new BehaviorSubject<PartialA>({});
  car$ = this.carBhvSubj.asObservable().pipe(assign());
  updateCar(carPart: PartialA): void {
    this.carBhvSubj.next(carPart);
  }
}

const ToursTools: FunctionComponent<ToursToolsProps> = ({ tours }) => {
  const canUseSuper = (user: { group: string }) =>
    user && user.group === 'super';
  const canUseAdmin = (user: { group: string }) =>
    user && (user.group === 'admin' || user.group === 'super');

  const [loading, setLoading] = useState<A | undefined>();
  const [loadingService] = useState<LoadingService>(new LoadingService());
  const [modalTitle, setModalTitle] = useState<string>('');

  useEffect(() => {
    const sub = loadingService.car$.subscribe((v) => {
      console.log(v);
      setLoading(v as any);
    });
    return () => sub.unsubscribe();
  }, []);

  const grantAll = async () => {
    try {
      setModalTitle('Alle Touren freigeben');

      const filtered = tours.sort((a, b) =>
        a.tag.toString().localeCompare(b.tag.toString())
      );

      const map1 = new Map(
        filtered.map((tour) => {
          return [tour.tag, ListLoadingStatus.loading];
        })
      );

      loadingService.updateCar(Object.fromEntries(map1));

      await Promise.map(
        filtered,
        (tour) => {
          return Request.post(`tours/${tour._id}/permission/grant`, {})
            .then(() =>
              loadingService.updateCar({ [tour.tag]: ListLoadingStatus.ok })
            )
            .catch(() =>
              loadingService.updateCar({ [tour.tag]: ListLoadingStatus.error })
            );
        },
        { concurrency: 5 }
      );
    } catch (error: any) {
      const msg = get(error, 'response.body.message');
      notification.error({
        message: 'Fehler',
        description: msg || (error as any).message || JSON.stringify(error),
        duration: 10000,
      });
    }
  };

  const sendAllCustomers = async () => {
    try {
      setModalTitle('Alle Standorte & Kunden benachrichtigen');

      let jobs = tours.reduce(
        (acc, tour) => {
          forEach(tour.jobs, (job, i) => {
            if (job._id && (job as any).location && (job as any).location._id) {
              const mails: (string | undefined)[] = [
                get(job, 'location.email'),
                get(job, 'location.email2'),
                get(job, 'location.customer.email'),
              ];

              acc.push({
                id: `${tour.tag} | Standort ${(job as any).location.tag}`,
                _id: job._id,
                location: (job as any).location._id,
                loc: `Standort ${i + 1} von ${tour.jobs.length}`,
                tour: tour.tag,
                tourId: tour._id,
                emails: mails.filter(
                  (e: string | undefined) => e && e.length > 0
                ) as string[],
              });
            }
          });

          return acc;
        },
        [] as {
          id: string;
          _id: string;
          location: string;
          loc: string;
          tour: number;
          tourId: string;
          emails: string[];
        }[]
      );

      jobs = jobs.sort((a, b) =>
        a.id.toString().localeCompare(b.id.toString())
      );

      const map1 = new Map(
        jobs.map((job) => {
          return [job.id, ListLoadingStatus.loading];
        })
      );

      loadingService.updateCar(Object.fromEntries(map1));

      await Promise.map(
        jobs,
        (job) => {
          return Request.put(
            'tours',
            job.tourId,
            { emails: job.emails },
            `share/customer/${job._id}`,
            false
          )
            .then(() =>
              loadingService.updateCar({ [job.id]: ListLoadingStatus.ok })
            )
            .catch(() =>
              loadingService.updateCar({ [job.id]: ListLoadingStatus.error })
            );
        },
        { concurrency: 5 }
      );
    } catch (error: any) {
      const msg = get(error, 'response.body.message');
      notification.error({
        message: 'Fehler',
        description: msg || (error as any).message || JSON.stringify(error),
        duration: 10000,
      });
    }
  };

  const sendAll = async (preview: boolean) => {
    try {
      setModalTitle(
        preview
          ? 'Alle vorläufigen Tourenpläne versenden '
          : 'Alle Tourenpläne versenden'
      );

      const filtered = tours.sort((a, b) =>
        a.tag.toString().localeCompare(b.tag.toString())
      );

      const map1 = new Map(
        filtered.map((tour) => {
          return [
            tour.tag,
            preview || canShare(tour)
              ? ListLoadingStatus.loading
              : ListLoadingStatus.ignore,
          ];
        })
      );

      loadingService.updateCar(Object.fromEntries(map1));

      await Promise.map(
        filtered,
        (tour) => {
          if (!preview && !canShare(tour)) return Promise.resolve();
          const url = preview ? 'share/temporarily' : 'share/rel';
          return Request.put(
            'tours',
            tour._id,
            {
              email: [
                tour.technician.email,
                ...(!preview ? ['doku@allessafe.de'] : []),
              ],
              temporarily: preview,
            },
            url
          )
            .then(() =>
              loadingService.updateCar({ [tour.tag]: ListLoadingStatus.ok })
            )
            .catch(() =>
              loadingService.updateCar({ [tour.tag]: ListLoadingStatus.error })
            );
        },
        { concurrency: 5 }
      );
    } catch (error: any) {
      const msg = get(error, 'response.body.message');
      notification.error({
        message: 'Fehler',
        description: msg || (error as any).message || JSON.stringify(error),
        duration: 10000,
      });
    }
  };

  const isLoading = loading && Object.keys(loading).length > 0;
  const isDone =
    loading &&
    reduce<ListLoadingStatus, boolean>(
      Object.values(loading),
      (acc, l) => acc && l !== ListLoadingStatus.loading,
      true
    );

  return (
    <>
      <AuthConsumer>
        {({ user }) =>
          canUseAdmin(user) && (
            <Wrapper>
              {canUseSuper(user) && (
                <Popconfirm
                  disabled={isLoading}
                  title='Sind Sie sich sicher?'
                  onConfirm={() => {
                    grantAll();
                  }}
                  okText='Ja'
                  cancelText='Nein'
                >
                  <Action disabled={isLoading}>
                    <CheckedIcon />
                    Alle Touren freigeben
                  </Action>
                </Popconfirm>
              )}
              {canUseAdmin(user) && (
                <Popconfirm
                  disabled={isLoading}
                  title='Sind Sie sich sicher?'
                  onConfirm={() => {
                    sendAll(true);
                  }}
                  okText='Ja'
                  cancelText='Nein'
                >
                  <Action disabled={isLoading}>
                    <SendIcon />
                    Alle vorläufigen Tourenpläne versenden
                  </Action>
                </Popconfirm>
              )}
              {canUseAdmin(user) && (
                <Popconfirm
                  disabled={isLoading}
                  title='Sind Sie sich sicher?'
                  onConfirm={() => {
                    sendAll(false);
                  }}
                  okText='Ja'
                  cancelText='Nein'
                >
                  <Action disabled={isLoading}>
                    <SendIcon />
                    Alle Tourenpläne versenden
                  </Action>
                </Popconfirm>
              )}
              {canUseAdmin(user) && (
                <Popconfirm
                  disabled={isLoading}
                  title='Sind Sie sich sicher?'
                  onConfirm={() => {
                    sendAllCustomers();
                  }}
                  okText='Ja'
                  cancelText='Nein'
                >
                  <Action disabled={isLoading}>
                    <SendIcon />
                    Alle Standorte & Kunden benachrichtigen
                  </Action>
                </Popconfirm>
              )}
            </Wrapper>
          )
        }
      </AuthConsumer>
      {isLoading &&
        renderListLoadingModal(loading!, modalTitle, isDone ?? false, () =>
          setLoading(undefined)
        )}
    </>
  );
};

export default ToursTools;
