import React, { Component } from 'react';

import { AuthConsumer } from '../../context/AuthContext';

class ProtectedLink extends Component {
  render() {
    return (
      <AuthConsumer>
        {({ hasPermission }) => (
          <React.Fragment>
            {hasPermission(this.props.requiredPermission) === true
              ? this.props.children
              : ''}
          </React.Fragment>
        )}
      </AuthConsumer>
    );
  }
}

export default ProtectedLink;
