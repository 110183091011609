import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg';

interface PinProps {
  children?: React.ReactNode;
  size?: number;
  color?: 'normal' | 'active' | 'disabled' | 'info';
  id?: string;
}

const _Pin = styled.div<{
  borderColor?: string;
}>`
  position: 'relative';
  border-color: ${({ borderColor }) => borderColor} !important;
`;

const getColor = (
  color: 'normal' | 'active' | 'disabled' | 'info' = 'normal'
) => {
  if (color === 'normal') return '#009842aa';
  if (color === 'active') return '#009842';
  if (color === 'disabled') return '#9b9b9b';
  if (color === 'info') return '#cda223';
};

const _PinSvg = styled(PinIcon)<{
  color?: 'normal' | 'active' | 'disabled' | 'info';
  size?: number;
}>`
  path {
    fill: ${({ color }) => getColor(color)};
  }
  position: absolute;
  width: ${({ size }) => (size ? size : '30')}px;
  height: ${({ size }) => (size ? size : '30')}px;
`;

const Pin: FunctionComponent<PinProps> = ({ id, children, color, size }) => {
  return (
    <_Pin id={id} borderColor={getColor(color)}>
      {children}
      <_PinSvg color={color} size={size} />
    </_Pin>
  );
};

export default Pin;
// import React, { PureComponent } from 'react';

// const pinStyle = {
//   fill: '#009842',
//   stroke: 'none',
//   position: 'absolute',
// };

// const outStyle = {
//   position: 'relative',
// };

// interface PinProps {
//   color?: string;
// }

// export default class Pin extends PureComponent<> {
//   render() {
//     const { children } = this.props;

//     const _children = children ? children : <div></div>;

//     return (
//       <div className='map-pin' style={outStyle}>
//         {_children}
//         <svg className='pin' viewBox='0 0 24 24' style={pinStyle}>
//           <path d={PIN_SVG} />
//         </svg>
//       </div>
//     );
//   }
// }
