import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface CardListGroupProps {
  title?: string;
  subtitle?: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
}

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 12px;
  color: rgb(0, 152, 66);
  font-weight: semibold;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  line-height: 12px;
  user-select: none;
`;

const Subtitle = styled.p`
  font-size: 12px;
  color: rgba(0, 152, 66, 0.5);
  font-weight: semibold;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  margin-left: 1rem;
  line-height: 12px;
  user-select: none;
`;

const Header = styled.div<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ collapsed }) =>
    collapsed ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'};
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
  padding: 20px 0;
  cursor: pointer;
  user-select: none;
`;

const CollapseButton = styled.button<{ collapsed: boolean }>`
  background: none;
  border: none;
  height: 28px;
  width: 28px;
  transition: transform 0.2s ease-in-out;
  transform-origin: 50% 50%;
  transform: ${({ collapsed }) =>
    collapsed ? 'rotate(-90deg)' : 'rotate(0deg)'};
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 20px;
  user-select: none;
`;

const CardListGroup: FunctionComponent<CardListGroupProps> = ({
  title,
  subtitle,
  children,
  defaultOpen = true,
}) => {
  const [open, setOpen] = React.useState<boolean>(defaultOpen);

  return (
    <Wrapper>
      <Header collapsed={!open} onClick={() => setOpen(!open)}>
        <CollapseButton collapsed={!open} />
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Header>
      {open ? <>{children}</> : false}
    </Wrapper>
  );
};

export default CardListGroup;
