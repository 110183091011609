import './Planning.print.scss';

import React, { Component } from 'react';

import { DateTime } from 'luxon';
import ReactToPrint from 'react-to-print';
import { Request } from '../../api/Request';
import qs from 'query-string';

export default class PlanningPrintLocations extends Component {
  state = {
    queryParams: null,
    items: [],
  };

  constructor({ location }) {
    super();
    this.state.queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    this.printRef = React.createRef();
  }

  componentDidMount() {
    Request.list('planning/locations/due', this.state.queryParams).then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.items || [],
          maxItems: result.total,
        });
        this.print();
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error,
        });
      }
    );
  }

  renderDate(value) {
    const d = DateTime.fromISO(value, { zone: 'UTC' })
      .toLocal()
      .setLocale('de');
    if (value) {
      if (d.isValid) {
        return d.toFormat('MMM yyyy');
      } else {
        return 'Ja';
      }
    } else {
      return '-';
    }
  }

  renderRow(item) {
    return (
      <React.Fragment key={item._id}>
        <div className='item'>
          <div className='row bolder big'>
            <div className='col col-3'>Standort</div>
            <div className='col col-3'></div>
            <div className='col col-3'></div>
            <div className='col col-3'>Prüfarten und Fälligkeiten</div>
          </div>
          <div className='row bold'>
            <div className='col col-3'>
              <span>Name</span>
            </div>
            <div className='col col-2'>
              <span>Obj</span>
            </div>
            <div className='col col-3'></div>
            <div className='col col-4'>
              <div className='row'>
                <div className='col'>OVG</div>
                <div className='col'>OVG2</div>
                <div className='col'>OFG</div>
                <div className='col'>OFA</div>
                <div className='col'>REG</div>
                <div className='col'>L&T</div>
                <div className='col'>NOT</div>
                <div className='col'>ROLL</div>
                <div className='col'>EBOX</div>
                <div className='col'>LUFT</div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col col-3'>
              <span>{item.name}</span>
            </div>
            <div className='col col-2'>
              <span>{item.tag}</span>
            </div>
            <div className='col col-3'></div>
            <div className='col col-4'>
              <div className='row'>
                <div className='col small'>
                  {this.renderDate(item._dues.ovg)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.ovg2)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.ofg)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.ofa)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.reg)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.lt)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.not)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.roll)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.ebox)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.luft)}
                </div>
              </div>
            </div>
          </div>
          <div className='row bold'>
            <div className='col col-3'>
              <span>Straße</span>
            </div>
            <div className='col col-2'>
              <span>Plz</span>
            </div>
            <div className='col col-3'>
              <span>Ort</span>
            </div>
            <div className='col col-4'>
              <div className='row'>
                <div className='col'>HUB</div>
                <div className='col'>MA</div>
                <div className='col'>GB</div>
                <div className='col'>TH</div>
                <div className='col'>MB</div>
                <div className='col'>VDS</div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col col-3'>
              <span>{item.address.street}</span>
            </div>
            <div className='col col-2'>
              <span>{item.address.postalCode}</span>
            </div>
            <div className='col col-3'>
              <span>{item.address.city}</span>
            </div>
            <div className='col col-4'>
              <div className='row'>
                <div className='col small'>
                  {this.renderDate(item._dues.hub)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.ma)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.gb)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.th)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.mb)}
                </div>
                <div className='col small'>
                  {this.renderDate(item._dues.vds)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  print() {
    const t = setInterval(() => {
      if (this.printRef) {
        clearInterval(t);
        this.printRef.handlePrint();
      }
    }, 500);
  }

  render() {
    const { items, queryParams } = this.state;
    return (
      <React.Fragment>
        <ReactToPrint
          className='print-planing-locations-action'
          ref={(el) => (this.printRef = el)}
          trigger={() => <div></div>}
          content={() => this.componentRef}
        />
        <div
          className='print-planing-locations'
          ref={(el) => (this.componentRef = el)}
        >
          <h3>Standorte - Fälligkeit bis {this.renderDate(queryParams.end)}</h3>
          <h6>
            Stand {DateTime.local().toLocaleString(DateTime.DATETIME_SHORT)}
          </h6>
          {items.map(this.renderRow.bind(this))}
        </div>
      </React.Fragment>
    );
  }
}
