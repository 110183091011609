import React, { FunctionComponent, useEffect, useState } from 'react';
import { Request } from '../../../api/Request';

interface DistanceExtraProps {
  p1: any;
  p2: any;
}

const DistanceExtra: FunctionComponent<DistanceExtraProps> = ({ p1, p2 }) => {
  const [data, setData] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    if (!p1 || !p2) return;
    Request.post(
      'distance',
      { p1: p1.location.coordinates, p2: p2.location.coordinates },
      false
    ).then((res) => {
      if (res) setData(`${res.distance}km | ${res.duration}h`);
      setLoading(false);
    });
  }, [p1, p2]);

  if (loading) return <span>...</span>;
  return <span>{data}</span>;
};

export default DistanceExtra;
