import './Filter.scss';

import React, { FunctionComponent, useState } from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import DatePicker, {
  DatePickerType,
  DatePickerValue,
} from '../Picker/DatePicker';

interface DateFilterProps {
  range?: DatePickerValue;
  active?: boolean;
  text?: string;
  onChange?: (value: DatePickerValue | undefined) => void;
  disabled?: boolean;
}

const DateFilter: FunctionComponent<DateFilterProps> = ({
  range,
  active,
  text,
  onChange,
  disabled,
}) => {
  const [value, setValue] = useState<DatePickerValue | undefined>(range);

  const handleChange = (value?: DatePickerValue) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <DatePicker
      disabled={disabled}
      className='datepicker filter-datepicker'
      types={[
        DatePickerType.week,
        DatePickerType.day,
        DatePickerType.month,
        DatePickerType.quarter,
        DatePickerType.year,
      ]}
      value={value}
      allowPast={true}
      onChange={handleChange}
    >
      <button className={`filter-button ${active ? 'filter-active' : ''}`}>
        {active && 'formatted' ? 'formatted' : text}
        <CloseIcon
          onClick={(event) => {
            event.preventDefault(); // Let's stop this event.
            event.stopPropagation(); // Really this time.
            handleChange(undefined);
          }}
        />
      </button>
    </DatePicker>
  );
};

export default DateFilter;

// class DateFilter extends Component {
//   state = {
//     active: false,
//     options: {},
//     values: [],
//   };

//   constructor(props) {
//     super(props);
//     this.state.options = props.options;
//     this.state.active =
//       props.name &&
//       has(this.state.options, props.name) &&
//       !isNil(this.state.options[props.name]);
//     this.handleChange = this.handleChange.bind(this);
//   }

//   UNSAFE_componentWillReceiveProps(newProps) {
//     this.updateOptions(newProps);
//   }

//   updateOptions({ name, values, options }) {
//     this.setState({
//       options,
//       values,
//       active: name && has(options, name) && !isNil(options[name]),
//     });
//   }

//   handleChange(startDate, endDate, formatted) {
//     const { onChange, name } = this.props;
//     const _value = { ...this.state.options };
//     if (!startDate && !endDate) {
//       delete _value[name];
//     } else {
//       _value[name] = {
//         startDate,
//         endDate,
//       };
//     }
//     this.setState({
//       formatted,
//     });
//     onChange(_value);
//   }

//   render() {
//     const { active, options, formatted, dateType } = this.state;
//     const { text, name } = this.props;
//     const range = options[name] || {
//       startDate: DateTime.local().set({ weekday: 1 }).startOf('day').toJSDate(),
//       endDate: DateTime.local()
//         .set({ weekday: 0 })
//         .plus({ weeks: 1 })
//         .endOf('day')
//         .toJSDate(),
//     };
//     const { startDate, endDate } = range;
//     return (
//       <DatePicker
//         className='datepicker filter-datepicker'
//         types={['week', 'day', 'month', 'quarter', 'year']}
//         type={dateType}
//         setType={(type) =>
//           this.setState({
//             dateType: type,
//           })
//         }
//         startDate={startDate}
//         endDate={endDate}
//         allowPast={true}
//         onChangeValue={this.handleChange}
//       >
//         <button className={`filter-button ${active ? 'filter-active' : ''}`}>
//           {active && formatted ? formatted : text}
//           <CloseIcon
//             onClick={(event) => {
//               event.preventDefault(); // Let's stop this event.
//               event.stopPropagation(); // Really this time.
//               this.handleChange(null, null, null);
//             }}
//           />
//         </button>
//       </DatePicker>
//     );
//   }
// }

// export default DateFilter;
