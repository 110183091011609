import './ExportSettings.scss';

import { Field, Line, LineWithTarget } from './fieldsDefault';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import ExportSettingLine from './ExportSettingLine';
import InPageNavigation from '../../InPageNavigation/InPageNavigation';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { Request } from '../../../api/Request';
import arrayMove from 'array-move';
import classNames from 'classnames';
import { notification } from 'antd';
import { reduce } from 'lodash';
import renderLoading from '../../SharesActions/renderLoading';

enum loadingTrigger {
  not,
  initial,
  reset,
  save,
}

const charCodeOfA = 'A'.charCodeAt(0),
  alphabetLength = 'Z'.charCodeAt(0) - charCodeOfA + 1;

function convertNumberToLetter(nNum: number) {
  const charCode = charCodeOfA + nNum - 1;
  return String.fromCharCode(charCode);
}

function numberToLetters(nNum: number) {
  if (nNum <= alphabetLength) {
    return convertNumberToLetter(nNum);
  } else {
    const firstNumber = Math.floor((nNum - 1) / alphabetLength);
    const firstLetter = convertNumberToLetter(firstNumber);

    const secondNumber = nNum % alphabetLength || alphabetLength;
    const secondLetter = convertNumberToLetter(secondNumber);

    return firstLetter + secondLetter;
  }
}

const SortableItem = ({
  line,
  fields,
  loading,
  onChange,
}: {
  line: LineWithTarget;
  fields: { [group: string]: Field[] };
  loading: boolean;
  onChange: (line: Line) => void;
}) => (
  <ExportSettingLine
    onChange={onChange}
    loading={loading}
    line={line}
    fields={fields}
  />
);

const SortableList = ({
  lines,
  fields,
  loading,
  onChange,
}: {
  lines: Line[];
  fields: { [group: string]: Field[] };
  loading: boolean;
  onChange: (index: number, line: Line) => void;
}) => {
  console.log(lines);
  return (
    <ul style={{ padding: 0 }}>
      {lines.map((line, index) => (
        <SortableItem
          onChange={(l) => onChange(index, l)}
          loading={loading}
          key={line._id}
          fields={fields}
          line={{
            ...line,
            target: numberToLetters(index + 1),
          }}
        />
      ))}
    </ul>
  );
};

export default function ExportSetting() {
  const [loading, setLoading] = useState<loadingTrigger>(
    loadingTrigger.initial
  );

  const [fields, setFields] = useState<{ [group: string]: Field[] }>({});

  const [lines, _setLines] = useState<Line[]>([]);
  const [valid, setValid] = useState<boolean>(false);

  async function fetchMappings() {
    const data = await Request.list('export/locations/fields', {});
    setFields(data);
  }

  async function fetchLines() {
    const data = await Request.list('export/locations/lines', {});
    _setLines(data);
  }

  async function saveLines() {
    const targetLines = lines.map((line, index) => ({
      ...line,
      target: numberToLetters(index + 1),
    }));
    await Request.put('export', 'locations/lines', targetLines);
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    console.log(
      numberToLetters(oldIndex + 1),
      '->',
      numberToLetters(newIndex + 1)
    );
    _setLines(arrayMove(lines, oldIndex, newIndex));
  };

  const add = () => {
    const item = {
      _id: `new-${new Date().getTime()}`,
      title: '',
      source: '',
    };
    setLinesAndValidate([...lines, item]);
  };

  const save = async () => {
    try {
      setLoading(loadingTrigger.save);
      await saveLines();
      notification.success({
        message: 'Änderungen gespeichert',
      });
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: 'Die Änderung konnte nicht gespeichert werden.',
      });
    } finally {
      setLoading(loadingTrigger.not);
    }
  };

  const reset = async () => {
    setLoading(loadingTrigger.reset);
    await fetchLines();
    setLoading(loadingTrigger.not);
  };

  const checkValid = (ls: Line[]) => {
    const checkLineValid = (line: Line) => {
      return (
        !!line.source &&
        line.source.length > 0 &&
        !!line.title &&
        line.title.length > 0
      );
    };
    const v = reduce(ls, (acc, line) => acc && checkLineValid(line), true);
    setValid(v);
  };

  const setLinesAndValidate = (lines: Line[]) => {
    _setLines(lines);
    checkValid(lines);
  };

  useEffect(() => {
    fetchMappings().then(() => {
      fetchLines().then(() => {
        setLoading(loadingTrigger.not);
      });
    });
  }, []);

  console.log(lines);

  if (loading === loadingTrigger.initial) return renderLoading();

  return (
    <div className='export container-inner'>
      <InPageNavigation to='/export' />
      <div className='page-header page-header-line row justify-content-between'>
        <div className='col-6 col'>
          <h1 className='page-title'>Export Einstellungen</h1>
        </div>
        <div className='col-6 col actions'>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== loadingTrigger.not || !valid,
            })}
            onClick={() => loading === loadingTrigger.not && valid && save()}
          >
            {loading !== loadingTrigger.save && <CheckIcon className='save' />}
            {loading === loadingTrigger.save && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Speichern
          </div>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== loadingTrigger.not,
            })}
            onClick={() => loading === loadingTrigger.not && reset()}
          >
            {loading !== loadingTrigger.reset && (
              <CloseIcon className='cancel' />
            )}
            {loading === loadingTrigger.reset && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Zurücksetzen
          </div>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== loadingTrigger.not,
            })}
            onClick={() => loading === loadingTrigger.not && add()}
          >
            {loading !== loadingTrigger.reset && <PlusIcon />}
            {loading === loadingTrigger.reset && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Neuer Eintrag
          </div>
        </div>
      </div>
      <div className='row page-content'>
        <div className='col col-12 mb-12'>
          {loading === loadingTrigger.not && lines && lines.length === 0 && (
            <span>Keine Einträge vorhanden.</span>
          )}
          {lines && lines.length > 0 && (
            <SortableList
              onChange={(index, l) => {
                lines.splice(index, 1, l);
                setLinesAndValidate(lines);
              }}
              loading={loading > loadingTrigger.not}
              lines={lines}
              fields={fields}
            />
          )}
        </div>
      </div>
    </div>
  );
}
