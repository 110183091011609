import './ImportSettings.scss';

import { Field, Line } from './fieldsDefault';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import ImportSettingLine from './ImportSettingLine';
import ImportSettingLineHeader from './ImportSettingLineHeader';
import InPageNavigation from '../../InPageNavigation/InPageNavigation';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { Request } from '../../../api/Request';
import classNames from 'classnames';
import { notification } from 'antd';
import renderLoading from '../../SharesActions/renderLoading';
import { uniqBy } from 'lodash';

enum loadingTrigger {
  not,
  initial,
  reset,
  save,
}

const SortableItem = ({
  line,
  fields,
  loading,
  onChange,
  index,
}: {
  index: string;
  line: Line;
  fields: { [group: string]: Field[] };
  loading: boolean;
  onChange: (line: Line) => void;
}) => (
  <ImportSettingLine
    key={index}
    onChange={onChange}
    loading={loading}
    line={line}
    fields={fields}
  />
);

const SortableList = ({
  lines,
  fields,
  loading,
  onChange,
}: {
  lines: Line[];
  fields: { [group: string]: Field[] };
  loading: boolean;
  onChange: (index: number, line: Line) => void;
}) => {
  return (
    <ul style={{ padding: 0 }}>
      {lines.map((line, index) => (
        <SortableItem
          onChange={(l) => onChange(index, l)}
          loading={loading}
          key={line._id}
          index={line._id}
          fields={fields}
          line={line}
        />
      ))}
    </ul>
  );
};

export default function ImportSetting() {
  const [loading, setLoading] = useState<loadingTrigger>(
    loadingTrigger.initial
  );

  const [fields, setFields] = useState<{ [group: string]: Field[] }>({});

  const [lines, _setLines] = useState<Line[]>([]);
  const [valid, setValid] = useState<boolean>(false);

  async function fetchMappings() {
    const data = await Request.list('import/locations/fields', {});
    setFields(data);
    return data;
  }

  async function fetchLines() {
    const data = await Request.list('import/locations/lines', {});
    const mapped = data.map((l: any) => ({
      _id: l._id,
      title: '',
      source: l.source,
      target: l.target,
      required: l.required,
    }));
    const u = uniqBy(mapped, (m: any) => m.source);
    _setLines(u);
  }

  async function saveLines() {
    await Request.put(
      'import',
      'locations/lines',
      lines
        .filter((l) => !l.required)
        .map((l) => ({
          title: '',
          _id: l._id,
          source: l.source,
          target: l.target,
        }))
    );
  }

  async function clearLines() {
    await Request.delete('import', 'locations/lines');
    await reset();
  }

  const add = () => {
    const item = {
      _id: `new-${new Date().getTime()}`,
      target: '',
      source: '',
    };
    setLinesAndValidate([...lines, item]);
  };

  const save = async () => {
    try {
      setLoading(loadingTrigger.save);
      await saveLines();
      notification.success({
        message: 'Änderungen gespeichert',
      });
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: 'Die Änderung konnte nicht gespeichert werden.',
      });
    } finally {
      setLoading(loadingTrigger.not);
    }
  };

  const clear = async () => {
    try {
      setLoading(loadingTrigger.save);
      await clearLines();
      notification.success({
        message: 'Änderungen gespeichert',
      });
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: 'Die Änderung konnte nicht gespeichert werden.',
      });
    } finally {
      setLoading(loadingTrigger.not);
    }
  };

  const reset = async () => {
    setLoading(loadingTrigger.reset);
    await fetchLines();
    setLoading(loadingTrigger.not);
  };

  // const checkLineValid = (line: Line) => {
  //   return (
  //     !!line.source &&
  //     line.source.length > 0 &&
  //     !!line.target &&
  //     line.target.length > 0
  //   );
  // };

  const checkValid = () => {
    setValid(true);
  };

  const setLinesAndValidate = (lines: Line[]) => {
    _setLines(lines);
    checkValid();
  };

  useEffect(() => {
    fetchMappings().then(() => {
      fetchLines().then(() => {
        setLoading(loadingTrigger.not);
      });
    });
  }, []);

  console.log(lines);

  if (loading === loadingTrigger.initial) return renderLoading();

  return (
    <div className='import container-inner'>
      <InPageNavigation to='/administration/import' />
      <div className='page-header page-header-line row justify-content-between'>
        <div className='col-6 col'>
          <h1 className='page-title'>Import Einstellungen</h1>
        </div>
        <div className='col-6 col actions'>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== loadingTrigger.not,
            })}
            onClick={() => loading === loadingTrigger.not && clear()}
          >
            {loading !== loadingTrigger.save && <CloseIcon className='save' />}
            {loading === loadingTrigger.save && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Alle Löschen{' '}
          </div>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== loadingTrigger.not || !valid,
            })}
            onClick={() => loading === loadingTrigger.not && valid && save()}
          >
            {loading !== loadingTrigger.save && <CheckIcon className='save' />}
            {loading === loadingTrigger.save && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Speichern
          </div>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== loadingTrigger.not,
            })}
            onClick={() => loading === loadingTrigger.not && reset()}
          >
            {loading !== loadingTrigger.reset && (
              <CloseIcon className='cancel' />
            )}
            {loading === loadingTrigger.reset && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Zurücksetzen
          </div>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== loadingTrigger.not,
            })}
            onClick={() => loading === loadingTrigger.not && add()}
          >
            {loading !== loadingTrigger.reset && <PlusIcon />}
            {loading === loadingTrigger.reset && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Neuer Eintrag
          </div>
        </div>
      </div>
      <div className='row page-content'>
        <div className='col col-12 mb-12'>
          {loading === loadingTrigger.not && lines && lines.length === 0 && (
            <span>Keine Einträge vorhanden.</span>
          )}
          {lines && lines.length > 0 && (
            <>
              <ImportSettingLineHeader />
              <SortableList
                onChange={(index, l) => {
                  lines.splice(index, 1, l);
                  setLinesAndValidate(lines);
                }}
                loading={loading > loadingTrigger.not}
                lines={lines}
                fields={fields}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
