import './layout.scss';

import React, { Component } from 'react';

import Header from './Header/Header';

// import ReactDOM from 'react-dom';

class MainLayout extends Component {
  marginTop = 70;
  header;

  mainStyle = {
    // marginTop: '70px'
    position: 'absolute',
    overflowY: 'scroll',
    top: `${this.marginTop}px`,
    left: 0,
    right: 0,
    bottom: 0,
  };

  // style = () => {
  //   if (!this.header)
  //     return {
  //       marginTop: '70px',
  //     };
  //   const ele = ReactDOM.findDOMNode(this.header);
  //   const height = ele.children[0].offsetHeight;
  //   if (this.marginTop !== height) {
  //     this.marginTop = height;
  //   }
  //   return {
  //     marginTop: `${this.marginTop}px`,
  //   };
  // };

  render() {
    return (
      <React.Fragment>
        <Header ref={(c) => (this.header = c)} />
        {/* <main role="main" style={this.style()}> */}
        <main role='main' style={this.mainStyle}>
          <div className='container-flex'>{this.props.children}</div>
        </main>
      </React.Fragment>
    );
  }
}

export default MainLayout;
