import React, { useEffect, useState } from 'react';

import Cookies from 'universal-cookie';
import { FORM_ERROR } from 'final-form';
import { RequestUnauthenticated } from '../api/Request';
import { datadogRum } from '@datadog/browser-rum';

export interface AuthContextProps {
  isAuth: () => boolean;
  login: (values: any) => Promise<any>;
  logout: () => void;
  user: any;
  hasPermission: (rp: any) => boolean;
}

const cookies = new Cookies();
export const AuthContext = React.createContext<AuthContextProps>({
  isAuth: () => false,
  user: null,
  login: () => Promise.resolve(false),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  hasPermission: () => false,
});

export const AuthProvider = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [token, setToken] = useState<string | null>(null);
  const [user, _setUser] = useState<any>();

  const setUser = (user: any) => {
    if (user) {
      datadogRum.setUser({
        id: user.id,
        email: user.email,
        name: user.name,
        scope: user.scope,
      });
    } else {
      datadogRum.removeUserProperty('name');
    }
    _setUser(user);
  };

  const login = (values: any) => {
    return RequestUnauthenticated.post(`authenticate`, values).then((res) => {
      const { user, token } = res;
      if (!token || !user)
        return { [FORM_ERROR]: 'Deine Zugangsdaten sind leider falsch.' };
      cookies.set('_token', token, { path: '/' });
      cookies.set('_user', JSON.stringify(user), { path: '/' });
      setUser(user);
      setToken(token);
      // configureScope(user);
      return null;
    });
  };

  const logout = () => {
    cookies.remove('_token', { path: '/' });
    cookies.remove('_user', { path: '/' });
    setUser(null);
    setToken(null);
    // configureScope(null);
  };

  const hasPermission = (rp: string | string[]) => {
    if (!rp || !(rp instanceof Array)) return true;
    return user && user.scope && rp.some((r) => user.scope.indexOf(r) >= 0);
  };

  const isAuth = () => {
    const _user = cookies.get('_user');
    const _token = cookies.get('_token');
    return !!_token && _token.length > 32 && !!_user && _user.id ? true : false;
  };

  useEffect(() => {
    const init = () => {
      const _user = cookies.get('_user');
      const _token = cookies.get('_token');
      // configureScope(_user);
      setUser(_user);
      setToken(_token);
    };
    init();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        user,
        login,
        logout,
        hasPermission,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
export const useAuth = () => React.useContext(AuthContext);
