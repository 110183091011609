import { Redirect, Route } from 'react-router-dom';

import { AuthConsumer } from '../../context/AuthContext';
import React from 'react';

const ProtectedAppRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <AuthConsumer>
    {({ isAuth }) => (
      <Route
        {...rest}
        render={(props) =>
          isAuth() ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to='/account/login' />
          )
        }
      />
    )}
  </AuthConsumer>
);

export default ProtectedAppRoute;
