import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { Job, Tour } from '../../../context/Route';
import StatusSelect from './StatusSelect';
import { useField } from 'formik';
import { set } from 'lodash';
import WorkingHoursField from './WorkingHoursField';
import WorkingHours from './WorkingHours';
import TransferWorkingHoursField from './TransferWorkingHoursField';
import { Col, Row } from 'antd';
import renderError from './renderError';

const Wrapper = styled.div`
  background-color: #f6f6f6;
  border-radius: 3px;
  display: flex;
  min-height: 68px;
  justify-content: space-between;
  margin: 6px;
  padding: 20px 14px 20px 62px;
  position: relative;
  z-index: 0;
  .ant-row {
    width: 100%;
  }
`;

const Task = styled.p`
  color: #009842;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
  line-height: 28px;
  text-transform: uppercase;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
`;

const ErrorDisplay = styled.p`
  color: red;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
  line-height: 28px;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
`;

interface TourJobTaskProps {
  tour: Tour;
  job: Job;
  task: string;
}

const W = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TourJobTask: FunctionComponent<TourJobTaskProps> = ({ job, task }) => {
  const fieldName = `jobs.${job._id}.tasks.${task}`;
  const jobFieldname = `jobs.${job._id}`;
  const [, meta] = useField(fieldName);

  return (
    <Wrapper>
      <Row gutter={[36, 24]}>
        <Col xs={24} md={16} lg={8} xxl={6}>
          <ErrorDisplay>{renderError(meta.error)}</ErrorDisplay>
        </Col>
        <Col xs={24} md={8} lg={8} xxl={2}>
          <Task>{task}</Task>
        </Col>
        <Col xs={24} lg={8} xxl={7}>
          <StatusSelect name={fieldName} setOnMixed={jobFieldname} />
        </Col>
        <Col xs={24} md={8} xxl={3}>
          <WorkingHours name={fieldName} title='StOr. AH' />
        </Col>
        <Col xs={24} md={8} xxl={3}>
          <TransferWorkingHoursField name={fieldName} />
        </Col>
        <Col xs={24} md={8} xxl={3}>
          <WorkingHoursField name={fieldName} />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default TourJobTask;
