import React, { FunctionComponent } from 'react';
import { HomeAccommodationJob, HotelJob, Job } from '../../../context/Route';
import CardListItem from '../../common/CardList/CardListItem';
import AddJob from './AddJob';
import ExtraDistance from './ExtraDistance';
import { AddWrapper } from './TourStopListLocationItem';
import { deleteJob } from './utils/delete-job';

interface TourStopListHomeItemProps {
  job: HomeAccommodationJob;
  next?: Job | HotelJob | HomeAccommodationJob;
  editing?: boolean;
  onChanged?: () => void;
}

const TourStopListHomeItem: FunctionComponent<TourStopListHomeItemProps> = ({
  job,
  next,
  editing,
  onChanged,
}) => {
  const renderAdd = () => (
    <AddWrapper>
      <AddJob onChanged={onChanged} before={job} />
    </AddWrapper>
  );

  const renderExtra = () => {
    // if (editing)
    //   return <ExtraWrapper editing={true}>{renderAdd()}</ExtraWrapper>;
    if (next) return <ExtraDistance job={job} next={next} />;
    return false;
  };

  return (
    <CardListItem
      type='hotel'
      leading={'H'}
      title={`Heimat`}
      subtitle={`${job.address.street} ${job.address.postalCode} ${job.address.city}`}
      extra={renderExtra()}
      onDelete={
        editing
          ? async () => {
              await deleteJob(job);
              onChanged && onChanged();
            }
          : undefined
      }
    ></CardListItem>
  );
};

export default TourStopListHomeItem;
