import { HotelJobWithLeg, JobWithLeg } from '../../../context/Route';

import PlanningStepTourJob from './PlanningStepTourJob';
import { PlanningStepTourJobActionFunc } from './PlanningStepTourJobAction';
import React from 'react';
// import { SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';
import jobPlanedOnFixed from '../helper/jobPlanedOnFixed';
import planedInHolidays from '../helper/jobPlanedInHolidays';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PlanningConsumer } from '../../../context/PlanningContext';

// import { ClipLoader } from 'react-spinners';

interface PlanningStepTourListItemProps {
  value: (JobWithLeg | HotelJobWithLeg) & { sortID: string };
  sortIndex: number;
  disabled: boolean;
  action: PlanningStepTourJobActionFunc;
  technician: any;
  planningWeek: Date;
  hasError?: boolean;
  setHighlight?: (id: string) => void;
}

const PlanningStepTourListItem = (props: PlanningStepTourListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.value.sortID });

  const classes = classNames(
    'route-step',
    `route-step-type-${props.value.type}`,
    {
      disabled: props.disabled,
      'failure-location': planedInHolidays(
        props.value as JobWithLeg,
        (props.value as JobWithLeg).holidays
      ),
      'failure-technician':
        planedInHolidays(
          props.value as JobWithLeg,
          props.technician.holidays
        ) || props.hasError,
      'failure-fixedDate': jobPlanedOnFixed(props.value as JobWithLeg),
    }
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      data-id={props.value.sortID}
      className='tour-item'
      ref={setNodeRef}
      style={style}
      {...attributes}
      // {...listeners}
    >
      {props.sortIndex > 0 && props.value.leg ? (
        <div className='row leg-info'>
          <div className='col'>
            <p>Strecke: {Math.floor(props.value.leg.distance / 1000)}km</p>
            <p>Fahrzeit: {Math.floor(props.value.leg.duration / 36) / 100}h</p>
          </div>
        </div>
      ) : (
        ''
      )}
      <div
        className={classes}
        onMouseEnter={() => {
          props.setHighlight && props.setHighlight(props.value._id ?? '');
        }}
        onMouseLeave={() => {
          props.setHighlight && props.setHighlight('');
        }}
      >
        <PlanningStepTourJob
          hasError={props.hasError}
          value={props.value as any}
          sortIndex={props.sortIndex}
          disabled={props.disabled}
          action={props.action}
          planningWeek={props.planningWeek}
          listeners={listeners}
        />
      </div>
    </li>
  );
};

export default PlanningStepTourListItem;
