import { FunctionComponent, default as React, useMemo } from 'react';
import { HomeAccommodationJob, HotelJob, Job } from '../../../context/Route';
import CardListItem from '../../common/CardList/CardListItem';
import AddJob from './AddJob';
import ExtraDistance from './ExtraDistance';
import { AddWrapper } from './TourStopListLocationItem';
import { deleteJob } from './utils/delete-job';

import EnterHotel from './EnterHotel';

interface TourStopListHotelItemProps {
  job: HotelJob;
  next?: Job | HotelJob | HomeAccommodationJob;
  editing?: boolean;
  onChanged?: () => void;
}

const TourStopListHotelItem: FunctionComponent<TourStopListHotelItemProps> = ({
  job,
  next,
  editing,
  onChanged,
}) => {
  const renderAdd = () => (
    <AddWrapper>
      <AddJob onChanged={onChanged} before={job} />
    </AddWrapper>
  );

  const renderExtra = () => {
    // if (editing)
    //   return <ExtraWrapper editing={true}>{renderAdd()}</ExtraWrapper>;
    if (next) return <ExtraDistance job={job} next={next} />;
    return false;
  };

  const subtitle = useMemo(() => {
    if (job.address) {
      return `${job.address.street} ${job.address.postalCode} ${job.address.city}`;
    }

    return job.nearByAddress;
  }, [job]);

  const title = useMemo(() => {
    return job.hotelName ?? 'Buchung offen';
  }, [job]);

  return (
    <CardListItem
      type='hotel'
      leading={'Ü'}
      title={title}
      subtitle={subtitle}
      extra={renderExtra()}
      action1={<EnterHotel job={job} onChanged={onChanged} />}
      onDelete={
        editing
          ? async () => {
              await deleteJob(job);
              onChanged && onChanged();
            }
          : undefined
      }
    ></CardListItem>
  );
};

export default TourStopListHotelItem;
