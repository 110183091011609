import request, { ResponseError } from 'superagent';

import Cookies from 'universal-cookie';
import { get } from 'lodash';
import { notification } from 'antd';

const cookies = new Cookies();

class _Request {
  isNotUnauthorized(err: ResponseError) {
    // if (err.status === 401 && err.message === 'Unauthorized') {
    if (err.status === 401) {
      cookies.remove('_token', { path: '/' });
      cookies.remove('_user', { path: '/' });
      return document.location.replace(
        `/account/login?referrer=${document.location.pathname}`
      );
    } else {
      return true;
    }
  }

  openNotificationWithErrorAndThrow(error: ResponseError) {
    const msg = get(error, 'response.body.message');
    notification.error({
      message: 'Fehler',
      description: msg || error.message || JSON.stringify(error),
      duration: 10000,
    });
    throw error;
  }

  async get(
    url: string,
    id: string,
    appendPath?: string,
    params = {},
    notifyError = true
  ) {
    const cu = url.startsWith('/') ? url : `/${url}`;
    let path = `${process.env.REACT_APP_REST_API}${cu}/${id}`;
    if (appendPath && appendPath.length > 0)
      path = path.concat(
        appendPath.startsWith('/') ? appendPath : `/${appendPath}`
      );
    return request
      .get(path)
      .query(params)
      .set('Authorization', 'Bearer ' + this.token)
      .then((res) => {
        return res.body;
      })
      .catch((err) => {
        if (this.isNotUnauthorized(err)) {
          if (notifyError) this.openNotificationWithErrorAndThrow(err);
        }
      });
  }

  async list(url: string, params: any, notifyError = true) {
    const cu = url.startsWith('/') ? url : `/${url}`;
    return request
      .get(`${process.env.REACT_APP_REST_API}${cu}`)
      .query(params)
      .set('Authorization', 'Bearer ' + this.token)
      .then((res) => {
        return res.body;
      })
      .catch((err) => {
        if (this.isNotUnauthorized(err)) {
          if (notifyError) {
            this.openNotificationWithErrorAndThrow(err);
          }
        }
      });
  }

  async post(url: string, data: any, notifyError = true) {
    const cu = url.startsWith('/') ? url : `/${url}`;
    return request
      .post(`${process.env.REACT_APP_REST_API}${cu}`)
      .timeout({
        response: 600000, // Wait 10 minutes for the server to start sending,
        deadline: 600000, // and allow 10 minutes for the file to finish loading.
      })
      .set('Authorization', 'Bearer ' + this.token)
      .send(data)
      .then((res: any) => {
        return res.body;
      })
      .catch((err: any) => {
        if (this.isNotUnauthorized(err)) {
          if (notifyError) {
            this.openNotificationWithErrorAndThrow(err);
          } else {
            throw err;
          }
        }
      });
  }

  postForRoutePreview(data: any) {
    return request
      .post(`${process.env.REACT_APP_REST_API}/planning/routes/preview`)
      .timeout({
        response: 120000, // Wait 2 minutes for the server to start sending,
        deadline: 120000, // and allow 2 minutes for the file to finish loading.
      })
      .set('Authorization', 'Bearer ' + this.token)
      .send(data);
  }

  async put(
    url: string,
    id: string,
    data: any,
    appendPath = '',
    notifyError = true
  ) {
    const cu = url.startsWith('/') ? url : `/${url}`;
    let path = `${process.env.REACT_APP_REST_API}${cu}/${id}`;
    if (appendPath && appendPath.length > 0)
      path = path.concat(
        appendPath.startsWith('/') ? appendPath : `/${appendPath}`
      );
    return request
      .put(path)
      .set('Authorization', 'Bearer ' + this.token)
      .send(data)
      .then((res) => {
        return res.body;
      })
      .catch((err) => {
        if (this.isNotUnauthorized(err)) {
          if (notifyError) {
            this.openNotificationWithErrorAndThrow(err);
          } else {
            throw err;
          }
        }
      });
  }

  async patch(url: string, id: string, data: any, appendPath?: string) {
    const cu = url.startsWith('/') ? url : `/${url}`;
    let path = `${process.env.REACT_APP_REST_API}${cu}/${id}`;
    if (appendPath && appendPath.length > 0)
      path = path.concat(
        appendPath.startsWith('/') ? appendPath : `/${appendPath}`
      );
    return request
      .patch(path)
      .set('Authorization', 'Bearer ' + this.token)
      .send(data)
      .then((res) => {
        return res.body;
      })
      .catch((err) => {
        if (this.isNotUnauthorized(err))
          this.openNotificationWithErrorAndThrow(err);
      });
  }

  async upload(
    url: string,
    id: string,
    file: File,
    data: any,
    appendPath?: string,
    doNotCatch = false,
    progress?: (p: number) => void
  ) {
    const cu = url.startsWith('/') ? url : `/${url}`;
    let path = `${process.env.REACT_APP_REST_API}${cu}/${id}`;
    if (appendPath && appendPath.length > 0)
      path = path.concat(
        appendPath.startsWith('/') ? appendPath : `/${appendPath}`
      );
    return request
      .post(path)
      .set('Authorization', 'Bearer ' + this.token)
      .attach('file', file)
      .field('lines', JSON.stringify(data))
      .on('progress', (event) => {
        progress && progress(event && event.percent ? event.percent : 0);
      })
      .then((res) => {
        return res.body;
      })
      .catch((err) => {
        if (doNotCatch) {
          throw err;
        } else if (this.isNotUnauthorized(err))
          this.openNotificationWithErrorAndThrow(err);
      });
  }

  async delete(url: string, id: string, appendPath?: string) {
    const cu = url.startsWith('/') ? url : `/${url}`;
    let path = `${process.env.REACT_APP_REST_API}${cu}/${id}`;
    if (appendPath && appendPath.length > 0)
      path = path.concat(
        appendPath.startsWith('/') ? appendPath : `/${appendPath}`
      );
    return request
      .delete(path)
      .set('Authorization', 'Bearer ' + this.token)
      .then((res) => {
        return res.body;
      })
      .catch((err) => {
        if (this.isNotUnauthorized(err)) {
          this.openNotificationWithErrorAndThrow(err);
        }
      });
  }

  get token() {
    return cookies.get('_token');
  }

  get relUploadInfo() {
    return {
      url: `${process.env.REACT_APP_REST_API}/rel/file`,
      headers: {
        authorization: 'Bearer ' + this.token,
      },
    };
  }
}

class _RequestUnauthenticated {
  post(url: string, data: any) {
    const cu = url.startsWith('/') ? url : `/${url}`;
    return request
      .post(`${process.env.REACT_APP_REST_API}${cu}`)
      .send(data)
      .then((res) => res.body);
  }
}

const Request = new _Request();
const RequestUnauthenticated = new _RequestUnauthenticated();

export { Request, RequestUnauthenticated };
