import React, { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/arrow-up.svg';
import { getBorderColor } from '../../../Tours/Filter';
import { Button } from 'antd';

interface ForesightedMonthCountProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const Wrapper = styled.div<{
  active: boolean;
  disabled?: boolean;
  required?: boolean;
  size?: string;
}>`
  position: relative;
  display: inline-block;
  margin: 0 1rem;
  background-color: transparent;
  border: 1px solid
    ${({ active, disabled }) => getBorderColor(active, disabled ?? false)};
  border-radius: 4px;
  cursor: pointer;
  width: 10rem;
  position: relative;
  > div {
    display: flex;
  }
  input {
    line-height: 1rem;
    font-size: 14px;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-style: none;
    appearance: none;
    background-color: transparent;
    border: 0;
    width: 100%;
    flex: 1 1 auto;
  }
  button {
    padding: 2px;
    border: 0;
    background-color: transparent;
    svg {
      margin: 0 6px 3px 6px;
      width: 12px;
      height: 12px;
      fill: #000;
      color: #000;
      cursor: pointer;
      &.disabled {
        cursor: default;
        pointer-events: none;
        path,
        g {
          fill: #a1a1a1 !important;
          color: #a1a1a1 !important;
        }
      }
    }
    &:hover {
      svg {
        fill: #009842;
        color: #009842;
      }
    }
  }
`;

const ForesightedMonthCount: FunctionComponent<ForesightedMonthCountProps> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <Wrapper active={value > 0} disabled={disabled}>
      <div>
        <NumberFormat
          id='foresightedMonthCount'
          disabled={disabled}
          thousandSeparator='.'
          decimalSeparator=','
          value={value}
          allowNegative={false}
          prefix='+ '
          suffix={
            ' Mon.'
            // foresightedMonthCount !== 1 ? ' Monate' : ' Monat'
          }
          decimalScale={0}
          onValueChange={(e) => {
            let a = e.floatValue;
            if (a === undefined || a === null) a = 3;
            onChange(a);
          }}
          onKeyDown={(e) => {
            // arrow up/down button should select next/previous list element
            if (e.keyCode === 38 && value > 0) {
              onChange(value - 1);
            } else if (e.keyCode === 40) {
              onChange(value + 1);
            }
          }}
        />
        <Button
          type='link'
          disabled={disabled || value === 0}
          onClick={() => {
            if (!disabled && value > 0) {
              onChange(value - 1);
            }
          }}
        >
          <ArrowDown />
        </Button>
        <Button
          type='link'
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              onChange(value + 1);
            }
          }}
        >
          <ArrowUp />
        </Button>
      </div>
    </Wrapper>
  );
};

export default ForesightedMonthCount;
