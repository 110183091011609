import React from 'react';
import { isNumber } from 'lodash';
import numeral from 'numeral';
numeral.locale('de');

interface LocationPricePreviewProps {
  location: string;
  data: any;
}

function shortCurrency(value: number) {
  return numeral(value).format('0.00');
}

function formatCurrency(value: number, fallback = '') {
  return value && isNumber(value) && value > 0
    ? `${shortCurrency(value)} €`
    : fallback;
}

function LocationPricePreview(props: LocationPricePreviewProps) {
  return (
    <ul
      style={{
        listStyle: 'none',
        padding: '8px',
        margin: '0px',
      }}
    >
      <li style={{ fontSize: '14px' }}>
        <b
          style={{
            minWidth: '42px',
            display: 'inline-block',
            textAlign: 'right',
            marginRight: '4px',
          }}
        >
          Preis Prüfungen
        </b>{' '}
        <span
          style={{
            display: 'inline-block',
            lineHeight: '14px',
            fontStyle: 'normal',
            fontSize: '12px',
            color: '#a1a1a1',
          }}
        >
          {formatCurrency(props.data.duePrice)}
        </span>
      </li>
      <li style={{ fontSize: '14px' }}>
        <b
          style={{
            minWidth: '42px',
            display: 'inline-block',
            textAlign: 'right',
            marginRight: '4px',
          }}
        >
          Restauftragswert
        </b>{' '}
        <span
          style={{
            display: 'inline-block',
            lineHeight: '14px',
            fontStyle: 'normal',
            fontSize: '12px',
            color: '#a1a1a1',
          }}
        >
          {formatCurrency(props.data.contractValueRest)}
        </span>
      </li>
    </ul>
  );
}

export default LocationPricePreview;
