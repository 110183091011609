import { ReactComponent as NoteIcon } from '../../assets/icons/notes.svg';
import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';

interface NoteTooltipProps {
  value: string;
}

export function NoteTooltip(props: NoteTooltipProps) {
  if (!props.value) return <span></span>;
  return (
    <TooltipTrigger
      placement='auto'
      trigger='hover'
      delayShow={320}
      tooltip={({
        getTooltipProps,
        getArrowProps,
        tooltipRef,
        arrowRef,
        placement,
      }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: 'tooltip-container',
          })}
        >
          <div
            {...getArrowProps({
              ref: arrowRef,
              'data-placement': placement,
              className: 'tooltip-arrow',
            })}
          />
          <div className='tooltip-body'>{props.value}</div>
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <div
          {...getTriggerProps({
            ref: triggerRef,
            className: 'trigger',
          })}
        >
          <NoteIcon
            style={{
              display: 'block',
              width: '16px',
              height: '16px',
              marginTop: '6px',
              marginLeft: '2px',
            }}
            className='note'
          />
        </div>
      )}
    </TooltipTrigger>
  );
}
