import { JOB_TYPE, NewJob, Technician } from '../../context/Route';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button } from 'antd';
import { PlanningContext } from '../../context/PlanningContext';
import { ReactComponent as SleepIcon } from '../../assets/icons/sleep.svg';

interface MatchParams {
  id: string;
}

interface TourLocationAddHomeProps extends RouteComponentProps<MatchParams> {
  technician: Technician;
  nearByAddress?: string;
  index: number;
  planedAtBefore?: Date;
  count: number;
  onAdd: (job: NewJob) => Promise<() => Promise<void>>;
}

interface TourLocationAddHomeState {
  error?: Error;
  loading: boolean;
}

class TourLocationAddHome extends Component<
  TourLocationAddHomeProps,
  TourLocationAddHomeState
> {
  state: TourLocationAddHomeState = {
    loading: false,
  };

  constructor(props: TourLocationAddHomeProps) {
    super(props);
    this.add = this.add.bind(this);
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  add() {
    if (!this.visible) return;
    this.setPromisifiedState({ loading: true }).then(() => {
      const job: NewJob = {
        sort: this.props.index,
        planedAt: this.props.planedAtBefore!,
        type: JOB_TYPE.HotelAccommodation,
        nearByAddress: this.props.nearByAddress,
        planedAtTimeByUser: false,
        // operatingExpense: 0,
        // duePrice: 0,
      };
      this.props.onAdd(job).then((reload) => {
        reload().then(() => {
          this.setPromisifiedState({ loading: false });
        });
      });
    });
  }

  get visible() {
    return this.props.planedAtBefore && this.props.nearByAddress;
  }

  render() {
    return (
      <div
        className='col col-4'
        style={{ textAlign: 'center' }}
        onClick={() => this.add()}
      >
        {this.visible && (
          <Button
            style={{ padding: '0 15px', height: '54px', width: '100%' }}
            type='text'
            loading={this.state.loading}
          >
            <SleepIcon />
          </Button>
        )}
      </div>
    );
  }
}

TourLocationAddHome.contextType = PlanningContext;

export default withRouter(TourLocationAddHome);
