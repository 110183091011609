import './Timeline.scss';
import 'react-big-schedule/dist/css/style.css';

import { flatten, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Request } from '../../api/Request';

import { Modal } from 'antd';
const { confirm, error } = Modal;

import {
  Scheduler,
  SchedulerData,
  ViewType,
  wrapperFun,
} from 'react-big-schedule';

import moment from 'moment';
import { AuthConsumer } from '../../context/AuthContext';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';

import * as dayjsLocale from 'dayjs/locale/de';
import * as antdLocale from 'antd/es/locale/de_DE';

moment.locale('de');

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let schedulerData;

const initialState = {
  showScheduler: false,
  viewModel: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'INITIALIZE':
      return { showScheduler: true, viewModel: action.payload };
    case 'UPDATE_SCHEDULER':
      return { ...state, viewModel: action.payload };
    case 'REINITIALIZE':
      return { ...state, showScheduler: false };
    default:
      return state;
  }
}

const OfftimeOverview = ({}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const cellWidth = 62;

  useEffect(() => {
    schedulerData = new SchedulerData(
      undefined,
      ViewType.Month,
      false,
      false,
      {
        schedulerWidth: '100%',
        crossResourceMove: false,
        checkConflict: true,
        displayWeekend: true,
        views: [],
        resourceName: 'Techniker',
        eventItemPopoverTrigger: 'click',
        nonWorkingTimeHeadColor: '#212529',
        nonWorkingTimeHeadBgColor: '#f0f0f000',
        nonWorkingTimeBodyBgColor: '#f0f0f000',
        dayCellWidth: cellWidth,
        dayCellWidth: cellWidth,
        weekCellWidth: cellWidth,
        monthCellWidth: cellWidth,
        quarterCellWidth: cellWidth,
        yearCellWidth: cellWidth,
        customCellWidth: cellWidth,
      },
      {
        getNonAgendaViewBodyCellBgColorFunc: (
          schedulerData,
          slotId,
          header
        ) => {
          let datetime = DateTime.fromFormat(
            header.time,
            'yyyy-MM-dd HH:mm:ss'
          );
          let colors = [
            '#C5B8CCFF',
            '#B8CCC1FF',
            '#FFE8E6FF',
            '#E3DBCCFF',
            '#C5B8CC66',
            '#B8CCC166',
            '#FFE8E666',
            '#E3DBCC66',
          ];
          let weekIndex = datetime.weekNumber % 4;
          let slotIndex =
            schedulerData.resources.findIndex((r) => r.id === slotId) % 2;
          let odd = slotIndex * 4;
          return colors[weekIndex + odd];
        },
      }
    );

    schedulerData.setSchedulerLocale(dayjsLocale);
    schedulerData.setCalendarPopoverLocale(antdLocale);

    dispatch({ type: 'INITIALIZE', payload: schedulerData });
    return () => dispatch({ type: 'REINITIALIZE' });
  }, []);

  const query = useQuery();

  const [reload, setReload] = useState(0);
  const [range, setRange] = useState({
    start: moment().startOf('month').toISOString(),
    end: moment().endOf('month').toISOString(),
  });

  const [technicians, setTechnicians] = useState([]);
  useEffect(() => {
    Request.list('technicians', { limit: 500 }).then((technicians) => {
      const l = technicians.items.filter((t) => !t.locked_planing);
      setTechnicians(l);
      const r = l.map((technician) => ({
        id: technician._id,
        name: technician.initials,
      }));
      schedulerData.setResources(r);
      dispatch({ type: 'UPDATE_SCHEDULER', payload: schedulerData });
    });
  }, [range]);

  useEffect(() => {
    if (query.get('filter')) {
      const year = query.get('year')
        ? parseInt(query.get('year'))
        : new Date().getFullYear();
      const month = query.get('month')
        ? parseInt(query.get('month'))
        : new Date().getMonth();
      setFilter({
        year,
        month,
      });
    }
  }, [query]);

  useEffect(() => {
    Promise.all([
      Request.get('technicians', 'offtime', undefined, range),
      Request.list('technicians/holidays', { limit: 10000, ...range }),
    ]).then(([data, holidays]) => {
      const e = flatten(
        data.map((tech) =>
          tech.offTime.map((offtime) => {
            const start = DateTime.fromISO(offtime.start)
              .startOf('day')
              .toISO();
            const end = DateTime.fromISO(offtime.end).endOf('day').toISO();
            return {
              id: offtime._id,
              start: start,
              end: end,
              resourceId: tech._id,
              title: `${offtime.title}`,
              bgColor: '#009842',
            };
          })
        )
      );

      const uh = uniqBy(holidays, 'title');
      const hol = flatten(
        technicians.map((tech) =>
          uh.map((holiday) => {
            const start = DateTime.fromISO(holiday.start)
              .startOf('day')
              .toISO();
            const end = DateTime.fromISO(holiday.end).startOf('day').toISO();
            return {
              id: holiday._id,
              start: start,
              end: end,
              resourceId: tech._id,
              title: holiday.title,
              bgColor: '#86cba4',
              resizable: false,
              movable: false,
              showPopover: false,
            };
          })
        )
      );

      console.log(e, hol);
      // schedulerData.setEvents([...e]);

      schedulerData.setEvents([...hol, ...e]);
      dispatch({ type: 'UPDATE_SCHEDULER', payload: schedulerData });
    });
  }, [range, technicians, reload]);

  const prevClick = (schedulerData) => {
    schedulerData.prev();
    setRange({
      start: schedulerData.getViewStartDate().toISOString(),
      end: schedulerData.getViewEndDate().toISOString(),
    });
  };

  const nextClick = (schedulerData) => {
    schedulerData.next();
    setRange({
      start: schedulerData.getViewStartDate().toISOString(),
      end: schedulerData.getViewEndDate().toISOString(),
    });
  };

  const onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    setRange({
      start: schedulerData.getViewStartDate().toISOString(),
      end: schedulerData.getViewEndDate().toISOString(),
    });
  };

  const onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    setRange({
      start: schedulerData.getViewStartDate().toISOString(),
      end: schedulerData.getViewEndDate().toISOString(),
    });
  };

  const newEvent = async (schedulerData, slotId, slotName, start, end) => {
    const _start = DateTime.fromFormat(start, 'yyyy-MM-dd HH:mm:ss').startOf(
      'day'
    );

    const _end = DateTime.fromFormat(end, 'yyyy-MM-dd HH:mm:ss').endOf('day');

    if (
      !(await showConfirmEnter(
        slotName,
        _start.toFormat('dd.MM.yyyy'),
        _end.toFormat('dd.MM.yyyy')
      ))
    )
      return;

    await Request.post(`technicians/${slotId}/offtime`, {
      title: 'Urlaub',
      start: _start.toISO(),
      end: _end.toISO(),
    });

    setReload(reload + 1);
  };

  const updateEventStart = async (schedulerData, event, newStart) => {
    const tech = technicians.find((t) => t._id === event.resourceId);

    const _start = DateTime.fromFormat(newStart, 'yyyy-MM-dd HH:mm:ss').startOf(
      'day'
    );

    const _end = DateTime.fromISO(event.end);

    if (
      !(await showConfirmEnter(
        tech.initials,
        _start.toFormat('dd.MM.yyyy'),
        _end.toFormat('dd.MM.yyyy')
      ))
    )
      return;

    await Request.put(`technicians/${event.resourceId}/offtime`, event.id, {
      start: _start.toISO(),
      end: _end.toISO(),
    });

    setReload(reload + 1);
  };

  const updateEventEnd = async (schedulerData, event, newEnd) => {
    const tech = technicians.find((t) => t._id === event.resourceId);

    const _end = DateTime.fromFormat(newEnd, 'yyyy-MM-dd HH:mm:ss').endOf(
      'day'
    );

    const _start = DateTime.fromISO(event.start);

    if (
      !(await showConfirmEnter(
        tech.initials,
        _start.toFormat('dd.MM.yyyy'),
        _end.toFormat('dd.MM.yyyy')
      ))
    )
      return;

    await Request.put(`technicians/${event.resourceId}/offtime`, event.id, {
      start: _start.toISO(),
      end: _end.toISO(),
    });

    setReload(reload + 1);
  };

  const moveEvent = async (
    schedulerData,
    event,
    slotId,
    slotName,
    start,
    end
  ) => {
    const tech = technicians.find((t) => t._id === event.resourceId);

    const _end = DateTime.fromFormat(end, 'yyyy-MM-dd HH:mm:ss').endOf('day');

    const _start = DateTime.fromFormat(start, 'yyyy-MM-dd HH:mm:ss').startOf(
      'day'
    );

    if (
      !(await showConfirmEnter(
        tech.initials,
        _start.toFormat('dd.MM.yyyy'),
        _end.toFormat('dd.MM.yyyy')
      ))
    )
      return;

    await Request.put(`technicians/${event.resourceId}/offtime`, event.id, {
      start: _start.toISO(),
      end: _end.toISO(),
    });

    setReload(reload + 1);
  };

  const ops1 = async (schedulerData, event) => {
    const tech = technicians.find((t) => t._id === event.resourceId);
    const _start = DateTime.fromISO(event.start);
    const _end = DateTime.fromISO(event.end);

    if (
      !(await showConfirmDelete(
        tech.initials,
        _start.toFormat('dd.MM.yyyy'),
        _end.toFormat('dd.MM.yyyy')
      ))
    )
      return;

    await Request.delete(`technicians/${event.resourceId}/offtime`, event.id);

    setReload(reload + 1);
  };

  const nonAgendaCellHeaderTemplateResolver = (
    schedulerData,
    item,
    formattedDateItems,
    style
  ) => {
    let datetime = DateTime.fromFormat(item.time, 'yyyy-MM-dd HH:mm:ss');
    let isCurrentDate = false;
    let isMonday = datetime.weekday === 1;

    if (schedulerData.viewType === ViewType.Day) {
      isCurrentDate = datetime.hasSame(DateTime.local(), 'hour');
    } else {
      isCurrentDate = datetime.hasSame(DateTime.local(), 'day');
    }

    if (isCurrentDate) {
      style.backgroundColor = '#009842';
      style.color = 'white';
    } else {
      let colors = ['#C5B8CC66', '#B8CCC166', '#FFE8E666', '#E3DBCC66'];
      let weekIndex = datetime.weekNumber % 4;
      style.backgroundColor = colors[weekIndex];
    }

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {isMonday && (
          <p
            style={{
              fontSize: '9px',
            }}
          >
            KW {datetime.weekNumber}
          </p>
        )}
        <p>{datetime.toFormat('dd.MM')}</p>
      </th>
    );
  };

  return (
    <AuthConsumer>
      {() => (
        <div className='offtime container-inner'>
          <InPageNavigation />
          <FormConsumer>
            {() => (
              <div className='page-content'>
                {state.showScheduler && (
                  <Scheduler
                    schedulerData={state.viewModel}
                    prevClick={prevClick}
                    nextClick={nextClick}
                    onSelectDate={onSelectDate}
                    onViewChange={onViewChange}
                    viewEventClick={ops1}
                    viewEventText='Löschen'
                    updateEventStart={updateEventStart}
                    updateEventEnd={updateEventEnd}
                    moveEvent={moveEvent}
                    newEvent={newEvent}
                    nonAgendaCellHeaderTemplateResolver={
                      nonAgendaCellHeaderTemplateResolver
                    }
                    conflictOccurred={(schedulerData, action, event) => {
                      showConflict(
                        technicians.find((t) => t._id === event.slotId).initials
                      );
                    }}
                  />
                )}
              </div>
            )}
          </FormConsumer>
        </div>
      )}
    </AuthConsumer>
  );
};

export default wrapperFun(OfftimeOverview);

function showConfirmEnter(technician, start, end) {
  return new Promise((resolve) => {
    confirm({
      title: 'Urlaub eintragen',
      content: (
        <p>
          Möchten Sie im Zeitraum {start} bis {end} für {technician} Urlaub
          eintragen?
        </p>
      ),
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(undefined);
      },
    });
  });
}

function showConfirmDelete(technician, start, end) {
  return new Promise((resolve) => {
    confirm({
      title: 'Urlaub löschen',
      content: (
        <p>
          Möchten Sie im Zeitraum {start} bis {end} für {technician} den Urlaub
          löschen?
        </p>
      ),
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(undefined);
      },
    });
  });
}

function showConflict(technician) {
  return new Promise((resolve) => {
    error({
      title: 'Urlaub überlappend',
      content: (
        <p>
          Der Urlaub für {technician} in diesem Zeitraum überlappt sich mit
          einem anderen Urlaub des Technikers.
        </p>
      ),
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(undefined);
      },
    });
  });
}
