import './Filter.scss';

import { Listbox } from '@headlessui/react';
import { debounce, isEqual } from 'lodash';
import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

interface FilterValue {
  key: string;
  value: string;
  text: string;
}

interface FilterProps {
  name: string;
  options: any;
  values: FilterValue[];
  onChange: (options: any) => void;
  noBorder?: boolean;
  style?: CSSProperties;
  children: React.ReactNode;
  multiple?: boolean;
  required?: boolean;
  size?: string;
  disabled?: boolean;
  isActive?: (options: any) => boolean;
  direction?: 'up' | 'down';
}

export const getBorderColor = (active: boolean, disabled: boolean) => {
  if (disabled && active) {
    return '#b5b7bb';
  }
  if (!disabled && active) {
    return '#009842';
  }
  return '#e5e7eb';
};

const Wrapper = styled.div<{
  active: boolean;
  required?: boolean;
  size?: string;
  disabled?: boolean;
}>`
  position: relative;
  display: inline-block;
  margin: 0 1rem;
  background-color: transparent;
  border: 1px solid
    ${({ active, disabled }) => getBorderColor(active, disabled ?? false)};
  border-radius: 4px;
  cursor: pointer;
  width: ${({ size }) => size ?? '8rem'};
  position: relative;
  > input {
    line-height: 1.25rem;
    padding-right: 2.5rem;
    padding-left: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-style: none;
    appearance: none;
    background-color: rgb(255, 255, 255);
    border-color: rgb(107, 114, 128);
    border-width: 1px;
    border-radius: 0px;
    width: 100%;
  }
  > button {
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: center;
    padding: 0.5rem;
    display: flex;
    color: #000;
  }
  .sheet {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 0.375rem;
    background-color: rgb(255 255 255);
    width: 20rem;
    margin-top: 0.25rem;
    position: absolute;
    margin: 0;
    padding: ${({ required }) =>
      required ? '44px 0 44px 0' : '44px 0 1rem 0'};
    z-index: 1000;
    > div {
      position: relative;
      max-height: 15rem;
      overflow: auto;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      float: none;
      display: block;
      width: 90%;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 0;
      text-align: inherit;
      text-overflow: ellipsis;
      border: none;
      outline: none;
      background: transparent;
      color: #000;
      padding: 0.5rem;
      margin: 0.5rem;
      background-color: #fff;
      border-bottom: 1px solid #e5e7eb;
      position: absolute;
      top: 0;
    }
    ul {
      background-color: rgb(255 255 255);
      list-style: none;
      margin: 0;
      padding: 0;
      z-index: 1000;
      border-radius: 0;
      li {
        padding: 0.25rem 1rem;
        cursor: pointer;
        &:hover {
          background-color: #f3f4f6;
        }
      }
    }
  }
`;

const Text = styled.div<{ active: boolean }>`
  color: ${({ active }) => (active ? '#009842' : '#000000')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`;

const Clear = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  float: none;
  display: block;
  width: 90%;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0;
  text-align: inherit;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  color: #009842;
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: #fff;
  border-top: 1px solid #e5e7eb;
  position: absolute;
  bottom: 0;
`;

const Filter: FunctionComponent<FilterProps> = ({
  values,
  options,
  onChange,
  name,
  children,
  multiple,
  required,
  size,
  disabled,
  isActive,
  direction,
}) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [query, setQuery] = useState('');

  const debouncedOnChange = debounce(onChange, 1000);

  useEffect(() => {
    if (!isEqual(selected, options[name] ?? [])) {
      debouncedOnChange({
        ...options,
        [name]: selected,
      });
    }
  }, [selected]);

  useEffect(() => {
    if (options[name]) {
      setSelected(options[name]);
    }
  }, [options[name]]);

  const filtered =
    query === ''
      ? values
      : values.filter((value) => {
          return value.text.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Wrapper
      disabled={disabled}
      required={required !== true}
      size={size}
      active={
        typeof isActive === 'function'
          ? isActive(options)
          : selected && Array.isArray(selected)
          ? selected.length > 0
          : selected
      }
    >
      <Listbox
        disabled={disabled}
        value={selected}
        onChange={setSelected}
        multiple={multiple ?? true}
      >
        <Listbox.Button>
          {children}
          {multiple && selected.length > 0 ? ` (${selected.length})` : false}
        </Listbox.Button>
        <Listbox.Options
          as='div'
          className='sheet'
          style={{
            bottom: direction === 'up' ? '34px' : 'unset',
            border: '1px solid #e5e7eb',
          }}
        >
          <input
            onChange={(event) => setQuery(event.target.value)}
            placeholder='Suchen'
          />
          <div>
            <ul>
              {filtered.map(({ value, text, key }) => (
                <Listbox.Option key={key} value={value}>
                  {({ selected }) => <Text active={selected}>{text}</Text>}
                </Listbox.Option>
              ))}
            </ul>
          </div>
          {required !== true ? (
            <Clear onClick={() => setSelected([])}>Löschen</Clear>
          ) : (
            false
          )}
        </Listbox.Options>
      </Listbox>
    </Wrapper>
  );
};

export default Filter;
