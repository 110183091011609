import './RelSettings.scss';

import { Field, Line } from './fieldsDefault';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InPageNavigation from '../../InPageNavigation/InPageNavigation';
import RelSettingFile from './RelSettingFile';
import RelSettingSection from './RelSettingSection';
import { Request } from '../../../api/Request';
import classNames from 'classnames';
import { notification } from 'antd';
import { cloneDeep, reduce } from 'lodash';
import renderLoading from '../../SharesActions/renderLoading';

export enum LoadingTrigger {
  not,
  initial,
  reset,
  save,
}

export default function RELSetting() {
  const [loading, setLoading] = useState<LoadingTrigger>(
    LoadingTrigger.initial
  );

  const [fields, setFields] = useState<{ [group: string]: Field[] }>({});
  const [metaFields, setMetaFields] = useState<{ [group: string]: Field[] }>(
    {}
  );
  const [hotelFields, setHotelFields] = useState<{ [group: string]: Field[] }>(
    {}
  );
  const [homeFields, setHomeFields] = useState<{ [group: string]: Field[] }>(
    {}
  );

  const [lines, _setLines] = useState<Line[]>([]);
  const [linesHotel, _setLinesHotel] = useState<Line[]>([]);
  const [linesHome, _setLinesHome] = useState<Line[]>([]);
  const [meta, _setMeta] = useState<Line[]>([]);
  const [valid, setValid] = useState<boolean>(false);

  async function fetchMappings() {
    const [data1, data2, data3, data4] = await Promise.all([
      Request.list('rel/fields', {
        group: [
          'Planung',
          'Tour',
          'Techniker',
          'Kunde',
          'Standort',
          'Planung',
          'Etikett',
          'Sonstiges',
          'Prüfungen',
        ],
      }),
      Request.list('rel/fields', {
        group: ['Tour', 'Techniker'],
      }),
      Request.list('rel/fields', {
        group: ['Tour', 'Techniker', 'Hotelübernachtung', 'Planung'],
      }),
      Request.list('rel/fields', {
        group: ['Tour', 'Techniker', 'Heimatadresse', 'Planung'],
      }),
    ]);
    setFields(data1);
    setMetaFields(data2);
    setHotelFields(data3);
    setHomeFields(data4);
  }

  async function fetchLines() {
    const data = await Request.list('rel/lines', {});
    _setLines(data);
  }

  async function fetchLinesHotel() {
    const data = await Request.list('rel/lines-hotel', {});
    _setLinesHotel(data);
  }

  async function fetchLinesHome() {
    const data = await Request.list('rel/lines-home', {});
    _setLinesHome(data);
  }

  async function fetchMeta() {
    const data = await Request.list('rel/meta', {});
    _setMeta(data);
  }

  async function saveLines() {
    await Promise.all([
      Request.put(
        'rel',
        'lines',
        lines.map((a) => ({ ...a, title: a.target }))
      ),
      Request.put(
        'rel',
        'lines-hotel',
        linesHotel.map((a) => ({ ...a, title: a.target }))
      ),
      Request.put(
        'rel',
        'lines-home',
        linesHome.map((a) => ({ ...a, title: a.target }))
      ),
      Request.put(
        'rel',
        'meta',
        meta.map((a) => ({ ...a, title: a.target }))
      ),
    ]);
  }

  const save = async () => {
    try {
      setLoading(LoadingTrigger.save);
      await saveLines();
      notification.success({
        message: 'Änderungen gespeichert',
      });
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: 'Die Änderung konnte nicht gespeichert werden.',
      });
    } finally {
      setLoading(LoadingTrigger.not);
    }
  };

  const reset = async () => {
    setLoading(LoadingTrigger.reset);
    await Promise.all([fetchLines(), fetchMeta()]);
    setLoading(LoadingTrigger.not);
  };

  const checkValid = (ls: Line[]) => {
    const checkLineValid = (line: Line) => {
      return (
        !!line.source &&
        line.source.length > 0 &&
        !!line.target &&
        line.target.length > 0
      );
    };
    const v = reduce(ls, (acc, line) => acc && checkLineValid(line), true);
    setValid(v);
  };

  const setLinesAndValidate = (lines: Line[]) => {
    _setLines(cloneDeep(lines));
    checkValid(cloneDeep(lines));
  };

  const setLinesHomeAndValidate = (lines: Line[]) => {
    _setLinesHome(cloneDeep(lines));
    checkValid(cloneDeep(lines));
  };

  const setLinesHotelAndValidate = (lines: Line[]) => {
    _setLinesHotel(cloneDeep(lines));
    checkValid(cloneDeep(lines));
  };

  const setMetaAndValidate = (lines: Line[]) => {
    _setMeta(cloneDeep(lines));
    checkValid(cloneDeep(lines));
  };

  useEffect(() => {
    fetchMappings().then(() => {
      Promise.all([
        fetchLines(),
        fetchLinesHome(),
        fetchLinesHotel(),
        fetchMeta(),
      ]).then(() => {
        setLoading(LoadingTrigger.not);
      });
    });
  }, []);

  if (loading === LoadingTrigger.initial) return renderLoading();

  return (
    <div className='rel container-inner'>
      <InPageNavigation to='/rel' />
      <div className='page-header page-header-line row justify-content-between'>
        <div className='col-6 col'>
          <h1 className='page-title'>REL Einstellungen</h1>
        </div>
        <div className='col-6 col actions'>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== LoadingTrigger.not || !valid,
            })}
            onClick={() => loading === LoadingTrigger.not && valid && save()}
          >
            {loading !== LoadingTrigger.save && <CheckIcon className='save' />}
            {loading === LoadingTrigger.save && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Speichern
          </div>
          <div
            className={classNames('action', 'float-right', {
              disabled: loading !== LoadingTrigger.not,
            })}
            onClick={() => loading === LoadingTrigger.not && reset()}
          >
            {loading !== LoadingTrigger.reset && (
              <CloseIcon className='cancel' />
            )}
            {loading === LoadingTrigger.reset && (
              <ClipLoader size={16} color={'#009842'} loading={true} />
            )}
            Zurücksetzen
          </div>
        </div>
      </div>
      <div className='row page-content'>
        <div className='col col-12 mb-12'>
          <RelSettingFile />
          <hr />
          <RelSettingSection
            text='Bitte die Adresse der Zelle angeben. Zum Beispiel A14. Handelt es
            sich bei der Zelle um eine Verbundene Zelle kann der Bereich angeben
            werden. Zum Beispiel B14:C14 oder B14:C15'
            title='Kopfdaten'
            fields={metaFields}
            setLinesAndValidate={(l) => setMetaAndValidate(l)}
            setLines={(l) => _setMeta(l)}
            lines={meta}
            loading={loading}
          />
          <hr />
          <RelSettingSection
            text='Bitte nur die Spalte angeben. Die Zeile wird durch Safeplan beim
            Erstellen ergänz. Zum Beispiel B. Handelt es sich bei der Spalte um
            eine Verbundene Zelle kann der Bereich angeben werden. Zum Beispiel
            B:C.'
            title='Standortdaten'
            fields={fields}
            setLinesAndValidate={(l) => setLinesAndValidate(l)}
            setLines={(l) => _setLines(l)}
            lines={lines}
            loading={loading}
          />
          <hr />
          <RelSettingSection
            text='Diese Daten werden verwendet, wenn der Job eine Hotelübernachtung ist.
        Bitte nur die Spalte angeben. Die Zeile wird durch Safeplan beim
        Erstellen ergänz. Zum Beispiel B. Handelt es sich bei der Spalte um eine
        Verbundene Zelle kann der Bereich angeben werden. Zum Beispiel B:C.'
            title='Hoteldaten'
            fields={hotelFields}
            setLinesAndValidate={(l) => setLinesHotelAndValidate(l)}
            setLines={(l) => _setLinesHotel(l)}
            lines={linesHotel}
            loading={loading}
          />
          <hr />
          <RelSettingSection
            text='Diese Daten werden verwendet, wenn der Job eine Übernachtung zu Hause ist.
        Bitte nur die Spalte angeben. Die Zeile wird durch Safeplan beim
        Erstellen ergänz. Zum Beispiel B. Handelt es sich bei der Spalte um eine
        Verbundene Zelle kann der Bereich angeben werden. Zum Beispiel B:C.'
            title='Heimatübernachtung'
            fields={homeFields}
            setLinesAndValidate={(l) => setLinesHomeAndValidate(l)}
            setLines={(l) => _setLinesHome(l)}
            lines={linesHome}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
