import { Location, Route, RouteInfo, Technician } from './Route';
import { get, map, pick } from 'lodash';

import Cookies from 'universal-cookie';
import { DateTime } from 'luxon';
import React from 'react';
import getSkillsForLocations from '../components/helper/getSkillsForLocations';

const PlanningContext = React.createContext<{
  wasEdit?: boolean;
  setWasEdit?: (edit: boolean) => Promise<void>;
  reset?: () => void;
  blocked?: Blocked;
  setRoute?: (route: Route) => Promise<void>;
  route?: Route;
  locations?: any[];
  setLocations?: (locations: any[]) => Promise<void>;
  technicians?: any[];
  setTechnicians?: (technicians: any[]) => void;
  planningWeek?: Date;
  planningWeekDT?: DateTime;
  setPlanningWeek?: (planningWeek: Date) => void;
  getRequiredSkillsForAllLocations?: () => string[];
  getCordsForAllLocations?: () => number[][];
  // info
  info?: RouteInfo;
  loadingInfo?: boolean;
  setInfo?: (info: RouteInfo) => void;
  setLoadingInfo?: (loading: boolean) => void;
  //
  setHighlight?: (highlight: string) => void;
  highlight?: string;
}>({});

const cookies = new Cookies();

const getDefaultPlaningWeek = () => {
  return DateTime.fromJSDate(new Date())
    .plus({ days: 7 })
    .startOf('week')
    .toJSDate();
};

interface Blocked {
  locations: any[];
  technicians: any[];
}

interface PlanningProviderProps {}

interface PlanningProviderState {
  highlight: string;
  locations: Location[];
  technicians: Technician[];
  route?: Route;
  planningWeek: Date;
  wasEdit: boolean;
  blocked: Blocked;
  loadingInfo?: boolean;
  info?: RouteInfo;
}

class PlanningProvider extends React.Component<
  PlanningProviderProps,
  PlanningProviderState
> {
  ws: WebSocket | null = null;

  state: PlanningProviderState = {
    highlight: '',
    locations: [],
    technicians: [],
    planningWeek: getDefaultPlaningWeek(),
    wasEdit: false,
    blocked: {
      locations: [],
      technicians: [],
    },
  };

  constructor(props: PlanningProviderProps) {
    super(props);
    this.reset = this.reset.bind(this);
    this.setLocations = this.setLocations.bind(this);
    this.setWasEdit = this.setWasEdit.bind(this);
    this.setRoute = this.setRoute.bind(this);
    this.setTechnicians = this.setTechnicians.bind(this);
    this.setPlanningWeek = this.setPlanningWeek.bind(this);
    this.getRequiredSkills = this.getRequiredSkills.bind(this);
    this.getCordsForAllLocations = this.getCordsForAllLocations.bind(this);
    this.setRouteInfo = this.setRouteInfo.bind(this);
    this.setLoadingInfo = this.setLoadingInfo.bind(this);
    this.setHighlight = this.setHighlight.bind(this);
  }

  componentDidMount() {
    this.setupWS();
  }

  setPromisifiedState<K extends keyof PlanningProviderState>(
    data: Pick<PlanningProviderState, K>
  ): Promise<void> {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  setupWS() {
    // console.log('(re)connect sync...');
    const blockerApiUrl = process.env.REACT_APP_WEPSOCKET_API_BLOCKER;
    if (!blockerApiUrl || blockerApiUrl.length === 0)
      throw new Error(
        'REACT_APP_WEPSOCKET_API_BLOCKER must contain a valid url'
      );
    this.ws = new WebSocket(blockerApiUrl);

    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      // console.log('planning sync connected');
    };

    /* eslint-disable no-extra-bind */
    this.ws.onmessage = ((ev: MessageEvent) => {
      // console.log('planning sync received something');
      const blocked = JSON.parse(ev.data);
      this.setState({ blocked });
    }).bind(this);
    /*eslint-enable*/

    this.ws.onclose = () => {
      // console.log('planning sync disconnected');
      setTimeout(() => {
        this.setupWS();
      }, 1100);
    };
  }

  async reset() {
    await this.setPromisifiedState({
      locations: [],
      route: undefined,
      technicians: [],
      planningWeek: getDefaultPlaningWeek(),
    });
    this.sendBlocked();
  }

  sendBlocked() {
    const { locations, technicians } = this.state;
    const user = cookies.get('_user');
    if (!user) return;
    const userId = !!user && user.id ? user.id : null;
    if (!userId) return;
    const data = {
      user: userId,
      locations: map(locations, '_id'),
      technicians: map(technicians, '_id'),
    };
    try {
      this.ws && this.ws.send(JSON.stringify(data));
    } catch (error: any) {}
  }

  setRoute(route: Route) {
    return this.setPromisifiedState({ route });
  }

  async setLocations(locations: any[]) {
    const f = locations.map((l) => {
      const a = pick(l, [
        '_id',
        'checkDepth',
        'price',
        'dues',
        'dueFixedDate',
        'address',
        'addressRouting',
        'name',
        'tag',
        'contractValueSum',
        'jobValueSum',
        'priceCombiCondition',
        'comment',
        'freezeComment',
        'note',
        '_dues',
        'holidays',
        'cv',
        'duePrice',
        'operatingExpense',
        'fixedDateFromAdditionalTasks',
        'locID', // saved location id for additional jobs
        'additionalTask', //  id for additional jobs
      ]);
      return a;
    });
    await this.setPromisifiedState({ locations: f });
    this.sendBlocked();
  }

  async setWasEdit(edit: boolean) {
    if (edit !== this.state.wasEdit) {
      await this.reset();
      return this.setPromisifiedState({ wasEdit: edit });
    }
  }

  setTechnicians(technicians: any[]) {
    this.setPromisifiedState({ technicians });
    this.sendBlocked();
  }

  async setPlanningWeek(planningWeek: any) {
    return this.setPromisifiedState({
      planningWeek,
      route: undefined,
      info: undefined,
      loadingInfo: false,
      locations: [],
      technicians: [],
    });
  }

  setRouteInfo(info: RouteInfo) {
    // console.log("setRouteInfo", info);
    this.setState({ info, loadingInfo: false });
  }

  setLoadingInfo(loading: boolean) {
    // console.log("setLoadingInfo", loading);
    this.setState({ loadingInfo: loading });
  }

  getCordsForAllLocations() {
    // console.log('getCordsForAllLocations');
    const { locations } = this.state;
    return locations.map((l) => {
      const point = get(l, 'addressRouting.location.coordinates');
      return point ? point : [0, 0];
    });
  }

  getRequiredSkills() {
    const { locations } = this.state;
    return getSkillsForLocations(locations);
  }

  setHighlight(highlight: string) {
    this.setState({ highlight });
  }

  render() {
    // console.log('PlanningContext', 'render');
    return (
      <PlanningContext.Provider
        value={{
          wasEdit: this.state.wasEdit,
          setWasEdit: this.setWasEdit,
          reset: this.reset,
          blocked: this.state.blocked,
          setRoute: this.setRoute,
          route: this.state.route,
          locations: this.state.locations,
          setLocations: this.setLocations,
          technicians: this.state.technicians,
          setTechnicians: this.setTechnicians,
          planningWeek: this.state.planningWeek,
          planningWeekDT: this.state.planningWeek
            ? DateTime.fromJSDate(this.state.planningWeek)
            : undefined,
          setPlanningWeek: this.setPlanningWeek,
          getRequiredSkillsForAllLocations: this.getRequiredSkills,
          getCordsForAllLocations: this.getCordsForAllLocations,
          info: this.state.info,
          setInfo: this.setRouteInfo,
          loadingInfo:
            this.state.loadingInfo !== undefined
              ? this.state.loadingInfo
              : false,
          setLoadingInfo: this.setLoadingInfo,
          setHighlight: this.setHighlight,
          highlight: this.state.highlight,
        }}
      >
        {this.props.children}
      </PlanningContext.Provider>
    );
  }
}
const PlanningConsumer = PlanningContext.Consumer;
export { PlanningProvider, PlanningConsumer, PlanningContext };
