import {
  FormatValue,
  TabledHeaderField,
} from '../Planing/Steps/createLocationTableHeader';
import React, { Fragment, useEffect, useState } from 'react';
import Table, { SELECTION_TYPE } from '../Table/Table';
import { Request } from '../../api/Request';
import { DateTime } from 'luxon';
import { Popconfirm } from 'antd';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import getTasks from './getTasks';
import { orderBy } from 'lodash';
import LocationJob from './LocationJob';

interface LocationJobs2Props {
  location: string;
  onDeleteJob: (job: string) => void;
}

// function createLocationTableHeader(
//   tasks: { key: string; title: string }[],
//   onDeleteJob: (job: string) => void
// ): TabledHeaderField[] {
//   return [
//     { key: 'tour.tag', width: '1', sort: false, title: 'Tour' },
//     {
//       key: 'planedAt',
//       width: '1',
//       sort: false,
//       title: 'KW',
//       format: (value: FormatValue) => (
//         <span>
//           {DateTime.fromISO(value as string, {
//             zone: 'UTC',
//           })
//             .toLocal()
//             .toFormat("'KW' WW '-' yyyy")}
//         </span>
//       ),
//     },
//     {
//       key: 'planedAt',
//       width: '1',
//       sort: false,
//       title: 'Tag',
//       format: (value: FormatValue) => (
//         <span>
//           {DateTime.fromISO(value as string, {
//             zone: 'UTC',
//           })
//             .toLocal()
//             .toLocaleString({
//               month: '2-digit',
//               day: '2-digit',
//               weekday: 'short',
//               year: 'numeric',
//             })}
//         </span>
//       ),
//     },
//     {
//       key: 'tasks',
//       width: '2',
//       sort: false,
//       title: 'Prüfungen',
//       format: (value: FormatValue) => {
//         return (
//           <div className='tasks row trigger'>
//             {tasks.map(({ title, key }: { title: string; key: string }) => (
//               <span
//                 key={key}
//                 className={
//                   value && (value as any)[key]
//                     ? 'task col col-2 task-done'
//                     : 'task col col-2'
//                 }
//               >
//                 {title}
//               </span>
//             ))}
//           </div>
//         );
//       },
//     },
//     {
//       key: 'tour.technician.name',
//       width: '1-5',
//       sort: false,
//       title: 'Techniker',
//     },
//     {
//       key: 'tour.changedBy',
//       width: '1-5',
//       sort: false,
//       title: 'Planer',
//       format: (value: FormatValue, data: any) => {
//         if (data && data.tour && data.tour.changedBy) {
//           return <span>{data.tour.changedBy.name}</span>;
//         }
//       },
//     },
//     {
//       key: 'failed',
//       width: '2',
//       sort: false,
//       title: 'Status',
//       format: (value: FormatValue, data: any) => {
//         if (!data.tour || !data.tour.tag) {
//           return <span>System</span>;
//         } else if (data.tour.enshrined && value) {
//           return <span>Fehlgeschlagen / Nicht gemacht</span>;
//         } else if (data.tour.enshrined && !value) {
//           return <span>Abgeschlossen</span>;
//         } else if (!data.tour.enshrined) {
//           return <span>Rückpflege</span>;
//         }
//       },
//     },
//     {
//       key: 'remove',
//       width: '1-5',
//       sort: false,
//       title: '',
//       format: (value: FormatValue, data: any) => {
//         if (!data.job || !data.tour || !data.tour.tag) {
//           return (
//             <span>
//               <Popconfirm
//                 title='Sind Sie sich sicher?'
//                 onConfirm={() => {
//                   onDeleteJob(data._id);
//                 }}
//                 okText='Ja'
//                 cancelText='Nein'
//               >
//                 <span
//                   style={{
//                     lineHeight: '20px',
//                     cursor: 'pointer',
//                   }}
//                 >
//                   <TrashIcon
//                     style={{
//                       maxHeight: '12px',
//                       margin: '4px 4px',
//                     }}
//                   />
//                   Löschen
//                 </span>
//               </Popconfirm>
//             </span>
//           );
//         } else {
//           return <span></span>;
//         }
//       },
//     },
//   ];
// }

export interface JobsAtLocation {
  _id: string;
  tour?: {
    _id: string;
    technician?: { name: string };
    changedAt?: string;
    changedBy?: { name: string };
    tag: number;
  };
  planedAt?: string;
  failed?: boolean;
  tasks?: Record<string, boolean>;
  state?: string;
  reason?: string;
  taskStates?: Record<string, any>;
}

export default function LocationJobs2({
  location,
  onDeleteJob,
}: LocationJobs2Props) {
  const [header, setHeader] = useState<TabledHeaderField[]>([]);
  const [jobs, setJobs] = useState<JobsAtLocation[]>();

  useEffect(() => {
    if (location) {
      Request.get('locations', location, 'jobs', { page: 0 }).then((j) =>
        setJobs(j)
      );
    }
  }, [location]);

  const [tasks, setTasks] = useState<any>();
  useEffect(() => {
    async function fetchData() {
      const headerTasks = await getTasks();
      setTasks(headerTasks);
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   async function fetchData() {
  //     const headerTasks = await getTasks();
  //     setHeader(createLocationTableHeader(headerTasks, onDeleteJob));
  //   }
  //   fetchData();
  // }, [onDeleteJob]);

  return (
    <Fragment>
      <div className='row mb-3'>
        <div className='col col-12'>
          <div className='row table-divider'>
            <div className='col'>
              <span>Jobs</span>
            </div>
          </div>
          <div className=''>
            {!(jobs && jobs.length > 0) && (
              <span>Für diesen Standort wurden noch keine Jobs geplant!</span>
            )}
            {jobs && jobs.length > 0
              ? jobs.map((job) => (
                  <LocationJob
                    tasks={tasks}
                    key={job._id}
                    job={job}
                    onDeleteJob={onDeleteJob}
                  />
                ))
              : null}

            {/* {jobs && jobs.length > 0 && (
              // <Table
              //   selectable={SELECTION_TYPE.disabled}
              //   loading={false}
              //   header={header}
              //   link={(item: any) => {
              //     if (!item || !item || !item.tour || !item.tour.tag)
              //       return undefined;
              //     return `/tours/${item.tour._id}?p=1#${item._id}`;
              //   }}
              //   linkTarget='_blank'
              //   items={orderBy(jobs, ['planedAt'], ['desc'])}
              // />
            //  */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
