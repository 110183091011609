import './Picker.scss';

import React, { FunctionComponent, useEffect, useState } from 'react';

import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as CheckDown } from '../../assets/icons/check.svg';
import { ReactComponent as Clock } from '../../assets/icons/calender.svg';
import { DateTime } from 'luxon';
import DatepickerInnerDay from './DatepickerInnerDay';
import DatepickerInnerMonth from './DatepickerInnerMonth';
import DatepickerInnerQuarter from './DatepickerInnerQuarter';
import DatepickerInnerWeek from './DatepickerInnerWeek';
import DatepickerInnerWeekday from './DatepickerInnerWeekday';
import DatepickerInnerYear from './DatepickerInnerYear';
import classNames from 'classnames';
import { Dialog } from '@headlessui/react';

export enum DatePickerType {
  day,
  weekday,
  date,
  week,
  month,
  quarter,
  year,
  range,
}

export const datePickerTypeFromString = (input: string) => {
  switch (input) {
    case 'day':
      return DatePickerType.day;
    case 'weekday':
      return DatePickerType.weekday;
    case 'date':
      return DatePickerType.date;
    case 'week':
      return DatePickerType.week;
    case 'month':
      return DatePickerType.month;
    case 'quarter':
      return DatePickerType.quarter;
    case 'year':
      return DatePickerType.year;
    case 'range':
      return DatePickerType.range;
    default:
      return DatePickerType.week;
  }
};

export const datePickerTypeTotring = (input: DatePickerType) => {
  switch (input) {
    case DatePickerType.day:
      return 'day';
    case DatePickerType.weekday:
      return 'weekday';
    case DatePickerType.date:
      return 'date';
    case DatePickerType.week:
      return 'week';
    case DatePickerType.month:
      return 'month';
    case DatePickerType.quarter:
      return 'quarter';
    case DatePickerType.year:
      return 'year';
    case DatePickerType.range:
      return 'range';
    default:
      return 'week';
  }
};

const typeDef = {
  [DatePickerType.day]: {
    text: 'Tag',
  },
  [DatePickerType.weekday]: {
    text: 'Plandatum',
  },
  [DatePickerType.date]: {
    text: 'Datum',
  },
  [DatePickerType.week]: {
    text: 'KW',
  },
  [DatePickerType.month]: {
    text: 'Monat',
  },
  [DatePickerType.quarter]: {
    text: 'Quartal',
  },
  [DatePickerType.year]: {
    text: 'Jahr',
  },
  [DatePickerType.range]: {
    text: 'Zeitraum',
  },
};

export function parseDate(startDate: DateValue) {
  return typeof startDate === 'string'
    ? DateTime.fromISO(startDate)
    : DateTime.fromJSDate(startDate).setLocale('de');
}

export type DateValue = string | Date;

export interface DatePickerValue {
  startDate: DateValue;
  endDate?: DateValue;
  time?: boolean;
  until?: string;
}

interface DatepickerProps {
  value?: DatePickerValue;
  useUntil?: boolean;
  canRemoveUntil?: boolean;
  types: DatePickerType[];
  type?: DatePickerType;
  className?: string;
  disabled?: boolean;
  format?: string;
  onChange?: DatepickerOnChangeHandler;
  allowPast?: boolean;
  rangeFirst?: Date;
  rangeLast?: Date;
}

export type DatepickerOnChangeHandler = (
  value: DatePickerValue,
  type: DatePickerType
) => void;

const Datepicker: FunctionComponent<DatepickerProps> = ({
  className,
  disabled,
  types,
  value,
  format,
  allowPast,
  rangeFirst,
  rangeLast,
  useUntil,
  onChange,
  type,
}) => {
  const [usedType, selectType] = useState<DatePickerType>(type ?? types[0]);
  const [temp, setTemp] = useState<DatePickerValue | undefined>(value);
  const [formatted, setFormatted] = useState<string | undefined>();

  useEffect(() => {
    selectType(type ?? types[0]);
  }, [type, types]);

  useEffect(() => {
    if (!value || !value.startDate) {
      setFormatted(undefined);
    } else {
      const p = parseDate(value!.startDate);
      if (usedType === DatePickerType.date) {
        setFormatted(DatepickerInnerMonth.format(p, format));
      } else if (usedType === DatePickerType.day) {
        setFormatted(DatepickerInnerDay.format(p, format));
      } else if (usedType === DatePickerType.weekday) {
        setFormatted(DatepickerInnerWeekday.format(p, format));
      } else if (usedType === DatePickerType.week) {
        setFormatted(DatepickerInnerWeek.format(p, format));
      } else if (usedType === DatePickerType.month) {
        setFormatted(DatepickerInnerMonth.format(p, format));
      } else if (usedType === DatePickerType.quarter) {
        setFormatted(DatepickerInnerQuarter.format(p, format));
      } else if (usedType === DatePickerType.year) {
        setFormatted(DatepickerInnerYear.format(p, format));
      } else {
        setFormatted(undefined);
      }
    }
  }, [usedType, value]);

  const renderIcon = (type: DatePickerType) => {
    if (type === DatePickerType.weekday) {
      return <Clock style={{ height: '18px', width: '18px' }} />;
    } else {
      return (
        <ArrowDown
          style={{ marginLeft: '8px', height: '14px', width: '14px' }}
        />
      );
    }
  };

  const onChangeSelection = (
    startDate: Date,
    endDate: Date,
    formatted: string,
    type: string,
    until?: string | undefined
  ) => {
    setTemp({
      startDate,
      endDate,
      until,
      time: false,
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <span
        className={classNames('trigger', {
          disabled: disabled,
        })}
        onClick={() => !disabled && setIsOpen(true)}
      >
        {formatted}
        {disabled !== true && renderIcon(usedType)}
      </span>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1500,
          background: 'rgba(0,0,0,0.4)',
          display: 'flex',
        }}
      >
        <Dialog.Panel
          className='datepicker tooltip-container'
          style={{ margin: 'auto' }}
        >
          <div className='bar'>
            <ul>
              {types.map((t) => {
                const def = typeDef[t];
                return (
                  <li
                    onClick={() => {
                      selectType(t);
                    }}
                    key={t}
                    className={`${usedType === t ? 'active' : ''}`}
                  >
                    {def ? def.text : ''}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className='inner'>
            {usedType === DatePickerType.day ? (
              <DatepickerInnerDay
                startDate={
                  temp ? parseDate(temp.startDate).toJSDate() : undefined
                }
                onChangeValue={onChangeSelection}
              />
            ) : null}
            {usedType === DatePickerType.weekday ? (
              <DatepickerInnerWeekday
                startDate={
                  temp ? parseDate(temp.startDate).toJSDate() : undefined
                }
                rangeFirst={rangeFirst ?? new Date()}
                rangeLast={rangeLast ?? new Date()}
                displayTime={temp ? temp.time : false}
                until={temp ? temp.until : ''}
                useUntil={useUntil ?? false}
                canRemoveUntil={useUntil ?? false}
                onChangeValue={onChangeSelection}
                onChangeDisplayTime={(time: boolean) => {
                  if (temp) setTemp({ ...temp, time });
                }}
                onChangeUntil={(u: string) => {
                  if (temp)
                    setTemp({
                      ...temp,
                      until: u.length > 0 ? u : undefined,
                    });
                }}
              />
            ) : null}
            {usedType === DatePickerType.week ? (
              <DatepickerInnerWeek
                startDate={
                  temp ? parseDate(temp.startDate).toJSDate() : undefined
                }
                onChangeValue={onChangeSelection}
              />
            ) : null}
            {usedType === DatePickerType.month ? (
              <DatepickerInnerMonth
                allowPast={allowPast}
                startDate={
                  temp ? parseDate(temp.startDate).toJSDate() : undefined
                }
                endDate={
                  temp && temp.endDate
                    ? parseDate(temp.endDate).toJSDate()
                    : undefined
                }
                onChangeValue={onChangeSelection}
              />
            ) : null}
            {usedType === DatePickerType.quarter ? (
              <DatepickerInnerQuarter
                allowPast={allowPast}
                startDate={
                  temp ? parseDate(temp.startDate).toJSDate() : undefined
                }
                endDate={
                  temp && temp.endDate
                    ? parseDate(temp.endDate).toJSDate()
                    : undefined
                }
                onChangeValue={onChangeSelection}
              />
            ) : null}
            {usedType === DatePickerType.year ? (
              <DatepickerInnerYear
                startDate={
                  temp ? parseDate(temp.startDate).toJSDate() : undefined
                }
                onChangeValue={onChangeSelection}
              />
            ) : null}
          </div>
          <div className='footer'>
            <button
              onClick={() => {
                temp && onChange && onChange(temp, usedType);
                setIsOpen(false);
              }}
            >
              <CheckDown />
              Anwenden
            </button>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

export default Datepicker;
