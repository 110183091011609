import {
  Comparator,
  FILTER_OPERATOR_CONTAINS,
  FILTER_OPERATOR_EMPTY,
  FILTER_OPERATOR_EQ_NUMBER,
  FilterComparator,
} from '../Table/Filter';
import React, { Fragment } from 'react';

import { CommentTooltip } from '../Planing/Steps/CommentTooltip';
import { DateTime } from 'luxon';
import LocationDuePreview from '../Planing/Steps/LocationDuePreview';
import LocationOperatingExpensePreview from '../Planing/Steps/LocationOperatingExpensePreview';
import LocationPricePreview from '../Planing/Steps/LocationPricePreview';
import { NoteTooltip } from '../common/NoteTooltip';
import { PlanningConsumer } from '../../context/PlanningContext';
import TooltipTrigger from 'react-popper-tooltip';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import shortCurrency from '../../utils/shortCurrency';

function dueToText(_due: DateTime) {
  return _due < DateTime.fromISO('1990-01-01')
    ? 'Überfällig'
    : _due.toFormat('MMM yyyy');
}

function getDueWarningLevel(
  dtz: DateTime,
  planningWeek: Date
): {
  'due-warning due-warning-1': boolean;
  'due-warning due-warning-2': boolean;
  'due-warning due-warning-3': boolean;
} {
  const r = {
    'due-warning due-warning-1':
      dtz <
      DateTime.fromJSDate(planningWeek).setLocale('de').minus({ months: 1 }),
    'due-warning due-warning-2':
      dtz <
      DateTime.fromJSDate(planningWeek).setLocale('de').minus({ months: 2 }),
    'due-warning due-warning-3':
      dtz <
      DateTime.fromJSDate(planningWeek).setLocale('de').minus({ months: 3 }),
  };
  return r;
}

export type FormatValue =
  | string
  | number
  | Date
  | boolean
  | { [key: string]: FormatValue }
  | Array<FormatValue>;

export interface TabledHeaderFieldBase {
  counter?: boolean;
  key: string;
  title: string;
  width: string | number;
  sort?: boolean;
  highlight?: boolean;
  noLink?: boolean;
  emptySymbol?: string;
  format?: (value: FormatValue, data: any) => JSX.Element | undefined;
}

export interface TabledHeaderFieldSum {
  sum: true;
  sumSec?: (item: any) => number;
  formatSum: (value: FormatValue, data: any) => string;
}

export interface TabledHeaderFieldFilter {
  filterable: true;
  suggestion: boolean;
  filterComparators?: FilterComparator[];
  equalComparator: Comparator;
  notEqualComparator: Comparator;
}

export type TabledHeaderFieldWithSum = TabledHeaderFieldBase &
  TabledHeaderFieldSum;
export type TabledHeaderFieldWithFilter = TabledHeaderFieldBase &
  TabledHeaderFieldFilter;
export type TabledHeaderFieldWithFilterAndSum = TabledHeaderFieldBase &
  TabledHeaderFieldSum &
  TabledHeaderFieldFilter;

export type TabledHeaderField =
  | TabledHeaderFieldBase
  | TabledHeaderFieldWithSum
  | TabledHeaderFieldWithFilter
  | TabledHeaderFieldWithFilterAndSum;

function stringifyDate(date: string) {
  return DateTime.fromISO(date, {
    zone: 'UTC',
  })
    .toLocal()
    .toFormat("'KW'WW");
}

export default function createLocationTableHeader(
  tasks: { key: string; title: string }[]
): TabledHeaderField[] {
  return [
    {
      key: 'allNextJobDate',
      width: '0-75',
      sort: false,
      suggestion: false,
      filterable: true,
      filterComparators: [
        ...FILTER_OPERATOR_CONTAINS,
        ...FILTER_OPERATOR_EMPTY,
      ],
      equalComparator: Comparator['contains'],
      notEqualComparator: Comparator['no-contains'],
      title: 'Plan',
      format: (value: FormatValue, data: any) => {
        const isPlaned =
          data && data.allNextJobDate && data.allNextJobDate.length > 0;
        return (
          <span>
            {isPlaned
              ? `${data.allNextJobDate
                  .map((a: string) => stringifyDate(a))
                  .join(', ')}`
              : ''}
          </span>
        );
      },
    },
    {
      key: 'customer.name',
      width: '1-25',
      sort: true,
      filterable: true,
      suggestion: true,
      equalComparator: Comparator['='],
      notEqualComparator: Comparator['!='],
      title: 'Kunde',
    },
    {
      counter: true,
      key: 'name',
      width: '1',
      filterable: true,
      suggestion: true,
      sort: true,
      title: 'Standort',
      equalComparator: Comparator['='],
      notEqualComparator: Comparator['!='],
      format: (value: FormatValue, data: any) => {
        const isPlaned = data && data.nextJob && data.nextJob.all;
        const classes = classNames({
          'name-with-planed-date': isPlaned,
        });
        return (
          <Fragment>
            <span className={classes}>{value}</span>
          </Fragment>
        );
      },
    },
    {
      key: 'tag',
      width: '1',
      sort: true,
      filterable: true,
      suggestion: true,
      equalComparator: Comparator['='],
      notEqualComparator: Comparator['!='],
      title: 'Standort NR',
    },
    {
      key: 'address.street',
      width: '1-25',
      filterable: true,
      suggestion: true,
      equalComparator: Comparator['='],
      notEqualComparator: Comparator['!='],
      sort: true,
      title: 'Straße',
    },
    {
      key: 'address.postalCode',
      width: '0-75',
      filterable: true,
      suggestion: true,
      equalComparator: Comparator['='],
      notEqualComparator: Comparator['!='],
      sort: true,
      title: 'PLZ',
    },
    {
      key: 'address.city',
      width: '1',
      filterable: true,
      suggestion: true,
      equalComparator: Comparator['='],
      notEqualComparator: Comparator['!='],
      sort: true,
      title: 'Ort',
    },
    {
      key: 'contractValueRest',
      width: '0-75',
      sort: false,
      filterable: true,
      title: 'rest. AW',
      sum: true,
      filterComparators: FILTER_OPERATOR_EQ_NUMBER,
      equalComparator: Comparator['=N'],
      notEqualComparator: Comparator['!=N'],
      format: (value: FormatValue, data: any | undefined) =>
        value != null ? (
          <>{`${shortCurrency(Number(value), '0')} €`}</>
        ) : (
          <p>Unendlich</p>
        ),
    },
    {
      key: 'contractValueSum',
      width: '0-75',
      filterable: true,
      sum: true,
      title: 'AW',
      filterComparators: FILTER_OPERATOR_EQ_NUMBER,
      equalComparator: Comparator['=N'],
      notEqualComparator: Comparator['!=N'],
      format: (value: FormatValue, data: any | undefined) =>
        value != null ? (
          <>{`${shortCurrency(Number(value), '0')} €`}</>
        ) : (
          <p>Unendlich</p>
        ),
    },
    {
      key: 'duePrice',
      width: '0-75',
      sort: true,
      // highlight: true,
      sum: true,
      // sumSec: (item) => item.contractValueRest ?? item.duePrice,
      filterComparators: FILTER_OPERATOR_EQ_NUMBER,
      equalComparator: Comparator['=N'],
      notEqualComparator: Comparator['!=N'],
      filterable: true,
      suggestion: false,
      title: 'Preis',
      formatSum: (value: FormatValue) =>
        value !== undefined &&
        value !== null &&
        typeof value === 'number' &&
        value > 0
          ? `${shortCurrency(value as number, '0.00')}€`
          : '',
      format: (value: FormatValue, data: any | undefined) => {
        const priceMissing = !isNumber(value);
        const _value = priceMissing
          ? 'Preis fehlt'
          : `${shortCurrency(value as number, '0')} €`;
        return (
          <TooltipTrigger
            placement='auto'
            trigger='hover'
            delayShow={320}
            tooltip={({
              getTooltipProps,
              getArrowProps,
              tooltipRef,
              arrowRef,
              placement,
            }) => (
              <div
                {...getTooltipProps({
                  ref: tooltipRef,
                  className: 'tooltip-container',
                })}
              >
                <div
                  {...getArrowProps({
                    ref: arrowRef,
                    'data-placement': placement,
                    className: 'tooltip-arrow',
                  })}
                />
                <div className='tooltip-body'>
                  <LocationPricePreview location={data._id} data={data} />
                </div>
              </div>
            )}
          >
            {({ getTriggerProps, triggerRef }) => (
              <div
                {...getTriggerProps({
                  ref: triggerRef,
                  className: 'tasks row trigger',
                })}
              >
                <Fragment>
                  <span
                    className={classNames({
                      highlight: !data?.duePriceManipulated && !priceMissing,
                      warning: data?.duePriceManipulated,
                      danger: priceMissing,
                    })}
                  >
                    {_value}
                  </span>
                  <span
                    className={classNames({
                      hidden: !data?.contractValueRest,
                    })}
                  >
                    {shortCurrency(data?.contractValueRest, '0')} €
                  </span>
                </Fragment>
              </div>
            )}
          </TooltipTrigger>
        );
      },
    },
    {
      key: 'operatingExpense',
      width: '0-75',
      sort: true,
      title: 'AZ',
      sum: true,
      filterComparators: FILTER_OPERATOR_EQ_NUMBER,
      equalComparator: Comparator['=N'],
      notEqualComparator: Comparator['!=N'],
      filterable: true,
      suggestion: false,
      format: (value: FormatValue, data: any) => {
        return (
          <TooltipTrigger
            placement='auto'
            trigger='hover'
            delayShow={320}
            tooltip={({
              getTooltipProps,
              getArrowProps,
              tooltipRef,
              arrowRef,
              placement,
            }) => (
              <div
                {...getTooltipProps({
                  ref: tooltipRef,
                  className: 'tooltip-container',
                })}
              >
                <div
                  {...getArrowProps({
                    ref: arrowRef,
                    'data-placement': placement,
                    className: 'tooltip-arrow',
                  })}
                />
                <div className='tooltip-body'>
                  <LocationOperatingExpensePreview
                    location={data._id}
                    tasks={tasks}
                    dues={data._dues}
                  />
                </div>
              </div>
            )}
          >
            {({ getTriggerProps, triggerRef }) => (
              <div
                {...getTriggerProps({
                  ref: triggerRef,
                  className: 'tasks row trigger',
                })}
              >
                <span
                  className={classNames({
                    warning: data?.duePriceManipulated,
                  })}
                >
                  {value !== undefined && typeof value === 'number' && value > 0
                    ? `${shortCurrency(value as number, '0.00')}h`
                    : null}
                </span>
              </div>
            )}
          </TooltipTrigger>
        );
      },
      formatSum: (value: FormatValue) =>
        value && value > 0 ? `${shortCurrency(value as number, '0.00')}h` : '',
    },
    {
      key: '_dues',
      width: '1',
      sort: false,
      title: 'Prüfungen',
      format: (value: FormatValue, data: any) => {
        return (
          <TooltipTrigger
            placement='auto'
            trigger='hover'
            delayShow={320}
            tooltip={({
              getTooltipProps,
              getArrowProps,
              tooltipRef,
              arrowRef,
              placement,
            }) => (
              <div
                {...getTooltipProps({
                  ref: tooltipRef,
                  className: 'tooltip-container',
                })}
              >
                <div
                  {...getArrowProps({
                    ref: arrowRef,
                    'data-placement': placement,
                    className: 'tooltip-arrow',
                  })}
                />
                <div className='tooltip-body'>
                  <LocationDuePreview location={data._id} tasks={tasks} />
                </div>
              </div>
            )}
          >
            {({ getTriggerProps, triggerRef }) => (
              <div
                {...getTriggerProps({
                  ref: triggerRef,
                  className: 'tasks row trigger',
                })}
              >
                {tasks
                  .filter(
                    ({ key }: { title: string; key: string }) =>
                      value && (value as any)[key]
                  )
                  .map(({ title, key }: { title: string; key: string }) => (
                    <span
                      key={key}
                      className={
                        value && (value as any)[key]
                          ? 'task col col-2 task-done'
                          : 'task col col-2'
                      }
                    >
                      {title}
                    </span>
                  ))}
              </div>
            )}
          </TooltipTrigger>
        );
      },
    },
    {
      key: 'dues.all',
      width: '0-5',
      sort: true,
      title: 'FK',
      format: (value: FormatValue, data: any) => {
        const dtz = DateTime.fromISO(value as string, {
          zone: 'UTC',
        }).toLocal();
        return (
          <PlanningConsumer>
            {({ planningWeek }: any) => {
              const dueFixed =
                (data.additionalTasks || []).filter((a: any) => a.dueFixed)
                  .length > 0;
              const classes = classNames('due', {
                'due-later': !value,
                'due-fixed': dueFixed,
                ...getDueWarningLevel(dtz, planningWeek),
              });
              return (
                <span className={classes}>
                  {dtz.isValid ? dueToText(dtz.setLocale('de')) : ''}
                </span>
              );
            }}
          </PlanningConsumer>
        );
      },
    },
    {
      key: 'comment',
      width: '0-15',
      sort: false,
      title: '',
      format: (value: FormatValue, data: any) => {
        return (
          <Fragment>
            <CommentTooltip value={data.comment} />
            <NoteTooltip value={data.note} />
          </Fragment>
        );
      },
    },
  ];
}
