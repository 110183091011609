import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const COLORS = [
  '#D6F5DB', // Sehr sanftes Pastellgrün
  '#FFFDF0', // Sehr sanftes Pastellgelb
  '#FFE4E9', // Sehr sanftes Pastellrosa
  '#F3DDF3', // Sehr sanftes Pastelllila
  '#D6E8FF', // Sehr sanftes Pastellblau
  '#E8F5E0', // Sehr sanftes Pastellmint
  '#FFF1E0', // Sehr sanftes Pastellorange
];

export interface CardListGroupProps {
  title?: string;
  subtitle?: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
  index: number;
  connect?: (item: React.ReactNode, index: number) => React.ReactNode;
}

const Wrapper = styled.div<{
  bg: string;
}>`
  margin-bottom: 2rem;
  .item {
    border-color: ${(props) => props.bg} !important;
    .header {
      background-color: ${(props) => props.bg};
    }
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 12px;
  color: rgb(0, 152, 66);
  font-weight: semibold;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  line-height: 12px;
  user-select: none;
`;

const Subtitle = styled.p`
  font-size: 12px;
  color: rgba(0, 152, 66, 0.5);
  font-weight: semibold;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  margin-left: 1rem;
  line-height: 12px;
  user-select: none;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: red;
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
  padding: 20px 0;
  cursor: pointer;
  user-select: none;
`;

const ColorCardListGroup: FunctionComponent<CardListGroupProps> = ({
  title,
  subtitle,
  children,
  index,
}) => {
  const color = COLORS[index % COLORS.length];
  return (
    <Wrapper attr-id={`g${index}`} bg={color}>
      {title ||
        (subtitle && (
          <Header>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Header>
        ))}
      {children}
    </Wrapper>
  );
};

export default ColorCardListGroup;
