import '../Planing/Planning.scss';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AuthConsumer } from '../../context/AuthContext';
import DatePicker, { DatePickerType, parseDate } from '../Picker/DatePicker';
import Filter from '../Tours/Filter';
import NumberFormat from 'react-number-format';
import { ReactComponent as World } from '../../assets/icons/world-map.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';
import Table, { SELECTION_TYPE } from '../Table/Table';
import { DateTime } from 'luxon';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { extensionRowRender } from '../Planing/Steps/PlanningStepLocationsCommon';
import { pickBy } from 'lodash';
import getTasks from '../Location/getTasks';
import createLocationTableHeader, {
  TabledHeaderField,
} from './createLocationTableHeader';
import { Request } from '../../api/Request';

interface AnalysisLocationsProps {}

const AnalysisLocations: FunctionComponent<AnalysisLocationsProps> = ({}) => {
  const [items, setItems] = useState([]);
  const [header, setHeader] = useState<TabledHeaderField[]>([]);
  const [options, setOptions] = useState<Record<string, any>>({
    filters: {},
  });
  const [majorOptions, setMajorOptions] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(true);
  const [foresightedMonthCount, setForesightedMonthCount] = useState(3);
  // const [unfilteredValues, setUnfilteredValues] = useState<
  //   Record<string, string[]>
  // >({});

  const [planningWeek, setPlanningWeek] = useState(new Date());

  const loadData = useCallback(async () => {
    const getLoadDateParams = () => {
      const endDate = DateTime.fromJSDate(planningWeek)
        .plus({ months: foresightedMonthCount })
        .toISO();

      const planningWeekStart = DateTime.fromJSDate(planningWeek)
        .startOf('week')
        .toISO();

      const planningWeekEnd = DateTime.fromJSDate(planningWeek)
        .endOf('week')
        .toISO();

      return {
        filters: JSON.stringify(options.filters),
        sort: options.sort,
        desc: options.desc,
        end: endDate,
        uncomplete: true,
        hstart: planningWeekStart,
        hend: planningWeekEnd,
      };
    };

    try {
      setLoading(true);

      const result = await Request.list(
        'planning/locations/due',
        getLoadDateParams()
      );

      // const unfilteredValues = addTasksToUnfilteredValues(
      //   result.unfilteredValues
      // );
      setItems(result.items);
      // setUnfilteredValues(unfilteredValues);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }, [planningWeek, foresightedMonthCount, options]);

  const loadMeta = useCallback(async () => {
    const headerTasks = await getTasks();
    const _header = createLocationTableHeader(
      headerTasks.map(({ key, title }: TabledHeaderField) => ({ title, key }))
    );
    setHeader(_header);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    loadMeta();
  }, []);

  return (
    <AuthConsumer>
      {({ user }: { user: { id?: string } }) => (
        <div className='planning-wrapper'>
          <div className='location container-inner'>
            <div className='page-header row justify-content-between'>
              <div className='col col-2'>
                <h1 className='page-title'>Auswertung</h1>
              </div>
              <div className='col col-2'>
                {!loading && (
                  <Filter
                    style={{ width: '100%', marginTop: '4px' }}
                    name='address.countryCode'
                    noBorder={true}
                    options={majorOptions}
                    values={[]}
                    onChange={(opts) => {
                      setOptions(opts);
                    }}
                  >
                    <World />
                    {(!majorOptions ||
                      !majorOptions['address.countryCode'] ||
                      majorOptions['address.countryCode'].length === 0) && (
                      <span>Alle Länder</span>
                    )}
                    {majorOptions &&
                      majorOptions['address.countryCode'] &&
                      majorOptions['address.countryCode'].length > 0 && (
                        <span>
                          {majorOptions['address.countryCode']
                            .slice(0, 2)
                            .join(', ')}
                          {majorOptions['address.countryCode'].length > 2
                            ? ', ...'
                            : ''}
                        </span>
                      )}
                  </Filter>
                )}
              </div>
              <div className='col col-3 justify-content-md-end'>
                <DatePicker
                  types={[DatePickerType.week]}
                  value={{ startDate: planningWeek ?? new Date() }}
                  format="'Plan-KW' WW '-' yyyy"
                  onChange={(value) => {
                    const jsd = parseDate(value.startDate).toJSDate();
                    setPlanningWeek(jsd);
                  }}
                />
              </div>
              <div className='col col-3 page-header-actions justify-content-md-start pt-md-0 foresighted-months'>
                <p>Vorrausblick: </p>
                <NumberFormat
                  id='foresightedMonthCount'
                  disabled={loading}
                  thousandSeparator='.'
                  decimalSeparator=','
                  value={foresightedMonthCount}
                  allowNegative={false}
                  prefix=''
                  suffix={foresightedMonthCount !== 1 ? ' Monate' : ' Monat'}
                  decimalScale={0}
                  onValueChange={(e) => {
                    let a = e.floatValue;
                    if (a === undefined || a === null) a = 3;
                    setForesightedMonthCount(a);
                  }}
                  onKeyDown={(e) => {
                    // arrow up/down button should select next/previous list element
                    if (e.keyCode === 38 && foresightedMonthCount > 0) {
                      setForesightedMonthCount(foresightedMonthCount - 1);
                    } else if (e.keyCode === 40) {
                      setForesightedMonthCount(foresightedMonthCount + 1);
                    }
                  }}
                />
                <button
                  onClick={() => {
                    if (foresightedMonthCount > 0) {
                      setForesightedMonthCount(foresightedMonthCount - 1);
                    }
                  }}
                >
                  <ArrowDown />
                </button>
                <button
                  onClick={() => {
                    setForesightedMonthCount(foresightedMonthCount + -1);
                  }}
                >
                  <ArrowUp />
                </button>
              </div>
              <div className='col col-1 page-header-actions justify-content-md pt-md-0 foresighted-months'>
                {!loading && (
                  <div onClick={() => loadData()}>
                    <RefreshIcon />
                    Aktualisieren
                  </div>
                )}
              </div>
            </div>
            <div className='row page-content'>
              <div className='col col-12'>
                <Table
                  selectable={SELECTION_TYPE.disabled}
                  loading={loading}
                  filterable={true}
                  options={options}
                  header={header}
                  items={items}
                  // unfilteredValues={unfilteredValues}
                  handleOptionsChanged={(opts) => setOptions(opts)}
                  // handleSelectionChanged={(locationToSet, selection) => {
                  //   handleSelectionChanged(
                  //     locationToSet,
                  //     selection,
                  //     locations || [],
                  //     setLocations,
                  //     setLoadingInfo,
                  //     setInfo
                  //   );
                  // }}
                  link='/administration/locations'
                  linkTarget='_blank'
                  extensionGetter={(item) => {
                    const {
                      _id,
                      address,
                      addressRouting,
                      tag,
                      customer,
                      contractValueSum,
                      checkDepth,
                      name,
                      allNextJobDate,
                      note,
                      holidays,
                      contractValueRest,
                    } = item;
                    return item.additionalTasks
                      .filter((t: any) => {
                        if (!t.dueFixed) return true;
                        if (!planningWeek) return false;
                        const dt = DateTime.fromISO(t.due);
                        const pt = DateTime.fromJSDate(planningWeek);
                        return (
                          dt.weekYear === pt.weekYear &&
                          dt.weekNumber === pt.weekNumber
                        );
                      }) // alle Termine in der Plan KW
                      .map((p: any) => {
                        return {
                          ...p,
                          disabled: allNextJobDate && allNextJobDate.length > 0,
                          additionalTask: p._id,
                          locID: _id,
                          address,
                          addressRouting,
                          tag,
                          customer,
                          contractValueSum,
                          checkDepth,
                          name,
                          comment: p.message,
                          note: note,
                          holidays,
                          contractValueRest,
                          operatingExpense: p.planHours,
                          duePrice: p.estimatedPrice,
                          job: p.job,
                          _dues: pickBy(
                            p.type,
                            (value, key) => !key.startsWith('_')
                          ),
                          freezeComment: true,
                          fixedDateFromAdditionalTasks: p.dueFixed
                            ? p.due
                            : undefined,
                        };
                      })
                      .sort(
                        (firstEl: any, secondEl: any) =>
                          firstEl &&
                          firstEl.fixedDateFromAdditionalTasks &&
                          secondEl &&
                          secondEl.fixedDateFromAdditionalTasks &&
                          firstEl.fixedDateFromAdditionalTasks.localeCompare(
                            secondEl.fixedDateFromAdditionalTasks
                          )
                      );
                  }}
                  extensionRow={(
                    item: any,
                    index: number,
                    columns: any[],
                    isItemSelected: (id: string) => boolean,
                    isItemDisabled?: (id: string) => boolean,
                    isItemDisabledInfo?: (item: any) => React.ReactNode,
                    handleSelectionChanged?: (item: any, value: boolean) => void
                  ) => {
                    return extensionRowRender(
                      item,
                      index,
                      columns,
                      isItemSelected,
                      (id: string) =>
                        (isItemDisabled && isItemDisabled(id)) || item.disabled,
                      () => <></>,
                      handleSelectionChanged
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </AuthConsumer>
  );
};

export default AnalysisLocations;
