import './Dashboard.scss';

import { get } from 'lodash';
import { DateTime, Interval } from 'luxon';
import qs from 'query-string';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Request } from '../../api/Request';
import DatePicker, {
  DatePickerType,
  datePickerTypeFromString,
  datePickerTypeTotring,
  parseDate,
} from '../Picker/DatePicker';
import BottomRentaChart, {
  defaultSize as BottomRentaChartDefaultSize,
} from './BuildInCharts/bottomRenta';
import DailyRate, {
  defaultSize as DailyRateDefaultSize,
} from './BuildInCharts/daily-rate';
import HotelsChart, {
  defaultSize as HotelsChartDefaultSize,
} from './BuildInCharts/hotels';
import LocationsDueChart, {
  defaultSize as LocationsDueChartDefaultSize,
} from './BuildInCharts/locationsDue';
import PlaningChart, {
  defaultSize as PlaningChartDefaultSize,
} from './BuildInCharts/planing';
import RentaChart, {
  defaultSize as RentaChartDefaultSize,
} from './BuildInCharts/renta';
import SalesLocationChart, {
  defaultSize as SalesLocationChartDefaultSize,
} from './BuildInCharts/sales-locations';
import TechsChart, {
  defaultSize as TechsChartDefaultSize,
} from './BuildInCharts/techs';
import TopRentaChart, {
  defaultSize as TopRentaChartDefaultSize,
} from './BuildInCharts/topRenta';
import TopRenta2Chart, {
  defaultSize as TopRenta2ChartDefaultSize,
} from './BuildInCharts/topRenta2';
import TotalChart, {
  defaultSize as TotalChartDefaultSize,
} from './BuildInCharts/total';
import TourRate, {
  defaultSize as TourRateDefaultSize,
} from './BuildInCharts/tour-rate';
import ToursChart, {
  defaultSize as ToursChartDefaultSize,
} from './BuildInCharts/tours';

import { SizeMeProps, withSize } from 'react-sizeme';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import {
  PageHeaderAction,
  PageHeaderActions,
} from '../PageHeader/PageHeaderActions';
import AddChartModal from './AddChartModal';
import UnkownChart from './BuildInCharts/unkown';
import EditChartModal from './EditChartModal';
import RentaTotalRatioChart from './BuildInCharts/renta-total-ratio';

interface DashboardProps {}

const Box = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 24px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  width: 100%;
  display: block;
`;

export interface Schema {
  index: string;
  type: string;
  x: number;
  y: number;
  w: number;
  h: number;
  colors: string[];
}

const getDefaultSize = (type: string) => {
  switch (type) {
    case 'TechsChart':
      return TechsChartDefaultSize;
    case 'PlaningChart':
      return PlaningChartDefaultSize;
    case 'HotelsChart':
      return HotelsChartDefaultSize;
    case 'SalesLocationChart':
      return SalesLocationChartDefaultSize;
    case 'LocationsDueChart':
      return LocationsDueChartDefaultSize;
    case 'TopRenta2Chart':
      return TopRenta2ChartDefaultSize;
    case 'TopRentaChart':
      return TopRentaChartDefaultSize;
    case 'BottomRentaChart':
      return BottomRentaChartDefaultSize;
    case 'DailyRateChart':
      return DailyRateDefaultSize;
    case 'TourRateChart':
      return TourRateDefaultSize;
    case 'ToursChart':
      return ToursChartDefaultSize;
    case 'TotalChart':
      return TotalChartDefaultSize;
    case 'RentaChart':
      return RentaChartDefaultSize;
    default:
      return { w: 2, h: 1, minW: 2, minH: 1, maxW: 4, maxH: 4 };
  }
};

const Dashboard: FunctionComponent<DashboardProps & SizeMeProps> = ({
  size: { width },
}) => {
  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    []
  );

  const [loading, setLoading] = React.useState<boolean>(true);
  const [schema, setSchema] = React.useState<Schema[]>([]);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [editChartId, setEditChartId] = React.useState<string | null>(null);

  const [dateType, setDateType] = React.useState<DatePickerType>();
  const [dateRange, setDateRange] = React.useState<Interval>();

  const loadData = useCallback(() => {
    console.log('loadData');
    Request.list('dashboard', {})
      .then((res: any) => {
        setSchema(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const loadOptionsFromURL = () => {
    // console.log('loadOptionsFromURL');
    // const data = qs.parse(location.search);

    // if (Object.keys(data).length === 0) {
    const interval = Interval.fromDateTimes(
      DateTime.local().startOf('week'),
      DateTime.local().endOf('week')
    );

    storeOptionsAtURL({
      dateType: DatePickerType.week,
      dateRange: interval,
    });

    setDateType(DatePickerType.week);
    setDateRange(interval);
    // } else {
    //   setDateType(
    //     datePickerTypeFromString(get(data, 'dateType', dateType) as string)
    //   );

    //   const from = DateTime.fromISO(
    //     get(
    //       data,
    //       'dateRange.start',
    //       DateTime.local().startOf('week').toISO()
    //     ) as string
    //   );
    //   const to = DateTime.fromISO(
    //     get(
    //       data,
    //       'dateRange.end',
    //       DateTime.local().endOf('week').toISO()
    //     ) as string
    //   );

    //   // console.log(from, to);

    //   setDateRange(Interval.fromDateTimes(from, to));
    // }
  };

  useEffect(() => {
    loadOptionsFromURL();
    loadData();
  }, []);

  const storeOptionsAtURL = (data: {
    dateType: DatePickerType;
    dateRange: {
      start: DateTime;
      end: DateTime;
    };
  }) => {
    return;
    console.log('storeOptionsAtURL');
    const s = {
      dateType: datePickerTypeTotring(data.dateType),
      'dateRange.start': data.dateRange.start.toISO(),
      'dateRange.end': data.dateRange.end.toISO(),
    };
    const stringified = qs.stringify(s);
    window.history.replaceState(s, 'safePlan', `?${stringified}`);
  };

  const add = () => {
    setModalOpen(true);
  };

  const dedit = () => {
    if (edit) {
      Request.post('dashboard', schema);
    }
    setEdit(!edit);
  };

  const addChart = async (type: string) => {
    // console.log(type);
    setSchema([
      ...schema,
      { index: uuid(), type, x: 0, y: 0, ...getDefaultSize(type), colors: [] },
    ]);
    setModalOpen(false);
  };

  const onDelete = (index: string) => {
    setSchema(schema.filter((s) => s.index !== index));
  };

  const onEdit = (index: string) => {
    setEditChartId(index);
  };

  const editChart = async (chart: Schema) => {
    setSchema(schema.map((s) => (s.index === chart.index ? chart : s)));
    setEditChartId(null);
  };

  const getChart = (item: Schema) => {
    if (!dateRange || !dateType) {
      return <div></div>;
    }

    if (item.type === 'ToursChart') {
      return (
        <ToursChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'DailyRate') {
      return (
        <DailyRate
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'TourRateChart') {
      return (
        <TourRate
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'PlaningChart') {
      return (
        <PlaningChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'TechsChart') {
      return (
        <TechsChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'HotelsChart') {
      return (
        <HotelsChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'TotalChart') {
      return (
        <TotalChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'RentaChart') {
      return (
        <RentaChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'RentaTotalRatioChart') {
      return (
        <RentaTotalRatioChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'SalesLocationChart') {
      return (
        <SalesLocationChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'LocationsDueChart') {
      return (
        <LocationsDueChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'TopRentaChart') {
      return (
        <TopRentaChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'TopRenta2Chart') {
      return (
        <TopRenta2Chart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }
    if (item.type === 'BottomRentaChart') {
      return (
        <BottomRentaChart
          dateRange={dateRange}
          colors={item.colors}
          delete={edit ? () => onDelete(item.index) : undefined}
          edit={edit ? () => onEdit(item.index) : undefined}
        />
      );
    }

    return (
      <UnkownChart
        title={item.type}
        delete={edit ? () => onDelete(item.index) : undefined}
      />
    );
  };

  if (!dateRange) {
    return <div>dateRange</div>;
  }

  if (!dateType) {
    return <div>dateType</div>;
  }

  return (
    <>
      <div className='dashboard container-inner'>
        <div className='page-header page-header-line row justify-content-between'>
          <div className='col col-12 col-md-6'>
            <DatePicker
              allowPast={true}
              disabled={edit}
              types={[
                DatePickerType.week,
                DatePickerType.month,
                DatePickerType.quarter,
                DatePickerType.year,
              ]}
              value={{
                startDate: dateRange.start.toJSDate(),
              }}
              type={dateType}
              onChange={(value, type) => {
                // console.log('onChange');
                setDateType(type);
                setDateRange(
                  Interval.fromDateTimes(
                    parseDate(value.startDate),
                    parseDate(value.endDate ?? new Date())
                  )
                );
                storeOptionsAtURL({
                  dateType: type,
                  dateRange: Interval.fromDateTimes(
                    parseDate(value.startDate),
                    parseDate(value.endDate ?? new Date())
                  ),
                });
              }}
            />
          </div>
          <PageHeaderActions className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
            {edit && (
              <PageHeaderAction onClick={() => add()}>
                <PlusIcon />
                <p>Neu</p>
              </PageHeaderAction>
            )}
            <PageHeaderAction onClick={() => dedit()}>
              {!edit ? <EditIcon /> : <CheckIcon />}
              <p>{!edit ? 'Bearbeiten' : 'Speichern'}</p>
            </PageHeaderAction>
          </PageHeaderActions>
        </div>
        {!loading && schema.length === 0 && (
          <div key='empty' className='empty center text-center p-4'>
            <p>Keine Charts vorhanden</p>
            <button
              className='btn btn-primary mt-1'
              onClick={() => {
                setEdit(true);
                add();
              }}
            >
              Chart hinzufügen
            </button>
          </div>
        )}
        {schema.length > 0 && (
          <ResponsiveReactGridLayout
            isDraggable={edit}
            isResizable={edit}
            style={{ width: '100%', left: '44px' }}
            className='layout'
            cols={{ lg: 4, md: 2, sm: 2, xs: 1, xxs: 1 }}
            rowHeight={204}
            width={width ?? 1920}
            containerPadding={[0, 32]}
            margin={[24, 24]}
            maxRows={Infinity}
            compactType={undefined}
            preventCollision={false}
            onLayoutChange={(layout) => {
              setSchema(
                layout.map((item) => ({
                  x: item.x,
                  y: item.y,
                  w: item.w,
                  h: item.h,
                  type: schema.find((s) => s.index === item.i)?.type ?? '',
                  colors: schema.find((s) => s.index === item.i)?.colors ?? [],
                  index: item.i,
                }))
              );
            }}
          >
            {schema.map((item) => {
              const dfs = getDefaultSize(item.type);
              return (
                <Box
                  key={item.index}
                  data-grid={{
                    x: item.x,
                    y: item.y,
                    w: item.w,
                    h: item.h,
                    minW: dfs.minW,
                    maxW: dfs.maxW,
                    minH: dfs.minH,
                    maxH: dfs.maxH,
                  }}
                >
                  {getChart(item)}
                </Box>
              );
            })}
          </ResponsiveReactGridLayout>
        )}
      </div>
      <AddChartModal
        isOpen={modalOpen}
        close={() => setModalOpen(false)}
        onSubmit={(type) => addChart(type)}
      />
      <EditChartModal
        isOpen={editChartId != null}
        chart={schema.find((s) => s.index === editChartId)}
        close={() => setEditChartId(null)}
        onSubmit={(schema) => editChart(schema)}
      />
    </>
  );
};

export default withSize()(Dashboard);
