import { Modal, Select } from 'antd';

import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import CheckInput from '../Form/CheckInput';
import { ReactComponent as CheckBoldIcon } from '../../assets/icons/check-bold.svg';
import {
  ConfirmDialogProps,
  confirmable,
  createConfirmation,
} from 'react-confirm';
import { check } from 'prettier';
import classNames from 'classnames';

const { confirm } = Modal;

interface Email {
  email: string;
  source: string;
  disabled?: boolean;
}

const List = styled.div``;
const Item = styled.div<{
  selected?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 20px 10px;
  border: 1px solid ${(props) => (props.selected ? '#009942' : '#f0f0f0')};
  border-radius: 5px;
  margin: 5px 0;
  cursor: pointer;
  &.disabled {
    background: #fff;
    cursor: not-allowed;
    border: 1px solid #f4f4f4;
    color: #b3b3b3 !important;
    p {
      color: #b3b3b3 !important;
    }
  }
  &:hover {
    background-color: #f0f0f0;
  }
  input[type='checkbox'] {
    visibility: hidden;
    display: none;
  }
  input[type='checkbox']:checked + .check {
    background: #009942;
    border: 0;
    svg {
      opacity: 1;
    }
  }
`;

const ItemTitle = styled.p`
  width: 100%;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 14px;
  padding: 0 0 1px 0;
  white-space: nowrap;
  overflow: hidden;
`;

const ItemSubTitle = styled.p`
  width: 100%;
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 14px;
  padding: 0 0 1px 0;
  white-space: nowrap;
  overflow: hidden;
`;

const Label = styled.label`
  margin: 0px 13px 0px 3px;
  width: 18px;
  height: 18px;
  background: transparent;
  border-radius: 5px;
  border: 1px #b3b3b3 solid;
  display: inline-block;
  svg {
    width: 12px;
    height: 12px;
    display: block;
    opacity: 0;
    margin: 3px;
    // margin: 0 10px 0 0;
    path {
      fill: #ffffff;
      color: #ffffff;
    }
  }
  &:hover {
    cursor: pointer;
    background: #a1a1a1;
    border: 0;
    svg {
      opacity: 1;
    }
  }
`;

const EMailSelector: FunctionComponent<
  ConfirmDialogProps<
    {
      emails: Email[];
    },
    string[] | undefined
  >
> = ({ proceed, emails, show }) => {
  const [selected, setSelected] = React.useState<Email[]>([]);
  const valid = selected.length > 0;

  return (
    <Modal
      title={'An welche E-Mail-Adresse(n) soll die Nachricht gesendet werden?'}
      open={show}
      okText='Senden'
      okButtonProps={{ disabled: !valid }}
      onOk={() => {
        valid && proceed(selected.map((e) => e.email));
      }}
      onCancel={() => {
        proceed(undefined);
      }}
    >
      <List>
        {emails.map((e, i) => {
          const element = `email-select-${i}`;
          const s = selected.some((s) => s.email === e.email);
          const handleChange = (t?: React.ChangeEvent<HTMLInputElement>) => {
            if (t?.target.value || selected.some((s) => s.email === e.email)) {
              setSelected(selected.filter((s) => s.email !== e.email));
            } else {
              setSelected([...selected, e]);
            }
          };
          return (
            <Item
              key={i}
              selected={s}
              onClick={() => {
                if (e.disabled) return;
                handleChange();
              }}
              className={classNames({
                check: s,
                disabled: e.disabled,
              })}
            >
              <input
                type='checkbox'
                id={element}
                checked={s}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
              />
              <Label className='check' htmlFor={element}>
                <CheckBoldIcon />
              </Label>
              <div>
                {!e.disabled && (
                  <>
                    <ItemTitle>{e.source}</ItemTitle>
                    <ItemSubTitle>{e.email}</ItemSubTitle>
                  </>
                )}
                {e.disabled && (
                  <>
                    <ItemTitle>{e.source}</ItemTitle>
                    <ItemSubTitle>Keine E-Mail hinterlegt</ItemSubTitle>
                  </>
                )}
              </div>
            </Item>
          );
        })}
      </List>
    </Modal>
  );
};

const showEMailSelector = (emails: Email[]) => {
  return createConfirmation(confirmable(EMailSelector))({ emails });
};

export default showEMailSelector;
