import React, { useEffect, useState } from 'react';

import { ClipLoader } from 'react-spinners';
import { DateTime } from 'luxon';
import { Request } from '../../../api/Request';

interface LocationOperatingExpensePreviewProps {
  location: string;
  tasks: { key: string; title: string }[];
  dues: any;
}

function LocationOperatingExpensePreview(
  props: LocationOperatingExpensePreviewProps
) {
  const [data, setData] = useState<any>({ hits: [] });
  const [isLoading, setIsLoading] = useState(false);
  const id = props.location;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const d = await Request.get('locations', id);
      if (d && d.operatingExpenses) {
        setData(d.operatingExpenses);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (isLoading) {
    return <ClipLoader size={20} color={'#009842'} loading={true} />;
  }

  const renderTask = (key: string) => {
    const dtz = DateTime.fromISO(props.dues[key], { zone: 'UTC' }).toLocal();
    return (
      <li key={key} style={{ fontSize: '14px' }}>
        <b
          style={{
            minWidth: '42px',
            display: 'inline-block',
            textAlign: 'right',
            marginRight: '4px',
          }}
        >
          {key.toUpperCase()}:
        </b>{' '}
        <span
          style={{
            display: 'inline-block',
            lineHeight: '14px',
            fontStyle: dtz.isValid ? 'normal' : 'italic',
            fontSize: dtz.isValid ? '14px' : '12px',
            color: dtz.isValid ? '#212529' : '#a1a1a1',
          }}
        >
          {data[key]}h
        </span>
      </li>
    );
  };

  return (
    <ul
      style={{
        listStyle: 'none',
        padding: '8px',
        margin: '0px',
      }}
    >
      {props.tasks.map(({ key }) => renderTask(key))}
    </ul>
  );
}

export default LocationOperatingExpensePreview;
