import './Modal.scss';

import { ClipLoader } from 'react-spinners';
import Modal from 'react-modal';
import React from 'react';

interface TransferModalLoadingProps {
  isOpen: boolean;
}

export default function TransferModalLoading(props: TransferModalLoadingProps) {
  Modal.setAppElement('#root');

  const { isOpen } = props;

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          width: 575,
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
      contentLabel=''
    >
      <div className='modal' tabIndex={-1} role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Einstellungen werden übertagen...
                <br />
                Dies kann etwas Zeit in Anspruch nehmen.
              </h5>
            </div>
            <div className='modal-body'>
              <div
                style={{
                  position: 'absolute',
                  left: '50%',
                  width: '80px',
                  marginLeft: '-40px',
                  marginTop: '20px',
                }}
              >
                <ClipLoader size={80} color={'#009842'} loading={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
