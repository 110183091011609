import React, { FunctionComponent } from 'react';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import styled from 'styled-components';

interface RemoveButtonProps {
  onClick: () => void;
}

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 0 0 8px;
  svg {
    fill: #b3b3b3;
    height: 16px;
    width: 16px;
    transform: translateY(-3px);
    path {
      fill: #b3b3b3;
    }
  }
  &:hover {
    color: #e44;
    svg {
      fill: #e44;
      path {
        fill: #e44;
      }
    }
  }
`;

const RemoveButton: FunctionComponent<RemoveButtonProps> = ({ onClick }) => {
  return (
    <Button onClick={() => onClick()}>
      <TrashIcon />
    </Button>
  );
};

export default RemoveButton;
