import React, { Component } from 'react';

import { Progress } from 'antd';
import { Request } from '../../api/Request';

interface ImportStepRunningState {
  task?: any;
  loading: boolean;
  connectionError?: Error;
}

interface ImportStepRunningProps {
  onNoTaskFound: () => void;
}

enum Status {
  'UNKOWN' = -1,
  'PROVISIONING' = 0,
  'PENDING' = 1,
  'RUNNING' = 2,
}

function getStatusFromString(s: string): Status {
  if (!s) return Status.UNKOWN;
  switch (s.toLowerCase()) {
    case 'provisioning':
      return Status.PROVISIONING;
    case 'pending':
      return Status.PENDING;
    case 'running':
      return Status.RUNNING;
    default:
      return Status.UNKOWN;
  }
}

export default class ImportStepRunning extends Component<
  ImportStepRunningProps,
  ImportStepRunningState
> {
  state: ImportStepRunningState = {
    loading: false,
  };

  interval?: NodeJS.Timeout;

  componentDidMount() {
    console.log('componentDidMount');
    this.fetch();
    this.interval = setInterval(() => this.fetch(), 2500);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    this.interval && clearInterval(this.interval);
  }

  async fetch() {
    console.log('Fetch');

    this.setState({ loading: true });

    let task = undefined;
    let connectionError = undefined;

    // fetch task
    try {
      const tasks = await Request.list('import/locations', {}, false);
      if (tasks.length > 0) task = tasks[0];
    } catch (e: any) {
      console.error(e);
      connectionError = e;
    }

    if (!task && !connectionError) {
      this.props.onNoTaskFound();
    } else {
      this.setState({ connectionError, task, loading: false });
    }
  }

  renderState(status: Status) {
    const current = getStatusFromString(this.state.task?.lastStatus);

    if (status === current) {
      return <Progress percent={100} showInfo={false} status='active' />;
    }

    if (status < current) {
      return <Progress percent={100} showInfo={true} />;
    }

    if (status > current) {
      return <Progress percent={0} showInfo={false} />;
    }
  }

  render() {
    const { task } = this.state;
    return (
      <div className='steps-content-running'>
        <p>
          Der Import wurde gestartet. Sie erhalten nach Abschluss eine E-Mail.
        </p>
        {/* <div className='row mb-2'>
          <div className='col col col-12 col-md-6 col-lg-4 col-xl-2 mb-2'>
            <p>Bereitstellung</p>
          </div>
          <div className='col col-12 col-md-6 col-lg-8 col-xl-5 mb-2'>
            {this.renderState(Status.PROVISIONING)}
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col col col-12 col-md-6 col-lg-4 col-xl-2 mb-2'>
            <p>Initialisieren</p>
          </div>
          <div className='col col-12 col-md-6 col-lg-8 col-xl-5 mb-2'>
            {this.renderState(Status.PENDING)}
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col col col-12 col-md-6 col-lg-4 col-xl-2 mb-2'>
            <p>Importieren</p>
          </div>
          <div className='col col-12 col-md-6 col-lg-8 col-xl-5 mb-2'>
            {this.renderState(Status.RUNNING)}
          </div>
        </div>
        {task && (
          <span>
            {task.lastStatus} | {task.taskArn} | {task.startedAt}
          </span>
        )} */}
      </div>
    );
  }
}
