import { DateTime, Interval } from 'luxon';

import { Job } from '../../../context/Route';
import { reduce } from 'lodash';

export default function planedInHolidays(value: Job, holidays: any[]) {
  
  if (value.type === 0) return false;
  const d =
    typeof value.planedAt === 'string'
      ? DateTime.fromISO(value.planedAt!)
      : DateTime.fromJSDate(value.planedAt!);
  return reduce(
    holidays,
    (prev, curr) => {
      const i = Interval.fromDateTimes(
        DateTime.fromISO(curr.start),
        DateTime.fromISO(curr.end)
      );
      const res = i.contains(d);
      prev = res || prev;
      return prev;
    },
    false
  );
}
