import { cloneDeep, isEqual, reduce, set } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { notification } from 'antd';
import { IBlockData } from 'easy-email-core';
import {
  EmailEditor,
  EmailEditorProvider,
  EmailEditorProviderProps,
  IEmailTemplate,
} from 'easy-email-editor';
import { StandardLayout } from 'easy-email-extensions';

import Mustache from 'mustache';
import { Request } from '../../api/Request';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { AuthConsumer } from '../../context/AuthContext';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import {
  PageHeaderAction,
  PageHeaderActions,
} from '../PageHeader/PageHeaderActions';
import renderLoading from '../SharesActions/renderLoading';
import { useMergeTagsModal } from './useMergeTagsModal';

import '@arco-themes/react-easy-email-theme/css/arco.css';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';

interface EditProps {}

type EmailTemplate = any;

const fontList = ['Karla'].map((item) => ({ value: item, label: item }));

const Edit: FunctionComponent<EditProps> = ({}) => {
  const { id } = useParams<{ id: string }>();
  const [item, setItem] = useState<EmailTemplate | null>(null);

  const {
    modal: mergeTagsModal,
    mergeTags,
    setMergeTags,
  } = useMergeTagsModal({});

  const fetchData = useCallback(async () => {
    if (id) {
      const [_item, _preview] = await Promise.all([
        Request.get('email-layouts', id),
        Request.get('tours/preview/share/customer', 'preview'),
      ]);
      setItem(_item);
      setMergeTags(_preview);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);

  const initialValues: IEmailTemplate | null = useMemo(() => {
    if (!item || !item.content) return null;
    const sourceData = JSON.parse(item.content) as IBlockData;
    return {
      subTitle: '',
      subject: item.subject,
      content: sourceData,
    };
  }, [item]);

  const onSubmit = useCallback(
    async (values: IEmailTemplate) => {
      if (id) {
        const isChanged = !(
          isEqual(initialValues?.content, values.content) &&
          isEqual(initialValues?.subTitle, values?.subTitle) &&
          isEqual(initialValues?.subject, values?.subject)
        );

        // if (!isChanged) {
        //   notification.success({
        //     message: 'Änderungen gespeichert',
        //   });
        //   return;
        // }

        try {
          const content = JSON.stringify(values.content);

          await Request.put('email-layouts', item._id, {
            subject: values.subject,
            content,
          });
          notification.success({
            message: 'Änderungen gespeichert',
          });
        } catch (error: any) {
          console.log(error);
          notification.error({
            message: 'Fehler beim Speichern',
            description: 'Die Änderung konnte nicht gespeichert werden.',
          });
        }
      }
    },
    [id, initialValues]
  );

  const onBeforePreview: EmailEditorProviderProps['onBeforePreview'] =
    useCallback((html: string, mergeTags: any) => {
      const arrayTags = reduce(
        mergeTags,
        (acc: string[], cur: any, key: string) => {
          if (Array.isArray(cur)) {
            acc.push(key);
          }
          return acc;
        },
        []
      );

      // replace all tags from arraytags with mustache array handler
      const _html =
        arrayTags.length > 0
          ? reduce(
              arrayTags,
              (acc: string, cur: string) => {
                const t = `<ul>{{#${cur}}}<li>{{.}}</li>{{/${cur}}}</ul>`;
                return acc.replace(`{{${cur}}}`, t);
              },
              html
            )
          : html;

      const result = Mustache.render(_html, mergeTags);
      return result;
    }, []);

  const onChangeMergeTag = useCallback((path: string, val: any) => {
    
    setMergeTags((old) => {
      const newObj = cloneDeep(old);
      set(newObj, path, val);
      return newObj;
    });
  }, []);

  if (!item || !initialValues) return renderLoading();

  return (
    <AuthConsumer>
      {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
        <>
          <EmailEditorProvider
            key={id}
            height={'calc(100vh - 68px)'}
            data={initialValues}
            fontList={fontList}
            onSubmit={onSubmit}
            autoComplete
            enabledLogic
            enabledMergeTagsBadge
            dashed={false}
            mergeTags={mergeTags}
            mergeTagGenerate={(tag: string) => `{{${tag}}}`}
            onChangeMergeTag={onChangeMergeTag}
            onBeforePreview={onBeforePreview}
            socialIcons={[]}
          >
            {(_, { submit }: any) => {
              return (
                <div className='email-templates container-inner'>
                  <InPageNavigation
                    to='/administration/email-templates'
                    item={item}
                  />
                  <div className='page-header page-header-line row justify-content-between'>
                    <div className='col-6 col'>
                      <h1 className='page-title'>{item.name}</h1>
                    </div>
                    <PageHeaderActions className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                      {hasPermission(['email-templates:write']) === true ? (
                        <PageHeaderAction onClick={() => submit()}>
                          <CheckIcon />
                          <p>{'Speichern'}</p>
                        </PageHeaderAction>
                      ) : (
                        false
                      )}
                    </PageHeaderActions>
                  </div>
                  <div className='row'>
                    <div className='col col-12'>
                      <StandardLayout categories={[]}>
                        <EmailEditor />
                      </StandardLayout>
                    </div>
                  </div>
                </div>
              );
            }}
          </EmailEditorProvider>
          {mergeTagsModal}
        </>
      )}
    </AuthConsumer>
  );
};

export default Edit;
