import { ClipLoader } from 'react-spinners';
import React from 'react';

export default function renderLoading(size = 80, className = 'center-loader') {
  return (
    <div
      className={`col ${className}`}
      style={{
        textAlign: 'center',
      }}
    >
      <ClipLoader size={size} color={'#009842'} loading={true} />
    </div>
  );
}
