import React, { FunctionComponent } from 'react';
import Filter from '../../../Tours/Filter';
import { PlanningStepLocationsOptions } from '../PlanningStepLocations';

interface CountryFilterProps {
  options: any;
  disabled?: boolean;
  name?: string;
  onChange: (options: PlanningStepLocationsOptions) => void;
  direction?: 'up' | 'down';
}

const CountryFilter: FunctionComponent<CountryFilterProps> = ({
  name,
  options,
  onChange,
  disabled,
  direction,
}) => {
  return (
    <Filter
      disabled={disabled}
      name={name ?? 'address.countryCode'}
      options={options}
      multiple={true}
      direction={direction}
      values={[
        {
          text: 'Deutschland',
          value: 'Deutschland',
          key: 'DE',
        },
        {
          text: 'Österreich',
          value: 'Österreich',
          key: 'AT',
        },
        {
          text: 'Schweiz',
          value: 'Schweiz',
          key: 'CH',
        },
        {
          text: 'Niederlande',
          value: 'Niederlande',
          key: 'NL',
        },
        {
          text: 'Belgien',
          value: 'Belgien',
          key: 'BE',
        },
        {
          text: 'Luxemburg',
          value: 'Luxemburg',
          key: 'LU',
        },
        {
          text: 'Frankreich',
          value: 'Frankreich',
          key: 'FR',
        },
        {
          text: 'Italien',
          value: 'Italien',
          key: 'IT',
        },
        {
          text: 'Spanien',
          value: 'Spanien',
          key: 'ES',
        },
        {
          text: 'Portugal',
          value: 'Portugal',
          key: 'PT',
        },
        {
          text: 'Polen',
          value: 'Polen',
          key: 'PL',
        },
        {
          text: 'Tschechien',
          value: 'Tschechien',
          key: 'CZ',
        },
        {
          text: 'Schweden',
          value: 'Schweden',
          key: 'SE',
        },
        {
          text: 'Dänemark',
          value: 'Dänemark',
          key: 'DK',
        },
        {
          text: 'Norwegen',
          value: 'Norwegen',
          key: 'NO',
        },
        {
          text: 'Finnland',
          value: 'Finnland',
          key: 'FI',
        },
      ]}
      onChange={onChange}
    >
      Länder
    </Filter>
  );
};

export default CountryFilter;
