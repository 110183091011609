import React, { FunctionComponent, useState } from 'react';
import { ReactComponent as HotelIcon } from '../../../assets/icons/sleep.svg';
import { Job, HotelJob, NewJob, JOB_TYPE } from '../../../context/Route';
import { DateTime } from 'luxon';
import { message } from 'antd';
import { ClipLoader } from 'react-spinners';
import { addJob } from './utils/add-job';

interface AddHotelProps {
  before?: Job | HotelJob;
  onChanged?: () => void;
}

const AddHotel: FunctionComponent<AddHotelProps> = ({ before, onChanged }) => {
  const [loading, setLoading] = useState<boolean>(false);

  if (!before || !before.tour || !before.planedAt) {
    return null;
  }

  const handleClick = async () => {
    setLoading(true);

    try {
      const l =
        typeof before.planedAt === 'string'
          ? DateTime.fromISO(before.planedAt)
          : DateTime.fromJSDate(before.planedAt!);

      const job: NewJob = {
        sort: before.sort,
        type: JOB_TYPE.HotelAccommodation,
        nearByAddress: `${before.address.street}, ${before.address.postalCode} ${before.address.city}`,
        planedAtTimeByUser: false,
        planedAt: l.endOf('day').toISO(),
      };

      console.log('job', job);

      await addJob(job, before.tour!);

      onChanged && onChanged();
    } catch (error: any) {
      message.error('Fehler beim Hinzufügen des Jobs');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <ClipLoader size={'1rem'} color={'#009842'} loading={true} />;
  }

  return <HotelIcon onClick={() => handleClick()} />;
};

export default AddHotel;
