import { Button, Col, Modal, Row } from 'antd';

import { pickBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import {
  ConfirmDialogProps,
  confirmable,
  createConfirmation,
} from 'react-confirm';
import { AuthConsumer } from '../../context/AuthContext';
import { PlanningConsumer } from '../../context/PlanningContext';
import getTasks from '../Location/getTasks';
import createLocationTableHeader, {
  TabledHeaderField,
} from '../Planing/Steps/createLocationTableHeader';
import {
  defaultOptions,
  getRemainingOrderValueFromString,
  PlanningStepLocationsOptions,
  RemainingOrderValue,
} from '../Planing/Steps/PlanningStepLocations';
import {
  extensionRowRender,
  isItemDisabled,
  isItemDisabledInfo,
  selectionCellRenderer,
} from '../Planing/Steps/PlanningStepLocationsCommon';
import Table, { SELECTION_TYPE } from '../Table/Table';
import styled from 'styled-components';
import { Request } from '../../api/Request';
import { Comparator } from '../Table/Filter';
import RemainingOrderValueFilter from '../Planing/Steps/PlanningStepLocationsFilter/RemainingOrderValue';
import ForesightedMonthCount from '../Planing/Steps/PlanningStepLocationsFilter/ForesightedMonthCount';
import CountryFilter from '../Planing/Steps/PlanningStepLocationsFilter/CountryFilter';

const { confirm } = Modal;

type Location = any;

const Wrapper = styled.div`
  height: 65vh;
  background-color: #f0f0f0;
`;

const LocationSelector: FunctionComponent<
  ConfirmDialogProps<
    {
      planningWeek: Date;
    },
    Location | undefined
  >
> = ({ proceed, show, planningWeek }) => {
  const [selected, setSelected] = React.useState<Location[]>([]);
  const valid = selected.length === 1;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<Location[]>();
  const [options, setOptions] = React.useState<any>(defaultOptions);
  const [foresightedMonthCount, setForesightedMonthCount] = React.useState(0);

  const [header, setHeader] = React.useState<TabledHeaderField[]>([]);

  const getLoadDateParams = (
    planningWeek: Date,
    foresightedMonthCount: number
  ) => {
    const endDate = DateTime.fromJSDate(planningWeek)
      .plus({ months: foresightedMonthCount })
      .toISO();

    const planningWeekStart = DateTime.fromJSDate(planningWeek)
      .startOf('week')
      .toISO();

    const planningWeekEnd = DateTime.fromJSDate(planningWeek)
      .endOf('week')
      .toISO();

    const f = {
      ...options.filters,
    };

    if (!f.contractValueRest) {
      f.contractValueRest = [
        {
          value: '0',
          comparator: Comparator['>N'],
        },
      ];
    }

    return {
      filters: JSON.stringify(f),
      sort: options.sort,
      desc: options.desc,
      remainingOrderValue: options.remainingOrderValue,
      end: endDate,
      uncomplete: true,
      hstart: planningWeekStart,
      hend: planningWeekEnd,
    };
  };

  useEffect(() => {
    const load = async () => {
      const headerTasks = await getTasks();
      const header = createLocationTableHeader(
        headerTasks.map(({ key, title }: TabledHeaderField) => ({ title, key }))
      );
      setHeader(header);

      setOptions(defaultOptions);
      setForesightedMonthCount(0);
    };
    load();
  }, []);

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const s = getLoadDateParams(planningWeek, foresightedMonthCount);

      const result = await Request.list('planning/locations/due', s);

      setItems(result.items);

      setLoading(false);
    };
    load();
  }, [planningWeek, options, foresightedMonthCount]);

  return (
    <Modal
      width={'90%'}
      title={'Wählen Sie einen Standort aus, der hinzugefügt werden soll.'}
      open={show}
      okText='Hinzufügen'
      okButtonProps={{ disabled: !valid }}
      onOk={() => {
        valid && proceed(selected[0]);
      }}
      onCancel={() => {
        proceed(undefined);
      }}
      footer={
        <Row justify='space-between'>
          <Col>
            <CountryFilter
              direction='up'
              disabled={loading}
              options={options}
              onChange={(options) => {
                const f = options.filters ?? {};
                f['address.countryCode'] = (
                  options['address.countryCode'] || []
                ).map((value) => ({
                  comparator: '=',
                  value,
                }));
                setOptions({ ...options, filters: f });
              }}
            />
            <RemainingOrderValueFilter
              direction='up'
              disabled={loading}
              options={options}
              onChange={(options: PlanningStepLocationsOptions) => {
                setOptions(options);
              }}
            />
            <ForesightedMonthCount
              disabled={loading}
              value={foresightedMonthCount}
              onChange={(value) => setForesightedMonthCount(value)}
            />
          </Col>
          <Col>
            <Button
              key='back'
              onClick={() => {
                proceed(undefined);
              }}
            >
              Schließen
            </Button>
            <Button
              disabled={!valid}
              key='submit'
              type='primary'
              onClick={() => {
                valid && proceed(selected[0]);
              }}
            >
              Hinzufügen
            </Button>
          </Col>
        </Row>
      }
    >
      <AuthConsumer>
        {({ user }: { user: { id?: string } }) => (
          <PlanningConsumer>
            {({ blocked }) => (
              <Wrapper className='bg-gray'>
                <Table
                  loading={loading}
                  filterable={true}
                  selectable={SELECTION_TYPE.Filter}
                  options={options}
                  header={header}
                  items={items}
                  selection={selected}
                  selectionCellRenderer={selectionCellRenderer}
                  isItemDisabled={(location) =>
                    isItemDisabled(blocked, user, location)
                  }
                  isItemDisabledInfo={(location) =>
                    isItemDisabledInfo(blocked, user, location)
                  }
                  handleOptionsChanged={(opts) =>
                    setOptions({ ...options, ...opts })
                  }
                  handleSelectionChanged={(locationToSet, selection) => {
                    if (selection) {
                      setSelected(locationToSet);
                    } else {
                      setSelected([]);
                    }
                  }}
                  extensionGetter={(item) => {
                    const {
                      _id,
                      address,
                      addressRouting,
                      tag,
                      customer,
                      contractValueSum,
                      checkDepth,
                      name,
                      allNextJobDate,
                      note,
                      holidays,
                      contractValueRest,
                    } = item;
                    return item.additionalTasks
                      .filter((t: any) => {
                        if (!t.dueFixed) return true;
                        if (!planningWeek) return false;
                        const dt = DateTime.fromISO(t.due);
                        const pt = DateTime.fromJSDate(planningWeek);
                        return (
                          dt.weekYear === pt.weekYear &&
                          dt.weekNumber === pt.weekNumber
                        );
                      }) // alle Termine in der Plan KW
                      .map((p: any) => {
                        return {
                          ...p,
                          disabled: allNextJobDate && allNextJobDate.length > 0,
                          additionalTask: p._id,
                          locID: _id,
                          address,
                          addressRouting,
                          tag,
                          customer,
                          contractValueSum,
                          checkDepth,
                          name,
                          comment: p.message,
                          note: note,
                          holidays,
                          contractValueRest,
                          operatingExpense: p.planHours,
                          duePrice: p.estimatedPrice,
                          job: p.job,
                          _dues: pickBy(
                            p.type,
                            (value, key) => !key.startsWith('_')
                          ),
                          freezeComment: true,
                          fixedDateFromAdditionalTasks: p.dueFixed
                            ? p.due
                            : undefined,
                        };
                      })
                      .sort(
                        (firstEl: any, secondEl: any) =>
                          firstEl &&
                          firstEl.fixedDateFromAdditionalTasks &&
                          secondEl &&
                          secondEl.fixedDateFromAdditionalTasks &&
                          firstEl.fixedDateFromAdditionalTasks.localeCompare(
                            secondEl.fixedDateFromAdditionalTasks
                          )
                      );
                  }}
                  extensionRow={(
                    item: any,
                    index: number,
                    columns: any[],
                    isItemSelected: (id: string) => boolean,
                    isItemDisabled?: (id: string) => boolean,
                    isItemDisabledInfo?: (item: any) => React.ReactNode,
                    handleSelectionChanged?: (item: any, value: boolean) => void
                  ) => {
                    return extensionRowRender(
                      item,
                      index,
                      columns,
                      isItemSelected,
                      (id: string) =>
                        (isItemDisabled && isItemDisabled(id)) || item.disabled,
                      () => <></>,
                      handleSelectionChanged
                    );
                  }}
                />
              </Wrapper>
            )}
          </PlanningConsumer>
        )}
      </AuthConsumer>
    </Modal>
  );
};

const showLocationSelector = (planningWeek: Date) => {
  return createConfirmation(confirmable(LocationSelector))({ planningWeek });
};

export default showLocationSelector;
