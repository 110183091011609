import { Col, Row } from 'antd';
import { reduce, uniq, uniqBy } from 'lodash';

import { DateTime } from 'luxon';
import { FunctionComponent } from 'react';
import React from 'react';
import styled from 'styled-components';

interface ToursInfoProps {
  tours: any[];
}

const BigNumber = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const SmallText = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;

const asNumber = (d = 0) => {
  return d.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 3px;
  overflow: hidden;
  margin: 0 0 16px 0;
  position: relative;
  padding: 20px 26px 18px 26px;
`;

const ToursInfo: FunctionComponent<ToursInfoProps> = ({ tours }) => {
  const estimatedPrice = reduce(
    tours,
    (acc, tour) => acc + tour.estimatedPrice,
    0
  );

  const jobs = reduce(
    (tours ?? []).map((tour) => tour.jobs.filter((job: any) => !job.isHotel)),
    (prev: any[], curr: any[]) => {
      return [...prev, ...curr];
    },
    []
  );

  const locations = uniqBy(
    (jobs ?? []).map((j) => j.location),
    (l) => (l ? l._id : null)
  );

  const days = reduce(
    (tours ?? []).map((tour) =>
      uniq(
        tour.jobs.map((job: any) => DateTime.fromISO(job.planedAt).toISODate())
      )
    ),
    (prev: any[], curr: any[]) => {
      return [...prev, ...curr];
    },
    []
  );

  return (
    <>
      <Wrapper>
        <Row>
          <Col xs={24} lg={12} xl={6}>
            <BigNumber>{(tours ?? []).length}</BigNumber>
            <SmallText>Touren geplant</SmallText>
          </Col>
          <Col xs={24} lg={12} xl={6}>
            <BigNumber>{(locations ?? []).length}</BigNumber>
            <SmallText>Standorte geplant</SmallText>
          </Col>
          <Col xs={24} lg={12} xl={6}>
            <BigNumber> {(days ?? []).length}</BigNumber>
            <SmallText>Manntage geplant</SmallText>
          </Col>
          <Col xs={24} lg={12} xl={6}>
            <BigNumber>{asNumber(estimatedPrice)}</BigNumber>
            <SmallText>Umsatz geplant</SmallText>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default ToursInfo;
