import { DateTime, LocaleOptions } from 'luxon';
import { HotelJobWithLeg, JOB_TYPE, JobWithLeg } from '../../../context/Route';
import React, { Component } from 'react';

import { ReactComponent as ClockIcon } from '../../../assets/icons/calender.svg';
import { ReactComponent as CommentIcon } from '../../../assets/icons/comment.svg';
import DatePicker, {
  DatePickerType,
  DatePickerValue,
  DateValue,
} from '../../Picker/DatePicker';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/list-menu.svg';
import { ReactComponent as NoteIcon } from '../../../assets/icons/notes.svg';
import { PlanningStepTourJobActionFunc } from './PlanningStepTourJobAction';
import { ReactComponent as SleepIcon } from '../../../assets/icons/sleep.svg';
import { ReactComponent as SplitIcon } from '../../../assets/icons/split.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import numeral from 'numeral';

import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { PlanningConsumer } from '../../../context/PlanningContext';

function parseDate(startDate: DateValue) {
  return typeof startDate === 'string'
    ? DateTime.fromISO(startDate)
    : DateTime.fromJSDate(startDate).setLocale('de');
}

// switch between locales
numeral.locale('de');

function shortCurrency(value: number, n = '0.[00]') {
  return numeral(value).format(n);
}

type JobsWithSplitted = (JobWithLeg | HotelJobWithLeg) & {
  splited: boolean;
  operatingExpense: number;
  duePrice: number;
};

interface PlanningStepTourJobProps {
  value: JobsWithSplitted;
  sortIndex: number;
  disabled: boolean;
  planningWeek: Date;
  action: PlanningStepTourJobActionFunc;
  hasError?: boolean;
  listeners: SyntheticListenerMap | undefined;
}

interface PlanningStepTourJobState {
  invalidString?: string;
  displayTime: boolean;
  valid?: boolean;
  date?: string | null | Date;
  until: string | undefined;
}

class PlanningStepTourJob extends Component<
  PlanningStepTourJobProps,
  PlanningStepTourJobState
> {
  state: PlanningStepTourJobState = {
    displayTime: false,
    until: undefined,
  };

  constructor(props: PlanningStepTourJobProps) {
    super(props);
    this.state.date = props.value.planedAt;
    this.state.displayTime = !!props.value.fixedDateFromAdditionalTasks;
    this.state.until = props.value.until;
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidUpdate(prevProps: PlanningStepTourJobProps) {
    let p = {};
    if (this.props.value.planedAt !== prevProps.value.planedAt)
      p = { ...p, date: this.props.value.planedAt };
    if (
      this.props.value.planedAtTimeByUser !== prevProps.value.planedAtTimeByUser
    )
      p = { ...p, displayTime: this.props.value.planedAtTimeByUser };
    if (this.props.value.until !== prevProps.value.until)
      p = { ...p, until: this.props.value.until };
    if (Object.keys(p).length > 0) {
      this.setState(p);
    }
  }

  // async setDateComponent(value) {
  //   let { invalidString } = this.state;
  //   let { action, sortIndex } = this.props;
  //   let d = DateTime.fromFormat(value.formattedValue, 'dd.MM.yyyy HH:mm');
  //   let q = null;
  //   if (!d.isValid) {
  //     invalidString = value.formattedValue;
  //   } else {
  //     q = d.toISO();
  //   }
  //   if (d.isValid) {
  //     await action(sortIndex, 'planedAt', q);
  //   }
  //   await this.setState({
  //     valid: d.isValid,
  //     invalidString,
  //     date: q,
  //   });
  // }

  async handleDateChange(value: DatePickerValue) {
    const { action, sortIndex } = this.props;
    const displayTime = value.time ?? false;
    const until = value.until;
    const q = parseDate(value.startDate).toISO();
    await action(sortIndex, 'planedAt', q, displayTime, until);
    await this.setState({
      valid: true,
      date: q,
      displayTime,
      until,
    });
  }

  getLocalFormat() {
    // const { displayTime } = this.state;
    const displayTime = true;
    const p = displayTime
      ? {
          hour: '2-digit',
          minute: '2-digit',
        }
      : {};
    return {
      month: '2-digit',
      day: '2-digit',
      weekday: 'short',
      ...p,
    };
  }

  renderOperatingExpense(value: JobsWithSplitted) {
    if (!(value.type === 1 && value.operatingExpense)) return <p></p>;
    return (
      <p className='operatingExpense'>
        {`${shortCurrency(value.operatingExpense)}h | ${shortCurrency(
          value.duePrice,
          '0,0'
        )}€`}
      </p>
    );
  }

  renderAddress(value: JobsWithSplitted) {
    if (
      value.type !== JOB_TYPE.HomeAccommodation &&
      value.type !== JOB_TYPE.Home &&
      value.type !== JOB_TYPE.Location
    )
      return <p></p>;
    if (!(value as JobWithLeg).address) return <p></p>;
    return (
      <p className='address'>
        {(value as JobWithLeg).address.street
          ? `${(value as JobWithLeg).address.street}, `
          : null}{' '}
        {(value as JobWithLeg).address.postalCode}{' '}
        {(value as JobWithLeg).address.city}
      </p>
    );
  }

  renderNearByAddress(
    value: JobsWithSplitted,
    sortIndex: number,
    action: PlanningStepTourJobActionFunc
  ) {
    if (!value.nearByAddress) return <p></p>;
    return (
      <p
        onClick={() => {
          action(
            sortIndex,
            'nearByAddress',
            value,
            this.state.displayTime,
            this.state.until
          );
        }}
        className='address clickable'
      >
        {value.nearByAddress}
      </p>
    );
  }

  renderPlanedDate(value: JobsWithSplitted) {
    // Type is Job or hotel and date is missing
    if (!value.planedAt && value.type !== 0) {
      return <p className='date missing'>Plandatum eingeben</p>;
    }

    const planedAtDT = DateTime.fromISO(value.planedAt as string)
      .setLocale('de')
      .set({
        millisecond: 0,
        second: 0,
      });

    const format = this.getLocalFormat() as LocaleOptions &
      Intl.DateTimeFormatOptions;
    // const classes = classNames('date');

    if (!planedAtDT.isValid) {
      if (value.type === 0) {
        // type is home
        return <p></p>;
      } else {
        // type is job or hotel
        return <p className='date missing'>Plandatum eingeben</p>;
      }
    }

    // Job fixed Date
    if (value.fixedDateFromAdditionalTasks) {
      const fixedDT = DateTime.fromISO(
        value.fixedDateFromAdditionalTasks as string
      )
        .setLocale('de')
        .set({
          millisecond: 0,
          second: 0,
        });

      if (planedAtDT.toUTC().toMillis() === fixedDT.toUTC().toMillis()) {
        // planed is equal to fixed date
        return (
          <p className='date date-was-fixed'>
            {planedAtDT.toLocaleString(format)}
          </p>
        );
      } else {
        return (
          <p className='date date-was-fixed'>
            {planedAtDT.toLocaleString(format)} | 
            {fixedDT.toLocaleString(format)}
          </p>
        );
      }
    }

    return <p className='date'>{planedAtDT.toLocaleString(format)}</p>;
  }

  renderIcon(
    actionName: string,
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    cond: (value: JobsWithSplitted) => boolean,
    className = ''
  ) {
    const { value, sortIndex, action } = this.props;
    const { displayTime, until } = this.state;
    const IconComponent = icon as React.ElementType;
    if (!cond(value)) return;
    return (
      <button
        onClick={() => {
          action(sortIndex, actionName, value, displayTime, until);
        }}
        className={`route-step-action ${className}`}
      >
        <IconComponent />
      </button>
    );
  }

  render() {
    const { date, displayTime, until } = this.state;
    const { value, sortIndex, disabled, action, planningWeek } = this.props;

    const dt = DateTime.fromJSDate(planningWeek);
    const rangeFirst = dt.set({ weekday: 1 }).startOf('day').toJSDate();
    const rangeLast = dt.set({ weekday: 5 }).endOf('day').toJSDate();

    const short = (s: string) => {
      if (!s || s.length < 1) return '';
      if (s.length > 8) {
        return `${s.substring(0, 6)}...`;
      } else {
        return s;
      }
    };

    return (
      <>
        <div className='route-step-icon'>
          {value.type === JOB_TYPE.Home ? <HomeIcon /> : ''}
          {value.type === JOB_TYPE.HomeAccommodation ? <HomeIcon /> : ''}
          {value.type === JOB_TYPE.HotelAccommodation ? <SleepIcon /> : ''}
          {value.type === JOB_TYPE.Location ? <p>{sortIndex}</p> : ''}
        </div>
        <div className='route-step-content'>
          <div className='row'>
            <div className='col col-6 pr-1'>
              <p className='name'>
                {(value.type === JOB_TYPE.HomeAccommodation ||
                  value.type === JOB_TYPE.Home) &&
                  'Heimatadresse'}
                {value.type === JOB_TYPE.HotelAccommodation &&
                  'Unterkunfte in der Nähe von:'}
                {value.type === JOB_TYPE.Location &&
                  `[${short(value.tag)}] ${value.name}`}
              </p>
              {value.type !== JOB_TYPE.HotelAccommodation &&
                this.renderAddress(value)}
              {value.type === JOB_TYPE.HotelAccommodation &&
                this.renderNearByAddress(value, sortIndex, action)}
            </div>
            <div className='col col-6 px-1'>
              {(value.type === JOB_TYPE.Home ||
                value.type === JOB_TYPE.Location) &&
                this.renderPlanedDate(value)}
              {this.renderOperatingExpense(value)}
            </div>
          </div>
        </div>

        <div className='route-step-actions'>
          {!disabled && (
            <div className='route-step-action sort' {...this.props.listeners}>
              <MenuIcon />
            </div>
          )}

          {this.renderIcon(
            'remove',
            TrashIcon,
            (value) =>
              value.splited ||
              value.type === JOB_TYPE.HomeAccommodation ||
              value.type === JOB_TYPE.HotelAccommodation ||
              value.type === JOB_TYPE.Location
          )}

          {this.renderIcon(
            'split',
            SplitIcon,
            (value) => !value.splited && value.type === 1
          )}

          {this.renderIcon(
            'sleep',
            SleepIcon,
            (value) => value.type === JOB_TYPE.Location
          )}

          {this.renderIcon(
            'sleepHome',
            HomeIcon,
            (value) => value.type === JOB_TYPE.Location
          )}

          {this.renderIcon(
            'comment',
            CommentIcon,
            (value) => value.type !== 0,
            value && value.comment && value.comment.length > 0
              ? 'has-comment'
              : ''
          )}

          {this.renderIcon(
            'note',
            NoteIcon,
            (value) =>
              value.type !== JOB_TYPE.Home &&
              value.note !== undefined &&
              value.note.length > 0,
            'has-note'
          )}

          {/* value.type !== JOB_TYPE.Home &&  */}
          {value.type === JOB_TYPE.Location && (
            <DatePicker
              className='route-step-action'
              types={[DatePickerType.weekday]}
              format=''
              value={{
                time: displayTime,
                until: until,
                startDate: (date != undefined
                  ? parseDate(date)
                  : new DateTime()
                ).toJSDate(),
              }}
              rangeFirst={rangeFirst}
              rangeLast={rangeLast}
              useUntil={true}
              onChange={this.handleDateChange}
            >
              <ClockIcon />
            </DatePicker>
          )}
        </div>
      </>
    );
  }
}

export default PlanningStepTourJob;
