import { LocaleOptions } from 'luxon';
export default function getLocalFormat(
  displayTime = false
): LocaleOptions & Intl.DateTimeFormatOptions {
  if (displayTime) {
    return {
      month: '2-digit',
      day: '2-digit',
      weekday: 'short',
      hour: '2-digit',
      minute: '2-digit',
    };
  } else {
    return {
      month: '2-digit',
      day: '2-digit',
      weekday: 'short',
    };
  }
}
