import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const ReloadButton = styled.button`
  background: none;
  float: right;
  border: none;
  height: 22px;
`;

interface ComparisonItemProps {
  title: string;
  onReload?: () => void;
  children: React.ReactNode;
}

const ComparisonItem: FunctionComponent<ComparisonItemProps> = ({
  title,
  children,
  onReload,
}) => {
  return (
    <React.Fragment>
      <div className='row table-divider'>
        <div className='col'>
          <span>{title}</span>
          {onReload ? (
            <ReloadButton onClick={() => onReload()}>
              Neu berechnen
            </ReloadButton>
          ) : (
            false
          )}
        </div>
      </div>
      <div className='row table-row'>
        <div className='col'>{children}</div>
      </div>
    </React.Fragment>
  );
};

export default ComparisonItem;
