import './Settings.scss';

import React, { Component } from 'react';

import { AuthConsumer } from '../../context/AuthContext';
import DirectInput from '../Form/DirectInput';
import EditableInput from '../Form/EditableInput';
import { FormConsumer } from '../../context/FormContext';
import { Request } from '../../api/Request';
import TreeMenu from 'react-simple-tree-menu';
import { chain } from 'lodash';

function toTree(dataset, options) {
  const { ID, id_parent } = options;
  const hashTable = Object.create(null);
  const dataTree = [];

  dataset.forEach(
    (aData) => (hashTable[aData[ID]] = Object.assign({ nodes: [] }, aData))
  );
  dataset.forEach((aData) => {
    if (aData[id_parent])
      hashTable[aData[id_parent]].nodes.push(hashTable[aData[ID]]);
    else dataTree.push(hashTable[aData[ID]]);
  });

  return dataTree;
}

class SettingsList extends Component {
  state = {
    error: null,
    isLoaded: false,
    items: [],
    item: null,
    newItem: null,
    active: null,
    tree: null,
  };

  constructor() {
    super();
    this.onClickNode = this.onClickNode.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  onClickNode = (node) => {
    this.setState({
      active: node,
    });
  };

  removeLastDirectoryPartOf(the_url) {
    const the_arr = the_url.split('/');
    the_arr.pop();
    return the_arr.join('/');
  }

  buildTree(items) {
    const d = chain(items)
      .map((item) => {
        return {
          key: item.name,
          parent: this.removeLastDirectoryPartOf(item.name),
          label: item.title ? item.title : item.name,
          type: item.type,
          value: item.value,
        };
      })
      .orderBy('label')
      .push({
        key: 'add-setting',
        label: 'Hinzufügen',
        type: 'add-setting',
      })
      .value();
    const tree = toTree(d, { id_parent: 'parent', ID: 'key' });
    return tree;
  }

  loadData() {
    Request.list('settings').then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result,
          tree: this.buildTree(result),
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error,
        });
      }
    );
  }

  findSetting(settings, args) {
    return settings.find((s) => s.name === args);
    // const _args = clone(typeof args === "string" ? args.split("/") : args);
    // const key = _args.shift();
    // const values = Array.isArray(settings) ? settings : settings.value;
    // const obj = values.find(v => v.name === key);
    // if (!obj) return null;
    // const value = obj.value;
    // return _args.length > 0 ? this.findSetting(value, _args) : obj;
  }

  selectPath(path) {
    if (!path) {
      this.setState({
        item: null,
      });
    } else {
      const setting = this.findSetting(this.state.items, path);
      this.setState({
        item: {
          path,
          ...setting,
        },
      });
    }
  }

  async save(path, value, title, type) {
    try {
      await Request.put('settings', path, {
        value,
        title,
        type,
      });
      this.loadData();
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  canSave() {
    const { item, newItem, items } = this.state;
    if (!newItem || !item) return false;
    const path = item.path.replace('add-setting', newItem.name);
    return this.findSetting(items, path) === null;
  }

  async removeAll() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Sind Sie sich sicher?')) {
      return Request.post(`/import/locations/clear`).then((res) => {
        alert(JSON.stringify(res));
      });
    }
  }

  render() {
    const { item, newItem, tree } = this.state;
    return (
      <AuthConsumer>
        {({ hasPermission }) => (
          <FormConsumer>
            {() => (
              <div className='settings container-inner'>
                <div className='page-header row justify-content-between'>
                  <div className='col col-12 col-md-6'>
                    <h1 className='page-title'>Einstellungen</h1>
                  </div>
                </div>
                <div className='row page-content'>
                  <div className='col mb-3 col-12 col-sm-6 col-xl-4'>
                    <TreeMenu
                      data={tree}
                      hasSearch={false}
                      //   openNodes={openNodes}
                      onClickItem={({ key, ...props }) => {
                        if (!props.hasNodes) {
                          this.selectPath(key);
                        } else {
                          // this.setState({ openNodes: [key], activeKey: key })
                          this.selectPath(null);
                        }
                      }}
                      debounceTime={125}
                    />
                  </div>
                  <div className='col mb-3 col-sm-6 col-xl-5'>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        {item && item.path.includes('add-setting') ? (
                          <DirectInput
                            title='Key'
                            value={newItem ? newItem.name : null}
                            required={true}
                            validate={(value) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 0
                            }
                            callback={(value) =>
                              this.setState({
                                newItem: {
                                  ...this.state.newItem,
                                  name: value,
                                },
                              })
                            }
                          />
                        ) : (
                          <EditableInput
                            title='Key'
                            readOnly={true}
                            value={item ? item.name : null}
                            validate={(value) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 0
                            }
                            callback={(value) =>
                              this.save(`${item.path}/name`, value)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        {item && item.path.includes('add-setting') ? (
                          <DirectInput
                            title='Wert'
                            value={newItem ? newItem.value : null}
                            required={true}
                            callback={(value) =>
                              this.setState({
                                newItem: {
                                  ...this.state.newItem,
                                  value: value,
                                },
                              })
                            }
                          />
                        ) : (
                          <EditableInput
                            title='Wert'
                            readOnly={
                              !item ||
                              hasPermission(['settings:write']) !== true
                            }
                            value={item ? item.value : null}
                            callback={(value) =>
                              this.save(`${item.path}/value`, value)
                            }
                          />
                        )}
                      </div>
                    </div>
                    {item && item.path.includes('add-setting') ? (
                      <React.Fragment>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <DirectInput
                              title='Beschreibung'
                              value={newItem ? newItem.title : null}
                              callback={(value) =>
                                this.setState({
                                  newItem: {
                                    ...this.state.newItem,
                                    title: value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row mt-3'>
                          <div className='col'>
                            <button
                              disabled={!this.canSave()}
                              className={`button ${
                                this.canSave() ? null : 'disabled'
                              }`}
                              onClick={() => {
                                this.save(
                                  newItem
                                    ? item.path.replace(
                                        'add-setting',
                                        newItem.name
                                      )
                                    : null,
                                  newItem ? newItem.value : null,
                                  newItem ? newItem.title : null,
                                  'text'
                                );
                              }}
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                  <div className='col col-12 col-sm-12 col-xl-3'>
                    <h3>Zurücksetzen</h3>
                    <button onClick={() => this.removeAll()}>
                      Alle Standorte und Touren löschen
                    </button>
                  </div>
                </div>
              </div>
            )}
          </FormConsumer>
        )}
      </AuthConsumer>
    );
  }
}

export default SettingsList;
