import { NewJob, Technician } from '../../context/Route';
import React, { Component } from 'react';

import { PlanningContext } from '../../context/PlanningContext';
import TourLocationAddHome from './TourLocationAddHome';
import TourLocationAddHotel from './TourLocationAddHotel';
import TourLocationAddLocation from './TourLocationAddLocation';

interface TourLocationAddProps {
  technician: Technician;
  nearByAddress?: string;
  index: number;
  planedAtBefore?: Date;
  planningWeek?: Date;
  count: number;
  onAdd: (job: NewJob) => Promise<() => Promise<void>>;
}

class TourLocationAdd extends Component<TourLocationAddProps> {
  render() {
    return (
      <React.Fragment>
        <div className='route-step-content'>
          <div className='row'>
            <TourLocationAddHome
              index={this.props.index}
              planedAtBefore={this.props.planedAtBefore}
              count={this.props.count}
              onAdd={this.props.onAdd}
              technician={this.props.technician}
            />
            <TourLocationAddLocation
              index={this.props.index}
              planningWeek={this.props.planningWeek}
              planedAtBefore={this.props.planedAtBefore}
              count={this.props.count}
              onAdd={this.props.onAdd}
              technician={this.props.technician}
            />
            <TourLocationAddHotel
              index={this.props.index}
              planedAtBefore={this.props.planedAtBefore}
              nearByAddress={this.props.nearByAddress}
              count={this.props.count}
              onAdd={this.props.onAdd}
              technician={this.props.technician}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TourLocationAdd.contextType = PlanningContext;

export default TourLocationAdd;
