import './SelectInput.scss';

import React, { Component } from 'react';

import { ReactComponent as CancelIcon } from '../../assets/icons/no.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { FormConsumer } from '../../context/FormContext';
import { v4 as uuid } from 'uuid';

class SelectInput extends Component {
  elementID = uuid();
  state = {
    editing: false,
    value: '',
    oldValue: '',
    options: [],
    valid: true,
    readOnly: false,
    direct: false,
  };

  constructor(props) {
    super(props);
    if (props.value) this.state.value = props.value;
    if (props.readOnly) this.state.readOnly = props.readOnly;
    if (props.options) this.state.options = props.options;
    if (props.direct) this.state.direct = props.direct;
    this.handleChange = this.handleChange.bind(this);
    this.startEdit = this.startEdit.bind(this);
    this.stopEdit = this.stopEdit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const valid = this.validate(newProps.value || '');
    this.setState({
      valid,
      options: newProps.options || [],
      value: newProps.value || '',
      oldValue: newProps.value || '',
      readOnly: newProps.readOnly || false,
      direct: newProps.direct || false,
    });
  }

  handleChange(event) {
    const value = event.target.value;
    const valid = this.validate(value);
    if (this.state.direct) {
      this.props.callback(value);
    } else {
      this.setState({ value, valid });
    }
  }

  validate(value) {
    return this.props.validate ? this.props.validate(value) === true : true;
  }

  startEdit() {
    if (this.props.type === 'password') {
      this.setState({ editing: true, valid: false, value: '' });
    } else {
      const valid = this.props.validate
        ? this.props.validate(this.state.value) === true
        : true;
      this.setState({ editing: true, valid });
    }
    setTimeout(() => this.nameInput.focus(), 25);
  }

  stopEdit(save) {
    if (save && this.state.valid) {
      this.setState({ oldValue: this.state.value, editing: false });
      this.props.callback(this.state.value);
    } else {
      this.setState({ value: this.state.oldValue, editing: false });
    }
  }

  render() {
    const { editing, direct, options, value, readOnly, valid } = this.state;
    return (
      <FormConsumer>
        {({ setEditing }) => (
          <div className={`select-input ${editing ? 'editing' : ''}`}>
            <label
              htmlFor={`select-input-${this.elementID}`}
              className={`${valid ? 'valid' : 'invalid'}`}
            >
              {this.props.title}
            </label>
            <select
              ref={(input) => {
                this.nameInput = input;
              }}
              id={`select-input-${this.elementID}`}
              disabled={!direct && !editing}
              value={value}
              onChange={this.handleChange}
              name='name'
              size='1'
            >
              {options.map((opt, index) => (
                <option key={index} value={opt.value}>
                  {opt.text}
                </option>
              ))}
            </select>
            {readOnly !== true && !direct ? (
              <div className='actions'>
                {!editing ? (
                  <EditIcon
                    className='edit'
                    onClick={() => {
                      setEditing(true);
                      this.startEdit();
                    }}
                  />
                ) : (
                  ''
                )}
                {editing ? (
                  <CheckIcon
                    className='save'
                    disabled={!valid}
                    onClick={() => {
                      setEditing(false);
                      this.stopEdit(true);
                    }}
                  />
                ) : (
                  ''
                )}
                {editing ? (
                  <CancelIcon
                    className='cancel'
                    onClick={() => {
                      setEditing(false);
                      this.stopEdit(false);
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </FormConsumer>
    );
  }
}

export default SelectInput;
