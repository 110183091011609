import './Import.scss';

import {
  CheckSquareOutlined,
  CloudUploadOutlined,
  CodeOutlined,
  FileExcelOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import React, { Component } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { find, flatten } from 'lodash';

import ImportSetting from './Settings/ImportSetting';
import { ImportStepConnect } from './ImportStepConnect';
import { ImportStepFields } from './ImportStepFields';
import { ImportStepMasterfile } from './ImportStepMasterfile';
import ImportStepRunning from './ImportStepRunning';
import { Line } from './Settings/fieldsDefault';
import { Request } from '../../api/Request';
import { ReactComponent as SettingsIcon } from '../../assets/icons/control.svg';
import { Steps } from 'antd';
import classNames from 'classnames';

const { Step } = Steps;

interface ImportFieldSelectorState {
  current: ImportStep;
  loading: boolean;
  connectionError?: Error;
  task?: any;
  masterfile?: File;
  lines?: LineWithEmbbededTarget[];
  uploadProgress: number;
}

enum ImportStep {
  connection,
  masterfile,
  fields,
  upload,
  running,
}

export interface LineWithEmbbededTarget extends Omit<Line, 'target'> {
  active: boolean;
  target: {
    group: string;
    key: string;
    name: string;
    type: string;
    value: string;
  };
}

class Import extends Component<RouteComponentProps, ImportFieldSelectorState> {
  state: ImportFieldSelectorState = {
    current: ImportStep.connection,
    loading: true,
    uploadProgress: 0,
  };

  componentDidMount() {
    this.setup();
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async setup() {
    const [task, lines] = await Promise.all([
      this.loadTasks(),
      this.fetchLines(),
    ]);
    this.setState({
      task,
      lines: lines?.map((l: LineWithEmbbededTarget) => ({
        ...l,
        active: true,
      })),
      current: task ? ImportStep.running : ImportStep.masterfile,
      loading: false,
    });
  }

  async loadTasks() {
    try {
      this.setPromisifiedState({ loading: true });
      const tasks = await Request.list('import/locations', {}, false);
      return tasks.length > 0 ? tasks[0] : undefined;
    } catch (connectionError: any) {
      console.error(connectionError);
      this.setState({ connectionError, loading: false });
    }
  }

  async fetchMappings() {
    const data = await Request.list('import/locations/fields', {});
    return data;
  }

  async fetchLines(): Promise<LineWithEmbbededTarget[] | undefined> {
    const mappings: any[] = flatten(Object.values(await this.fetchMappings()));
    const data = await Request.list('import/locations/lines', {});
    const mapped = data.map((l: any) => ({
      _id: l._id,
      title: '',
      source: l.source,
      target: find(mappings, (m) => l.target === m.key),
      required: l.required,
    }));
    console.log(mapped);
    return mapped;
  }

  next() {
    this.setState({
      current: this.state.current + 1,
    });
  }

  prev() {
    if (this.state.current > 0)
      this.setState({ current: this.state.current - 1 });
  }

  onMasterfile(data: File) {
    this.setState({ current: ImportStep.fields, masterfile: data });
  }

  async upload() {
    const { masterfile, lines } = this.state;
    const progress = (p: number) => this.setState({ uploadProgress: p });
    await this.setPromisifiedState({ current: ImportStep.upload });
    await Request.upload(
      'import',
      'locations',
      masterfile!,
      lines,
      undefined,
      true,
      progress
    );
    await this.setPromisifiedState({ current: ImportStep.running });
  }

  abort() {
    Request.post('import/locations/abort', {});
  }

  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.path}/settings`}>
          <ImportSetting />
        </Route>
        <Route exact path={this.props.match.path}>
          {this.renderOld()}
        </Route>
      </Switch>
    );
  }

  renderOld() {
    const { current, loading, connectionError, lines } = this.state;
    return (
      <div className='import container-inner'>
        <div className='page-header row justify-content-between'>
          <div className='col col-12 col-md-6'>
            <h1 className='page-title'>Import</h1>
          </div>
          <div className='col-6 col actions'>
            <div
              className={classNames('action', 'float-right', {})}
              onClick={() =>
                this.props.history.push('/administration/import/settings')
              }
            >
              <SettingsIcon />
              Einstellungen
            </div>
          </div>
        </div>
        <div className='row page-content'>
          <div className='col col-12 mb-12'>
            <Steps current={current}>
              <Step title='Verbinden' icon={<LinkOutlined />}></Step>
              <Step title='Masterfile' icon={<FileExcelOutlined />}></Step>
              <Step title='Felder' icon={<CheckSquareOutlined />}></Step>
              <Step title='Hochladen' icon={<CloudUploadOutlined />}></Step>
              <Step title='Verarbeiten' icon={<CodeOutlined />}></Step>
              {/* <Step title='Fertig' icon={<FileDoneOutlined />}></Step> */}
            </Steps>
            <div className='steps-content'>
              {current === ImportStep.connection && (
                <ImportStepConnect
                  onReset={() => this.loadTasks()}
                  loading={loading}
                  error={connectionError}
                />
              )}
              {current === ImportStep.masterfile && (
                <ImportStepMasterfile
                  onMasterfile={(data) => this.onMasterfile(data)}
                />
              )}
              {current === ImportStep.fields && lines && (
                <ImportStepFields
                  onSelect={(lines) => this.setState({ lines })}
                  lines={lines}
                />
              )}
              {current === ImportStep.upload && lines && (
                <p>Hochladen... ({this.state.uploadProgress}%)</p>
              )}
              {current === ImportStep.running && (
                <ImportStepRunning onNoTaskFound={() => this.setup()} />
              )}
            </div>
            <div className='steps-action'>
              {current === ImportStep.fields && (
                <button onClick={() => this.prev()}>Zurück</button>
              )}
              {current === ImportStep.fields && (
                <button onClick={() => this.upload()}>Weiter</button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Import);
