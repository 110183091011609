import './Export.scss';

import React, { Component } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import DirectInput from '../Form/DirectInput';
import { EMAIL_REGEXP } from '../regex';
import { ExportResult } from './ExportResult';
import ExportSetting from './Settings/ExportSetting';
import { Request } from '../../api/Request';
import { ReactComponent as SettingsIcon } from '../../assets/icons/control.svg';
import classNames from 'classnames';
import { get } from 'lodash';

interface ExportFieldSelectorState {
  current: number;
  email?: string;
  error?: Error;
  tasks: any[];
}

interface MatchParams {
  id: string;
}

interface ExportFieldSelectorProps extends RouteComponentProps<MatchParams> {}

class Export extends Component<
  ExportFieldSelectorProps,
  ExportFieldSelectorState
> {
  state: ExportFieldSelectorState = {
    current: 0,
    tasks: [],
  };

  intervalID: ReturnType<typeof setInterval> | undefined;

  componentDidMount() {
    this.intervalID = setInterval(() => this.loadTasks(), 1500);
    // prefill email to field
    const userEMail: string = get(this, 'context.user.email', '');

    this.setState({
      email: userEMail,
    });
  }

  componentWillUnmount() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async loadTasks() {
    try {
      const tasks = await Request.list('export/locations', {}, false);
      this.setState({ tasks });
    } catch (error: any) {
      console.error(error);
    }
  }

  async next() {
    try {
      this.setPromisifiedState({ current: 1 });
      await Request.post(
        'export/locations',
        {
          email: this.state.email,
        },
        false
      );
    } catch (error: any) {
      this.setPromisifiedState({ error });
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.path}/settings`}>
          <ExportSetting />
        </Route>
        <Route exact path={this.props.match.path}>
          {this.renderOld()}
        </Route>
      </Switch>
    );
  }

  renderOld() {
    const { current } = this.state;
    return (
      <div className='export container-inner'>
        <div className='page-header row justify-content-between'>
          <div className='col col-12 col-md-6'>
            <h1 className='page-title'>Export</h1>
          </div>
          <div className='col-6 col actions'>
            <div
              className={classNames('action', 'float-right', {})}
              onClick={() => this.props.history.push('/export/settings')}
            >
              <SettingsIcon />
              Einstellungen
            </div>
          </div>
        </div>
        <div className='row page-content'>
          <div className='col col-12 col-md-6 col-lg-6 col-xl-4 mb-12'>
            <div className='steps-content'>
              {current === 0 && (
                <div className='row table-row input-row'>
                  <div className='col col-12'>
                    <p>
                      Bitte geben Sie die E-Mail ein, an die der Export gesendet
                      werden soll.
                    </p>
                  </div>
                  <div className='col col-12'>
                    <DirectInput
                      title='E-Mail'
                      type='email'
                      value={this.state.email}
                      validate={(value: string) =>
                        value !== undefined &&
                        value !== null &&
                        EMAIL_REGEXP.exec(value) !== null
                      }
                      callback={(value: string) =>
                        this.setState({ email: value })
                      }
                    />
                  </div>
                </div>
              )}
              {current === 1 && <ExportResult error={this.state.error} />}
            </div>
            <div className='steps-action'>
              {current === 0 && (
                <button
                  disabled={
                    !this.state.email ||
                    EMAIL_REGEXP.exec(this.state.email) === null
                  }
                  onClick={() => this.next()}
                >
                  Senden
                </button>
              )}
            </div>
          </div>
          <div className='col col-12 col-md-6 col-lg-6 col-xl-8 mb-12'>
            <div className='steps-content'>
              <div className='row table-row input-row'>
                <div className='col col-12'>
                  <p>Running Tasks</p>
                </div>
                <div className='col col-12'>
                  <ul>
                    {this.state.tasks &&
                      this.state.tasks.map((t, index) => {
                        return (
                          <li key={index}>
                            {t.lastStatus} |{' '}
                            {get(
                              t,
                              'overrides.containerOverrides[0].environment[0].value'
                            )}{' '}
                            | {t.createdAt}
                          </li>
                        );
                      })}
                    {!this.state.tasks ||
                      (this.state.tasks.length === 0 && (
                        <li>{'Keine aktiven Tasks'}</li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// PROVISIONING
// PENDING
// RUNNING
// STOPPED

Export.contextType = AuthContext;

export default withRouter(Export);
