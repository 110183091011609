import './Customer.scss';

import React, { Component } from 'react';
import { map, set } from 'lodash';

import { AuthConsumer } from '../../context/AuthContext';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import DirectInput from '../Form/DirectInput';
import { EMAIL_REGEXP } from '../regex';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import { Redirect } from 'react-router-dom';
import { Request } from '../../api/Request';
import SelectInput from '../Form/SelectInput';

class CustomerNew extends Component {
  state = {
    redirect: null,
    error: null,
    isLoaded: false,
    valid: false,
    item: {},
    location: null,
    groups: [{ value: null, text: '' }],
    partners: [{ value: null, text: '' }],
    editing: false,
  };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.send = this.send.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const [partners, groups] = await Promise.all([
      Request.list('partners', { limit: 25000 }),
      Request.list('customer-groups', { limit: 25000 }),
    ]).catch((error) => {
      this.setState({
        isLoaded: true,
        error,
        redirectList: error.status === 404,
      });
    });

    if (partners && groups) {
      this.setState({
        isLoaded: true,
        partners: [
          { value: null, text: '' },
          ...map(partners.items, (p) => {
            return {
              value: p._id,
              text: p.name,
            };
          }),
        ],
        groups: [
          { value: null, text: '' },
          ...map(groups.items, (p) => {
            return {
              value: p._id,
              text: p.name,
            };
          }),
        ],
      });
    }
  }

  capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  async save(k, value) {
    const data = set(this.state.item, k, value);

    this.setState({
      item: data,
      valid: this.isValid(),
    });
  }

  send() {
    Request.post('customers', this.state.item).then(
      (result) => {
        this.setState({
          isLoaded: true,
          redirect: result._id,
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error,
        });
      }
    );
  }

  isValid() {
    return (
      true &&
      this.state.item &&
      this.state.item.name &&
      this.state.item.name.length > 2
    );
    // && (this.state.item.address && this.state.item.address.location)
  }

  getPartner(item) {
    if (!item || !item.partner) return null;
    if (typeof item.partner === 'string') return item.partner;
    return item.partner._id;
  }

  getGroup(item) {
    if (!item || !item.group) return null;
    if (typeof item.group === 'string') return item.group;
    return item.group._id;
  }

  render() {
    const { item, partners, groups } = this.state;
    return (
      <AuthConsumer>
        {() => (
          <div className='customer container-inner'>
            {this.state.redirect ? (
              <Redirect
                to={`/administration/customers/${this.state.redirect}`}
              />
            ) : (
              ''
            )}
            <InPageNavigation to='/administration/customers' />
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col-6 col'>
                <h1 className='page-title'>Kunde hinzufügen</h1>
              </div>
              <div className='col-6 col actions'>
                {/* { this.state.valid ? ( */}
                <div
                  className='float-right'
                  disabled={!this.state.valid}
                  onClick={this.send}
                >
                  <CheckIcon className='save' />
                  Hinzufügen
                </div>
                {/* ) : '' } */}
              </div>
            </div>
            <FormConsumer>
              {() => (
                <div className={`page-content row`}>
                  <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Stammdaten</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <SelectInput
                          title='Kundengruppe'
                          value={this.getGroup(item)}
                          options={groups}
                          callback={(value) => this.save('group', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Kundennummer'
                          value={item.customernumber}
                          validate={(value) =>
                            value !== undefined &&
                            value !== null &&
                            value.length > 2
                          }
                          callback={(value) =>
                            this.save('customernumber', value)
                          }
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Kundenname'
                          required={true}
                          value={item.name}
                          validate={(value) =>
                            value !== undefined &&
                            value !== null &&
                            value.length > 2
                          }
                          callback={(value) => this.save('name', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <SelectInput
                          title='Partner'
                          value={this.getPartner(item)}
                          options={partners}
                          callback={(value) => this.save('partner', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Rechnungsadresse</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='E-Mail'
                          value={item.billingEmail}
                          type='email'
                          validate={(value) =>
                            !value ||
                            (value && EMAIL_REGEXP.exec(value) !== null)
                          }
                          callback={(value) => this.save('billingEmail', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Straße'
                          value={
                            item.billingAddress
                              ? item.billingAddress.street
                              : null
                          }
                          callback={(value) =>
                            this.save('billingAddress.street', value)
                          }
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Plz'
                          value={
                            item.billingAddress
                              ? item.billingAddress.postalCode
                              : null
                          }
                          callback={(value) =>
                            this.save('billingAddress.postalCode', value)
                          }
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Stadt'
                          value={
                            item.billingAddress
                              ? item.billingAddress.city
                              : null
                          }
                          callback={(value) =>
                            this.save('billingAddress.city', value)
                          }
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Land'
                          value={
                            item.billingAddress
                              ? item.billingAddress.countryCode
                              : null
                          }
                          callback={(value) =>
                            this.save('billingAddress.countryCode', value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Kontakt</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='E-Mail'
                          value={item.email}
                          type='email'
                          validate={(value) =>
                            !value ||
                            (value && EMAIL_REGEXP.exec(value) !== null)
                          }
                          callback={(value) => this.save('email', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Telefon'
                          value={item.phone}
                          type='tel'
                          validate={(value) =>
                            value === undefined ||
                            (value !== undefined && value.length > 2)
                          }
                          callback={(value) => this.save('phone', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Fax'
                          value={item.fax}
                          type='tel'
                          validate={(value) =>
                            value === undefined ||
                            (value !== undefined && value.length > 2)
                          }
                          callback={(value) => this.save('fax', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Berichte</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='E-Mail'
                          value={item.reportEmail}
                          type='email'
                          validate={(value) =>
                            !value ||
                            (value && EMAIL_REGEXP.exec(value) !== null)
                          }
                          callback={(value) => this.save('reportEmail', value)}
                        />
                      </div>
                    </div>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Sonstiges</span>
                      </div>
                    </div>
                    <div className='row table-row input-row'>
                      <div className='col'>
                        <DirectInput
                          title='Techniker Info'
                          value={item.comment}
                          callback={(value) => this.save('comment', value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </FormConsumer>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default CustomerNew;
