import './Modal.scss';

import React, { useState } from 'react';

import classNames from 'classnames';
import Modal from 'react-modal';
import styled from 'styled-components';
import { withWidth } from '../helper/modalCustomStyles';

const Charts = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ChartBox = styled.li<{ selected: boolean }>`
  background-color: #fff;
  border: ${({ selected }) =>
    selected ? '1px solid #009842' : '1px solid #e6e6e6'};
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  aspect-ratio: 1 / 1;
  margin: 20px 20px 20px 20px;
  max-width: 140px;
  text-align: center;
  overflow-wrap: break-word;
  vertical-align: middle;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 100%;
  border-color: span {
    align-self: center;
    width: 100%;
    overflow-wrap: break-word;
  }
  &:hover {
    border-color: #b3b3b3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 0 0 3px #e6e6e6;
  }
  &:focus {
    outline: none;
  }
  &:active {
    border-color: #b3b3b3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 0 0 3px #e6e6e6;
  }
  &.selected {
    border-color: #b3b3b3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 0 0 3px #e6e6e6;
  }
`;

interface AddChartModalProps {
  isOpen: boolean;
  close: () => void;
  onSubmit: (type: string) => Promise<void>;
}

export default function AddChartModal(props: AddChartModalProps) {
  Modal.setAppElement('#root');

  const { isOpen, close, onSubmit } = props;

  const [type, setType] = useState<string>('');

  const valid = () => {
    return type !== '';
  };

  const submitClasses = classNames('btn', 'btn-primary', {
    disabled: !valid(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => close && close()}
      style={withWidth('50%')}
      contentLabel=''
    >
      <div className='modal' tabIndex={-1} role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Chart hinzufügen</h5>
            </div>
            <div className='modal-body'>
              <Charts>
                <ChartBox
                  selected={type === 'ToursChart'}
                  onClick={() => setType('ToursChart')}
                >
                  <span>Touren</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'TourRateChart'}
                  onClick={() => setType('TourRateChart')}
                >
                  <span>Wochen- / Tour-Satz</span>
                </ChartBox>
                {/* <ChartBox
                  selected={type === 'DailyRateChart'}
                  onClick={() => setType('DailyRateChart')}
                >
                  <span>Tagessatz</span>
                </ChartBox> */}
                <ChartBox
                  selected={type === 'PlaningChart'}
                  onClick={() => setType('PlaningChart')}
                >
                  <span>Planung</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'TechsChart'}
                  onClick={() => setType('TechsChart')}
                >
                  <span>Techniker</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'HotelsChart'}
                  onClick={() => setType('HotelsChart')}
                >
                  <span>Hotels</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'TotalChart'}
                  onClick={() => setType('TotalChart')}
                >
                  <span>Umsatz</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'RentaChart'}
                  onClick={() => setType('RentaChart')}
                >
                  <span>Renta</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'RentaTotalRatioChart'}
                  onClick={() => setType('RentaTotalRatioChart')}
                >
                  <span>Umsatz-Renta-Vergleich</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'SalesLocationChart'}
                  onClick={() => setType('SalesLocationChart')}
                >
                  <span>Neue Standorte</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'LocationsDueChart'}
                  onClick={() => setType('LocationsDueChart')}
                >
                  <span>Fälligkeit</span>
                </ChartBox>
                <ChartBox
                  selected={type === 'TopRentaChart'}
                  onClick={() => setType('TopRentaChart')}
                >
                  <span>Top Touren - Renta</span>
                </ChartBox>
                {/* <ChartBox
                  selected={type === 'TopRenta2Chart'}
                  onClick={() => setType('TopRenta2Chart')}
                >
                  <span>Top Touren - Renta - Performance</span>
                </ChartBox> */}
                <ChartBox
                  selected={type === 'BottomRentaChart'}
                  onClick={() => setType('BottomRentaChart')}
                >
                  <span>Rangliste - Renta</span>
                </ChartBox>
              </Charts>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => close && close()}
              >
                Schließen
              </button>
              <button
                type='button'
                className={submitClasses}
                onClick={async () => {
                  if (onSubmit && valid()) {
                    await onSubmit(type);
                  }
                }}
              >
                Hinzufügen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
