import React, { FunctionComponent, useMemo } from 'react';
import { ReactComponent as NotifyIcon } from '../../../assets/icons/send.svg';
import { ReactComponent as NotifiedIcon } from '../../../assets/icons/check-circle.svg';

import StatusAction, {
  Status,
} from '../../common/CardList/Actions/StatusAction';
import { isNotified } from './utils/is-notified';

interface NotifyProps {
  job: any;
  readonly?: boolean;
  onClick?: () => void;
}

const Notify: FunctionComponent<NotifyProps> = ({ job, onClick }) => {
  const shared = useMemo(() => isNotified(job), [job]);
  return (
    <StatusAction
      state={shared ? Status.success : Status.neutral}
      icon={shared ? <NotifiedIcon /> : <NotifyIcon />}
      onClick={onClick}
    >
      {!shared && <span>Benachrichtigen</span>}
      {shared && <span>Benachrichtigt</span>}
    </StatusAction>
  );
};

export default Notify;
