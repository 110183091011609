import './InPageNavigation.scss';

import React, { Component } from 'react';

import { ReactComponent as BackIcon } from '../../assets/icons/left.svg';
import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import qs from 'query-string';
import { withRouter } from 'react-router';

class InPageNavigation extends Component {
  state = {
    to: null,
    item: null,
  };

  constructor(props) {
    super(props);
    if (props.to) this.state.to = props.to;
    if (props.left) this.state.left = props.left;
    if (props.right) this.state.right = props.right;
    if (props.item) this.state.item = props.item;
    const queryParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state.isPopUp = queryParams.p === '1';
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ to: newProps.to, item: newProps.item });
  }

  close() {
    window.close();
  }

  renderLink() {
    if (this.state.isPopUp) {
      return (
        <p onClick={this.close} className='page-navigation-back'>
          <BackIcon />
          <span>Schließen</span>
        </p>
      );
    } else if (this.state.to) {
      return (
        <NavLink to={this.state.to} className='page-navigation-back'>
          <BackIcon />
          <span>Zurück</span>
        </NavLink>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='page-navigation row justify-content-between'>
        <div className='col col-4'>
          {this.renderLink()}
          {this.state.left ? this.state.left() : ''}
        </div>
        <div className='col col-4'>{this.props.children}</div>
        <div className='col col-4'>
          {this.state.item &&
          this.state.item.changedAt &&
          this.props.hideChangedAt !== true ? (
            <p className='last-changed float-right'>
              Letzte Änderung:{' '}
              {DateTime.fromISO(this.state.item.changedAt)
                .setLocale('de')
                .toLocaleString(DateTime.DATETIME_SHORT)}
            </p>
          ) : (
            ''
          )}
          {this.state.right ? this.state.right() : ''}
        </div>
      </div>
    );
  }
}

export default withRouter(InPageNavigation);
