import 'rc-calendar/assets/index.css';
import './Picker.scss';
import 'moment/locale/de';

import React, { Component } from 'react';

import Calender from 'rc-calendar';
import { DatePickerInnerProps } from './interface';
import { DateTime } from 'luxon';
import moment from 'moment';

moment.locale('de');

interface DatepickerInnerYearState {
  selectedDate?: DateTime;
}

class DatepickerInnerYear extends Component<DatePickerInnerProps> {
  static format(startDate: DateTime, format?: string) {
    if (!startDate) return startDate;
    return startDate.toFormat(format ?? 'yyyy');
  }

  state: DatepickerInnerYearState = {};

  constructor(props: DatePickerInnerProps) {
    super(props);
    this.state.selectedDate = (
      props.startDate ? DateTime.fromJSDate(props.startDate) : DateTime.local()
    ).setLocale('de');
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props: DatePickerInnerProps) {
    this.setState({
      startDate: (props.startDate
        ? DateTime.fromJSDate(props.startDate)
        : DateTime.local()
      ).setLocale('de'),
    });
  }

  onChange = (value: moment.Moment | null) => {
    const { onChangeValue } = this.props;
    if (!value) return;
    const valueString = value.toISOString();
    const valueDT = DateTime.fromISO(valueString).setLocale('de');
    const startDate = valueDT.startOf('year');
    const endDate = valueDT.endOf('year');
    this.setState({ value }, () => {
      onChangeValue &&
        onChangeValue(
          startDate.toJSDate(),
          endDate.toJSDate(),
          DatepickerInnerYear.format(startDate),
          'year'
        );
    });
  };

  onDateCellContentRender(value: moment.Moment) {
    return (
      <div className='col select-date'>{value && value.format('YYYY')}</div>
    );
  }

  render() {
    return (
      <Calender
        mode='year'
        style={{ zIndex: 1000 }}
        onChange={this.onChange}
        showToday={false}
        showDateInput={false}
        value={moment(this.state.selectedDate?.toJSDate())}
      />
    );
  }
}

export default DatepickerInnerYear;
