export const checkActive = (match: any, location: any, prefix?: string) => {
  if (prefix) {
    if (!location) return false;
    const { pathname } = location;
    return pathname.startsWith(prefix);
  } else {
    if (!match) return false;
    if (!location) return false;
    const { pathname } = location;
    const { url } = match;
    return pathname.startsWith(url) || pathname === url;
  }
};
