import { Menu } from '@headlessui/react';
import React, { FunctionComponent } from 'react';
import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { AuthConsumer } from '../../../../context/AuthContext';

interface UserProps {}

const B = styled(Menu.Button)`
  cursor: pointer;
  background-color: transparent;
  padding: 0 0.5rem 2px 0.5rem;
  padding-bottom: 16px;
  border: none;
  svg {
    width: 27px;
    height: 27px;
    display: inline-block;
    fill: #86cba4;
    g {
      fill: #86cba4;
    }
  }
  &:hover {
    svg {
      fill: #009842;
      g {
        fill: #009842;
      }
    }
  }
`;

const User: FunctionComponent<UserProps> = ({}) => {
  return (
    <AuthConsumer>
      {({ logout, user }) => (
        <Menu
          as='div'
          style={{
            position: 'relative',
          }}
        >
          <B>
            <UserIcon />
          </B>

          <Menu.Items
            style={{
              textAlign: 'left',
              boxSizing: 'border-box',
              border: '0 solid #e5e7eb',
              position: 'absolute',
              right: 0,
              marginTop: '0.5rem',
              width: '14rem',
              borderRadius: '0.375rem',
              backgroundColor: 'rgb(255 255 255)',
            }}
          >
            <Menu.Item
              as='div'
              style={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.175)',
                color: 'rgba(0, 0, 0, 0.275)',
                display: 'block',
                padding: '0.5rem 1rem',
                userSelect: 'none',
              }}
            >
              <span>{user ? user.name : ''}</span>
            </Menu.Item>
            <Menu.Item as='div'>
              <NavLink
                className='nav-link'
                activeClassName='active'
                to='/account/profile'
              >
                <span>Mein Profil</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              as='div'
              className='nav-link'
              style={{
                color: '#009842',
                cursor: 'pointer',
                marginBottom: '0.5rem',
              }}
              onClick={() => logout()}
            >
              <span>Abmelden</span>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      )}
    </AuthConsumer>
  );
};

export default User;

// import './User.scss';

// import React, { Component } from 'react';

// import { AuthConsumer } from '../../../../context/AuthContext';
// import { ReactComponent as LogoutIcon } from '../../../../assets/icons/logout.svg';
// import { NavLink } from 'react-router-dom';

// class User extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       dropdownVisible: false,
//     };

//     // This binding is necessary to make `this` work in the callback
//     this.toggleDropdown = this.toggleDropdown.bind(this);
//   }

//   UNSAFE_componentWillMount() {
//     document.addEventListener('mousedown', this.handleClick.bind(this), false);
//   }

//   componentWillUnmount() {
//     document.removeEventListener(
//       'mousedown',
//       this.handleClick.bind(this),
//       false
//     );
//   }

//   handleClick(e) {
//     if (this.node && !this.node.contains(e.target)) {
//       this.setState({ dropdownVisible: false });
//     }
//   }

//   toggleDropdown() {
//     this.setState((state) => ({
//       dropdownVisible: !state.dropdownVisible,
//     }));
//   }

//   render() {
//     return (
//       <AuthConsumer>
//         {({ logout, user }) => (
//           <div className='header-user' ref={(node) => (this.node = node)}>
//             <ul className='navbar-nav justify-content-end'>
//               <li className='nav-item'>
//                 <NavLink
//                   className='nav-link'
//                   activeClassName='active'
//                   to='/account/profile'
//                 >
//                   <span>{user ? user.name : ''}</span>
//                 </NavLink>
//               </li>
//               <li className='nav-item' onClick={logout}>
//                 <div className='nav-link'>
//                   <LogoutIcon className='nav-icon logout' />
//                 </div>
//               </li>
//               {/* <li className="nav-item dropdown" onClick={this.toggleDropdown}>
//                     <NavLink
//                       className="nav-link"
//                       to="#"
//                       role="button"
//                       aria-haspopup="true"
//                       aria-expanded="false">
//                       <span className="mbn1">{user.name}</span>
//                       <LogoutIcon className="nav-icon logout"/>
//                     </NavLink>
//                     <div
//                       className={this.state.dropdownVisible
//                       ? 'dropdown-menu show'
//                       : 'dropdown-menu'}>
//                       <NavLink className="dropdown-item" to="#">
//                         <span>Mein Konto</span>
//                       </NavLink>
//                       <NavLink className="dropdown-item" to="#">
//                         <span>Abmelden</span>
//                       </NavLink>
//                     </div>
//                   </li> */}
//             </ul>
//           </div>
//         )}
//       </AuthConsumer>
//     );
//   }
// }

// export default User;
