import { Button, Result } from 'antd';
import {
  HotelJob,
  HotelJobWithLeg,
  JOB_TYPE,
  Job,
  JobWithLeg,
  Route,
} from '../../../context/Route';
import {
  PlanningConsumer,
  PlanningContext,
} from '../../../context/PlanningContext';
import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { clone, get, map, pick } from 'lodash';

import { DateTime, LocaleOptions } from 'luxon';
import { Geocoding } from '../../../api/MapboxGeo';
import PlanningStepTourList from './PlanningStepTourList';
import PlanningStepTourLoadingOverlay from './PlanningStepTourLoadingOverlay';
import { Request } from '../../../api/Request';
import { ResponseError } from 'superagent';
import Trip from '../../Mapbox/Trip';
import arrayMove from 'array-move';
import qs from 'query-string';
import { withRouter } from 'react-router';

function routeToJsonString(route: Route | undefined) {
  // check if route is set
  if (!route) return JSON.stringify({});
  // convert to string
  const asString = JSON.stringify(route);
  // remove all non latin characters
  const cleand = asString.replace(/[^\x00-\x7F]/g, '');
  // encode to base64
  return btoa(cleand);
}

interface PlanningStepTourState {
  error?: ResponseError;
  redirect: boolean;
  loading: boolean;
  route?: Route;
  routeInfo?: any;
}

interface MatchParams {
  id: string;
}

interface PlanningStepTourProps extends RouteComponentProps<MatchParams> {}

class PlanningStepTour extends Component<
  PlanningStepTourProps,
  PlanningStepTourState
> {
  state: PlanningStepTourState = {
    redirect: false,
    loading: true,
  };

  constructor(props: PlanningStepTourProps) {
    super(props);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.action = this.action.bind(this);
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) =>
      this.setState((state) => ({ ...state, ...data }), resolve)
    );
    // return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
      const { locations, technicians, planningWeekDT } = this.context;
      const { tour } = qs.parse(this.props.location.search);

      await this.context.setWasEdit(!!tour);

      if (
        tour ||
        !locations ||
        !technicians ||
        !planningWeekDT ||
        locations.length === 0 ||
        technicians.length === 0
      ) {
        // some data is missing -> redirect to planing start
        this.setState({ redirect: true });
      }

      const plw = planningWeekDT.plus({ hours: 3 }).toJSDate();
      const tchs = technicians.map((t: any) =>
        pick(t, ['_id', 'address', 'name', 'email', 'overlappingOfftime'])
      );

      const data = await Request.post(
        'planning/routes/optimized/v2',
        {
          locations,
          technicians: tchs,
          planningWeek: plw,
        },
        false
      );

      this.context.setRoute(data);
      await this.setPromisifiedState({
        loading: false,
        route: data,
      });
    } catch (error: any) {
      // console.log(error);
      if (
        // some data is missing -> redirect to planing start
        (error as any).response?.message === 'ERROR_PLANING_NO_TECHNICIANS' ||
        (error as any).response?.message === 'ERROR_PLANING_NO_LOCATIONS'
      ) {
        this.setState({ redirect: true });
      } else {
        this.setState({ loading: false, error: error as any });
      }
    }
  }

  /**
   *
   * @param newItems
   */
  async onSortEnd(newItems: (JobWithLeg | HotelJobWithLeg)[]) {
    try {
      const { route } = this.state;

      console.log(route!.jobs.map((i) => i._id));
      console.log(newItems.map((i) => i._id));

      await this.setPromisifiedState({ loading: true });
      route!.jobs = newItems;
      const newRoutes = await this.fetchInfo(route);
      await this.context.setRoute(newRoutes);
      await this.setPromisifiedState({ loading: false, route: newRoutes });
    } catch (error) {
      alert('Fehler beim Sortieren');
    } finally {
      await this.setPromisifiedState({ loading: false });
    }

    // // display loading
    // await this.setPromisifiedState({ loading: true });
    // // sort
    // const { route } = this.state;
    // const beforeValue = routeToJsonString(route);

    // route!.jobs = arrayMove(route!.jobs, oldIndex, newIndex);
    // // fetch routes
    // const afterValue = routeToJsonString(route);
    // // console.log(afterValue);
    // if (beforeValue !== afterValue) {
    //   const newRoutes = await this.fetchInfo(route);
    //   await this.context.setRoute(newRoutes);
    //   await this.setPromisifiedState({ loading: false, route: newRoutes });
    // } else {
    //   await this.setPromisifiedState({ loading: false });
    // }
  }

  async fetchInfo(route: any) {
    const resultIndexMap = new Array(route.jobs.length);
    const filtered: any[] = [];

    const jobs = await Promise.all(
      route.jobs.map((j: any) => {
        if (j.addressRouting) return Promise.resolve(j);
        if (j.address) return Promise.resolve(j);
        return Geocoding.find(j.nearByAddress).then((geoSearchResult: any) => {
          if (!geoSearchResult.valid) return null;
          return {
            address: {
              location: geoSearchResult.data.location,
            },
          };
        });
      })
    );

    jobs.forEach((j, i) => {
      if (j) {
        const cord =
          get(j, 'addressRouting.location.coordinates') ||
          get(j, 'address.location.coordinates');
        if (cord) {
          resultIndexMap[filtered.length] = i;
          filtered.push(cord);
        }
      }
    });

    const data = await Request.post('planning/routes/preview', filtered);

    route.distance = data.distance;
    route.duration = data.duration;

    data.route.forEach((s: any, i: number) => {
      const index = resultIndexMap[i];
      if (index) route.jobs[index].leg = s;
    });

    return route;
  }

  async action(
    index: number,
    event: string,
    value: any,
    displayTime: boolean,
    until: string | undefined
  ) {
    // console.log(index, event);
    await this.setPromisifiedState({ loading: true });

    let act: (
      index: number,
      value: any,
      displayTime: boolean,
      until: string | undefined
    ) => any = () => Promise.resolve(this.state.route);

    if (event === 'comment') act = this._actionComment;
    if (event === 'note') act = this._actionNote;
    if (event === 'split') act = this._actionSplit;
    if (event === 'sleep') act = this._actionAddHotel;
    if (event === 'sleepHome') act = this._actionAddHome;
    if (event === 'remove') act = this._actionRemove;
    if (event === 'nearByAddress') act = this._actionSetNearBy;
    if (event === 'planedAt') act = this._actionSetplanedAt;

    const route: any = await act.bind(this)(index, value, displayTime, until);

    if (route) {
      // adjust planetAt for last stop (home)
      const planedAtHome = DateTime.fromISO(
        route.jobs[route.jobs.length - 2].planedAt
      ).plus({
        seconds: route.jobs[route.jobs.length - 1].leg.duration,
      });
      route.jobs[route.jobs.length - 1].planedAt = planedAtHome.toISO();

      // adjust planetAt before first stop (home)
      route.jobs[0].planedAt = DateTime.fromISO(value)
        .minus({ seconds: (route.jobs[1] as any).leg.duration })
        .toISO();

      // update sort index
      route.jobs = route.jobs.map((job: any, sort: number) => ({
        ...job,
        sort,
      }));

      await this.context.setRoute(route);
      await this.setPromisifiedState({ route, loading: false });
    } else {
      await this.setPromisifiedState({ loading: false });
    }
  }

  async _actionSetNearBy(index: number) {
    const { route } = this.state;
    if (!route) return;
    const value = prompt(
      'Hotel im Umkreis von:',
      route.jobs[index].nearByAddress
    );
    if (value) {
      route.jobs[index].nearByAddress = value;
      return route;
    }
  }

  async _actionSetplanedAt(
    index: number,
    value: string | any,
    displayTime: boolean,
    until: string | undefined
  ) {
    const { route } = this.state;
    if (!route) return;
    route.jobs[index].planedAt = value;
    (route.jobs[index] as Job).planedAtTimeByUser = displayTime;
    (route.jobs[index] as Job).until = until;

    return route;
  }

  async _actionRemove(index: number) {
    const { route } = this.state;

    const beforeValue = routeToJsonString(route);
    // console.log(beforeValue);

    if (!route) return;
    const job = route.jobs[index] as Job;
    if (job.ref) {
      for (let otherIndex = 0; otherIndex < route.jobs.length; otherIndex++) {
        const otherJob = route.jobs[otherIndex] as Job;
        console.log(otherJob.ref, job.ref, otherIndex, index);
        if (otherJob.ref === job.ref && otherIndex !== index) {
          otherJob.operatingExpense =
            otherJob.operatingExpense + job.operatingExpense;
          otherJob.duePrice = otherJob.duePrice + job.duePrice;
          otherJob.splited = false;
          break;
        }
      }
    }
    route.jobs.splice(index, 1);

    const afterValue = routeToJsonString(route);
    // console.log(afterValue);
    if (beforeValue !== afterValue) return await this.fetchInfo(route);
  }

  async _actionComment(index: number) {
    const { route } = this.state;
    if (!route) return;

    const newCommnet = prompt(
      'Techniker Info ändern',
      route.jobs[index].comment
    );

    if (newCommnet !== null) {
      route.jobs[index].comment = newCommnet;
      return route;
    }
  }

  async _actionNote(index: number) {
    const { route } = this.state;
    if (!route) return;
    alert(route.jobs[index].note);
    return route;
  }

  async _actionSplit(index: number) {
    const { route } = this.state;

    const beforeValue = routeToJsonString(route);
    // console.log(beforeValue);

    if (!route) return;
    let hoursAsString = prompt(
      'Wie viele Stunden soll der erste Job behalten?'
    );
    if (hoursAsString) {
      if (hoursAsString.startsWith('.')) hoursAsString = `0${hoursAsString}`;
      const hours = Number(hoursAsString);
      if (isNaN(hours)) return;
      if (hours > 0 && hours < (route.jobs[index] as Job).operatingExpense) {
        const newJobOperatingExpense =
          (route.jobs[index] as Job).operatingExpense - hours;
        const fak =
          newJobOperatingExpense / (route.jobs[index] as Job).operatingExpense;

        const newJobDuePrice = (route.jobs[index] as Job).duePrice * fak;
        const oldJobDuePrice = (route.jobs[index] as Job).duePrice * (1 - fak);

        const cloneJob = {
          ...clone(route.jobs[index]),
          operatingExpense: newJobOperatingExpense,
          duePrice: newJobDuePrice,
          splited: true,
        };

        (route.jobs[index] as Job).operatingExpense = hours;
        (route.jobs[index] as Job).duePrice = oldJobDuePrice;
        route.jobs.splice(index + 1, 0, cloneJob);

        const afterValue = routeToJsonString(route);
        // console.log(afterValue);
        if (beforeValue !== afterValue) return await this.fetchInfo(route);
      }
    }
  }

  async _actionAddHotel(index: number) {
    const { route } = this.state;
    const beforeValue = routeToJsonString(route);
    if (!route) return;
    const job = route.jobs[index] as Job;
    route.jobs.splice(index + 1, 0, {
      sort: index + 1,
      isHotel: true,
      isHome: false,
      fixed: false,
      name: 'Unterkunft',
      type: JOB_TYPE.HotelAccommodation,
      planedAt: job ? job.planedAt : undefined,
      nearByAddress:
        job && job.address
          ? `${job.address.street}, ${job.address.postalCode} ${job.address.city}`
          : undefined,
    });
    const afterValue = routeToJsonString(route);
    if (beforeValue !== afterValue) return await this.fetchInfo(route);
  }

  async _actionAddHome(index: number) {
    const { route } = this.state;
    const beforeValue = routeToJsonString(route);
    if (!route) return;
    const job = route.jobs[index] as Job;
    route.jobs.splice(index + 1, 0, {
      sort: index + 1,
      isHotel: false,
      isHome: true,
      fixed: false,
      name: 'Zu Hause',
      type: JOB_TYPE.HomeAccommodation,
      planedAt: job ? job.planedAt : undefined,
      address: route.technician.address,
    });
    const afterValue = routeToJsonString(route);
    if (beforeValue !== afterValue) return await this.fetchInfo(route);
  }

  addLegsToItems(
    legs: any,
    jobs: (any | Job | HotelJob)[]
  ): ((JobWithLeg | HotelJobWithLeg) & { sortID: string })[] {
    let index = -1;

    const r: ((JobWithLeg | HotelJobWithLeg) & { sortID: string })[] =
      jobs?.map((job: any, i) => {
        if (index === -1) {
          return { ...job, sortID: `${job._id}_${i}` };
        }
        if (job.type === 2) {
          return { ...job, sortID: `${job._id}_${i}` };
        }
        index += 1;
        return {
          ...job,
          leg: legs && legs.length > index ? legs[index] : null,
          sortID: `${job._id}_${i}`,
        };
      });

    return r;
  }

  formatPlaningWeek(planningWeek?: Date) {
    if (!planningWeek) return '';
    const format: LocaleOptions & Intl.DateTimeFormatOptions = {
      month: 'numeric',
      day: 'numeric',
      weekday: 'short',
    };
    const dt = DateTime.fromJSDate(planningWeek).setLocale('de');
    const asKW = dt.toFormat("'KW' WW '-' yyyy");
    const firstDate = dt.startOf('week').toLocaleString(format);
    const lastDate = dt.endOf('week').toLocaleString(format);
    return `${asKW} | ${firstDate} - ${lastDate}`;
  }

  renderHeader(planningWeek?: Date) {
    return (
      <div className='page-header row justify-content-between'>
        <div className='col col-12'>
          <h1 className='page-title'>
            Tour planen | {this.formatPlaningWeek(planningWeek)}
          </h1>
        </div>
      </div>
    );
  }

  renderRoute(route: Route, planningWeek: Date) {
    return (
      <PlanningConsumer>
        {({ info }) => {
          const itemsWithLegs = this.addLegsToItems(info?.legs, route.jobs);
          return (
            <PlanningStepTourList
              items={itemsWithLegs}
              onSortEnd={this.onSortEnd}
              action={this.action}
              // distance={5}
              technician={route.technician}
              planningWeek={planningWeek}
            />
          );
        }}
      </PlanningConsumer>
    );
  }

  hasRoutingError() {
    const { error } = this.state;
    return (
      error && error.response && error.response.text.includes('routes is empty')
    );
  }

  hasError() {
    const { error } = this.state;
    return !!error;
  }

  render() {
    const { route, redirect, loading } = this.state;

    // redirect to login
    if (redirect) {
      return <Redirect to='/planning/locations' />;
    }

    if (this.hasRoutingError()) {
      return (
        <Result
          status='warning'
          title='Die Route enthält einen Anschrift, zu der nicht navigiert werden kann.'
          extra={
            <Button
              type='primary'
              key='console'
              onClick={() => this.setState({ redirect: true })}
            >
              Zurück
            </Button>
          }
        />
      );
    }

    if (this.hasError()) {
      return (
        <Result
          status='warning'
          title='Es ist ein Fehler aufgetreten!'
          extra={
            <Button
              type='primary'
              key='console'
              onClick={() => this.setState({ redirect: true })}
            >
              Zurück
            </Button>
          }
        />
      );
    }

    return (
      <PlanningConsumer>
        {({ planningWeek }) => (
          <div className='route container-inner'>
            <div className='row'>
              <div className='col col-8 col-lg-7'>
                {this.renderHeader(planningWeek)}
                <div className='row page-content scroll'>
                  <div className='col col-12'>
                    {loading && <PlanningStepTourLoadingOverlay />}
                    {route &&
                      planningWeek &&
                      this.renderRoute(route, planningWeek)}
                  </div>
                </div>
              </div>
              <div className='col col-4 col-lg-5 col-trip'>
                <Trip
                  className='map'
                  marker={
                    route
                      ? map(route.jobs, (jl, index) => {
                          const d =
                            get(jl, 'addressRouting.location.coordinates') ||
                            get(jl, 'address.location.coordinates');
                          return {
                            _id: jl._id,
                            type: jl.type,
                            index: index,
                            longitude: d ? d[0] : null,
                            latitude: d ? d[1] : null,
                          };
                        })
                      : []
                  }
                />
              </div>
            </div>
          </div>
        )}
      </PlanningConsumer>
    );
  }
}

PlanningStepTour.contextType = PlanningContext;

export default withRouter(PlanningStepTour);
