import './Search.scss';

import { Input } from 'antd';
import React, { FunctionComponent, useState } from 'react';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

interface SearchProps {
  value?: string | null;
  placeholder?: string;
  onChange: (value: string | undefined, _: false) => void;
}

const Search: FunctionComponent<SearchProps> = ({
  placeholder,
  onChange,
  value,
}) => {
  const [_value, setValue] = useState(value || '');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
    !!onChange && onChange(value, false);
  };

  return (
    <Input.Group compact className='search'>
      <SearchIcon style={{ margin: '8px 4px 8px 0' }} />
      <Input
        placeholder={placeholder || 'Suche'}
        allowClear
        value={_value}
        onChange={handleInputChange}
      />
    </Input.Group>
  );
};

export default Search;
