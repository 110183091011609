import { Location } from '../../../context/Route';
import React from 'react';
import numeral from 'numeral';
import { sumHours } from '../helper/sum';

// switch between locales
numeral.locale('de');

function shortCurrency(value: number, smallFormat: string) {
  const f = value < 10000 ? smallFormat || '0,0' : '0.0a';
  return numeral(value).format(f);
}

export default function CheckTime(props: {
  locations: Location[];
  loading?: boolean;
}) {
  const a = ['Prüfzeit:'];
  if (props.loading) {
    a.push('berechne...');
  } else if (props.locations && props.locations.length > 0) {
    const hours = sumHours(props.locations);
    const d = `${shortCurrency(hours as number, '0.00')}h`;
    a.push(d);
  } else {
    a.push(`0h`);
  }
  return <span style={{ display: 'block' }}>{a.join(' ')}</span>;
}
