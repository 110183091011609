import React from 'react';

const mainStyle = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: '40px',
  paddingBottom: '40px',
  background: '#ffffff',
};

const AltLayout = (props) => (
  <React.Fragment>
    <main role='main' style={mainStyle}>
      <div className='container'>{props.children}</div>
    </main>
  </React.Fragment>
);

export default AltLayout;
