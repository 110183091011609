import React from 'react';

const FormContext = React.createContext();

class FormProvider extends React.Component {
  state = { isEditing: false };

  constructor() {
    super();
    this.setEditing = this.setEditing.bind(this);
  }

  setEditing(isEditing) {
    this.setState({ isEditing });
  }

  render() {
    return (
      <FormContext.Provider
        value={{
          isEditing: this.state.isEditing,
          setEditing: this.setEditing,
        }}
      >
        {this.props.children}
      </FormContext.Provider>
    );
  }
}
const FormConsumer = FormContext.Consumer;
export { FormProvider, FormConsumer };
