import 'rc-calendar/assets/index.css';
import './Picker.scss';
import 'moment/locale/de';

import React, { Component } from 'react';

import Calender from 'rc-calendar';
import { DatePickerInnerProps } from './interface';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import moment from 'moment';

moment.locale('de');

class DatepickerInnerWeek extends Component<DatePickerInnerProps> {
  static format(startDate: DateTime, format?: string) {
    if (!startDate) return startDate;
    return startDate
      .set({ weekday: 0 })
      .plus({ weeks: 1 })
      .endOf('day')
      .toFormat(format ?? "'KW' WW '-' yyyy");
  }

  state = {
    startDate: DateTime.local().setLocale('de'),
  };

  currentWeekNumber = DateTime.local().setLocale('de').weekNumber;
  currentYear = DateTime.local().setLocale('de').year;

  constructor(props: DatePickerInnerProps) {
    super(props);
    this.state.startDate = DateTime.fromJSDate(this.props.startDate!);
    this.onChange = this.onChange.bind(this);
    this.dateRender = this.dateRender.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ startDate }: DatePickerInnerProps) {
    this.setState({
      startDate: DateTime.fromJSDate(startDate!),
    });
  }

  onChange = (value: moment.Moment | null) => {
    if (!value) return;
    const { onChangeValue } = this.props;
    const valueString = value.toISOString();
    const valueDT = DateTime.fromISO(valueString).setLocale('de');
    const startDate = valueDT.startOf('week');
    const endDate = valueDT.endOf('week');
    this.setState({ value }, () => {
      onChangeValue &&
        onChangeValue(
          startDate.toJSDate(),
          endDate.toJSDate(),
          DatepickerInnerWeek.format(startDate),
          'week'
        );
    });
  };

  dateRender = (current: moment.Moment) => {
    const currentDT = DateTime.fromJSDate(current.toDate());
    const startDateDt = DateTime.fromJSDate(this.props.startDate!);

    const isWeek =
      currentDT.weekYear === startDateDt.weekYear &&
      currentDT.weekNumber === startDateDt.weekNumber;

    const classes = classNames('rc-calendar-date', {
      'rc-calendar-selected-day': isWeek,
    });

    return <div className={classes}>{current.date()}</div>;
  };

  render() {
    return (
      <Calender
        showWeekNumber
        showDateInput={false}
        showToday={false}
        style={{ zIndex: 1000 }}
        onChange={this.onChange}
        dateRender={this.dateRender}
        defaultValue={moment(this.props.startDate)}
      />
    );
  }
}

export default DatepickerInnerWeek;
