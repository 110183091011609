import ComparisonInput from './ComparisonInput';
import ComparisonItem from './ComparisonItem';
import React from 'react';
import { isNumber } from 'lodash';
import { Request } from '../../api/Request';

interface KPIComparisonProps {
  item: any;
}

function getHotelCount(item: any) {
  if (!item || !item.jobs) return 0;
  return item.jobs.filter((f: any) => f.isHotel).length;
}

function getHotelBookedCount(item: any) {
  if (!item || !item.jobs) return 0;
  return item.jobs.filter((f: any) => f.isHotel && f.address && f.hotelName)
    .length;
}

export default function KPIComparison(props: KPIComparisonProps) {
  const { item } = props;

  // const renta = calculateRenta(item);
  const hotelCount = getHotelCount(item);
  const hotelCountBooked = getHotelBookedCount(item);

  const asNumber = (item: any, key: string, d = 0) => {
    return item && item[key] && isNumber(item[key])
      ? Math.round(item[key] * 100) / 100
      : d;
  };

  const reloadRenta = async () => {
    await Request.post(`tours/${item._id}/renta`, {});
    location.reload();
  };

  return (
    <div className='row'>
      {
        // Money
      }
      <div className='col col-12 col-xs-12 col-lg-6 col-xl-4 col-line'>
        <ComparisonItem title='Umsatz'>
          <ComparisonInput
            actual={asNumber(item, 'actualPrice')}
            estimated={asNumber(item, 'estimatedPrice')}
            suffix=' €'
            readOnly={true}
            dangerForHigher={false}
            dangerForLower={true}
          />
        </ComparisonItem>
        <ComparisonItem title='Renta' onReload={() => reloadRenta()}>
          <ComparisonInput
            actual={asNumber(item, 'rentaIst')}
            estimated={asNumber(item, 'rentaPlan')}
            suffix=' €'
            readOnly={true}
            dangerForHigher={false}
            dangerForLower={true}
          />
        </ComparisonItem>
        <ComparisonItem title='Tagessatz'>
          <ComparisonInput
            actual={asNumber(item, 'tagessatzIst')}
            estimated={asNumber(item, 'tagessatzPlan')}
            suffix=' €'
            readOnly={true}
            dangerForHigher={false}
            dangerForLower={true}
          />
        </ComparisonItem>
        <ComparisonItem title='Kosten pro Stunde'>
          <ComparisonInput
            actual={asNumber(item, 'costsPerHour')}
            hideEstimated={true}
            suffix=' €'
            readOnly={true}
            dangerForHigher={false}
            dangerForLower={false}
          />
        </ComparisonItem>
      </div>
      {
        // Time
      }
      <div className='col col-12 col-xs-12 col-lg-6 col-xl-4 col-line'>
        <ComparisonItem title='Arbeitszeit'>
          <ComparisonInput
            actual={
              asNumber(item, 'actualRuWHours') +
              asNumber(item, 'actualWorkingHours') +
              asNumber(item, 'actualDrivingHours')
            }
            estimated={
              asNumber(item, 'planWorkingHours') +
              asNumber(item, 'planDrivingHours')
            }
            suffix=' h'
            readOnly={true}
            dangerForHigher={true}
            dangerForLower={false}
          />
        </ComparisonItem>
        <ComparisonItem title='Prüfzeit'>
          <ComparisonInput
            actual={asNumber(item, 'actualWorkingHours')}
            estimated={asNumber(item, 'planWorkingHours')}
            suffix=' h'
            readOnly={true}
            dangerForHigher={true}
            dangerForLower={false}
          />
        </ComparisonItem>
        <ComparisonItem title='Fahrzeit'>
          <ComparisonInput
            actual={asNumber(item, 'actualDrivingHours')}
            estimated={asNumber(item, 'planDrivingHours')}
            suffix=' h'
            readOnly={true}
            dangerForHigher={true}
          />
        </ComparisonItem>
        <ComparisonItem title='R.u.W.-Zeit'>
          <ComparisonInput
            actual={asNumber(item, 'actualRuWHours')}
            hideEstimated={true}
            suffix=' h'
            readOnly={true}
            dangerForHigher={false}
            dangerForLower={false}
          />
        </ComparisonItem>
      </div>
      {
        // Distance
      }
      <div className='col col-12 col-xs-12 col-lg-6 col-xl-4 col-line'>
        <ComparisonItem title='Fahrstrecke'>
          <ComparisonInput
            actual={asNumber(item, 'actualDrivingDistance')}
            estimated={asNumber(item, 'planDrivingDistance')}
            suffix=' km'
            readOnly={true}
            dangerForHigher={true}
            dangerForLower={false}
          />
        </ComparisonItem>
        <ComparisonItem title='Mehr-KM'>
          <ComparisonInput
            actual={asNumber(item, 'mehrIstKM')}
            estimated={asNumber(item, 'mehrPlanKM')}
            suffix=' km'
            readOnly={true}
            dangerForHigher={true}
            dangerForLower={false}
          />
        </ComparisonItem>
        <ComparisonItem title='Übernachtungen'>
          <ComparisonInput
            actual={
              hotelCountBooked && Math.max(hotelCountBooked, 0) > 0
                ? hotelCountBooked
                : 0
            }
            estimated={
              hotelCount && Math.max(hotelCount, 0) > 0 ? hotelCount : 0
            }
            suffix=''
            istText='Gebucht'
            readOnly={true}
            dangerForHigher={true}
            dangerForLower={true}
          />
        </ComparisonItem>
      </div>
    </div>
  );
}
