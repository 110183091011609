import React, { FunctionComponent, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import DatepickerInnerWeek from '../Picker/DatepickerInnerWeek';
import { Modal, notification } from 'antd';
import { Request } from '../../api/Request';
import { get, reduce } from 'lodash';

interface ChangeWeekModalProps {
  startDate: string;
  technician: string;
  onClose: () => void;
  onSubmit: (weeks: number) => void;
}

const ChangeWeekModal: FunctionComponent<ChangeWeekModalProps> = (
  props: ChangeWeekModalProps
) => {
  const [checking, setChecking] = useState(false);
  const [isOk, setOK] = useState(false);

  const [date, setDate] = useState(
    DateTime.fromISO(props.startDate).toJSDate()
  );
  const [weeks, setWeeks] = useState(0);
  useEffect(() => {
    const a = DateTime.fromISO(props.startDate);
    const b = DateTime.fromJSDate(date);
    const c = b.diff(a);
    const w = c.as('weeks');
    setWeeks(Math.ceil(w));
  }, [date]);

  useEffect(() => {
    try {
      setChecking(true);
      Request.list('planning/technicians/available', {
        sort: 'name',
        desc: false,
        limit: 25,
        page: 0,
        start: DateTime.fromJSDate(date)
          .set({ weekday: 1 })
          .startOf('day')
          .toISO(),
        end: DateTime.fromJSDate(date).set({ weekday: 7 }).endOf('day').toISO(),
        locations: [],
      }).then((res) => {
        // TODO: Suche in der Liste, ob der Techniker dirn ist, wenn ja alles ok, wenn nein, kann er an dem Datum nicht

        const result = reduce(
          res.items,
          (prev, item) => {
            return prev || item._id === props.technician;
          },
          false
        );

        setOK(result);
        setChecking(false);
      });
    } catch (error: any) {
      const msg = get(error, 'response.body.message');
      notification.error({
        message: 'Fehler',
        description: msg || error.message || JSON.stringify(error),
        duration: 10000,
      });
    }
  }, [date]);

  return (
    <Modal
      className='tour-changeweek-modal'
      title='Tour verschieben'
      closable={true}
      keyboard={false}
      closeIcon={undefined}
      visible={true}
      onCancel={() => props.onClose()}
      onOk={() => {
        props.onSubmit(weeks);
      }}
      okButtonProps={{
        disabled: weeks <= 0 || checking || !isOk,
      }}
      width={286}
    >
      <p style={{ padding: 8 }}>
        Bitte voher prüfen, ob der Techniker in der Zielwoche verfügbar ist!
      </p>
      <DatepickerInnerWeek
        startDate={date}
        onChangeValue={(startDate) => {
          setDate(startDate);
        }}
      />
    </Modal>
  );
};

export default ChangeWeekModal;
