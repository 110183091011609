/**
 * Returns the error message to be displayed
 * @param error
 * @returns
 */
const renderError = (error: any) => {
  if (typeof error === 'object') {
    const values = Object.values(error);
    if (values.length === 1) {
      return renderError(values[0]);
    } else {
      return 'Bitte korrigieren Sie die Fehler';
    }
  }
  return error;
};

export default renderError;
