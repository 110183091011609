import {
  PlanningConsumer,
  PlanningContext,
} from '../../../context/PlanningContext';
import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import Table, { SELECTION_TYPE, TableOptions } from '../../Table/Table';
import { debounce, filter, keyBy, mapValues, uniq } from 'lodash';

import { AuthConsumer } from '../../../context/AuthContext';
import { DateTime } from 'luxon';
import { Request } from '../../../api/Request';
import { TabledHeaderField } from './createLocationTableHeader';
import qs from 'query-string';
import request from 'superagent';
import styled from 'styled-components';

const WrapperFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  > span {
    padding: 2px 4px;
  }
`;

interface PlanningStepTechniciansOptions extends TableOptions {}

const defaultOptions: PlanningStepTechniciansOptions = {
  sort: 'distance',
  desc: false,
};

interface PlanningStepTechniciansState {
  error?: Error;
  loading: boolean;
  options: PlanningStepTechniciansOptions;
  items: any[];
}

class PlanningStepTechnicians extends Component<
  RouteComponentProps,
  PlanningStepTechniciansState
> {
  state = {
    loading: true,
    options: defaultOptions,
    items: [],
  };

  oldRequest?: request.SuperAgentRequest;
  storeOptionsAndLoadDebounced: () => void;

  header: TabledHeaderField[] = [
    { key: 'initials', width: '0-5', sort: true, title: 'Kürzel' },
    { key: 'name', width: '1-5', sort: true, title: 'Name' },
    { key: 'address.street', width: '1-5', sort: true, title: 'Straße' },
    { key: 'address.postalCode', width: '0-75', sort: true, title: 'PLZ' },
    { key: 'address.city', width: '1-5', sort: true, title: 'Ort' },
    {
      key: 'distance',
      width: 1,
      sort: true,
      title: 'Distanz',
      format: (value) => <span>{`${value}km`}</span>,
    },
    {
      key: 'overlappingOfftime',
      width: '1-5',
      sort: false,
      title: 'Nicht verfügbar',
      format: (value) => {
        if (!value) return <span></span>;
        return (
          <WrapperFlex>
            {uniq(value as string[])
              .sort((a: string, b: string) => a.localeCompare(b))
              .map((d: any) => (
                <span key={d} className='skill' style={{ color: '#ff0000' }}>
                  {DateTime.fromISO(d).toFormat('dd.MM.yy')}
                </span>
              ))}
          </WrapperFlex>
        );
      },
    },
  ];

  constructor(props: RouteComponentProps) {
    super(props);
    this.handleOptionsChanged = this.handleOptionsChanged.bind(this);
    this.storeOptionsAndLoadDebounced = debounce(this.storeOptionsAndLoad, 500);
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async componentDidMount() {
    await this.context.setWasEdit(false);
    await this.loadOptionsFromURL();
    this.loadWithPlaningWeek();
  }

  loadOptionsFromURL() {
    const data = qs.parse(this.props.location.search);
    return new Promise<void>((resolve) => {
      const s: PlanningStepTechniciansOptions = {
        filters:
          data && data.filters
            ? JSON.parse(data.filters as string)
            : defaultOptions.filters,
        sort: data && data.sort ? (data.sort as string) : defaultOptions.sort,
        desc: data && data.desc ? data.desc === 'true' : defaultOptions.desc,
      };
      this.setState({ options: s }, async () => {
        const d = DateTime.fromISO(data.end as string);
        if (d.isValid) {
          await this.context.setPlanningWeek(new Date());
        }
        resolve();
      });
    });
  }

  storeOptionsAndLoad() {
    this.storeOptionsAtURL();
    this.loadWithPlaningWeek();
  }

  storeOptionsAtURL() {
    const s = {
      filters: JSON.stringify(this.state.options.filters),
      sort: this.state.options.sort,
      desc: this.state.options.desc,
    };
    const stringified = qs.stringify(s);
    window.history.replaceState(s, 'safePlan', `?${stringified}`);
  }

  loadWithPlaningWeek() {
    const { planningWeek } = this.context;
    this.loadData(planningWeek);
  }

  loadData(planningWeek: Date) {
    const { getRequiredSkillsForAllLocations, getCordsForAllLocations } =
      this.context;
    const cords = getCordsForAllLocations();
    const _skills = getRequiredSkillsForAllLocations();
    const skills = mapValues(
      keyBy(_skills, (p) => p),
      () => true
    );
    Request.list('planning/technicians/available', {
      sort: this.state.options.sort,
      desc: this.state.options.desc,
      limit: this.state.options.limit,
      page: this.state.options.page,
      start: DateTime.fromJSDate(planningWeek)
        .set({ weekday: 1 })
        .startOf('day')
        .toISO(),
      end: DateTime.fromJSDate(planningWeek)
        .set({ weekday: 7 })
        .endOf('day')
        .toISO(),
      ...skills,
      locations: cords,
    }).then(
      (result) => {
        this.setState({
          loading: false,
          items: result.items,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          error,
        });
      }
    );
  }

  async handleOptionsChanged(opts: PlanningStepTechniciansOptions) {
    await this.setPromisifiedState({ isLoaded: false, options: opts });
    this.storeOptionsAtURL();
    this.loadWithPlaningWeek();
  }

  handleSelectionChanged(
    techniciansToSet: any[],
    selection: boolean,
    technicians: any[],
    locations: any[],
    setTechnicians?: (technicians: any[]) => void
  ) {
    let value: any[] = [];

    if (selection) {
      value = technicians.concat(techniciansToSet);
    } else {
      const ids = techniciansToSet.map((l) => l._id);
      value = filter(technicians, (l) => !ids.includes(l._id));
    }

    // this.loadInfoPreview.bind(this)(
    //   { ...value, ...locations },
    //   setLoadingInfo,
    //   setInfo
    // );
    setTechnicians && setTechnicians(value);
  }

  // loadInfoPreview(
  //   locations: any[],
  //   setLoadingInfo?: (loading: boolean) => void,
  //   setInfo?: (info: RouteInfo) => void
  // ) {
  //   try {
  //     // stop existing request
  //     if (this.oldRequest) {
  //       this.oldRequest.abort();
  //     }

  //     // set loading
  //     setLoadingInfo && setLoadingInfo(true);

  //     // prepare coordinates
  //     const reqData = locations.map((l) =>
  //       get(l, 'address.location.coordinates')
  //     );

  //     // build request
  //     this.oldRequest = Request.postForRoutePreview(reqData);

  //     // run request
  //     this.oldRequest
  //       .then((res) => res.body)
  //       .then((data) => {
  //         // set result and loading
  //         setInfo && setInfo(data);
  //       });
  //   } catch (error: any) {
  //     if (
  //       error &&
  //       error.response &&
  //       error.response.text.includes('routes is empty')
  //     ) {
  //       setLoadingInfo && setLoadingInfo(false);
  //       notification.error({
  //         duration: 0,
  //         message: 'Vorschau nicht möglich',
  //         description:
  //           'Die Route enthält einen Anschrift zu der nicht navigiert werden kann.',
  //       });
  //     } else if (error.code !== 'ABORTED') {
  //       setLoadingInfo && setLoadingInfo(false);
  //       notification.error({
  //         duration: 0,
  //         message: `Vorschau nicht möglich (Code ${error.code})`,
  //         description: 'Unbekannter Fehler.',
  //       });
  //     }
  //   }
  // }

  isItemDisabled(blocked: any, user: any, technician: any) {
    const block =
      blocked &&
      blocked.te &&
      !!blocked.technicians[technician._id] &&
      blocked.technicians[technician._id] !== user.id;
    const addr = !(
      technician.address &&
      technician.address.street &&
      technician.address.postalCode &&
      technician.address.city
    );
    return block || addr;
  }

  render() {
    const { items, loading, options } = this.state;
    return (
      <AuthConsumer>
        {({ user }: { user: { id?: string } }) => (
          <PlanningConsumer>
            {({
              blocked,
              locations,
              technicians,
              setTechnicians,
              getRequiredSkillsForAllLocations,
            }) =>
              locations && locations.length > 0 ? (
                <div className='location container-inner'>
                  <div className='page-header row justify-content-between'>
                    <div className='col col-10'>
                      <h1 className='page-title'>Techniker auswählen</h1>
                      <div className='page-subtitle'>
                        <span>Bennötige Kenntnisse: </span>
                        {getRequiredSkillsForAllLocations &&
                          getRequiredSkillsForAllLocations().map((skill) => (
                            <span className='skill-bubble' key={skill}>
                              {skill}
                            </span>
                          ))}
                      </div>
                    </div>
                    <div className='col col-6 page-header-actions justify-content-md-end pt-md-0' />
                  </div>
                  <div className='row page-content'>
                    <div className='col col-12'>
                      <Table
                        loading={loading}
                        selectable={SELECTION_TYPE.Filter}
                        options={options}
                        header={this.header}
                        items={items}
                        selection={technicians}
                        isItemDisabled={(technician) =>
                          this.isItemDisabled(blocked, user, technician)
                        }
                        handleOptionsChanged={(opts) =>
                          this.handleOptionsChanged(opts)
                        }
                        handleSelectionChanged={(technician, selection) => {
                          this.handleSelectionChanged(
                            technician,
                            selection,
                            technicians || [],
                            locations || [],
                            setTechnicians
                          );
                        }}
                        link='/administration/technicians'
                        linkTarget='_blank'
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <Redirect to='/planning/locations' />
              )
            }
          </PlanningConsumer>
        )}
      </AuthConsumer>
    );
  }
}

PlanningStepTechnicians.contextType = PlanningContext;

export default withRouter(PlanningStepTechnicians);
