import './Location.scss';

import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { get, reduce, set } from 'lodash';
import { validateEMail, validateTask } from './validation';

import { AuthConsumer } from '../../context/AuthContext';
import CheckInput from '../Form/CheckInput';
import DirectInput from '../Form/DirectInput';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import MapInput from '../Form/MapInput';
import { Request } from '../../api/Request';
import SaveButton from '../SharesActions/Save';
import SelectInput from '../Form/SelectInput';
import getTasks from './getTasks';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import { notification } from 'antd';
import prepareForDropdown from '../helper/prepareForDropdown';
import renderTask from './renderTask';

type Task = any;
type Location = any;

interface LocationNewState {
  redirectTo?: string;
  error?: Error;
  loading: boolean;
  valid: {
    all: boolean;
    name: boolean;
    customer: boolean;
    addressRouting: boolean;
    tasks: boolean;
  };
  item: Location;
  customers: {
    value: string | null;
    text: string;
  }[];
  customerNumbers: {
    value: string | null;
    text: string;
  }[];
  customerGroups: {
    value: string | null;
    text: string;
  }[];
  tasks: Task[];
}

function SectionHeader({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) {
  return (
    <div className='row table-divider'>
      <div className='col'>
        <span>{title}</span>
        {subtitle && <small className='ml-1'>{subtitle}</small>}
      </div>
    </div>
  );
}

class LocationNew extends Component<RouteComponentProps, LocationNewState> {
  state: LocationNewState = {
    loading: true,
    valid: {
      all: false,
      name: false,
      customer: false,
      addressRouting: false,
      tasks: false,
    },
    item: {
      price: {},
      address: {
        location: {
          coordinates: [0, 0],
          type: 'Point',
        },
      },
    },
    customers: [],
    customerNumbers: [],
    customerGroups: [],
    tasks: [],
  };

  constructor(props: RouteComponentProps) {
    super(props);
    this.storeValue = this.storeValue.bind(this);
    this.send = this.send.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
      const [tasks, customers] = await Promise.all([
        getTasks(),
        Request.list('customers', { limit: 25000 }),
      ]);
      this.setState({
        loading: false,
        tasks,
        customers: prepareForDropdown(
          customers.items,
          'Kunde auswählen',
          'name',
          'customernumber'
        ),
        customerNumbers: prepareForDropdown(
          customers.items,
          'Kunde auswählen',
          'tag'
        ),
        customerGroups: prepareForDropdown(
          customers.items,
          'Kunde auswählen',
          'group.name'
        ),
      });
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Laden',
        description: 'Die Kunden konnten nicht geladen werden.',
      });
      this.setState({
        loading: false,
        error,
      });
    }
  }

  async storeValue(k: string, value: any) {
    // console.log(value);
    const data = set(this.state.item, k, value);
    this.setState({
      item: data,
      valid: this.isValid(),
    });
  }

  async send() {
    try {
      const res = await Request.post('locations', this.state.item, false);
      this.setState({ redirectTo: res._id || res.id });
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Laden',
        description:
          'Der Kunde konnte aufgrund eines Fehlers nicht geladen werden.',
      });
      this.setState({
        loading: false,
        error,
      });
    }
  }

  getCustomer(item: Location) {
    if (!item || !item.customer) return null;
    if (typeof item.customer === 'string') return item.customer;
    return item.customer._id;
  }

  isValid() {
    const { item } = this.state;
    if (!item)
      return {
        all: false,
        name: false,
        customer: false,
        addressRouting: false,
        tasks: false,
      };
    const name = this.state.item.name && this.state.item.name.length > 1;
    const customer = !!this.state.item.customer;
    const addressRouting =
      !!this.state.item.addressRouting &&
      !!this.state.item.addressRouting.location;
    const tasks = reduce(
      this.state.tasks,
      (p, task) => {
        return p && validateTask(task, this.state.item);
      },
      true
    );
    return {
      all: name && customer && addressRouting && tasks,
      name,
      customer,
      addressRouting,
      tasks,
    };
  }

  renderDirectInputRow(
    item: any,
    key: string,
    title: string,
    validate: (value: string) => boolean = () => true,
    defaultValue?: string
  ) {
    return (
      <div className='row table-row input-row'>
        <div className='col'>
          <DirectInput
            title={title}
            value={get(item, key)}
            callback={(value: string) => this.storeValue(key, value)}
            validate={(value: string) => validate(value)}
            defaultValue={defaultValue}
          />
        </div>
      </div>
    );
  }

  renderSelectInputRow(value: any, key: string, title: string, options: any) {
    return (
      <div className='row table-row input-row'>
        <div className='col'>
          <SelectInput
            title={title}
            value={value}
            direct={true}
            validate={(value: string) =>
              value !== undefined && value !== null && value.length > 0
            }
            options={options}
            callback={(value: string) => this.storeValue(key, value)}
          />
        </div>
      </div>
    );
  }

  render() {
    const { item, customers, tasks, valid } = this.state;
    if (this.state.redirectTo) {
      return (
        <Redirect to={`/administration/locations/${this.state.redirectTo}`} />
      );
    }
    // console.log(valid);
    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='location container-inner'>
            <InPageNavigation to='/administration/locations' />
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col-6 pl-0'>
                <h1 className='page-title'>Standort hinzufügen</h1>
              </div>
              <div className='col-6 p-0 actions'>
                <SaveButton
                  hidden={!hasPermission(['location:write'])}
                  disabled={!valid.all}
                  callback={() => this.send()}
                />
              </div>
            </div>
            <div className='page-content'>
              <div className='row'>
                <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                  <SectionHeader title='Stammdaten' />
                  {this.renderSelectInputRow(
                    this.getCustomer(item),
                    'customer',
                    'Kunde',
                    customers
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'name',
                    'Name',
                    (value) =>
                      value !== undefined && value !== null && value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'tag',
                    'Standort NR',
                    (value) =>
                      value !== undefined && value !== null && value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'contact',
                    'Ansprechpartner',
                    (value) => !value || value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'email',
                    'E-Mail',
                    (value) => !value || validateEMail(value)
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'email2',
                    'E-Mail 2',
                    (value) => !value || validateEMail(value)
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'phone',
                    'Telefon',
                    (value) => !value || value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'phone2',
                    'Telefon 2',
                    (value) => !value || value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'fax',
                    'Fax',
                    (value) => !value || value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'space',
                    'Fläche',
                    (value) => !value || Number(value) > 0
                  )}
                  <SectionHeader title='Sonstiges' />
                  {this.renderDirectInputRow(
                    item,
                    'comment',
                    'Techniker Info',
                    (value) => !value || value.length > 1,
                    item.customer ? item.customer.comment : null
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'note',
                    'Planer Info',
                    (value) => !value || value.length > 1,
                    'Wird nur in der Planung angezeigt'
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'openingHours',
                    'Prüfzeiten',
                    (value) => !value || value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'area_ks',
                    'Area/KS',
                    (value) => !value || value.length > 1
                  )}
                  <SectionHeader
                    title='Einheiten'
                    subtitle='(Boxen, Kammern, Abteilungen, Konzessionen,
                                Bereiche, Werkbänke, etc.)'
                  />
                  {this.renderDirectInputRow(
                    item,
                    'basisItemCount',
                    'Anzahl',
                    (value) => !value || Number(value) > 0
                  )}
                </div>
                <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                  <SectionHeader title='Adresse' />
                  {this.renderDirectInputRow(
                    item,
                    'address.street',
                    'Straße',
                    (value) =>
                      value !== undefined && value !== null && value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'address.city',
                    'Stadt',
                    (value) =>
                      value !== undefined && value !== null && value.length > 1
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'address.postalCode',
                    'Plz',
                    (value) =>
                      value !== undefined &&
                      value !== null &&
                      value.length > 3 &&
                      value.length < 6
                  )}
                  {this.renderDirectInputRow(
                    item,
                    'address.countryCode',
                    'Land',
                    (value) =>
                      value !== undefined && value !== null && value.length > 3
                  )}
                  <SectionHeader title='Adresse Navigation' />
                  <div className='row input-row'>
                    <div className='col'>
                      <MapInput
                        force={true}
                        className='small'
                        value={item.addressRouting}
                        callback={(value) =>
                          this.storeValue('addressRouting', value)
                        }
                      />
                    </div>
                  </div>
                  <SectionHeader title='Label' />
                  <div className='row table-row input-row'>
                    <div className='col-5 pr-0'>
                      <CheckInput
                        // short='Am'
                        title='geprüft am'
                        value={item.labelCheckedAt}
                        callback={(value: boolean) =>
                          this.storeValue('labelCheckedAt', value)
                        }
                      />
                    </div>
                    <div className='col-4 pl-0'>
                      <CheckInput
                        // short='Nch'
                        title='nächste Prüfung'
                        value={item.labelNextCheckedAt}
                        callback={(value: boolean) =>
                          this.storeValue('labelNextCheckedAt', value)
                        }
                      />
                    </div>
                  </div>
                  <SectionHeader title='Sperre' />
                  <div className='row table-row input-row'>
                    <div className='col-5 pr-0'>
                      <CheckInput
                        title='für Planung sperren'
                        value={item.locked_planing}
                        callback={(value: boolean) =>
                          this.storeValue('locked_planing', value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col col-12'>
                  <SectionHeader title='Prüfungen' />
                  {tasks.map((task) =>
                    renderTask(task, item, true, false, this.storeValue, false)
                  )}
                  <div className='mb-3'>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default LocationNew;
