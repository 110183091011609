import React, { Component, Fragment } from 'react';

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import EditabledInput from '../Form/EditableInput';
import MapInput from '../Form/MapInput';
import Modal from 'react-modal';
import classNames from 'classnames';
import customStyles from '../helper/modalCustomStyles';

interface TourHotelEnterNameProps {
  value: {
    _id: string;
    address?: any;
    hotelName?: string;
  };
  onChangeAddress: (id: string, data: any) => void;
  onChangeHotelname: (id: string, data: any) => void;
}

interface TourHotelEnterNameState {
  invalidString?: string;
  date?: string;
  modalIsOpen: boolean;
}

Modal.setAppElement('#root');

class TourHotelEnterName extends Component<
  TourHotelEnterNameProps,
  TourHotelEnterNameState
> {
  state: TourHotelEnterNameState = {
    modalIsOpen: false,
  };

  constructor(props: TourHotelEnterNameProps) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.rememberMapInputValue = this.rememberMapInputValue.bind(this);
    this.rememberHotelname = this.rememberHotelname.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  rememberMapInputValue(ad: any) {
    const { value, onChangeAddress } = this.props;
    onChangeAddress && onChangeAddress(value._id, ad);
  }

  rememberHotelname(ad: any) {
    const { value, onChangeHotelname } = this.props;
    onChangeHotelname && onChangeHotelname(value._id, ad);
  }

  render() {
    const { value } = this.props;
    return (
      <Fragment>
        <button
          onClick={this.openModal}
          className={classNames('route-step-action', {
            danger: false,
          })}
        >
          <EditIcon className='edit-icon' />
          {value.hotelName ? 'Hotel ändern' : 'Hotel eingeben'}
        </button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel=''
        >
          <h4>Hotel erfassen</h4>
          <EditabledInput
            title='Name des Hotels'
            value={value.hotelName}
            callback={this.rememberHotelname}
          />
          <MapInput
            value={value.address}
            callback={this.rememberMapInputValue}
          />
        </Modal>
      </Fragment>
    );
  }
}

export default TourHotelEnterName;
