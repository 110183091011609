import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/calender.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { de } from 'date-fns/locale/de';
import { DateTime } from 'luxon';

registerLocale('de', de);
setDefaultLocale('de');

interface DateActionProps {
  value: Date;
  format?: 'date' | 'time' | 'datetime' | undefined;
  onChange?: (res: { date: Date; fixed: boolean }) => void;
  noBackground?: boolean;
  keepWeek?: boolean;
}

const Wrapper = styled.div<{
  disabled?: boolean;
  noBackground?: boolean;
}>`
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.noBackground ? 'transparent' : 'rgba(203, 199, 215, 0.14)'};
  opacity: 1;
  color: rgba(0, 152, 66, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  user-select: none;
  display: flex;
  align-items: center;
  vertical-align: middle;
  // width: max-content;
  svg,
  .react-datepicker__calendar-icon {
    opacity: 1 !important;
    width: 18px !important;
    height: 18px !important;
    margin-right: 10px !important;
    padding: 0px !important;
    g,
    p,
    path {
      fill: rgba(0, 152, 66, 1);
    }
  }
  .react-datepicker__view-calendar-icon input {
    padding: 0px 0px 0px 35px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
    user-select: none;
  }
`;

export const DTP = styled(DatePicker)`
  border: 0;
  z-index: 999;
  background: transparent;
  -webkit-appearance: none;
  outline-color: rgba(0, 0, 0, 0);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 700;
  padding: 6px 0;
  user-select: none;
  cursor: pointer;
  margin: 0;
`;

const DateAction: FunctionComponent<DateActionProps> = ({
  value,
  format,
  onChange,
  noBackground,
  keepWeek,
}) => {
  const allowed = useMemo(() => {
    if (keepWeek) {
      return {
        start: DateTime.fromJSDate(value).startOf('week').toJSDate(),
        end: DateTime.fromJSDate(value).endOf('week').toJSDate(),
      };
    }
    return undefined;
  }, [keepWeek]);

  const usedformat = useMemo(() => {
    if (format === 'date') {
      return 'EEE dd.MM.yy';
    }
    if (format === 'time') {
      return 'HH:mm';
    }
    if (format === 'datetime') {
      return 'EEE dd.MM.yy HH:mm';
    }
    return 'EEE dd.MM.yy';
  }, [format]);

  const [temp, setTemp] = React.useState(value);

  return (
    <Wrapper noBackground={noBackground} disabled={onChange === undefined}>
      <DTP
        disabled={onChange === undefined}
        selected={temp}
        locale='de'
        todayButton='Heute'
        dateFormat={usedformat}
        minDate={allowed ? allowed.start : undefined}
        maxDate={allowed ? allowed.end : undefined}
        onCalendarClose={() => {
          if (onChange !== undefined && temp.getTime() !== value.getTime()) {
            onChange({ date: temp, fixed: false });
          }
        }}
        onChange={(update: Date) => {
          setTemp(update);
        }}
        showWeekNumbers
        withPortal
        icon={<ClockIcon />}
        showIcon
        showTimeInput
      />
    </Wrapper>
  );
};

export default DateAction;
