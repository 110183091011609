export enum Comparator {
  '!=E' = '!=E', // is empty
  '=B' = '=B', // equal boolean
  '!=B' = '!=B', // equal boolean not
  '=' = '=', // equal
  '!=' = '!=', // not equal
  '=N' = '=N', // equal numbers
  '!=N' = '!=N', // not equal for number
  '>N' = '>N', // greater for number
  '<N' = '<N', // lower for number
  '<I' = '<I', // not infinite
  'prefix' = 'prefix',
  'contains' = 'contains',
  'no-prefix' = 'no-prefix',
  'no-contains' = 'no-contains',
}

export function comparatorFromString(value?: string): Comparator | undefined {
  if (value === '!=E') return Comparator['!=E'];
  if (value === '=B') return Comparator['=B'];
  if (value === '!=B') return Comparator['!=B'];
  if (value === '!=') return Comparator['!='];
  if (value === '=') return Comparator['='];
  if (value === '=N') return Comparator['=N'];
  if (value === '!=N') return Comparator['!=N'];
  if (value === '<N') return Comparator['<N'];
  if (value === '>N') return Comparator['>N'];
  if (value === '<I') return Comparator['<I'];
  if (value === 'prefix') return Comparator['prefix'];
  if (value === 'contains') return Comparator['contains'];
  if (value === 'no-prefix') return Comparator['no-prefix'];
  if (value === 'no-contains') return Comparator['no-contains'];
  return undefined;
}

export interface Filter {
  value: string;
  comparator: FilterComparator;
}

export interface FilterComparator {
  title: string;
  comparator?: Comparator;
}

const _empty: FilterComparator[] = [
  { title: 'ist leer', comparator: Comparator['!=E'] },
];
const _eq: FilterComparator[] = [
  { title: 'ist', comparator: Comparator['='] },
  { title: 'ist nicht', comparator: Comparator['!='] },
];
const _eqBool: FilterComparator[] = [
  { title: 'ist', comparator: Comparator['=B'] },
  { title: 'ist nicht', comparator: Comparator['!=B'] },
];
const _eqMath: FilterComparator[] = [
  { title: 'ist', comparator: Comparator['=N'] },
  { title: 'ist nicht', comparator: Comparator['!=N'] },
  { title: 'größer', comparator: Comparator['>N'] },
  { title: 'kleiner', comparator: Comparator['<N'] },
  { title: 'nicht unendlich', comparator: Comparator['<I'] },
];
const _begins: FilterComparator[] = [
  { title: 'beginnt mit', comparator: Comparator['prefix'] },
  { title: 'beginnt nicht mit', comparator: Comparator['no-prefix'] },
];
const _contains: FilterComparator[] = [
  { title: 'enthält', comparator: Comparator['contains'] },
  { title: 'enthält nicht', comparator: Comparator['no-contains'] },
];

// export const FILTER_OPERATOR_NO = _no;
export const FILTER_OPERATOR_DEFAULT = _contains[0];
export const FILTER_OPERATOR_EMPTY = [..._empty];
export const FILTER_OPERATOR_EQ = [..._eq];
export const FILTER_OPERATOR_EQ_BOOL = [..._eqBool];
export const FILTER_OPERATOR_EQ_NUMBER = [..._eqMath];
export const FILTER_OPERATOR_BEGINS = [..._begins];
export const FILTER_OPERATOR_CONTAINS = [..._contains];
export const FILTER_OPERATOR_ALL = [..._eq, ..._contains, ..._begins];
