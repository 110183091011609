import React, { FunctionComponent, useState } from 'react';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg';
import {
  HotelJob,
  Job,
  JOB_TYPE,
  NewJob,
  Technician,
} from '../../../context/Route';
import { addJob } from './utils/add-job';
import { DateTime } from 'luxon';
import { message } from 'antd';
import { ClipLoader } from 'react-spinners';

interface AddHomeProps {
  before?: Job | HotelJob;
  technician: Technician;
  onChanged?: () => void;
}

const AddHome: FunctionComponent<AddHomeProps> = ({
  before,
  technician,
  onChanged,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  if (!before || !before.tour || !before.planedAt || !technician.address) {
    return null;
  }

  const handleClick = async () => {
    setLoading(true);

    try {
      const l =
        typeof before.planedAt === 'string'
          ? DateTime.fromISO(before.planedAt)
          : DateTime.fromJSDate(before.planedAt!);

      const job: NewJob = {
        sort: before.sort,
        type: JOB_TYPE.HomeAccommodation,
        address: technician.address,
        planedAtTimeByUser: false,
        planedAt: l.endOf('day').toISO(),
      };

      console.log('job', job);

      await addJob(job, before.tour!);

      onChanged && onChanged();
    } catch (error: any) {
      message.error('Fehler beim Hinzufügen des Jobs');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <ClipLoader size={'1rem'} color={'#009842'} loading={true} />;
  }

  return <HomeIcon onClick={() => handleClick()} />;
};

export default AddHome;
