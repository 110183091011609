import './Tours.scss';

import { Affix, Modal, Tooltip } from 'antd';
import { get, map, reduce, set } from 'lodash';
import React, { Component, Fragment, FunctionComponent } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { JOB_TYPE, Job, NewJob, Tour } from '../../context/Route';

import { Switch, notification } from 'antd';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { Request } from '../../api/Request';
import { ReactComponent as ChangeWeek } from '../../assets/icons/calender.svg';
import { ReactComponent as ResponseIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as HistoryIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as CommentIcon } from '../../assets/icons/comment.svg';
import { ReactComponent as NoteIcon } from '../../assets/icons/notes.svg';
import { ReactComponent as TechnicianIcon } from '../../assets/icons/technician2.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { AuthConsumer } from '../../context/AuthContext';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import {
  PlanningConsumer,
  PlanningProvider,
} from '../../context/PlanningContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import Trip from '../Mapbox/Trip';
import showModalInput from '../ModalInput';
import renderLoading from '../SharesActions/renderLoading';
import renderLoadingModal from '../SharesActions/renderLoadingModal';
import ChangeTechnicianModal from './ChangeTechnicianModal';
import ChangeWeekModal from './ChangeWeekModal';
import KPIComparison from './KPIComparison';
import TourHome from './TourHome';
import TourHotel from './TourHotel';
import TourLocation from './TourLocation';
import TourLocationAdd from './TourLocationAdd';
import TourTechnician from './TourTechnician';
import TourTechnicianPermissionGranted from './TourTechnicianPermissionGranted';
import TourTechnicianPlan from './TourTechnicianPlan';
import TourTechnicianPreviewPlan from './TourTechnicianPreviewPlan';
import CardList from '../common/CardList/CardList';
import CollapsableCardListGroup from '../common/CardList/CollapsableCardListGroup';
import CardListItem from '../common/CardList/CardListItem';
import TourStopList from './TourStopList';

const { confirm } = Modal;

const PageHeaderActions = styled.ul`
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  word-break: break-all;
`;
const PageHeaderAction = styled.li<{ danger?: boolean }>`
  padding: 0 1rem;
  cursor: pointer;
  display: inline;
  p {
    display: inline-block;
    color: #a1a1a1;
  }
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 12px 3px 0;
    fill: #a1a1a1;
    stroke: #a1a1a1;
    g {
      fill: #a1a1a1;
      stroke: #a1a1a1;
    }
  }
  &:hover {
    p,
    svg {
      color: ${({ danger }) => (danger ? '#e44' : '#009842')} !important;
      fill: ${({ danger }) => (danger ? '#e44' : '#009842')} !important;
      stroke: ${({ danger }) => (danger ? '#e44' : '#009842')} !important;
    }
  }
`;

const Info = styled.p`
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0px;
  font-weight: normal;
  color: #a1a1a1;
`;

const DayColors: { [key: number]: string } = {
  6: '#ccb4c7', // Mo
  2: '#d8e8e1', // Di
  3: '#fbffd1', // Mi
  4: '#d1dbe3', // Do
  5: '#f7cbda', // Fr
  1: '#cbc7d6', // Sa
  7: '#fae6eb', // So
};

const RouteStepWrapper = styled.div<{ day?: number }>`
  position: relative;
  height: 136px;
  background-color: ${(props) =>
    DayColors[props.day ?? 0] ?? '#fff'} !important; }
  .route-step {
    z-index: 0;
    background-color: ${(props) =>
      DayColors[props.day ?? 0] ?? '#fff'} !important; }
  }
`;

const StepExtensionWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  // background: rgba(255, 255, 255, 0.5);
  height: 68px;
  z-index: 0;
  border-radius: 3px;
  padding: 20px 20px 20px 58px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  p {
    cursor: pointer;
    span.no {
      color: #aaa !important;
    }
  }
  svg {
    width: 14px;
    margin-right: 14px;
    path {
      fill: #aaa !important;
    }
  }
`;

export const StepExtension: FunctionComponent<{
  readonly: boolean;
  comment?: string;
  note?: string;
  onEditComment: () => void;
  onEditNote: () => void;
}> = ({ readonly, comment, note, onEditComment, onEditNote }) => {
  return (
    <StepExtensionWrapper>
      <Tooltip
        title={
          readonly ? 'Techniker Info Info fixiert' : 'Techniker Info bearbeiten'
        }
      >
        <p onClick={() => !readonly && onEditComment()}>
          <CommentIcon />
          {comment && comment.length > 0 && <span>{comment}</span>}
          {(!comment || comment.length === 0) && (
            <span className='no'>keine Techniker Info</span>
          )}
        </p>
      </Tooltip>
      <Tooltip
        title={readonly ? 'Planer Info fixiert' : 'Planer Info bearbeiten'}
      >
        <p onClick={() => !readonly && onEditNote()}>
          <NoteIcon />
          {note && note.length > 0 && <span>{note}</span>}
          {(!note || note.length === 0) && (
            <span className='no'>keine Planer Info</span>
          )}
        </p>
      </Tooltip>
    </StepExtensionWrapper>
  );
};

interface MatchParams {
  id: string;
}

interface ToursViewProps extends RouteComponentProps<MatchParams> {}

interface ToursViewState {
  error?: Error | any;
  loading: boolean;
  deleting: boolean;
  editing: boolean;
  redirectList: boolean;
  displayChangeWeekModal: boolean;
  displayChangeTechnicianModal: boolean;
  changingWeek: boolean;
  changingTechnician: boolean;
  item?: Tour;
  highlight?: string;
}

class ToursView extends Component<ToursViewProps, ToursViewState> {
  state: ToursViewState = {
    loading: true,
    deleting: false,
    editing: false,
    redirectList: false,
    displayChangeWeekModal: false,
    displayChangeTechnicianModal: false,
    changingWeek: false,
    changingTechnician: false,
  };

  constructor(props: ToursViewProps) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.onHotelChangeAddress = this.onHotelChangeAddress.bind(this);
    this.rememberHotelname = this.rememberHotelname.bind(this);
    this.remove = this.remove.bind(this);
    this.removeJob = this.removeJob.bind(this);
    this.setFixed = this.setFixed.bind(this);
    this.onChangePlanedAt = this.onChangePlanedAt.bind(this);
    this.onSentCustomerNotification =
      this.onSentCustomerNotification.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async loadData() {
    const { id } = this.props.match.params;

    try {
      const tour = await Request.get('tours', id, '', { slim: true });
      this.setState({
        loading: false,
        item: tour,
        displayChangeWeekModal: false,
        displayChangeTechnicianModal: false,
        changingWeek: false,
        changingTechnician: false,
      });
    } catch (error: any) {
      this.setState({
        loading: false,
        error,
      });
    }
  }

  async onSentCustomerNotification(job: any) {
    this.loadData();
  }

  async onChangePlanedAt(
    job: any,
    date: any,
    planedAtTimeByUser: boolean,
    until?: string
  ) {
    try {
      await this.setPromisifiedState({ loading: true });
      await Request.put(
        'jobs',
        job,
        {
          planedAt: date,
          planedAtTimeByUser,
          until: !until || until.length === 0 ? false : until,
          loading: false,
        },
        '',
        false
      );
      this.loadData();
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: get(
          error,
          'message',
          'Die Änderung konnte nicht gespeichert werden.'
        ),
      });
      this.setState({
        loading: false,
        error,
      });
    }
  }

  async onHotelChangeAddress(job: any, address: any) {
    try {
      await Request.put(
        'jobs',
        job,
        {
          address,
        },
        '',
        false
      );
      this.loadData();
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: get(
          error,
          'message',
          'Die Änderung konnte nicht gespeichert werden.'
        ),
      });
    }
  }

  async rememberHotelname(job: any, hotelName: any) {
    try {
      await Request.put(
        'jobs',
        job,
        {
          hotelName,
        },
        '',
        false
      );
      this.loadData();
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: get(
          error,
          'message',
          'Die Änderung konnte nicht gespeichert werden.'
        ),
      });
    }
  }

  async saveLocation(_id: string, k: 'comment' | 'note', value: string) {
    const data = set({}, k, value);
    Request.put('locations', _id, data).then(
      () => {
        this.loadData();
      },
      () => {
        notification.error({
          message: 'Fehler beim Speichern',
          description: 'Die Änderung konnte nicht gespeichert werden.',
        });
      }
    );
  }

  renderTourJobs(tour: Tour) {
    if (!tour || this.state.loading)
      return <ClipLoader size={80} color={'#009842'} loading={true} />;
    const sortedJobs = tour.jobs.sort((j) => j.sort);
    return sortedJobs.map((job, index) => {
      if (!job) return '';

      let planedAtBefore: Date | undefined = undefined;

      const _jl = (job as Job).location;
      const nearByAddress =
        _jl && _jl.address
          ? `${_jl.address.street}, ${_jl.address.postalCode} ${_jl.address.city}`
          : undefined;

      const p = job.planedAt;

      if (typeof p === 'string') {
        planedAtBefore = DateTime.fromISO(p).toJSDate();
      } else {
        planedAtBefore = p;
      }

      return (
        <Fragment key={index}>
          {this.state.editing &&
            index === 0 &&
            this.renderTourLocationAdd(
              index,
              tour.jobs.length,
              planedAtBefore,
              nearByAddress
            )}
          {job.isHotel && this.renderTourHotel(job)}
          {job.isHome && this.renderTourHome(job)}
          {!job.isHotel &&
            !job.isHome &&
            this.renderTourLocation(job, tour, index)}

          {this.state.editing &&
            this.renderTourLocationAdd(
              index + 1,
              tour.jobs.length,
              planedAtBefore,
              nearByAddress
            )}
        </Fragment>
      );
    });
  }

  renderTourJobs2(tour: Tour, editing: boolean) {
    return (
      <TourStopList
        editing={editing}
        tour={tour}
        onChanged={() => {
          this.loadData();
        }}
      />
    );
  }

  renderTourLocationAdd(
    index: number,
    count: number,
    planedAtBefore: Date | undefined,
    nearByAddress?: string
  ) {
    if (!this.state.item) return;
    const planningWeek = DateTime.fromISO(
      this.state.item.jobs[0].planedAt as string
    )
      .startOf('week')
      .toJSDate();
    return (
      <div key={`add-${index}`} className='route-step route-step-add'>
        <TourLocationAdd
          planningWeek={planningWeek}
          count={count}
          planedAtBefore={planedAtBefore}
          technician={this.state.item.technician}
          nearByAddress={nearByAddress}
          index={index}
          onAdd={(job) => this.addJob(job)}
        />
      </div>
    );
  }

  renderTourLocation(job: any, tour: any, indexTag: number) {
    if (!this.state.item) return;
    if (!job) return '';
    // (job.comment && job.comment.length > 0) ||
    // (job.note && job.note.length > 0);

    return (
      <PlanningConsumer>
        {({ setHighlight }) => (
          <RouteStepWrapper
            day={DateTime.fromISO(job.planedAt || '').weekday}
            onMouseEnter={() => {
              setHighlight && setHighlight(job._id);
            }}
            onMouseLeave={() => {
              setHighlight && setHighlight('');
            }}
          >
            <div id={job._id} key={job._id} className='route-step'>
              <TourLocation
                indexTag={indexTag}
                value={job}
                tourId={tour._id}
                onDelete={this.removeJob}
                onSetFixed={this.setFixed}
                onChangePlanedAt={this.onChangePlanedAt}
                onSentCustomerNotification={this.onSentCustomerNotification}
                editing={this.state.editing}
              />
            </div>
            <StepExtension
              readonly={job.freezeComment}
              comment={job.comment}
              note={job.note}
              onEditComment={async () => {
                const newComment = await showModalInput(
                  job.comment,
                  'Techniker Info'
                );
                if (newComment) {
                  await this.saveLocation(
                    job.location._id,
                    'comment',
                    newComment
                  );
                  await this.loadData();
                }
              }}
              onEditNote={async () => {
                const newNote = await showModalInput(job.note, 'Planer Info');
                if (newNote) {
                  await this.saveLocation(job.location._id, 'note', newNote);
                  await this.loadData();
                }
              }}
            />
          </RouteStepWrapper>
        )}
      </PlanningConsumer>
    );
  }

  renderTourHotel(job: any) {
    if (!job) return '';
    const cl = classNames('route-step', {
      'route-step-danger': !job.address || !job.hotelName,
    });
    return (
      <RouteStepWrapper
        day={DateTime.fromISO(job.planedAt || '').weekday}
        id={job._id}
        key={job._id}
        className={cl}
      >
        <TourHotel
          onChangeHotelname={this.rememberHotelname}
          onChangeAddress={this.onHotelChangeAddress}
          value={job}
          onDelete={this.removeJob}
          editing={this.state.editing}
        />
      </RouteStepWrapper>
    );
  }

  renderTourHome(job: any) {
    if (!job) return '';
    const cl = classNames('route-step');
    return (
      <RouteStepWrapper
        day={DateTime.fromISO(job.planedAt || '').weekday}
        id={job._id}
        key={job._id}
        className={cl}
      >
        <TourHome
          value={job}
          onDelete={this.removeJob}
          editing={this.state.editing}
        />
      </RouteStepWrapper>
    );
  }

  async setFixed(jobId: string, fixed: boolean) {
    return Request.put('jobs', jobId, { fixed }).then(
      () => {
        this.loadData();
      },
      (error) => {
        notification.error({
          message: 'Fehler beim Speichern',
          description: get(
            error,
            'message',
            'Die Änderung konnte nicht gespeichert werden.'
          ),
        });
      }
    );
  }

  async removeJob(jobId: string) {
    const { id } = this.props.match.params;
    await Request.delete('tours', id, `jobs/${jobId}?shrink=false`);
    await this.loadData();
  }

  async addJob(job: NewJob) {
    const { id } = this.props.match.params;
    await Request.post(`tours/${id}/jobs`, job);
    return async () => {
      await this.loadData();
    };
  }

  async remove() {
    if (this.state.item) {
      const onOK = async () => {
        try {
          await this.setPromisifiedState({ deleting: true });
          await Request.delete('tours', this.state.item!._id);
          this.props.history.goBack();
        } catch (error: any) {
          this.setState({
            loading: false,
            error,
            redirectList: (error as any).status === 404,
          });
        }
      };

      confirm({
        title: 'Tour löschen',
        content: (
          <p>Diese Tour wird endgültig gelöscht. Sind Sie sich sicher?</p>
        ),
        onOk() {
          onOK();
        },
        // onCancel() {},
      });
    }
  }

  async changeWeek() {
    this.setState({
      displayChangeWeekModal: true,
    });
  }

  async changeTechnician() {
    this.setState({
      displayChangeTechnicianModal: true,
    });
  }

  async grantPermission() {
    if (!this.state.item) {
      return;
    }
    try {
      await Request.post(`tours/${this.state.item._id}/permission/grant`, {});
      this.loadData();
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: get(
          error,
          'message',
          'Die Änderung konnte nicht gespeichert werden.'
        ),
      });
    }
  }

  hasFixed(tour: Tour) {
    return reduce(
      tour.jobs,
      (acc, job) => {
        return acc || !!job.fixed;
      },
      false
    );
  }

  render() {
    const {
      item,
      loading,
      deleting,
      changingWeek,
      changingTechnician,
      displayChangeWeekModal,
      displayChangeTechnicianModal,
      highlight,
    } = this.state;

    const editable = item && item._id;

    if (this.state.redirectList) {
      return <Redirect to='/tours' />;
    }

    if (!item) return renderLoading();

    const formatDate = (d?: string | Date) => {
      if (!d) return '';
      return (
        typeof d === 'string'
          ? DateTime.fromISO(d)
          : DateTime.fromJSDate(d).setLocale('de')
      )
        .toJSDate()
        .toLocaleString();
    };

    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='tours container-inner'>
            <InPageNavigation
              to={`/tours${this.props.location.search}`}
              item={item}
              hideChangedAt={true}
            />
            <p>{this.state.highlight}</p>
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col col-12 col-md-6'>
                <h1 className='page-title'>
                  {item ? (
                    <span>
                      Tour&nbsp;
                      {item.tag}&nbsp;|&nbsp;
                      {item.startDate &&
                        DateTime.fromISO(item.startDate)
                          .setLocale('de')
                          .toFormat("'KW' WW")}
                      &nbsp;|&nbsp;
                      {item && item.technician ? item.technician.name : ''}
                      {item.createdBy ? (
                        <Info>
                          Erstellt: {item.createdBy.name} -{' '}
                          {formatDate(item.createdAt as any)}
                        </Info>
                      ) : (
                        ''
                      )}
                      {item.changedBy ? (
                        <Info>
                          Letzte Änderung: {item.changedBy.name} -{' '}
                          {formatDate(item.changedAt as any)}
                        </Info>
                      ) : (
                        ''
                      )}
                    </span>
                  ) : (
                    <span />
                  )}
                </h1>
              </div>
              <PageHeaderActions className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                <PageHeaderAction>
                  <NavLink to={`/tours/${item._id}/history/`}>
                    <HistoryIcon />
                    <p>Verlauf</p>
                  </NavLink>
                </PageHeaderAction>
                {hasPermission(['tour:write']) === true && (
                  <PageHeaderAction>
                    <NavLink to={`/tours/${item._id}/response/`}>
                      <ResponseIcon />
                      <p>Rückerfassung</p>
                    </NavLink>
                  </PageHeaderAction>
                )}
                {hasPermission(['tour:write']) === true &&
                  this.hasFixed(item) === false && (
                    <PageHeaderAction onClick={() => this.changeWeek()}>
                      <ChangeWeek />
                      <p>Verschieben</p>
                    </PageHeaderAction>
                  )}
                {hasPermission(['tour:write']) === true && (
                  <PageHeaderAction onClick={() => this.changeTechnician()}>
                    <TechnicianIcon />
                    <p>Techniker ersetzen</p>
                  </PageHeaderAction>
                )}
                {hasPermission(['tour:write']) === true &&
                  this.hasFixed(item) === false && (
                    <PageHeaderAction
                      danger={true}
                      onClick={() => this.remove()}
                    >
                      <TrashIcon />
                      <p>Tour löschen</p>
                    </PageHeaderAction>
                  )}
              </PageHeaderActions>
              {/* {hasPermission(['tour:write']) === true && (
                <div className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                  {item && (
                    <React.Fragment>
                      <NavLink
                        to={`/tours/${item._id}/history/`}
                        className='float-right'
                      >
                        <CheckIcon />
                        Verlauf
                      </NavLink>
                      <NavLink
                        to={`/tours/${item._id}/response/`}
                        className='float-right'
                      >
                        <CheckIcon />
                        Rückerfassung
                      </NavLink>
                      {this.hasFixed(item) === false && (
                        <p
                          className='delete float-right'
                          onClick={() => this.changeWeek()}
                        >
                          <ChangeWeek />
                          Verschieben
                        </p>
                      )}
                      {this.hasFixed(item) === false && (
                        <p className='delete float-right' onClick={this.remove}>
                          <TrashIcon />
                          Tour löschen
                        </p>
                      )}
                    </React.Fragment>
                  )}
                </div>
              )} */}
            </div>
            <PlanningProvider>
              <div className={`page-content`}>
                <KPIComparison item={item} />
                <div className='row pt-3'>
                  <div className='col col-12 col-xs-12 col-md-12 col-lg-8 col-xl-8'>
                    <div className='row table-divider'>
                      <div className='col-8'>
                        <span>Route</span>
                      </div>
                      <div className='col-4'>
                        <div className='header float-right'>
                          <Switch
                            loading={!item || !!loading}
                            // disabled={(item && item.enshrined) || !editable}
                            disabled={!item || !editable}
                            checked={this.state.editing}
                            title='Bearbeiten'
                            onChange={(checked) =>
                              this.setState({ editing: checked })
                            }
                          />
                          <span
                            style={{
                              marginLeft: '8px',
                            }}
                          >
                            Bearbeiten
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='row table-row'>
                      <div className='col'>
                        {this.renderTourJobs2(item, this.state.editing)}
                      </div>
                    </div>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Techniker</span>
                      </div>
                    </div>
                    <div className='row table-row'>
                      <div className='col'>
                        <div className='route-step'>
                          {item && item.technician && (
                            <TourTechnician
                              tour={item}
                              technician={item.technician}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row table-divider'>
                      <div className='col'>
                        <span>Planung</span>
                      </div>
                    </div>
                    <div className='row table-row'>
                      <div className='col'>
                        <div className='route-step'>
                          {item && item.technician && (
                            <TourTechnicianPermissionGranted
                              tour={item}
                              permissionGranted={item.permissionGranted}
                              onGrantPermission={() => this.grantPermission()}
                            />
                          )}
                        </div>
                        <div className='route-step'>
                          {item && item.technician && (
                            <TourTechnicianPreviewPlan
                              tour={item}
                              sharedAt={item.sharedAt}
                              onRELShared={() => this.loadData()}
                              technician={item.technician}
                              disabled={!!!item}
                            />
                          )}
                        </div>
                        <div className='route-step'>
                          {item && item.technician && (
                            <TourTechnicianPlan
                              tour={item}
                              sharedAt={item.sharedAt}
                              onRELShared={() => this.loadData()}
                              technician={item.technician}
                              disabled={
                                !!!item ||
                                !!!item.permissionGranted ||
                                !!!item.permissionGranted.at
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col-12 col-xs-12 col-md-12 col-lg-4 col-xl-4 max-height-600'>
                    <Affix
                      offsetTop={0}
                      onChange={(affixed) => console.log(affixed)}
                      target={() => document.getElementsByTagName('main')[0]!}
                    >
                      <Trip
                        className='map'
                        style={{
                          width: '510px',
                          height: '510px',
                        }}
                        marker={
                          item
                            ? map(
                                [
                                  ...(item &&
                                  item.technician &&
                                  item.technician.address
                                    ? [
                                        {
                                          index: 0,
                                          address: item.technician.address,
                                          type: JOB_TYPE.Home,
                                          sort: -1,
                                        },
                                      ]
                                    : []),
                                  ...item.jobs,
                                  ...(item &&
                                  item.technician &&
                                  item.technician.address
                                    ? [
                                        {
                                          index: item.jobs.length,
                                          address: item.technician.address,
                                          type: JOB_TYPE.Home,
                                          sort: Number.MAX_SAFE_INTEGER,
                                        },
                                      ]
                                    : []),
                                ],
                                (jl) => {
                                  const d =
                                    get(
                                      jl,
                                      'addressRouting.location.coordinates'
                                    ) ||
                                    get(jl, 'address.location.coordinates');
                                  return {
                                    _id: (jl as any)._id ?? '',
                                    index: jl.sort + 1,
                                    type: jl.type,
                                    longitude: d ? d[0] : null,
                                    latitude: d ? d[1] : null,
                                  };
                                }
                              )
                            : []
                        }
                      />
                    </Affix>
                  </div>
                </div>
              </div>
            </PlanningProvider>
            {deleting && renderLoadingModal('Tour wird gelöscht...')}
            {changingWeek && renderLoadingModal('Tour wird verschoben...')}
            {changingTechnician &&
              renderLoadingModal('Techniker wird geändert...')}

            {displayChangeTechnicianModal && !!item && (
              <ChangeTechnicianModal
                onClose={() =>
                  this.setState({ displayChangeTechnicianModal: false })
                }
                tour={item}
                onSubmit={async (technician: string) => {
                  try {
                    await this.setPromisifiedState({
                      changingTechnician: true,
                      displayChangeTechnicianModal: false,
                    });
                    if (this.state.item) {
                      await Request.put(
                        'tours',
                        this.state.item._id,
                        { technician },
                        'change-technician'
                      );
                    }
                    await this.loadData();
                  } catch (error: any) {
                    notification.error({
                      message: 'Fehler beim Speichern',
                      description: get(
                        error,
                        'message',
                        'Der Techniker konnte nicht geändert werden.'
                      ),
                    });
                    this.setState({
                      loading: false,
                      changingTechnician: false,
                      error,
                    });
                  }
                }}
              />
            )}
            {displayChangeWeekModal && !!item.startDate && (
              <ChangeWeekModal
                onClose={() => this.setState({ displayChangeWeekModal: false })}
                technician={item.technician._id}
                startDate={item.startDate!}
                onSubmit={async (weeks: number) => {
                  try {
                    await this.setPromisifiedState({
                      changingWeek: true,
                      displayChangeWeekModal: false,
                    });
                    if (this.state.item) {
                      await Request.put(
                        'tours',
                        this.state.item._id,
                        { weeks },
                        'change-week'
                      );
                    }
                    await this.loadData();
                  } catch (error: any) {
                    notification.error({
                      message: 'Fehler beim Speichern',
                      description: get(
                        error,
                        'message',
                        'Die Tour konnte nicht verschoben werden'
                      ),
                    });
                    this.setState({
                      loading: false,
                      changingWeek: false,
                      error,
                    });
                  }
                }}
              />
            )}
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(ToursView);
