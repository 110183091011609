import './Technician.scss';

import React, {
  Component,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { hasPermissionFunc } from '../../context/hasPermissionFunc';

import { AuthConsumer } from '../../context/AuthContext';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import CheckInput from '../Form/CheckInput';
import DirectInput from '../Form/DirectInput';
import { EMAIL_REGEXP } from '../regex';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import MapInput from '../Form/MapInput';
import { Redirect } from 'react-router-dom';
import { Request } from '../../api/Request';
import { get, set } from 'lodash';
import createLocationTableHeader, {
  TabledHeaderField,
} from '../analysis/createLocationTableHeader';
import getTasks from '../Location/getTasks';
import {
  PageHeaderActions,
  PageHeaderAction,
} from '../PageHeader/PageHeaderActions';

interface NewTechnicianProps {}

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const NewTechnician: FunctionComponent<NewTechnicianProps> = ({}) => {
  const [error, setError] = useState<any>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string | null>(null);
  const [tasks, setTasks] = useState<any[]>([]);
  const [item, setItem] = useState<any>({
    price: {},
  });

  const loadMeta = useCallback(async () => {
    const headerTasks = await getTasks();
    setTasks(headerTasks);
  }, []);

  useEffect(() => {
    loadMeta();
  }, []);

  const save = async (k: string, value: any) => {
    const data = set(item, k, value);
    if (k === 'name') {
      if (!value) {
        data['name'] = '';
        data.defautlInitials = '';
      } else {
        const initials = value.match(/\b\w{2}/g) || [];
        data.defautlInitials =
          capitalize(initials.shift() || '') + capitalize(initials.pop() || '');
      }
    }
    setItem(data);
    setValid(isValid());
  };

  const submit = async () => {
    setLoading(true);
    try {
      const res = await Request.post('technicians', item);
      setRedirect(res._id);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const isValid = () =>
    tasks.length > 0 &&
    item &&
    item.name &&
    item.name.length > 4 &&
    item.email &&
    EMAIL_REGEXP.exec(item.email) !== null &&
    item.phone &&
    item.phone.length > 2 &&
    item.address &&
    item.address.location;

  return (
    <AuthConsumer>
      {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
        <div className='technician container-inner'>
          {redirect ? (
            <Redirect to={`/administration/technicians/${redirect}`} />
          ) : (
            ''
          )}

          <FormConsumer>
            {({ isEditing }) => (
              <>
                <InPageNavigation
                  to='/administration/technicians'
                  item={item}
                />
                <div className='page-header page-header-line row justify-content-between'>
                  <div className='col-6 col'>
                    <h1 className='page-title'>Neuen Techniker hinzufügen</h1>
                  </div>
                  <PageHeaderActions className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                    {hasPermission(['technician:write']) === true ? (
                      <PageHeaderAction onClick={() => valid && submit()}>
                        <CheckIcon />
                        <p>{'Speichern'}</p>
                      </PageHeaderAction>
                    ) : (
                      false
                    )}
                  </PageHeaderActions>
                </div>
                <div className={`page-content ${isEditing ? 'editing' : ''}`}>
                  <div className='row'>
                    <form className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Stammdaten</span>
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <DirectInput
                            title='Name'
                            value={item.name}
                            validate={(value) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 4
                            }
                            callback={(value) => save('name', value)}
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <DirectInput
                            title='Kürzel'
                            value={item.initialsOverwrite}
                            defaultValue={item.defautlInitials}
                            validate={(value) =>
                              value === undefined ||
                              (value !== undefined && value.length === 4)
                            }
                            callback={(value) =>
                              save('initialsOverwrite', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <DirectInput
                            title='E-Mail'
                            value={item.email}
                            type='email'
                            validate={(value) =>
                              value && EMAIL_REGEXP.exec(value) !== null
                            }
                            callback={(value) => save('email', value)}
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <DirectInput
                            title='Telefon'
                            value={item.phone}
                            validate={(value) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 2
                            }
                            callback={(value) => save('phone', value)}
                          />
                        </div>
                      </div>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Fähigkeiten</span>
                        </div>
                      </div>
                      <div className='row justify-content-between table-row input-row'>
                        {tasks
                          .filter((t) => /\d/.test(t.key) === false) // remove all tasks with numbers in the key because they are clone tasks
                          .map((task) => (
                            <div className='col-5 pr-0' key={task._id}>
                              <CheckInput
                                title={get(
                                  task,
                                  'importFields.defaultTasks',
                                  task.title
                                )}
                                short={task.key.toUpperCase()}
                                value={
                                  item.skills ? item.skills[task.key] : false
                                }
                                callback={(value) =>
                                  save(`skills.${task.key}`, value)
                                }
                              />
                            </div>
                          ))}
                      </div>
                    </form>
                    <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Wohnort</span>
                        </div>
                      </div>
                      <div className='row input-row'>
                        <div className='col'>
                          <MapInput
                            value={item.address}
                            callback={(value) => save('address', value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </>
            )}
          </FormConsumer>
        </div>
      )}
    </AuthConsumer>
  );
};

export default NewTechnician;
