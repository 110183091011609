import { DateTime } from 'luxon';

export default function getDueWarningLevel(
  dtz: DateTime,
  planningWeek: Date
): {
  'due-warning due-warning-1': boolean;
  'due-warning due-warning-2': boolean;
  'due-warning due-warning-3': boolean;
} {
  const r = {
    'due-warning due-warning-1':
      dtz <
      DateTime.fromJSDate(planningWeek).setLocale('de').minus({ months: 1 }),
    'due-warning due-warning-2':
      dtz <
      DateTime.fromJSDate(planningWeek).setLocale('de').minus({ months: 2 }),
    'due-warning due-warning-3':
      dtz <
      DateTime.fromJSDate(planningWeek).setLocale('de').minus({ months: 3 }),
  };
  return r;
}
