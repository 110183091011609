import './MapInput.scss';

// import MbxClient from '@mapbox/mapbox-sdk';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { debounce, get } from 'lodash';

import { ReactComponent as CancelIcon } from '../../assets/icons/no.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { DebounceInput } from 'react-debounce-input';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { FormConsumer } from '../../context/FormContext';
import { Geocoding } from '../../api/MapboxGeo';
import Map from '../Mapbox/Map';
import classnames from 'classnames';
import { v4 } from 'uuid';

interface MapInputProps {
  value: any;
  force?: boolean;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  // direct?: boolean;
  callback?: (value: any) => void;
}

function valueToFormated(value: any) {
  if (!value) return '';
  return `${value.street}, ${value.postalCode} ${value.city}, ${value.countryCode}`;
}

const MapInput: FunctionComponent<MapInputProps> = ({
  className,
  callback,
  readOnly,
  // direct,
  ...props
}) => {
  const force = true;
  const [compEditing, setCompEditing] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [value, setValue] = useState(props.value);
  const [temp, setTemp] = useState<any>();
  // const [location, setLocation] = useState<any>();
  const [text, setText] = useState<string>('');
  const [valid, setValid] = useState(true);
  const [id, setID] = useState<string | undefined>();
  const [suggestions, setSuggestions] = useState<any[]>([]);

  useEffect(() => {
    const l = get(props, 'value.location');
    setText(valueToFormated(props.value));
    setValid(!!l);
    setTemp(props.value);
  }, [props.value]);

  useEffect(() => {
    setID(v4());
  }, []);

  const search = async (input: string) => {
    try {
      // setLoading(true);
      const res = await Geocoding.search(input);
      console.log(res);
      setSuggestions(res);
    } catch (error: any) {
      // TODO
    } finally {
      // setLoading(false);
    }
  };

  const searchDebounced = debounce(search, 500);

  // Search for Location
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (force) setTemp(undefined);
    const { value } = event.target;
    setText(value);
    searchDebounced(value);
  };

  // Format value on blur
  // const onBlur = async () => {
  //
  //   if (direct) {
  //     if (temp && props.value !== temp) {
  //       callback && callback(temp);
  //     }
  //   } else if (force) {
  //     setText(valueToFormated(temp));
  //   }
  // };

  // stopEdit(save) {
  //   if (save && this.state.temp) {
  //     this.setState({ editing: false });
  //     this.props.callback(this.state.temp);
  //   } else {
  //     this.setState({
  //       temp: this.state.value,
  //       text: this.valueToFormated(this.state.value),
  //       editing: false,
  //     });
  //   }
  // }

  return (
    <FormConsumer>
      {({ setEditing }: any) => (
        <div
          className={classnames('map-input', className, {
            editing: compEditing,
          })}
        >
          <div className='map-holder'>
            {/* {JSON.stringify(temp)} */}
            <Map className='map' location={temp} />
          </div>
          <div className='input-holder'>
            <label
              htmlFor={`map-input-${id}`}
              className={classnames({
                valid: valid,
                invalid: !valid,
              })}
            >
              Adresse
            </label>
            <DebounceInput
              type='text'
              value={text}
              id={`map-input-${id}`}
              disabled={!compEditing}
              name='name'
              placeholder='Adresse eingeben'
              minLength={0}
              debounceTimeout={500}
              onChange={(event) => onChange(event)}
              // onBlur={() => onBlur()}
            />
            {readOnly !== true ? (
              <div className='actions'>
                {!compEditing ? (
                  <button
                    className='edit'
                    onClick={() => {
                      setSuggestions([]);
                      setEditing(true);
                      setCompEditing(true);
                    }}
                  >
                    <EditIcon />
                  </button>
                ) : (
                  ''
                )}
                {compEditing ? (
                  <button
                    className='save'
                    disabled={force && (!temp || !temp.suggestion)}
                    onClick={() => {
                      if (force) {
                        if (temp && temp.suggestion) {
                          callback && callback(temp);
                          setEditing(false);
                          setCompEditing(false);
                        }
                      } else {
                        callback && callback(temp);
                        setEditing(false);
                        setCompEditing(false);
                      }
                    }}
                  >
                    <CheckIcon />
                  </button>
                ) : (
                  ''
                )}
                {compEditing ? (
                  <button
                    className='cancel'
                    onClick={() => {
                      setEditing(false);
                      setCompEditing(false);
                    }}
                  >
                    <CancelIcon />
                  </button>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
          {compEditing && suggestions.length > 0 && (
            <div className='suggestions-holder'>
              <ul>
                {suggestions.map((suggestion, i) => (
                  <li
                    onClick={() => {
                      setTemp({ ...suggestion, suggestion: true });
                      setText(valueToFormated(suggestion));
                    }}
                    key={i}
                  >
                    {valueToFormated(suggestion)}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </FormConsumer>
  );
};

export default MapInput;
