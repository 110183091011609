import {
  ConfirmDialogProps,
  confirmable,
  createConfirmation,
} from 'react-confirm';

import { Modal } from 'antd';
import React, { FunctionComponent, useMemo, useState } from 'react';
import styled from 'styled-components';

import { debounce } from 'lodash';
import { DebounceInput } from 'react-debounce-input';
import { Geocoding } from '../../../api/MapboxGeo';
import DirectInput from '../../Form/DirectInput';
import { FormProvider } from '../../../context/FormContext';

interface HotelValue {
  hotelName: string;
  address: {
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
  };
}

type EnterHotalProps = {
  value?: HotelValue;
};

const Body = styled.div`
  white-space: pre-line;
  overflow-wrap: break-word;
`;

const EnterHotalModal: FunctionComponent<
  ConfirmDialogProps<EnterHotalProps, HotelValue | false>
> = ({ proceed, value, show }) => {
  const [name, setName] = useState<string>(value?.hotelName || '');
  const [address, setAddress] = useState(value?.address);

  const valid = name.length > 0 && address !== undefined;

  return (
    <Modal
      width={600}
      title={value ? 'Hotel ändern' : 'Hotel eingeben'}
      open={show}
      okText='Speichern'
      onOk={() => {
        valid &&
          proceed({
            hotelName: name.trim(),
            address,
          });
      }}
      onCancel={() => {
        proceed(false);
      }}
    >
      <FormProvider>
        <form autoComplete='off'>
          <DirectInput title='Name' value={name} callback={setName} />
          <DirectInput
            type='address'
            title='Adresse'
            value={address}
            callback={setAddress}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export function enterHotel(value: HotelValue | undefined) {
  return createConfirmation(confirmable(EnterHotalModal))({ value });
}
