export default function shareTypeToText(
  shareType: undefined | null | '' | 'datetime' | 'date' | 'kw'
) {
  switch (shareType) {
    case 'datetime':
      return 'Datum & Uhrzeit';
    case 'date':
      return 'Datum';
    case 'kw':
      return 'Kalenderwoche';
    default:
      return 'Keine Angabe';
  }
}
