import { HotelJobWithLeg, JobWithLeg } from '../../../context/Route';

import { DateTime } from 'luxon';
import { PlanningStepTourJobActionFunc } from './PlanningStepTourJobAction';
import PlanningStepTourListItem from './PlanningStepTourListItem';
import React from 'react';
// import { SortableContainer } from 'react-sortable-hoc';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { findIndex } from 'lodash';
import { PlanningConsumer } from '../../../context/PlanningContext';

interface PlanningStepTourListProps {
  items: ((JobWithLeg | HotelJobWithLeg) & { sortID: string })[];
  action: PlanningStepTourJobActionFunc;
  technician: any;
  planningWeek: Date;
  onSortEnd: (items: (JobWithLeg | HotelJobWithLeg)[]) => void;
}

const PlanningStepTourList = (props: PlanningStepTourListProps) => {
  const overlappingOfftime = props.technician.overlappingOfftime.map(
    (d: string) => DateTime.fromISO(d).startOf('day')
  );

  const test = (item: JobWithLeg | HotelJobWithLeg) => {
    const a = overlappingOfftime.filter((o: DateTime) => {
      const b = DateTime.fromISO(item.planedAt as string).startOf('day');
      return (o as any).ts === (b as any).ts;
    });
    return a.length > 0;
  };

  const sensors = useSensors(
    useSensor(PointerSensor)
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = findIndex(props.items, (i) => i.sortID === active.id);
      const newIndex = findIndex(props.items, (i) => i.sortID === over.id);
      const n = arrayMove(props.items, oldIndex, newIndex);
      props.onSortEnd(n);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={props.items.map((i) => ({ id: i.sortID! }))}
        strategy={verticalListSortingStrategy}
      >
        <PlanningConsumer>
          {({ setHighlight }) => (
            <ul>
              {props.items?.map((value, index) => (
                <PlanningStepTourListItem
                  setHighlight={setHighlight}
                  key={`item-${index}`}
                  disabled={index === 0 || index === props.items.length - 1}
                  sortIndex={index}
                  value={value}
                  action={props.action}
                  technician={props.technician}
                  planningWeek={props.planningWeek}
                  hasError={test(value)}
                />
              ))}
            </ul>
          )}
        </PlanningConsumer>
      </SortableContext>
    </DndContext>
  );
};

export default PlanningStepTourList;
