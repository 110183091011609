import React, { Component } from 'react';

import { ReactComponent as ClockIcon } from '../../assets/icons/calender.svg';
import { DateTime } from 'luxon';
import { ReactComponent as SleepIcon } from '../../assets/icons/sleep.svg';
import TourHotelEnterName from './TourHotelEnterName';
import TourItemDelete from './TourItemDelete';
import addressToString from '../helper/addressToString';
import classNames from 'classnames';

interface TourHotelProps {
  editing: boolean;
  value: {
    _id: string;
    address?: any;
    nearByAddress?: any;
    planedAt?: string;
    hotelName?: string;
    sort: number;
  };
  onChangeAddress: (id: string, data: any) => void;
  onChangeHotelname: (id: string, data: any) => void;
  onDelete: (id: string) => void;
}

interface TourHotelState {
  date?: string;
}

class TourHotel extends Component<TourHotelProps, TourHotelState> {
  state: TourHotelState = {};

  constructor(props: TourHotelProps) {
    super(props);
    if (props.value.planedAt) this.state.date = props.value.planedAt;
    this.rememberMapInputValue = this.rememberMapInputValue.bind(this);
    this.rememberHotelname = this.rememberHotelname.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps: TourHotelProps) {
    // console.log('UNSAFE_componentWillReceiveProps', typeof newProps.value.planedAt);
    this.setState({
      date: newProps.value.planedAt,
    });
  }

  rememberMapInputValue(ad: any) {
    const { value, onChangeAddress } = this.props;
    onChangeAddress && onChangeAddress(value._id, ad);
  }

  rememberHotelname(ad: any) {
    const { value, onChangeHotelname } = this.props;
    onChangeHotelname && onChangeHotelname(value._id, ad);
  }

  infoOld() {
    const { value } = this.props;
    const p = DateTime.fromISO(value.planedAt || '');
    const l = DateTime.local();
    return p < l;
  }

  render() {
    const { value } = this.props;

    const classes = classNames('route-step-content', {
      'route-step-danger': !value.address || !value.hotelName,
    });
    return (
      <React.Fragment>
        <div className='route-step-icon'>
          <SleepIcon />
        </div>
        <div className={classes}>
          <div className='row'>
            <div className='col col-7'>
              <p className='name'>
                {!value.address || !value.hotelName
                  ? 'Hotelbuchung offen '
                  : value.hotelName
                  ? value.hotelName
                  : 'Kein Name '}
              </p>
              <p className='address'>
                {value.address
                  ? addressToString(value.address)
                  : `In der Nähe von: ${value.nearByAddress}`}
              </p>
            </div>
            <div className='col col-4'>
              {value.planedAt ? (
                <p className='date full-height'>
                  <ClockIcon />
                  {DateTime.fromISO(value.planedAt)
                    .setLocale('de')
                    .toLocaleString({
                      month: '2-digit',
                      day: '2-digit',
                      weekday: 'short',
                    })}
                </p>
              ) : (
                <p className='date missing'>Plandatum eingeben</p>
              )}
            </div>
          </div>
        </div>
        <div className='route-step-actions extra'>
          {!this.props.editing && !this.infoOld() && (
            <TourHotelEnterName
              value={value}
              onChangeAddress={this.props.onChangeAddress}
              onChangeHotelname={this.props.onChangeHotelname}
            />
          )}
          {this.props.editing && (
            <TourItemDelete
              text=''
              value={value}
              onDelete={this.props.onDelete}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default TourHotel;
