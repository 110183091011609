import { get, map } from 'lodash';

export default function prepareForDropdown(
  items: { _id: string; name: string }[],
  placeholder = '',
  key = 'name',
  key2?: string
) {
  return [
    { value: null, text: placeholder },
    ...map(items, (p) => {
      let text = get(p, key);
      if (key2) {
        const text2 = get(p, key2);
        if (text2) {
          text = `${text} [${text2}]`;
        }
      }
      return {
        value: p._id,
        text,
      };
    }),
  ];
}
