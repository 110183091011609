import Pagination from './Pagination';
import React from 'react';

interface TableFooterProps {
  width: number;
  page?: number;
  maxItems?: number;
  limit?: number;
  onPagination: (page: number) => void;
}

const TableFooter = ({
  width,
  page,
  maxItems,
  limit,
  onPagination,
}: TableFooterProps) => {
  const m = maxItems || 1;
  const l = limit || Infinity;
  const pageCount = Math.ceil(m / l);
  const style = {
    width: `${width}px`,
  };
  if (pageCount > 1) {
    return (
      <div className='table-footer' style={style}>
        <div className='row'>
          <div className='col-12'>
            <Pagination
              page={page || 0}
              pageCount={pageCount}
              onChange={onPagination}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};
export default TableFooter;
