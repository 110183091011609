import './CheckInput.scss';

import React, { Component } from 'react';

import { ReactComponent as CheckBoldIcon } from '../../assets/icons/check-bold.svg';
import { v4 as uuid } from 'uuid';

class CheckInput extends Component {
  elementID = uuid();

  state = {
    value: false,
    valid: true,
    readOnly: false,
  };

  constructor(props) {
    super(props);
    if (props.value) {
      this.state.valid = this.props.validate
        ? this.props.validate(props.value) === true
        : true;
      this.state.value = props.value;
    } else {
      this.state.valid = !!!props.required;
    }
    this.state.readOnly = props.readOnly;
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const a = {};

    if (this.state.value !== newProps.value) {
      a.valid = this.props.validate
        ? this.props.validate(newProps.value) === true
        : true;
      a.value = newProps.value;
    }

    if (this.state.readOnly !== newProps.readOnly)
      a.readOnly = newProps.readOnly;

    if (Object.keys(a).length > 0) this.setState(a);
  }

  handleChange(event) {
    if (this.state.readOnly) return;
    const valid = this.props.validate
      ? this.props.validate(event.target.checked) === true
      : true;
    this.setState({ value: event.target.checked, valid }, () => {
      this.props.callback(this.state.value);
    });
  }

  render() {
    const { title, short, titleLeft } = this.props;
    const { value, valid, readOnly } = this.state;
    const shortStyle = {
      display: short ? 'inline-block' : 'none',
    };
    return (
      <div
        className={`check-input ${readOnly ? 'readOnly' : ''} ${
          value || false ? 'check' : ''
        }`}
      >
        {titleLeft ? <label className='label-left'>{short}</label> : null}
        <input
          type='checkbox'
          id={`check-input-${this.elementID}`}
          disabled={readOnly}
          checked={value || false}
          onChange={this.handleChange}
        />
        <label className='check' htmlFor={`check-input-${this.elementID}`}>
          <CheckBoldIcon />
        </label>
        {!titleLeft ? (
          <p
            style={shortStyle}
            className={`short-title ${valid ? 'valid' : 'invalid'}`}
          >
            {short}
          </p>
        ) : null}
        <p className='title'>{title}</p>
      </div>
    );
  }
}

export default CheckInput;
