import './APIStatus.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import request from 'superagent';

enum APIStatusState {
  'loading',
  'ok',
  'error',
}

interface CircleProps {
  className?: string;
  size?: number;
}

export function Circle(props: CircleProps) {
  const _size = props.size || 25;
  return (
    <span
      className={`api-status-circle ${props.className}`}
      style={{
        height: `${_size}px`,
        width: `${_size}px`,
      }}
    ></span>
  );
}

export default function APIStatus() {
  const [state, setState] = useState<APIStatusState>(APIStatusState.loading);

  useEffect(() => {
    async function fetchData() {
      try {
        const { build } = await request
          .get(`${process.env.REACT_APP_REST_API}/status`)
          .then((res) => res.body);
        setState(build ? APIStatusState.ok : APIStatusState.error);
      } catch (error: any) {
        setState(APIStatusState.error);
      }
    }
    setTimeout(() => fetchData(), 250);
  }, []);

  const getText = () => {
    if (state === APIStatusState.ok) return 'UP';
    if (state === APIStatusState.error) return 'UNREACHABLE';
    if (state === APIStatusState.loading) return 'LOADING';
  };

  return (
    <div
      className={classNames('api-status', {
        'api-status-success': state === APIStatusState.ok,
        'api-status-error': state === APIStatusState.error,
      })}
    >
      <Circle
        className={classNames('api-status', {
          stripe: state === APIStatusState.loading,
        })}
        size={12}
      ></Circle>
      <span>{getText()}</span>
    </div>
  );
}
