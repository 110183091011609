import { notification } from 'antd';
import { Job } from '../../../../context/Route';
import { get } from 'lodash';
import { Request } from '../../../../api/Request';

export async function setFixed(job: Job, fixed: boolean) {
  const jobId: string = job._id as string;
  return Request.put('jobs', jobId, { fixed }).then(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
    (error) => {
      notification.error({
        message: 'Fehler beim Speichern',
        description: get(
          error,
          'message',
          'Die Änderung konnte nicht gespeichert werden.'
        ),
      });
    }
  );
}
