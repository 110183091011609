import 'rc-calendar/assets/index.css';
import './Picker.scss';
import 'moment/locale/de';

import React, { Component } from 'react';

import { DateTime } from 'luxon';
import MonthCalendar from 'rc-calendar/lib/MonthCalendar';
import deDE from 'rc-calendar/lib/locale/de_DE';
import moment from 'moment';

moment.locale('de');

class DatepickerInnerMonth extends Component {
  static format(startDate, format) {
    if (!startDate) return startDate;
    return startDate.toFormat(format ?? "MMM '-' yyyy");
  }

  state = {};

  constructor({ startDate }) {
    super();
    this.state.startDate =
      typeof startDate === 'string'
        ? DateTime.fromISO(startDate)
        : DateTime.fromJSDate(startDate).setLocale('de');
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ startDate }) {
    this.setState({
      startDate:
        typeof startDate === 'string'
          ? DateTime.fromISO(startDate)
          : DateTime.fromJSDate(startDate).setLocale('de'),
    });
  }

  onChange = (value) => {
    const { onChangeValue } = this.props;
    const valueString = value.toISOString();
    const valueDT = DateTime.fromISO(valueString).setLocale('de');
    const startDate = valueDT.startOf('month');
    const endDate = valueDT.endOf('month');
    this.setState({ value }, () => {
      onChangeValue &&
        onChangeValue(
          startDate.toJSDate(),
          endDate.toJSDate(),
          DatepickerInnerMonth.format(startDate, endDate),
          'month'
        );
    });
  };

  onMonthCellContentRender(value) {
    return (
      <div className='col select-date'>{value && value.format('MMM')}</div>
    );
  }

  render() {
    return (
      <MonthCalendar
        locale={deDE}
        style={{ zIndex: 1000 }}
        onChange={this.onChange}
        monthCellContentRender={this.onMonthCellContentRender}
        defaultValue={moment(this.props.startDate)}
      />
    );
  }
}

export default DatepickerInnerMonth;
