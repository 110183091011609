import React, { Component } from 'react';

import { ReactComponent as ClockIcon } from '../../assets/icons/calender.svg';
import { DateTime } from 'luxon';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import TourItemDelete from './TourItemDelete';
import addressToString from '../helper/addressToString';
import classNames from 'classnames';

interface TourHomeProps {
  editing: boolean;
  value: {
    _id: string;
    address?: any;
    planedAt?: string;
    sort: number;
  };
  onDelete: (id: string) => void;
}

interface TourHomeState {
  date?: string;
}

class TourHome extends Component<TourHomeProps, TourHomeState> {
  state: TourHomeState = {};

  constructor(props: TourHomeProps) {
    super(props);
    if (props.value.planedAt) this.state.date = props.value.planedAt;
  }

  UNSAFE_componentWillReceiveProps(newProps: TourHomeProps) {
    this.setState({
      date: newProps.value.planedAt,
    });
  }

  render() {
    const { value } = this.props;

    const classes = classNames('route-step-content');
    return (
      <React.Fragment>
        <div className='route-step-icon'>
          {/* <p>{value.sort + 1}</p> */}
          <HomeIcon />
        </div>
        <div className={classes}>
          <div className='row'>
            <div className='col col-7'>
              <p className='name'>Heimatadresse</p>
              <p className='address'>
                {value.address ? addressToString(value.address) : ``}
              </p>
            </div>
            <div className='col col-4'>
              {value.planedAt ? (
                <p className='date full-height'>
                  <ClockIcon />
                  {DateTime.fromISO(value.planedAt)
                    .setLocale('de')
                    .toLocaleString({
                      month: '2-digit',
                      day: '2-digit',
                      weekday: 'short',
                    })}
                </p>
              ) : (
                <p className='date missing'>Plandatum eingeben</p>
              )}
            </div>
          </div>
        </div>
        <div className='route-step-actions extra'>
          {this.props.editing && (
            <TourItemDelete
              text=''
              value={value}
              onDelete={this.props.onDelete}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default TourHome;
