import React, { useState } from 'react';

import { ClipLoader } from 'react-spinners';
import { ReactComponent as DateCheckIcon } from '../../assets/icons/date.svg';
import Popconfirm from 'antd/es/popconfirm';

interface TourItemToggleFixedProps {
  text: string;
  value: {
    _id: string;
    fixed: boolean;
  };
  onChange: (id: string, value: boolean) => Promise<void>;
}

export default function TourItemToggleFixed(props: TourItemToggleFixedProps) {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Popconfirm
      className='route-step-action'
      placement='bottomRight'
      title={
        props.value.fixed ? 'Fixierung wirklich aufheben?' : 'Termin fixieren?'
      }
      onConfirm={() => {
        setLoading(true);
        props.onChange(props.value._id, !props.value.fixed).then(() => {
          setLoading(false);
        });
      }}
      okText='Ja'
      cancelText='Nein'
    >
      {loading && (
        <>
          <div
            style={{
              paddingTop: '2px',
              paddingRight: '12px',
            }}
          >
            <ClipLoader size={24} color={'#009842'} loading={true} />
          </div>
        </>
      )}
      {!loading && (
        <>
          <DateCheckIcon className='edit-icon' />
          {props.text}
        </>
      )}
    </Popconfirm>
  );
}
