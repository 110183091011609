import { Field, Line, LineWithTarget } from './fieldsDefault';
import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { ReactComponent as MenuIcon } from '../../../assets/icons/list-menu.svg';
import classNames from 'classnames';
import { flatten } from 'lodash';

const { Option } = Select;

interface ExportSettingLineProps {
  line: LineWithTarget;
  fields: { [group: string]: Field[] };
  loading: boolean;
  onChange: (line: Line) => void;
}

export default function ExportSettingLine(props: ExportSettingLineProps) {
  const rawFields = flatten(Object.values(props.fields));
  const groups = Object.keys(props.fields).sort((b, a) => a.localeCompare(b));

  const rawField = rawFields.find((f) => f.key === props.line.source);

  const [group, setGroup] = useState<string | undefined>(rawField?.group);
  const [field, _setField] = useState<string | undefined>(rawField?.key);
  const [title, setTitle] = useState<string>(props.line.title);
  const [valid, setValid] = useState<boolean>(props.loading);

  const setField = (f: string) => {
    _setField(f);
    setTimeout(() => {
      props.onChange &&
        props.onChange({
          _id: props.line._id,
          source: f,
          title,
        });
    }, 50);
  };

  useEffect(() => {
    const checkLineValid = () => {
      setValid(!!title && title.length > 0 && !!field);
    };
    checkLineValid();
  }, [field, title]);

  return (
    <div
      id={props.line._id}
      className={classNames('row', 'export-settings-row', {
        invalid: !props.loading && !valid,
      })}
      style={{
        padding: '16px 0',
        margin: '9px 0',
        background: '#ffffff',
        borderRadius: ' 3px',
      }}
    >
      <div className='col col-2'>{props.line.target}</div>
      <div className='col col-3'>
        <Input
          value={title}
          disabled={props.loading}
          onBlur={() => {
            props.onChange &&
              props.onChange({
                _id: props.line._id,
                source: field || '',
                title,
              });
          }}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className='col col-3'>
        <Select
          value={group}
          style={{ width: 320 }}
          disabled={props.loading}
          placeholder='Gruppe auswählen'
          optionFilterProp='children'
          filterOption={true}
          showSearch
          onChange={(value) => {
            setGroup(value);
            const g = props.fields[value];
            setField(g[0].key);
          }}
        >
          {groups.map((group, index) => (
            <Option key={`ExportSettingLineOptioGroup-${index}`} value={group}>
              {group}
            </Option>
          ))}
        </Select>
      </div>
      <div className='col col-3'>
        {group && props.fields[group] && props.fields[group].length > 0 && (
          <Select
            optionFilterProp='children'
            filterOption={true}
            showSearch
            value={field}
            style={{ width: 320 }}
            disabled={props.loading}
            placeholder='Quälle auswählen'
            onChange={(value: string) => setField(value)}
          >
            {props.fields[group]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((f, index) => (
                <Option key={`ExportSettingLineOption-${index}`} value={f.key}>
                  {f.name}
                </Option>
              ))}
          </Select>
        )}
      </div>
      <div className='col col-1'>
        <div className='sorter'>
          <MenuIcon />
        </div>
      </div>
    </div>
  );
}
