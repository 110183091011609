import './Login.scss';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import APIStatus from './APIStatus';
import ASLogo from '../../../assets/zOqSmTsZUuJH6R7Svyg0TKNkiA.png';
import { AuthContext } from '../../../context/AuthContext';
import { ReactComponent as Lock } from '../../../assets/icons/lock.svg';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { ResponseError } from 'superagent';
import SubmitButton from './SubmitButton';
import { ReactComponent as User } from '../../../assets/icons/user2.svg';
import qs from 'query-string';
import release from '../../../build';
import packageInfo from '../../../../package.json';

const SignupSchema = Yup.object().shape({
  username: Yup.string().required('fehlt'),
  password: Yup.string().required('fehlt'),
});

interface LoginState {
  apiVersion: string;
  error?: ResponseError;
}

class Login extends Component<RouteComponentProps, LoginState> {
  static contextType = AuthContext;

  state: LoginState = {
    apiVersion: 'loading',
  };

  renderLogo() {
    return <Logo className='logo' />;
  }

  renderLogoAS() {
    return (
      <a className='as-logo' target='blank' href='https://allessafe.de/'>
        <img src={ASLogo} alt='Logo' />
      </a>
    );
  }

  renderFooter() {
    return (
      <div className='mt-5 mb-1 text-muted text-center'>
        v{packageInfo.version} ({release}) | <APIStatus />
      </div>
    );
  }

  renderUnauthorized() {
    if (
      this.state.error &&
      (this.state.error.toString() === 'Unauthorized' ||
        this.state.error.message === 'Unauthorized')
    ) {
      return <div className='error'>Benutzername oder Passwort falsch!</div>;
    }
  }

  renderError() {
    if (this.state.error && this.state.error.message !== 'Unauthorized') {
      return (
        <div className='error'>
          Es ist eine Fehler aufgetreten. Code {this.state.error.status}
        </div>
      );
    }
  }

  renderForm() {
    return (
      <Formik
        validationSchema={SignupSchema}
        initialValues={{ username: '', password: '' }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          this.setState({ error: undefined }, () => {
            setTimeout(() => {
              this.context
                .login(values)
                .catch((error: Error) => {
                  console.error(error);
                  this.setState({ error });
                })
                .then(() => {
                  console.log('reset form');
                  actions.setSubmitting(false);
                  actions.resetForm({
                    values: {
                      password: '',
                      username: values.username,
                    },
                  });
                });
            }, 550);
          });
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div
              className={`input-group ${
                errors.username && touched.username ? 'invalid' : 'valid'
              }`}
            >
              <div className='input-group-prepend'>
                <label htmlFor='username' className='input-group-text'>
                  <User />
                </label>
              </div>
              <Field
                className='form-control'
                name='username'
                type='text'
                placeholder='Username'
                autoComplete='username'
              />
            </div>
            <div
              className={`input-group ${
                errors.password && touched.password ? 'invalid' : 'valid'
              }`}
            >
              <div className='input-group-prepend'>
                <label htmlFor='password' className='input-group-text'>
                  <Lock />
                </label>
              </div>
              <Field
                className='form-control'
                name='password'
                type='password'
                placeholder='Passwort'
                autoComplete='current-password'
              />
            </div>
            <SubmitButton
              disabled={!!errors.username || !!errors.password}
              isSubmitting={isSubmitting}
              failed={!!this.state.error}
            />
          </Form>
        )}
      </Formik>
    );
  }

  render() {
    if (this.context.isAuth()) {
      const queryParams = qs.parse(this.props.location.search);
      return <Redirect to={(queryParams.referrer as string) || '/'} />;
    }
    return (
      <div className='form-signin'>
        {this.renderLogo()}
        {this.renderUnauthorized()}
        {this.renderError()}
        {this.renderForm()}
        {this.renderFooter()}
        {this.renderLogoAS()}
      </div>
    );
  }
}

export default withRouter(Login);
