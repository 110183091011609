import { JOB_TYPE, NewJob, Technician } from '../../context/Route';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button } from 'antd';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { PlanningContext } from '../../context/PlanningContext';

interface MatchParams {
  id: string;
}

interface TourLocationAddHomeProps extends RouteComponentProps<MatchParams> {
  technician: Technician;
  index: number;
  planedAtBefore?: Date;
  count: number;
  onAdd: (job: NewJob) => Promise<() => Promise<void>>;
}

interface TourLocationAddHomeState {
  error?: Error;
  loading: boolean;
}

class TourLocationAddHome extends Component<
  TourLocationAddHomeProps,
  TourLocationAddHomeState
> {
  state: TourLocationAddHomeState = {
    loading: false,
  };

  constructor(props: TourLocationAddHomeProps) {
    super(props);
    this.add = this.add.bind(this);
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async add() {
    if (!this.visible) return;
    await this.setPromisifiedState({ loading: true });
    const job: NewJob = {
      sort: this.props.index,
      type: JOB_TYPE.HomeAccommodation,
      address: this.props.technician.address,
      planedAtTimeByUser: false,
      planedAt: this.props.planedAtBefore!,
      // operatingExpense: 0,
      // duePrice: 0,
    };
    const reload = await this.props.onAdd(job);
    await reload();
    await this.setPromisifiedState({ loading: false });
  }

  get visible() {
    return (
      this.props.planedAtBefore &&
      this.props.index > 0 &&
      this.props.index < this.props.count
    );
  }

  render() {
    return (
      <div
        className='col col-4'
        style={{ textAlign: 'center' }}
        onClick={async () => this.visible && (await this.add())}
      >
        {this.visible && (
          <Button
            style={{ padding: '0 15px', height: '54px', width: '100%' }}
            type='text'
            loading={this.state.loading}
          >
            <HomeIcon />
          </Button>
        )}
      </div>
    );
  }
}

TourLocationAddHome.contextType = PlanningContext;

export default withRouter(TourLocationAddHome);
