import './User.scss';

import React, { Component } from 'react';

import DirectInput from '../Form/DirectInput';
import { EMAIL_REGEXP } from '../regex';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import { Redirect } from 'react-router-dom';
import { Request } from '../../api/Request';
import SaveButton from '../SharesActions/Save';
import SelectInput from '../Form/SelectInput';
import zxcvbn from 'zxcvbn';

const GROUPS = [
  { value: 'hotel', text: 'Hotel Planer' },
  { value: 'planer', text: 'Tour Planer' },
  { value: 'admin', text: 'Administrator' },
];

const zxcvbnConfig = {
  minLength: 6,
};

interface NewUser {
  group?: string;
  defautlInitials?: string;
  initialsOverwrite?: string;
  username?: string;
  name?: string;
  email?: string;
  password?: string;
}

interface UserNewState {
  redirect?: string;
  error?: Error;
  loading: boolean;
  valid: boolean;
  item: NewUser;
}

class UserNew extends Component {
  state: UserNewState = {
    loading: false,
    valid: false,
    item: {
      group: 'planer',
    },
  };

  capitalize(s: string) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  async save(k: keyof NewUser, value: any) {
    const data = this.state.item;
    data[k] = value;
    if (k === 'name') {
      if (!value) {
        data['username'] = '';
        data.defautlInitials = '';
      } else {
        data['username'] = value
          .replace(/[ ]/gi, '.')
          .replace(/[^\w\s.]/gi, '')
          .toLowerCase();
        const initials = value.match(/\b\w{2}/g) || [];
        data.defautlInitials =
          this.capitalize(initials.shift() || '') +
          this.capitalize(initials.pop() || '');
      }
    }
    await this.setState({
      item: data,
      valid: this.isValid(),
    });
  }

  async send() {
    const result = await Request.post('users', this.state.item);
    this.setState({
      isLoaded: true,
      redirect: result._id,
    });
  }

  isValid() {
    return (
      true &&
      this.state.item &&
      this.state.item.name &&
      this.state.item.name.length > 2 &&
      (!this.state.item.initialsOverwrite ||
        (this.state.item.initialsOverwrite &&
          this.state.item.initialsOverwrite.length === 4)) &&
      this.state.item.email &&
      EMAIL_REGEXP.exec(this.state.item.email) !== null &&
      this.calcPasswordScore(this.state.item.password) >= 3 &&
      !!this.state.item.group
    );
  }

  calcPasswordScore(value?: string) {
    let result: zxcvbn.ZXCVBNResult | null = null;
    let score = 0;
    if (value && value.length >= zxcvbnConfig.minLength) {
      result = zxcvbn(value);
      ({ score } = result);
    }
    return score;
  }

  render() {
    const { item } = this.state;
    if (this.state.redirect)
      return <Redirect to={`/administration/users/${this.state.redirect}`} />;
    return (
      <div className='user container-inner'>
        <InPageNavigation to='/administration/users' />
        <div className='page-header page-header-line row justify-content-between'>
          <div className='col-6 col'>
            <h1 className='page-title'>Benutzer hinzufügen</h1>
          </div>
          <div className='col-6 col actions'>
            <SaveButton
              disabled={!this.state.valid}
              callback={() => this.send()}
            />
          </div>
        </div>
        <div className={`page-content row`}>
          <form className='col col-12 col-xs-6 col-md-8 col-lg-6 col-xl-6'>
            <div className='row table-divider'>
              <div className='col'>
                <span>Stammdaten</span>
              </div>
            </div>
            <div className='row table-row input-row'>
              <div className='col'>
                <DirectInput
                  title='Name'
                  required={true}
                  value={item.name}
                  validate={(value: string) =>
                    value !== undefined && value !== null && value.length >= 2
                  }
                  callback={(value: string) => this.save('name', value)}
                />
              </div>
            </div>
            <div className='row table-row input-row'>
              <div className='col'>
                <DirectInput
                  title='Kürzel'
                  value={item.initialsOverwrite}
                  defaultValue={item.defautlInitials}
                  validate={(value: string) =>
                    value === undefined ||
                    (value !== undefined && value.length === 4)
                  }
                  callback={(value: string) =>
                    this.save('initialsOverwrite', value)
                  }
                />
              </div>
            </div>
            <div className='row table-row input-row'>
              <div className='col'>
                <DirectInput
                  title='E-Mail'
                  required={true}
                  value={item.email}
                  type='email'
                  validate={(value: string) =>
                    value !== undefined &&
                    value !== null &&
                    EMAIL_REGEXP.exec(value) !== null
                  }
                  callback={(value: string) => this.save('email', value)}
                />
              </div>
            </div>
            <div className='row table-divider'>
              <div className='col'>
                <span>Login</span>
              </div>
            </div>
            <div className='row table-row input-row'>
              <div className='col'>
                <DirectInput
                  title='Benutzer'
                  value={item.username}
                  readOnly={true}
                />
              </div>
            </div>
            <div className='row table-row input-row'>
              <div className='col col-12'>
                <DirectInput
                  title='Passwort'
                  required={true}
                  value={item.password}
                  type='password'
                  validate={(value: string) =>
                    this.calcPasswordScore(value) >= 3
                  }
                  callback={(value: string) => this.save('password', value)}
                />
              </div>
            </div>
          </form>
          <form className='col col-12 col-xs-6 col-md-8 col-lg-6 col-xl-6'>
            <div className='row table-divider'>
              <div className='col'>
                <span>Rechte</span>
              </div>
            </div>
            <div className='row table-row input-row'>
              <div className='col'>
                <SelectInput
                  title='Gruppe'
                  value={item.group}
                  options={GROUPS}
                  validate={(value: string) => !!value}
                  callback={(value: string) => this.save('group', value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default UserNew;
