import React from 'react';

import classNames from 'classnames';
import RemoveButton from './RemoveButton';
export const defaultSize = {
  w: 2,
  h: 1,
  minW: 1,
  maxW: 4,
  minH: 1,
  maxH: 6,
};

export default function UnkownChart(props: {
  delete?: () => void;
  title: string;
}) {
  const headerClass = classNames({
    'box-header': true,
    row: true,
    'justify-content-between': true,
    'has-title': true,
  });

  const editClass = classNames({
    box: true,
    'box-editing': false,
  });

  return (
    <div className={editClass}>
      <div className={headerClass}>
        <div className='col col-8'>
          <div className='box-title'>Unknown chart type [{props.title}]</div>
          {props.delete != undefined ? (
            <RemoveButton onClick={() => props.delete!()} />
          ) : null}
        </div>
      </div>
      <div className='box-content'>
        You have selected a chart type that is not implemented yet or was
        replaced by a better one. Please delete this chart and add a new one.
      </div>
    </div>
  );
}
