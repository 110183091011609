import './MapInput.scss';

// import MbxClient from '@mapbox/mapbox-sdk';
import { debounce, get } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { DebounceInput } from 'react-debounce-input';
import styled from 'styled-components';
import { Geocoding } from '../../api/MapboxGeo';
import { FormConsumer } from '../../context/FormContext';

interface AddressInputProps {
  id: string;
  value: any;
  force?: boolean;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  direct?: boolean;
  callback?: (value: any) => void;
}

function valueToFormated(value: any) {
  if (!value) return '';
  return `${value.street}, ${value.postalCode} ${value.city}, ${value.countryCode}`;
}

const SuggestionsHolder = styled.div`
  position: absolute;
  z-index: 999;
  background: #fff;
  margin: 0 0 0 125px;
  max-width: 440px;
  border: 1px solid rgb(204, 204, 204);
  border-top: 0;
  height: 190px;
  ul {
    padding: 0;
    background: #fff;
    li {
      z-index: 1000;
      background: #fff;
      text-decoration: none;
      list-style: none;
      padding: 8px 12px 8px 5px;
      border-bottom: 1px solid #ccc;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 22px;
      font-weight: normal;
      color: #009842;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`;

const AddressInput: FunctionComponent<AddressInputProps> = ({
  className,
  callback,
  readOnly,
  id,
  ...props
}) => {
  const force = true;
  const [text, setText] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    setText(valueToFormated(props.value));
  }, [props.value]);

  const search = async (input: string) => {
    try {
      const res = await Geocoding.search(input);
      console.log(res);
      setSuggestions(res);
    } catch (error: any) {
      // TODO
    } finally {
    }
  };

  const searchDebounced = debounce(search, 500);

  // Search for Location
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setText(value);
    searchDebounced(value);
  };

  return (
    <FormConsumer>
      {() => (
        <>
          <DebounceInput
            autoComplete='false'
            type='text'
            value={text}
            id={`address-input-${id}`}
            name='address'
            placeholder='Adresse eingeben'
            minLength={0}
            debounceTimeout={500}
            onChange={(event) => onChange(event)}
            onFocus={() => setHasFocus(true)}
            onBlur={() => {
              setTimeout(() => setHasFocus(false), 100);
            }}
            data-lpignore='true'
          />
          {hasFocus && suggestions.length > 0 && (
            <SuggestionsHolder>
              <ul>
                {suggestions.map((suggestion, i) => (
                  <li
                    onClick={() => {
                      setText(valueToFormated(suggestion));
                      callback && callback(suggestion);
                    }}
                    key={i}
                  >
                    {valueToFormated(suggestion)}
                  </li>
                ))}
              </ul>
            </SuggestionsHolder>
          )}
        </>
      )}
    </FormConsumer>
  );
};

export default AddressInput;
