import './TourLocationAddLocation.scss';

import { Button, notification } from 'antd';
import {
  JOB_TYPE,
  Location,
  NewJob,
  RouteInfo,
  Technician,
} from '../../context/Route';
import {
  PlanningConsumer,
  PlanningContext,
} from '../../context/PlanningContext';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Table, { SELECTION_TYPE, TableOptions } from '../Table/Table';
import createLocationTableHeader, {
  TabledHeaderField,
} from '../Planing/Steps/createLocationTableHeader';
import { debounce, filter, get, isNil, isNumber, pickBy } from 'lodash';
import {
  extensionRowRender,
  isItemDisabled,
  isItemDisabledInfo,
  selectionCellRenderer,
} from '../Planing/Steps/PlanningStepLocationsCommon';

import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { AuthConsumer } from '../../context/AuthContext';
import { Comparator } from '../Table/Filter';
import { DateTime } from 'luxon';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';
import { Request } from '../../api/Request';
import classNames from 'classnames';
import customStyles from '../helper/modalCustomStyles';
import getTasks from '../Location/getTasks';
import request from 'superagent';

interface TourLocationAddOptions extends TableOptions {}

const defaultOptions: TourLocationAddOptions = {
  sort: 'address.postalCode',
  desc: false,
  filters: {
    duePrice: [
      {
        value: '0',
        comparator: Comparator['>N'],
      },
    ],
  },
};

interface MatchParams {
  id: string;
}

interface MatchParams {
  id: string;
}

interface TourLocationAddLocationProps
  extends RouteComponentProps<MatchParams> {
  technician: Technician;
  index: number;
  planedAtBefore?: Date;
  planningWeek?: Date;
  count: number;
  onAdd: (job: NewJob) => Promise<() => Promise<void>>;
}

interface TourLocationAddLocationState {
  error?: Error;
  loading: boolean;
  loadingAdd: boolean;
  options: TourLocationAddOptions;
  foresightedMonthCount: number;
  items: any[];
  // unfilteredValues: { [col: string]: string[] };
  header: TabledHeaderField[];
  isOpen: boolean;
}

class TourLocationAddLocation extends Component<
  TourLocationAddLocationProps,
  TourLocationAddLocationState
> {
  storeOptionsAndLoadDebounced: () => void;

  oldRequest?: request.SuperAgentRequest;

  state: TourLocationAddLocationState = {
    loading: false,
    loadingAdd: false,
    options: defaultOptions,
    foresightedMonthCount: 3,
    items: [],
    // unfilteredValues: {},
    header: [],
    isOpen: false,
  };

  constructor(props: TourLocationAddLocationProps) {
    super(props);
    this.handleOptionsChanged = this.handleOptionsChanged.bind(this);
    this.add = this.add.bind(this);
    this.storeOptionsAndLoadDebounced = debounce(this.storeOptionsAndLoad, 500);
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async add(
    locations: Location[],
    setLocations?: (locations: any[]) => Promise<void>
  ) {
    if (!this.visible) return;
    await this.setPromisifiedState({ loadingAdd: true });

    let reload = async () => Promise.resolve();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for await (const reloadPromise of locations.map(async (location) => {
      const job: NewJob = {
        sort: this.props.index,
        type: JOB_TYPE.Location,
        planedAt: this.props.planedAtBefore!,
        location,
      };

      reload = await this.props.onAdd(job);
    }))
      await reload();

    setLocations && (await setLocations([]));

    await this.setPromisifiedState({ loadingAdd: false, isOpen: false });
  }

  get visible() {
    return true;
  }

  async open() {
    await this.setPromisifiedState({ isOpen: true });
    this.storeOptionsAndLoad();
  }

  loadWithPlaningWeek() {
    const { planningWeek } = this.context;
    this.loadData(planningWeek);
  }

  getLoadDateParams(planningWeek: Date) {
    const { foresightedMonthCount } = this.state;

    const endDate = DateTime.fromJSDate(planningWeek)
      .plus({ months: foresightedMonthCount })
      .toISO();

    const planningWeekStart = DateTime.fromJSDate(planningWeek)
      .startOf('week')
      .toISO();

    const planningWeekEnd = DateTime.fromJSDate(planningWeek)
      .endOf('week')
      .toISO();

    return {
      filters: JSON.stringify(this.state.options.filters),
      sort: this.state.options.sort,
      desc: this.state.options.desc,
      end: endDate,
      uncomplete: true,
      hstart: planningWeekStart,
      hend: planningWeekEnd,
    };
  }

  async loadData(planningWeek: Date) {
    this.setPromisifiedState({ loading: true });

    try {
      const headerTasks = await getTasks();
      const header = createLocationTableHeader(
        headerTasks.map(({ key, title }: TabledHeaderField) => ({ title, key }))
      );

      const result = await Request.list(
        'planning/locations/due',
        this.getLoadDateParams(planningWeek)
      );

      // const unfilteredValues = this.addTasksToUnfilteredValues(
      //   result.unfilteredValues
      // );

      this.setPromisifiedState({
        loading: false,
        items: result.items,
        header,
        // unfilteredValues,
      });
    } catch (error: any) {
      if (error instanceof Error) {
        notification.error({
          message: 'Fehler',
          description: error.message || JSON.stringify(error),
          duration: 10000,
        });
      }
      this.setPromisifiedState({ loading: false, error });
    }
  }

  async handleOptionsChanged(opts: TourLocationAddOptions, planningWeek: Date) {
    await this.setPromisifiedState({ isLoaded: false, options: opts });
    this.loadData(planningWeek);
  }

  handleSelectionChanged(
    locationsToSet: any[],
    selection: boolean,
    locations: any[],
    setLocations?: (locations: any[]) => Promise<void>,
    setLoadingInfo?: (loading: boolean) => void,
    setInfo?: (info: RouteInfo) => void
  ) {
    let value: any[] = [];

    if (selection) {
      value = locations.concat(locationsToSet);
    } else {
      const ids = locationsToSet.map((l) => l._id);
      value = filter(locations, (l) => !ids.includes(l._id));
    }

    this.loadInfoPreview.bind(this)(value, setLoadingInfo, setInfo);
    setLocations && setLocations(value);
  }

  handleRouteEmptyError(error: any) {
    if (
      error &&
      error.response &&
      error.response.text.includes('routes is empty')
    ) {
      notification.error({
        duration: 0,
        message: 'Vorschau nicht möglich',
        description:
          'Die Route enthält einen Anschrift zu der nicht navigiert werden kann.',
      });
      return true;
    }
    return false;
  }

  handleAbortError(error: any) {
    if (error.code === 'ABORTED') {
      return true;
    }
    return false;
  }

  handleOtherErrors(error: any) {
    notification.error({
      duration: 0,
      message: `Vorschau nicht möglich (Code ${error.code})`,
      description: 'Unbekannter Fehler.',
    });
  }

  loadInfoPreview(
    locations: any[],
    setLoadingInfo?: (loading: boolean) => void,
    setInfo?: (info: RouteInfo) => void
  ) {
    try {
      // stop existing request
      if (this.oldRequest) {
        this.oldRequest.abort();
      }

      // set loading
      setLoadingInfo && setLoadingInfo(true);

      // prepare coordinates
      const reqData = locations.map((l) =>
        get(l, 'address.location.coordinates')
      );

      // build request
      this.oldRequest = Request.postForRoutePreview(reqData);

      // run request
      this.oldRequest
        .then((res) => res.body)
        .then((data) => {
          // set result and loading
          setInfo && setInfo(data);
        })
        .catch((error) => {
          setLoadingInfo && setLoadingInfo(false);
          if (this.handleRouteEmptyError(error)) return;
          if (this.handleAbortError(error)) return;
          this.handleOtherErrors(error);
        });
    } catch (error: any) {
      setLoadingInfo && setLoadingInfo(false);
      if (this.handleRouteEmptyError(error)) return;
      if (this.handleAbortError(error)) return;
      this.handleOtherErrors(error);
    }
  }

  setForesightedMonthCount(floatValue: number) {
    if (
      !isNil(floatValue) &&
      isNumber(floatValue) &&
      floatValue !== this.state.foresightedMonthCount
    ) {
      this.setState({ foresightedMonthCount: floatValue }, () => {
        this.storeOptionsAndLoadDebounced();
      });
    }
  }

  storeOptionsAndLoad() {
    this.loadData(this.props.planningWeek!);
  }

  // isItemDisabled(blocked: any, user: any, location: any) {
  //   const block =
  //     blocked &&
  //     blocked.locations &&
  //     !!blocked.locations[location._id] &&
  //     blocked.locations[location._id] !== user.id;
  //   const addr = !(
  //     location.address &&
  //     location.address.street &&
  //     location.address.postalCode &&
  //     location.address.city
  //   );
  //   const planed = location.nextJob && location.nextJob.all;
  //   const hasBudget =
  //     location.contractValueSum ||
  //     location.jobValueSum < location.contractValueSum;
  //   return (planed && !hasBudget) || block || addr;
  // }

  render() {
    const {
      items,
      options,
      loading,
      loadingAdd,
      foresightedMonthCount,
      // unfilteredValues,
    } = this.state;

    return (
      <div className='col col-4' style={{ textAlign: 'center' }}>
        {this.visible && (
          <AuthConsumer>
            {({ user }: { user: { id?: string } }) => (
              <PlanningConsumer>
                {({
                  blocked,
                  locations,
                  setLocations,
                  planningWeek,
                  setInfo,
                  setLoadingInfo,
                }) => {
                  return (
                    <Fragment>
                      <Button
                        onClick={() => this.visible && this.open()}
                        style={{
                          padding: '0 15px',
                          height: '54px',
                          width: '100%',
                        }}
                        type='text'
                      >
                        <AddIcon />
                      </Button>

                      <Modal
                        isOpen={this.state.isOpen}
                        onRequestClose={() => this.setState({ isOpen: false })}
                        style={customStyles}
                        contentLabel=''
                      >
                        <div
                          className='modal add-location-modal'
                          tabIndex={-1}
                          role='dialog'
                        >
                          <div className='modal-dialog' role='document'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <div className='row'>
                                  <div className='col col-4'>
                                    <h5 className='modal-title'>
                                      Standort hinzufügen
                                    </h5>
                                  </div>
                                  <div
                                    className='col col-4'
                                    style={{ textAlign: 'center' }}
                                  >
                                    <h5 className='modal-title'>
                                      {planningWeek &&
                                        DateTime.fromJSDate(
                                          planningWeek
                                        ).toFormat("'Plan-KW' WW '-' yyyy")}
                                    </h5>
                                  </div>
                                  <div
                                    className='col col-4 col foresighted-months'
                                    style={{ textAlign: 'right' }}
                                  >
                                    <span>Vorrausblick: </span>
                                    <NumberFormat
                                      id='foresightedMonthCount'
                                      disabled={loading}
                                      thousandSeparator='.'
                                      decimalSeparator=','
                                      value={foresightedMonthCount}
                                      allowNegative={false}
                                      prefix=''
                                      suffix={
                                        foresightedMonthCount !== 1
                                          ? ' Monate'
                                          : ' Monat'
                                      }
                                      decimalScale={0}
                                      onValueChange={(e) =>
                                        this.setForesightedMonthCount(
                                          e.floatValue || 3
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='modal-body'>
                                <Table
                                  loading={loading}
                                  filterable={true}
                                  selectable={SELECTION_TYPE.Filter}
                                  options={options}
                                  header={this.state.header}
                                  items={items}
                                  // unfilteredValues={unfilteredValues}
                                  extensionGetter={(item) => {
                                    const {
                                      _id,
                                      address,
                                      addressRouting,
                                      tag,
                                      customer,
                                      contractValueSum,
                                      checkDepth,
                                      name,
                                      // comment,
                                      note,
                                      holidays,
                                      contractValueRest,
                                    } = item;
                                    return item.additionalTasks
                                      .filter((t: any) => {
                                        if (!t.dueFixed) return true;
                                        if (!planningWeek) return false;
                                        const dt = DateTime.fromISO(t.due);
                                        const pt =
                                          DateTime.fromJSDate(planningWeek);
                                        return (
                                          dt.weekYear === pt.weekYear &&
                                          dt.weekNumber === pt.weekNumber
                                        );
                                      }) // alle Termine in der Plan KW
                                      .map((p: any) => {
                                        return {
                                          ...p,
                                          additionalTask: p._id,
                                          locID: _id,
                                          address,
                                          addressRouting,
                                          tag,
                                          customer,
                                          contractValueSum,
                                          checkDepth,
                                          name,
                                          comment: p.message,
                                          note: note,
                                          holidays,
                                          contractValueRest,
                                          operatingExpense: p.planHours,
                                          duePrice: p.estimatedPrice,
                                          job: p.job,
                                          _dues: pickBy(
                                            p.type,
                                            (value, key) => !key.startsWith('_')
                                          ),
                                          freezeComment: true,
                                          fixedDateFromAdditionalTasks:
                                            p.dueFixed ? p.due : undefined,
                                        };
                                      })
                                      .sort(
                                        (firstEl: any, secondEl: any) =>
                                          firstEl &&
                                          firstEl.fixedDateFromAdditionalTasks &&
                                          secondEl &&
                                          secondEl.fixedDateFromAdditionalTasks &&
                                          firstEl.fixedDateFromAdditionalTasks.localeCompare(
                                            secondEl.fixedDateFromAdditionalTasks
                                          )
                                      );
                                  }}
                                  extensionRow={extensionRowRender.bind(this)}
                                  selection={locations}
                                  selectionCellRenderer={selectionCellRenderer}
                                  isItemDisabled={(location) =>
                                    isItemDisabled(blocked, user, location)
                                  }
                                  isItemDisabledInfo={(location) =>
                                    isItemDisabledInfo(blocked, user, location)
                                  }
                                  handleOptionsChanged={(opts) =>
                                    this.handleOptionsChanged(
                                      opts,
                                      planningWeek!
                                    )
                                  }
                                  handleSelectionChanged={(
                                    locationToSet,
                                    selection
                                  ) => {
                                    this.handleSelectionChanged(
                                      locationToSet,
                                      selection,
                                      locations || [],
                                      setLocations,
                                      setLoadingInfo,
                                      setInfo
                                    );
                                  }}
                                  link='/administration/locations'
                                  linkTarget='_blank'
                                />
                              </div>
                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className={classNames(
                                    'btn',
                                    'btn-secondary',
                                    {
                                      disabled: loadingAdd,
                                    }
                                  )}
                                  data-dismiss='modal'
                                  onClick={() =>
                                    this.setState({ isOpen: false })
                                  }
                                >
                                  Schließen
                                </button>
                                <button
                                  type='button'
                                  className={classNames('btn', 'btn-primary', {
                                    disabled:
                                      loadingAdd ||
                                      !locations ||
                                      locations.length !== 1,
                                  })}
                                  onClick={async () => {
                                    if (this.visible) {
                                      await this.add(
                                        locations || [],
                                        setLocations
                                      );
                                    }
                                  }}
                                >
                                  Erstellen
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </Fragment>
                  );
                }}
              </PlanningConsumer>
            )}
          </AuthConsumer>
        )}
      </div>
    );
  }
}

TourLocationAddLocation.contextType = PlanningContext;

export default withRouter(TourLocationAddLocation);
