import { ClipLoader } from 'react-spinners';
import React from 'react';

export default function PlanningStepTourLoadingOverlay() {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 999,
        left: 0,
        background: 'rgba(0, 0, 0, 0.33)',
        right: 0,
        top: 0,
        bottom: '-32px',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '40px',
          left: 'calc(50% - 40px)',
          cursor: 'wait',
        }}
      >
        <ClipLoader size={80} color={'#009842'} loading={true} />
      </div>
    </div>
  );
}
