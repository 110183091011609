import { Button, Result, Spin } from 'antd';
import React, { FunctionComponent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

interface ImportStepConnectProps {
  loading: boolean;
  error: Error | undefined;
  onReset: () => void;
}

export const ImportStepConnect: FunctionComponent<ImportStepConnectProps> = ({
  error,
  onReset,
}) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 96, color: '#009842' }} spin />
  );
  const renderError = () => (
    <Result
      status='500'
      title='Verbindungsfehler'
      subTitle='Es konnte keine Verbindung zum Server hergestellt werden.'
      extra={
        <Button onClick={() => onReset()} type='primary'>
          Erneut versuchen
        </Button>
      }
    />
  );
  const renderConnect = () => (
    <div>
      <Spin indicator={antIcon} />
      <p className='mt-4'>Verbindung wird hergestellt...</p>
    </div>
  );
  return (
    <div className='row'>
      <div className='col col-12'>
        {error && renderError()}
        {!error && renderConnect()}
      </div>
    </div>
  );
};
