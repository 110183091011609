import './Profile.scss';

import { User, UserGroup, UserGroupByString } from '../../../context/Route';
import { useEffect, useState } from 'react';

import { EMAIL_REGEXP } from '../../regex';
import EditableInput from '../../Form/EditableInput';
import { FormConsumer } from '../../../context/FormContext';
import React from 'react';
import { Request } from '../../../api/Request';
import SelectInput from '../../Form/SelectInput';
import classNames from 'classnames';
import renderLoading from '../../SharesActions/renderLoading';
import zxcvbn from 'zxcvbn';

const zxcvbnConfig = {
  minLength: 9,
};

function calcPasswordScore(value: string | undefined) {
  let result: zxcvbn.ZXCVBNResult | null = null;
  let score = 0;
  if (value && value.length >= zxcvbnConfig.minLength) {
    result = zxcvbn(value);
    ({ score } = result);
  }
  return score;
}

type UserWithOutDefautlInitials = Omit<User, 'defautlInitials'>;

export default function Profile() {
  const userID = 'me';
  const [item, setData] = useState<User>();
  const [loading, setLoading] = useState(true);

  const save = async (
    k: keyof UserWithOutDefautlInitials | 'password',
    value: any
  ) => {
    const data: Partial<UserWithOutDefautlInitials & { password: string }> = {};
    data[k] = value;
    const result = await Request.put('users', userID, data);
    setData(result);
  };

  useEffect(() => {
    async function fetchData() {
      const result = await Request.get('users', userID);
      setData(result);
      setLoading(false);
    }
    fetchData();
  }, [userID]);

  if (loading || !item) return renderLoading();

  return (
    <div className='profile container-inner'>
      <div className='page-header page-header-line row justify-content-between'>
        <div className='col col-6'>
          <h1 className='page-title'>Mein Benutzer</h1>
        </div>
        <div className='col col-6'></div>
      </div>
      <FormConsumer>
        {({ isEditing }: { isEditing: boolean }) => (
          <div
            className={classNames('page-content', 'row', {
              editing: isEditing,
            })}
          >
            <form className='col col-12 col-xs-6 col-md-8 col-lg-6 col-xl-6'>
              <div className='row table-divider'>
                <div className='col'>
                  <span>Stammdaten</span>
                </div>
              </div>
              <div className='row table-row input-row'>
                <div className='col'>
                  <EditableInput
                    title='Name'
                    value={item.name}
                    validate={(value: string) =>
                      value !== undefined && value !== null && value.length > 2
                    }
                    callback={(value: string) => save('name', value)}
                  />
                </div>
              </div>
              <div className='row table-row input-row'>
                <div className='col'>
                  <EditableInput
                    title='Kürzel'
                    value={item.initialsOverwrite}
                    defaultValue={item.defautlInitials}
                    validate={(value: string) =>
                      value === undefined ||
                      (value !== undefined && value.length === 4)
                    }
                    callback={(value: string) =>
                      save('initialsOverwrite', value)
                    }
                  />
                </div>
              </div>
              <div className='row table-row input-row'>
                <div className='col'>
                  <EditableInput
                    title='E-Mail'
                    value={item.email}
                    type='email'
                    validate={(value: string) =>
                      value && EMAIL_REGEXP.exec(value) !== null
                    }
                    callback={(value: string) => save('email', value)}
                  />
                </div>
              </div>
            </form>
            <form className='col col-12 col-xs-6 col-md-8 col-lg-6 col-xl-6'>
              <div className='row table-divider'>
                <div className='col'>
                  <span>Passwort ändern</span>
                </div>
              </div>
              {/* <div className='row table-row input-row'>
                <div className='col'>
                  <EditableInput
                    title='Benutzer'
                    value={item.username}
                    readOnly={true}
                  />
                </div>
              </div> */}
              <div className='row table-row input-row'>
                <div className='col col-12'>
                  <EditableInput
                    title='Passwort'
                    type='password'
                    validate={(value?: string) => calcPasswordScore(value) >= 3}
                    callback={(value: string) => save('password', value)}
                  />
                </div>
              </div>
              <div className='row table-divider'>
                <div className='col'>
                  <span>Rechte</span>
                </div>
              </div>
              <div className='row table-row input-row'>
                <div className='col'>
                  <SelectInput
                    title='Gruppe'
                    value={item.group}
                    options={Object.keys(UserGroup).map((key: string) => ({
                      value: UserGroupByString(key),
                      text: key,
                    }))}
                    readOnly={true}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </FormConsumer>
    </div>
  );
}
