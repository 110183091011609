import React, { FunctionComponent } from 'react';
import DateAction from '../../common/CardList/Actions/DateAction';
import { DateTime } from 'luxon';
import { Job, HotelJob } from '../../../context/Route';
import { setPlanedAt } from './utils/set-planedAt';
import showPlanDateModal from '../../ModalInput/showPlanDateModal';

interface PlanedAtProps {
  job: Job | HotelJob;
  readonly?: boolean;
  onChanged?: () => void;
}

const PlanedAt: FunctionComponent<PlanedAtProps> = ({
  readonly,
  job,
  onChanged,
}) => {
  const d =
    typeof job.planedAt === 'string'
      ? DateTime.fromISO(job.planedAt)
      : DateTime.fromJSDate(job.planedAt ?? new Date());

  // const format = job.fixed ? 'datetime' : 'date';
  const format = 'datetime';
  const date = d.toJSDate();

  const handleChange = async (date: Date, fixed: boolean) => {
    await setPlanedAt(job as Job, date, fixed);
    onChanged && onChanged();
  };

  return job.planedAt ? (
    <DateAction
      noBackground
      keepWeek
      format={format}
      value={date}
      onChange={
        readonly ? undefined : (res) => handleChange(res.date, res.fixed)
      }
    />
  ) : (
    <></>
  );
};

export default PlanedAt;
