import './RelSettings.scss';

import { Button, Upload, message } from 'antd';

import React from 'react';
import { Request } from '../../../api/Request';
import download from 'downloadjs';

export default function RelSettingFile() {
  const downloadREL = async () => {
    const { base64 } = await Request.list('rel/file', {});
    download(
      base64,
      'rel-vorlage.xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  };

  return (
    <>
      <h3>Datei</h3>
      <p className='info'>
        Hier kann die REL in Safeplan ersetzt werden. Die Änderung wird umgehend
        übernommen. Um zu Prüfen, welche REL Datei aktuell verwendet wird, kann
        diese heruntergeladen werden.
      </p>
      <div className='row'>
        <div className='col col-12 col-md-2'>
          <Button onClick={() => downloadREL()}>Datei herunterladen</Button>
        </div>
        <div className='col col-12 col-md-2'>
          <Upload
            name='file'
            accept={
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            action={Request.relUploadInfo.url}
            headers={Request.relUploadInfo.headers}
            onChange={(info) => {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            progress={{
              strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
              },
              strokeWidth: 3,
              format: (percent) => `${parseFloat((percent || 0).toFixed(2))}%`,
            }}
          >
            <Button>Datei hochladen</Button>
          </Upload>
        </div>
      </div>
    </>
  );
}
