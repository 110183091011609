import styled from 'styled-components';

export const PageHeaderActions = styled.ul`
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  word-break: break-all;
`;

export const PageHeaderAction = styled.li<{
  danger?: boolean;
  disabled?: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  user-select: none;
  padding: 0 1rem;
  display: inline;
  p {
    display: inline-block;
    color:${({ disabled, danger }) =>
      disabled === false ? (danger ? '#e44' : '#009842') : '#a1a1a1'}
  }
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 12px 3px 0;
    fill: ${({ disabled, danger }) =>
      disabled === false ? (danger ? '#e44' : '#009842') : '#a1a1a1'}
    stroke: ${({ disabled, danger }) =>
      disabled === false ? (danger ? '#e44' : '#009842') : '#a1a1a1'}
    g {
      fill: ${({ disabled, danger }) =>
        disabled === false ? (danger ? '#e44' : '#009842') : '#a1a1a1'}
      stroke:${({ disabled, danger }) =>
        disabled === false ? (danger ? '#e44' : '#009842') : '#a1a1a1'}
    }
  }
  &:hover {
    p,
    svg {
      color: ${({ danger, disabled }) =>
        disabled ? '#a1a1a1' : danger ? '#e44' : '#009842'} !important;
      fill: ${({ danger, disabled }) =>
        disabled ? '#a1a1a1' : danger ? '#e44' : '#009842'} !important;
      stroke: ${({ danger, disabled }) =>
        disabled ? '#a1a1a1' : danger ? '#e44' : '#009842'} !important;
    }
  }
`;
