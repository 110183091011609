import numeral from 'numeral';

// load a locale
numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function () {
    return '.';
  },
  currency: {
    symbol: '€',
  },
});

// switch between locales
numeral.locale('de');

export default function shortCurrency(value: number, smallFormat: string) {
  const f = value < 10000 ? smallFormat || '0,0' : '0.0a';
  return numeral(value).format(f);
}
