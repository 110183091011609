import React, { FunctionComponent } from 'react';

import ImportLine from './ImportLine';
import { LineWithEmbbededTarget } from './Import';
import classNames from 'classnames';

interface ImportStepConnectProps {
  lines: LineWithEmbbededTarget[];
  onSelect: (lines: LineWithEmbbededTarget[]) => void;
}

export const ImportStepFields: FunctionComponent<ImportStepConnectProps> = ({
  lines,
  onSelect,
}) => {
  return (
    <>
      <div
        className='row'
        style={{
          margin: '0',
        }}
      >
        <div className='col col-12'>
          <button
            className='btn btn-link'
            onClick={() => onSelect(lines.map((l) => ({ ...l, active: true })))}
            style={{
              marginRight: '24px',
            }}
          >
            Alle auswählen
          </button>
          <button
            className='btn btn-link'
            onClick={() =>
              onSelect(
                lines.map((l) => ({ ...l, active: l.required || false }))
              )
            }
          >
            Alle abwählen
          </button>
        </div>
      </div>
      <ul style={{ padding: 0 }}>
        <div
          className={classNames('row', 'import-row')}
          style={{
            padding: '16px 0',
            margin: '9px 0',
            background: '#ffffff',
            borderRadius: '3px',
            fontWeight: 'bold',
          }}
        >
          <div className='col col-1'>Aktiv</div>
          <div className='col col-2'>Spalte</div>
          <div className='col col-2'>Ziel</div>
        </div>
        {lines.map((line, index) => (
          <ImportLine key={index} line={line} />
        ))}
      </ul>
    </>
  );
};
