import { notification } from 'antd';
import { HotelJob } from '../../../../context/Route';
import { get } from 'lodash';
import { Request } from '../../../../api/Request';

interface HotelValue {
  hotelName: string;
  address: {
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
  };
}

export async function saveHotel(job: HotelJob, value: HotelValue) {
  const jobId: string = job._id as string;
  return Request.put('jobs', jobId, value, '', false).then(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
    (error) => {
      notification.error({
        message: 'Fehler beim Speichern',
        description: get(
          error,
          'message',
          'Die Änderung konnte nicht gespeichert werden.'
        ),
      });
    }
  );
}
