import 'mapbox-gl/dist/mapbox-gl.css';
import './Map.scss';

import MapGL, { Marker, NavigationControl, ViewState } from 'react-map-gl';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Pin from './Pin';

const fallbackCoordinates = [8.5784877, 49.5412437];

interface MapProps {
  className: string;
  location: {
    city?: string;
    countryCode?: string;
    location: { coordinates: number[]; type: 'Point' };
    postalCode?: string;
    regionCode?: string;
    street?: string;
  };
}

const defaultViewport: ViewState = {
  latitude: fallbackCoordinates[1],
  longitude: fallbackCoordinates[0],
  zoom: 15,
  bearing: 0,
  pitch: 0,
};

const Map: FunctionComponent<MapProps> = ({ location }) => {
  const [marker, setMarker] = useState<{ longitude: number; latitude: number }>(
    { latitude: fallbackCoordinates[1], longitude: fallbackCoordinates[0] }
  );
  const [viewport, setViewport] = useState<ViewState>(defaultViewport);

  useEffect(() => {
    if (location) {
      setMarker({
        latitude: location.location.coordinates[1],
        longitude: location.location.coordinates[0],
      });
      setViewport({
        ...defaultViewport,
        latitude: location.location.coordinates[1],
        longitude: location.location.coordinates[0],
      });
    } else {
      setMarker({
        latitude: fallbackCoordinates[1],
        longitude: fallbackCoordinates[0],
      });
      setViewport(defaultViewport);
    }
  }, [location]);

  const _updateViewport = (viewState: any) => {
    setViewport(viewState);
  };

  return (
    <MapGL
      {...viewport}
      reuseMaps={true}
      width='100%'
      height='100%'
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      mapStyle='mapbox://styles/notionug/cju5ioxp50sjc1fqm0z88bzyq?optimize=true'
      onViewportChange={_updateViewport}
    >
      <Marker
        longitude={marker.longitude}
        latitude={marker.latitude}
        offsetTop={-15}
        offsetLeft={-15}
      >
        <Pin size={30} />
      </Marker>
      <div
        className='nav'
        style={{ position: 'absolute', top: 0, right: 0, padding: '10px' }}
      >
        <NavigationControl onViewportChange={_updateViewport} />
      </div>
    </MapGL>
  );
};
export default Map;
