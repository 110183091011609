import { Button } from 'antd';
import React from 'react';

interface SubmitButtonProps {
  isSubmitting: boolean;
  failed: boolean;
  disabled: boolean;
}

export default function SubmitButton(props: SubmitButtonProps) {
  return (
    <Button
      disabled={props.disabled}
      className='btn btn-lg btn-primary btn-block'
      htmlType='submit'
      loading={props.isSubmitting}
    >
      Anmelden
    </Button>
  );
}
