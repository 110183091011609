import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Login from './Login/Login';
import MainLayout from '../Layouts/main.layout';
import Profile from './Profile/Profile';
import ProtectedAppRoute from '../Routes/ProtectedRoute';
import React from 'react';

export default function Account() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <h3>Please select a topic.</h3>
      </Route>
      <Route path={`${path}/login`}>
        <Login />
      </Route>
      <ProtectedAppRoute
        exact
        path={`${path}/profile`}
        layout={MainLayout}
        component={Profile}
      />
    </Switch>
  );
}
