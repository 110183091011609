import './print.scss';

import {
  PlanedLocation,
  PlanedLocationJob,
  ToursLocationsListOptions,
  defaultOptions,
} from './ToursLocationsList';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get, keys, pick, pickBy } from 'lodash';

import { DateTime } from 'luxon';
import ReactToPrint from 'react-to-print';
import { Request } from '../../../api/Request';
import numeral from 'numeral';
import qs from 'query-string';

function shortCurrency(value: number, smallFormat: string) {
  const f = value < 10000 ? smallFormat || '0,0' : '0.0a';
  return numeral(value).format(f);
}

class PrintLocationsList extends Component<RouteComponentProps> {
  state = {
    queryParams: null,
    items: [],
  };

  printRef: ReactToPrint | null = null;
  componentRef: React.ReactInstance | null = null;

  componentDidMount() {
    const options = this.loadOptionsFromURL(this.props.location);
    this.loadData(options);
  }

  dateFromMillis(m: string | number) {
    return DateTime.fromMillis(Number(m)).toJSDate();
  }

  loadOptionsFromURL(location: { search: string }) {
    const data = qs.parse(location.search);
    const s: ToursLocationsListOptions = {};
    s.sort = (data.sort as string) || defaultOptions.sort;
    s.page = Number(data.page) || defaultOptions.page;
    s.desc = Boolean(data.desc) || defaultOptions.desc;
    s.limit = Number(data.limit) || defaultOptions.limit;
    s.dateRange = {
      startDate: data.startDate
        ? this.dateFromMillis(data.startDate as string)
        : defaultOptions.dateRange.startDate,
      endDate: data.endDate
        ? this.dateFromMillis(data.endDate as string)
        : defaultOptions.dateRange.endDate,
    };
    return s;
  }

  async loadData(options: ToursLocationsListOptions) {
    let s: Pick<
      ToursLocationsListOptions,
      'sort' | 'desc' | 'page' | 'limit'
    > & {
      startDate?: string;
      endDate?: string;
    } = pick(options, ['sort', 'desc', 'page', 'limit']);

    const _start = get(this.state, 'options.dateRange.startDate');
    const _end = get(this.state, 'options.dateRange.endDate');

    const startDate =
      _start !== undefined ? DateTime.fromJSDate(_start) : undefined;
    const endDate = _end !== undefined ? DateTime.fromJSDate(_end) : undefined;

    s = {
      ...s,
      startDate:
        startDate !== undefined && startDate.isValid
          ? startDate.toISO()
          : undefined,
      endDate:
        endDate !== undefined && endDate.isValid ? endDate.toISO() : undefined,
    };

    const result: {
      filtered: any[];
    } = await Request.list('locationsWithJob', s);

    this.setState(
      {
        loading: false,
        items: result.filtered,
      },
      () => this.print()
    );
  }

  renderRow(item: PlanedLocation) {
    return (
      <React.Fragment key={item._id}>
        <div className='item'>
          <div className='row bolder big'>
            <div className='col col-3'>Standort</div>
            <div className='col col-3'></div>
            <div className='col col-3'></div>
            <div className='col col-3'></div>
          </div>
          <div className='row bold'>
            <div className='col col-2'>
              <span>Standort</span>
            </div>
            <div className='col col-2'>
              <span>Standort NR</span>
            </div>
            <div className='col col-2'>
              <span>Straße</span>
            </div>
            <div className='col col-2'>
              <span>Plz</span>
            </div>
            <div className='col col-2'>
              <span>Ort</span>
            </div>
          </div>
          <div className='row'>
            <div className='col col-2'>
              <span>{item.name}</span>
            </div>
            <div className='col col-2'>
              <span>{item.tag}</span>
            </div>
            <div className='col col-2'>
              <span>{item.address.street}</span>
            </div>
            <div className='col col-2'>
              <span>{item.address.postalCode}</span>
            </div>
            <div className='col col-2'>
              <span>{item.address.city}</span>
            </div>
          </div>
          <div className='row bold'>
            <div className='col col-2'>
              <span>Telefon</span>
            </div>
            <div className='col col-2'>
              <span>Fax</span>
            </div>
            <div className='col col-2'>
              <span>E-Mail</span>
            </div>
            <div className='col col-2'>
              <span>Ansprechpartner</span>
            </div>
            <div className='col col-2'>
              <span>Öffnungszeiten</span>
            </div>
          </div>
          <div className='row'>
            <div className='col col-2'>
              <span>{item.phone}</span>
            </div>
            <div className='col col-2'>
              <span>{item.fax}</span>
            </div>
            <div className='col col-2'>
              <span>{item.email}</span>
            </div>
            <div className='col col-2'>
              <span>{item.contact}</span>
            </div>
            <div className='col col-2'>
              <span>{item.openingHours}</span>
            </div>
          </div>
          <div className='row bolder big'>
            <div className='col col-3'>Prüfung(en)</div>
            <div className='col col-3'></div>
            <div className='col col-3'></div>
            <div className='col col-3'></div>
          </div>
          {item.jobs.map((job: PlanedLocationJob, index: number) => (
            <Fragment key={index}>
              <div
                key={job._id}
                className='row bold'
                style={{ marginTop: '4px' }}
              >
                <div className='col col-2'>
                  <span>Termin</span>
                </div>
                <div className='col col-1'>
                  <span>Pos.</span>
                </div>
                <div className='col col-1'>
                  <span>Pos.</span>
                </div>
                <div className='col col-2'>
                  <span>Techniker</span>
                </div>
                <div className='col col-1'>
                  <span>Dauer</span>
                </div>
                <div className='col col-1'>
                  <span>Preis</span>
                </div>
                <div className='col col-4'>
                  <span>Prüfungen</span>
                </div>
              </div>
              <div className='row'>
                <div className='col col-2'>
                  <span>
                    {DateTime.fromISO(job.planedAt)
                      .setLocale('de')
                      .toLocaleString({
                        month: '2-digit',
                        day: '2-digit',
                        weekday: 'short',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                  </span>
                </div>
                <div className='col col-1'>
                  <span>{`${job.tour.tag}`}</span>
                </div>
                <div className='col col-1'>
                  <span>{`${job.sort}`}</span>
                </div>
                <div className='col col-2'>
                  <span>{job.technician.name}</span>
                </div>
                <div className='col col-1'>
                  <span>{` ${
                    job.planHours && job.planHours > 0
                      ? `${shortCurrency(job.planHours as number, '0.00')}h`
                      : ''
                  }`}</span>
                </div>
                <div className='col col-1'>
                  <span>{`${
                    job.estimatedPrice && job.estimatedPrice > 0
                      ? `${shortCurrency(job.estimatedPrice as number, '0')}€`
                      : ''
                  }`}</span>
                </div>
                <div className='col col-4'>
                  <span>
                    {keys(
                      pickBy(
                        job.tasks,
                        (value, key) => key !== '_id' && !!value
                      )
                    )
                      .join(', ')
                      .toUpperCase()}
                  </span>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  }

  print() {
    const t = setInterval(() => {
      if (this.printRef) {
        clearInterval(t);
        (this.printRef as any).handlePrint();
      }
    }, 800);
  }

  render() {
    const { items } = this.state;
    const options = this.loadOptionsFromURL(this.props.location);
    let asKW = '';
    if (options.dateRange?.startDate) {
      const dt = DateTime.fromJSDate(options.dateRange?.startDate).setLocale(
        'de'
      );
      asKW = dt.toFormat("'KW' WW '-' yyyy");
    }
    return (
      <React.Fragment>
        <ReactToPrint
          ref={(el) => (this.printRef = el)}
          trigger={() => <div></div>}
          content={() => this.componentRef!}
        />
        <div
          className='print-planed-locations'
          ref={(el) => (this.componentRef = el)}
        >
          <h3>geplante Standorte - {asKW}</h3>
          <h6>
            {`Stand ${DateTime.local().toLocaleString(
              DateTime.DATETIME_SHORT
            )} - Anzahl Standorte: ${items.length}`}
          </h6>
          {items.map(this.renderRow.bind(this))}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PrintLocationsList);
