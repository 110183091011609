import './Partner.scss';

import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Table, { SELECTION_TYPE } from '../Table/Table';

import { AuthConsumer } from '../../context/AuthContext';
import EditableInput from '../Form/EditableInput';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import RemoveButton from '../SharesActions/Remove';
import { Request } from '../../api/Request';
import { ResponseError } from 'superagent';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import { notification } from 'antd';
import renderLoading from '../SharesActions/renderLoading';

type Partner = any;
type Customer = any;

interface MatchParams {
  id: string;
}

interface PartnerEditProps extends RouteComponentProps<MatchParams> {}

interface PartnerEditState {
  redirectList: boolean;
  error?: ResponseError;
  loading: boolean;
  item?: Partner;
  customers: Customer[];
  editing: boolean;
}

class PartnerEdit extends Component<PartnerEditProps, PartnerEditState> {
  state: PartnerEditState = {
    redirectList: false,
    editing: false,
    loading: true,
    customers: [],
  };

  header = [{ key: 'name', width: 5, sort: false, title: 'Name' }];

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { id } = this.props.match.params;
    try {
      const [item, customers] = await Promise.all([
        Request.get('partners', id),
        Request.get('partners', id, 'customers', {
          sort: 'name',
          limit: 25000,
        }),
      ]);

      this.setState({
        loading: false,
        item,
        customers,
      });
    } catch (error: any) {
      if (error.status === 404) {
        this.setState({
          loading: false,
          redirectList: true,
        });
      } else {
        notification.error({
          message: 'Fehler beim Laden',
          description:
            'Der Partner konnte aufgrund eines Fehlers nicht geladen werden.',
        });
        this.setState({
          loading: false,
          error,
        });
      }
    }
  }

  async remove() {
    try {
      await Request.delete('partners', this.state.item._id);
      this.setState({ redirectList: true });
    } catch (error: any) {
      if (error.status === 409) {
        notification.error({
          message: 'Fehler beim Löschen',
          description:
            'Ein Partner mit verbundenen Kunden kann nicht gelöscht werden.',
        });
      } else {
        notification.error({
          message: 'Fehler beim Löschen',
          description:
            'Der Partner konnte aufgrund eines Fehlers nicht gelöscht werden.',
        });
        this.setState({
          loading: false,
          error,
        });
      }
    }
  }

  async save(key: string, value: any) {
    try {
      const data: any = {};
      data[key] = value;
      const item = await Request.put('partners', this.state.item._id, data);
      this.setState({ item });
    } catch (error: any) {
      // console.log(error);
      notification.error({
        message: 'Fehler beim Speichern',
        description: 'Die Änderung konnte nicht gespeichert werden.',
      });
      this.setState({
        loading: false,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  render() {
    const { item, customers } = this.state;
    if (this.state.redirectList)
      return <Redirect to='/administration/partners' />;
    if (!item) return renderLoading();
    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='partners container-inner'>
            <InPageNavigation to='/administration/partners' item={item} />
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col-6 col'>
                <h1 className='page-title'>{item.name}</h1>
              </div>
              <div className='col-6 col'>
                <RemoveButton
                  hidden={!hasPermission(['partner:write'])}
                  itemTypeText='Gruppe'
                  callback={() => this.remove()}
                />
              </div>
            </div>
            <FormConsumer>
              {({ isEditing }: { isEditing: boolean }) => (
                <div className={`page-content ${isEditing ? 'editing' : ''}`}>
                  <div className='row'>
                    <form className='col col-12 col-xs-6 col-md-6 col-lg-4 col-xl-4'>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Stammdaten</span>
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Name'
                            readOnly={hasPermission(['partner:write']) !== true}
                            value={item.name}
                            validate={(value: string) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 2
                            }
                            callback={(value: string) =>
                              this.save('name', value)
                            }
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='row row-divider-top'>
                    <div className='col col-12'>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Kunden</span>
                        </div>
                      </div>
                      {customers && customers.length > 0 ? (
                        <div className='h-300px'>
                          <Table
                            selectable={SELECTION_TYPE.disabled}
                            loading={this.state.loading}
                            header={this.header}
                            items={customers}
                            link={'/administration/customers'}
                          />
                        </div>
                      ) : (
                        <div className='row row-no-entries'>
                          <div className='col'>
                            <span>
                              Dieser Gruppe wurden noch keine Kunden zugeordnet.
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </FormConsumer>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default PartnerEdit;
