import mbxGeocoding, {
  GeocodeRequest,
} from '@mapbox/mapbox-sdk/services/geocoding';

import { find } from 'lodash';
import request from 'superagent';

const mbxGeocodingService = mbxGeocoding({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
});

class _Geocoding {
  _makeGeoJSON(coordinates: any) {
    return {
      type: 'Point',
      coordinates,
    };
  }

  async search(searchString: string): Promise<any[]> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?language=de&address=${encodeURI(
      searchString
    )}&key=AIzaSyAuOgAXv5idXQAKa2RX3av15ZJp_nGDYyw`;
    console.log(url);
    return request.get(url).then(({ body }) => {
      const { results } = body;
      return results.map((result: any) => {
        const t_country = find(result.address_components, (c) =>
          c.types.includes('country')
        );
        const t_region = find(result.address_components, (c) =>
          c.types.includes('administrative_area_level_1')
        );
        const t_city =
          find(result.address_components, (c) =>
            c.types.includes('locality')
          ) ??
          find(result.address_components, (c) =>
            c.types.includes('postal_town')
          );

        const t_postalCode = find(result.address_components, (c) =>
          c.types.includes('postal_code')
        );
        const t_street = find(result.address_components, (c) =>
          c.types.includes('route')
        );
        const t_street_number = find(result.address_components, (c) =>
          c.types.includes('street_number')
        );
        return {
          results,
          location: {
            type: 'Point',
            coordinates: [
              result.geometry.location.lng,
              result.geometry.location.lat,
            ],
          },
          city: t_city ? t_city.long_name : '',
          regionCode: t_region ? t_region.long_name : '',
          countryCode: t_country ? t_country.long_name : '',
          postalCode: t_postalCode ? t_postalCode.long_name : '',
          street: `${t_street ? t_street.long_name : ''} ${
            t_street_number ? t_street_number.long_name : ''
          }`,
        };
      });
    });
  }

  async find(searchString: string) {
    const request: GeocodeRequest = {
      query: searchString,
      language: ['de'],
      limit: 1,
      mode: 'mapbox.places',
    };
    const { features } = await mbxGeocodingService
      .forwardGeocode(request)
      .send()
      .then((res) => res.body);
    if (features.length === 0) return null;
    const { geometry, place_type, routable_points, context, address, text } =
      features[0] as any;
    const type = place_type[0];
    if (type === 'address') {
      const t_country = find(
        context,
        (c) => c.id && c.id.startsWith('country')
      );
      const t_region = find(context, (c) => c.id && c.id.startsWith('region'));
      const t_city = find(context, (c) => c.id && c.id.startsWith('place'));
      const t_postalCode = find(
        context,
        (c) => c.id && c.id.startsWith('postcode')
      );
      return {
        valid: true,
        data: {
          location:
            routable_points && routable_points.points.length > 0
              ? routable_points
              : geometry,
          city: t_city ? t_city.text : null,
          regionCode: t_region ? t_region.short_code : null,
          countryCode: t_country ? t_country.text : null,
          postalCode: t_postalCode ? t_postalCode.text : null,
          street: `${text} ${address}`,
        },
      };
    } else {
      return {
        valid: false,
        data: {
          location: geometry,
          city: null,
          regionCode: null,
          countryCode: null,
          postalCode: null,
          street: null,
        },
      };
    }
  }
}

const Geocoding = new _Geocoding();

export { Geocoding };
