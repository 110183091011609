import React, { FunctionComponent, useState } from 'react';
import StatusAction, {
  Status,
} from '../../common/CardList/Actions/StatusAction';
import { Job } from '../../../context/Route';
import { ReactComponent as UntilIcon } from '../../../assets/icons/until.svg';
import showModalInput from '../../ModalInput';
import { setUntil } from './utils/set-until';

interface UntilProps {
  job: Job;
  readonly?: boolean;
  onChanged?: () => void;
}

const Until: FunctionComponent<UntilProps> = ({ job, onChanged }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    const newUntil = await showModalInput(job.until, 'Ändern');
    if (newUntil !== undefined) {
      setLoading(true);
      await setUntil(job, newUntil);
      onChanged && onChanged();
      setLoading(false);
    }
  };

  return (
    <StatusAction
      loading={loading}
      noBackground
      state={job.until ? Status.success : Status.neutral}
      icon={<UntilIcon />}
      onClick={handleClick}
    >
      {job.until && <span>{job.until}</span>}
      {!job.until && <span>keine Endzeit</span>}
    </StatusAction>
  );
};

export default Until;
