import { Field, Line } from './fieldsDefault';
import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { flatten } from 'lodash';
import styled from 'styled-components';

const DeleteButton = styled.button`
  background: #fff;
  border: 0;
  box-shadow: none;
`;

const { Option } = Select;

interface RELSettingLineProps {
  line: Line;
  fields: { [group: string]: Field[] };
  loading: boolean;
  onChange: (line: Line) => void;
  onDelete: () => void;
}

export default function RELSettingLine(props: RELSettingLineProps) {
  const rawFields = flatten(Object.values(props.fields));
  const groups = Object.keys(props.fields).sort((b, a) => a.localeCompare(b));

  const rawField = rawFields.find((f) => f.key === props.line.source);

  const [group, setGroup] = useState<string | undefined>(rawField?.group);
  const [field, _setField] = useState<string | undefined>(rawField?.key);
  const [target, setTarget] = useState<string>(props.line.target);
  const [valid, setValid] = useState<boolean>(props.loading);

  const setField = (f: string) => {
    _setField(f);
    setTimeout(() => {
      props.onChange &&
        props.onChange({
          _id: props.line._id,
          source: f,
          target,
        });
    }, 50);
  };

  useEffect(() => {
    const checkLineValid = () => {
      setValid(!!target && target.length > 0 && !!field);
    };
    checkLineValid();
  }, [field, target]);

  return (
    <div
      id={props.line._id}
      className={classNames('row', 'rel-settings-row', {
        invalid: !props.loading && !valid,
      })}
      style={{
        padding: '16px 0',
        margin: '9px 0',
        background: '#ffffff',
        borderRadius: ' 3px',
      }}
    >
      <div className='col col-3'>
        <Input
          value={target}
          disabled={props.loading}
          placeholder='B'
          onBlur={() => {
            props.onChange &&
              props.onChange({
                _id: props.line._id,
                source: field || '',
                target,
              });
          }}
          onChange={(e) => setTarget(e.target.value)}
        />
      </div>
      <div className='col col-3'>
        <Select
          value={group}
          style={{ width: 320 }}
          disabled={props.loading}
          placeholder='Gruppe auswählen'
          optionFilterProp='children'
          filterOption={true}
          showSearch
          onChange={(value) => {
            setGroup(value);
            const g = props.fields[value];
            setField(g[0].key);
          }}
        >
          {groups.map((group, index) => (
            <Option key={`RELSettingLineOptioGroup-${index}`} value={group}>
              {group}
            </Option>
          ))}
        </Select>
      </div>
      <div className='col col-3'>
        {group && props.fields[group] && props.fields[group].length > 0 && (
          <Select
            optionFilterProp='children'
            filterOption={true}
            showSearch
            value={field}
            style={{ width: 320 }}
            disabled={props.loading}
            placeholder='Quälle auswählen'
            onChange={(value: string) => setField(value)}
          >
            {props.fields[group]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((f, index) => (
                <Option key={`RELSettingLineOption-${index}`} value={f.key}>
                  {f.name}
                </Option>
              ))}
          </Select>
        )}
      </div>
      <div className='col col-3'>
        <DeleteButton onClick={() => props.onDelete()}>Löschen</DeleteButton>
      </div>
    </div>
  );
}
