import './Modal.scss';

import React, { useState } from 'react';

import DirectInput from '../Form/DirectInput';
import Modal from 'react-modal';
import classNames from 'classnames';
import { withWidth } from '../helper/modalCustomStyles';

interface AddContractModalProps {
  isOpen: boolean;
  close: () => void;
  onSubmit: (value: number) => Promise<void>;
}

export default function AddContractModal(props: AddContractModalProps) {
  Modal.setAppElement('#root');

  const { isOpen, close, onSubmit } = props;

  const [contractValue, setContractValue] = useState<number>();

  const valid = () => {
    if (
      contractValue === undefined ||
      contractValue === null ||
      contractValue < 0
    )
      return false;
    return true;
  };

  const submitClasses = classNames('btn', 'btn-primary', {
    disabled: !valid(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => close && close()}
      style={withWidth('550px')}
      contentLabel=''
    >
      <div className='modal' tabIndex={-1} role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Restauftragswert festlegen</h5>
            </div>
            <div className='modal-body'>
              <div className='row table-row input-row'>
                <div className='col'>
                  <DirectInput
                    title='Preis'
                    type='currency'
                    value={contractValue}
                    validate={(value: number) =>
                      value != undefined && value >= 0
                    }
                    callback={(value: number) => {
                      setContractValue(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => close && close()}
              >
                Schließen
              </button>
              <button
                type='button'
                className={submitClasses}
                onClick={async () => {
                  if (onSubmit && valid()) {
                    await onSubmit(contractValue as number);
                    setContractValue(0);
                  }
                }}
              >
                Einstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
