import { Technician, Tour } from '../../context/Route';

import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { ReactComponent as TechnicianIcon } from '../../assets/icons/technician2.svg';

interface TourTechnicianProps {
  technician: Technician;
  tour: Tour;
}

export default function TourTechnician(props: TourTechnicianProps) {
  const { technician, tour } = props;
  return (
    <React.Fragment>
      <div className='route-step-icon'>
        <TechnicianIcon />
      </div>
      <div className='route-step-content'>
        <div className='row'>
          <div className='col col-8'>
            <p className='name'>{technician.name}</p>
            <p className='address'>
              {technician.address.street
                ? `${technician.address.street}, `
                : null}{' '}
              {technician.address.postalCode} {technician.address.city}
            </p>
          </div>
        </div>
      </div>
      <div className='route-step-actions extra'>
        <NavLink
          to={`/tours/${tour._id}/response/`}
          className='route-step-action route-step-action-feedback'
        >
          <CheckIcon />
          Rückerfassung
        </NavLink>
      </div>
    </React.Fragment>
  );
}
