import React, { FunctionComponent } from 'react';
import { HotelJob } from '../../../context/Route';
import Action from '../../common/CardList/Actions/Action';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { enterHotel } from '../Hotels/EnterHotalModal';
import { notification } from 'antd';
import { saveHotel } from './utils/save-hotel';
import { get } from 'lodash';
import StatusAction, {
  Status,
} from '../../common/CardList/Actions/StatusAction';

interface EnterHotelProps {
  job: HotelJob;
  readonly?: boolean;
  onChanged?: () => void;
}

const EnterHotel: FunctionComponent<EnterHotelProps> = ({ onChanged, job }) => {
  const ok = job.hotelName && job.hotelName.trim().length > 0 && job.address;

  const _enterHotel = async () => {
    const res = await enterHotel(
      job.hotelName
        ? {
            hotelName: job.hotelName,
            address: job.address,
          }
        : undefined
    );

    if (!res) return;

    try {
      await saveHotel(job, res);
      onChanged && onChanged();
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: get(
          error,
          'message',
          'Die Änderung konnte nicht gespeichert werden.'
        ),
      });
    }
  };

  return (
    <StatusAction
      state={ok ? Status.success : Status.warning}
      icon={<EditIcon />}
      onClick={() => {
        _enterHotel();
      }}
    >
      Hotel erfassen
    </StatusAction>
  );
};

export default EnterHotel;
