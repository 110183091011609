import React from 'react';
import { RouteInfo } from '../../../context/Route';
import numeral from 'numeral';

// switch between locales
numeral.locale('de');

function shortCurrency(value: number, smallFormat: string) {
  const f = value < 10000 ? smallFormat || '0,0' : '0.0a';
  return numeral(value).format(f);
}

export default function DrivingTime(props: {
  info?: RouteInfo;
  extra: boolean;
  loading?: boolean;
}) {
  // console.log("DrivingTime: ", props.loading);
  const a = ['Fahrzeit:'];
  if (props.loading) {
    a.push('berechne...');
  } else if (props.info && props.info.duration) {
    const v = props.info.duration / 3600;
    const v25 = (props.info.duration / 3600) * 0.25;
    const d = `${shortCurrency((v as number) + v25, '0.00')}h`;
    a.push(d);
    if (props.extra) {
      const e = `${shortCurrency(v25 as number, '0.00')}h`;
      a.push(`(inkl. ${e})`);
    }
  } else {
    a.push(`0h`);
  }
  return <span style={{ display: 'block' }}>{a.join(' ')}</span>;
}
