import { get, groupBy, map, merge, pick } from 'lodash';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Request } from '../../../api/Request';
import DateFilter from '../DateFilter';
import { TableOptions } from '../../Table/Table';
import CardList from '../../common/CardList/CardList';
import CardListGroup from '../../common/CardList/CardListGroup';
import CardListItem from '../../common/CardList/CardListItem';
import DateAction from '../../common/CardList/Actions/DateAction';
import StatusAction, {
  Status,
} from '../../common/CardList/Actions/StatusAction';
import qs from 'query-string';

import { ReactComponent as _CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as MapIcon } from '../../../assets/icons/pin.svg';
import { ReactComponent as TechIcon } from '../../../assets/icons/technician2.svg';
import { ReactComponent as NoteIcon } from '../../../assets/icons/info1.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import Action from '../../common/CardList/Actions/Action';
import { enterHotel } from './EnterHotalModal';
import { notification } from 'antd';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

interface ToursHotelsListProps {}

const CopyIcon = styled(_CopyIcon)`
  fill: #009842;
  color: #009842;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 0.5rem !important;
`;

export interface ToursHotelsListOptions extends TableOptions {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  technicians?: any[];
}

const defaultOptions: ToursHotelsListOptions = {
  sort: 'tour.tag',
  desc: false,
  page: 0,
  limit: 2500,
  dateRange: {
    startDate: DateTime.fromJSDate(new Date()).startOf('week').toJSDate(),
    endDate: DateTime.fromJSDate(new Date()).endOf('week').toJSDate(),
  },
};

const dateFromMillis = (m: string | number) => {
  return DateTime.fromMillis(Number(m)).toJSDate();
};

const ToursHotelsList: FunctionComponent<ToursHotelsListProps> = ({}) => {
  const { search } = useLocation();
  const [loading, setLoading] = useState<boolean>(true);

  const [items, setItems] = useState<{
    [key: string]: any;
  }>([]);

  const [options, setOptions] = useState<ToursHotelsListOptions | null>(null);

  useEffect(() => {
    const data = qs.parse(search);
    const s: ToursHotelsListOptions = {
      ...defaultOptions,
      dateRange: {
        startDate: data.startDate
          ? dateFromMillis(data.startDate as string)
          : defaultOptions.dateRange.startDate,
        endDate: data.endDate
          ? dateFromMillis(data.endDate as string)
          : defaultOptions.dateRange.endDate,
      },
    };
    setOptions(s);
  }, []);

  const fetchData = async () => {
    if (!options) return;

    let s: Pick<
      ToursHotelsListOptions,
      'sort' | 'desc' | 'page' | 'limit' | 'technicians'
    > & {
      startDate?: string;
      endDate?: string;
    } = pick(options, ['sort', 'desc', 'page', 'limit', 'technicians']);

    const _start = get(
      options,
      'dateRange.startDate',
      defaultOptions.dateRange.startDate
    );
    const _end = get(
      options,
      'dateRange.endDate',
      defaultOptions.dateRange.endDate
    );

    const startDate =
      _start !== undefined ? DateTime.fromJSDate(_start) : undefined;
    const endDate = _end !== undefined ? DateTime.fromJSDate(_end) : undefined;

    s = {
      ...s,
      startDate:
        startDate !== undefined && startDate.isValid
          ? startDate.toISO()
          : undefined,
      endDate:
        endDate !== undefined && endDate.isValid ? endDate.toISO() : undefined,
    };

    const result: {
      search: boolean;
      hotels: any[];
      total: number;
    } = await Request.list('hotels', s);

    setItems(groupBy(result.hotels, 'tour.tag'));
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [options]);

  useEffect(() => {
    // store options in query string
    if (!options) return;
    const data = qs.stringify({
      startDate: DateTime.fromJSDate(options.dateRange.startDate).toMillis(),
      endDate: DateTime.fromJSDate(options.dateRange.endDate).toMillis(),
    });
    window.history.pushState({}, '', `${window.location.pathname}?${data}`);
  }, [options]);

  const allHotelsAreBooked = (items: any[]) => {
    return items.every((item) => item.hotelName);
  };

  const _enterHotel = async (item: any) => {
    const res = await enterHotel(
      item.hotelName
        ? {
            hotelName: item.hotelName,
            address: item.address,
          }
        : undefined
    );

    if (!res) return;

    try {
      await Request.put('jobs', item._id, res, '', false);
      fetchData();
    } catch (error: any) {
      notification.error({
        message: 'Fehler beim Speichern',
        description: get(
          error,
          'message',
          'Die Änderung konnte nicht gespeichert werden.'
        ),
      });
    }
  };

  if (!options) return null;

  const formatAddress = (item: any) =>
    [
      get(item, 'address.street', '').trim(),
      `${get(item, 'address.postalCode', '').trim()} ${get(
        item,
        'address.city',
        ''
      ).trim()}`,
      `${get(item, 'address.countryCode', '').trim()}`,
    ].join(', ');

  return (
    <div className='tours container-inner'>
      <div className='page-header row justify-content-between'>
        <div className='col col-6'>
          <h1 className='page-title'>Hotels</h1>
          <div className='page-subtitle'>
            <DateFilter
              text='Zeitraum'
              range={{
                startDate: options.dateRange.startDate,
                endDate: options.dateRange.endDate,
              }}
              onChange={(value) => {
                const no = merge({}, options, { dateRange: value });
                setOptions(no);
              }}
            />
          </div>
        </div>
      </div>
      <div className='page-content'>
        <CardList loading={loading}>
          {map(items, (value, key) => {
            const allBooked = allHotelsAreBooked(value);
            return (
              <CardListGroup
                key={key}
                title={`Tour ${key} - ${value[0].technician.name}`}
                subtitle={allBooked ? 'Alle Hotels gebucht' : 'Buchung offen'}
                defaultOpen={!allBooked}
              >
                {map(value, (item, index) => (
                  <CardListItem
                    key={index}
                    leading={index + 1}
                    title={item.hotelName ?? 'Kein Hotel gebucht'}
                    subtitle={!item.address ? '' : formatAddress(item)}
                    action1={
                      <DateAction
                        format='date'
                        value={DateTime.fromISO(item.planedAt).toJSDate()}
                      />
                    }
                    action2={
                      <StatusAction
                        state={item.hotelName ? Status.success : Status.warning}
                        onClick={() => {
                          _enterHotel(item);
                        }}
                      >
                        {item.hotelName ? 'Hotel gebucht' : 'Buchung offen'}
                      </StatusAction>
                    }
                    action3={
                      <Action
                        onClick={() => {
                          _enterHotel(item);
                        }}
                      >
                        <EditIcon />
                        Hotel erfassen
                      </Action>
                    }
                  >
                    <p>
                      <MapIcon />
                      {get(item, 'nearByAddress', '')}
                      <CopyIcon
                        onClick={() => {
                          navigator.clipboard.writeText(
                            get(item, 'nearByAddress', '')
                          );
                          notification.open({
                            message: 'Zwischenablage',
                            description:
                              'Die Anschrift wurde in die Zwischenablage kopiert.',
                            icon: <CopyIcon style={{ color: '#009842' }} />,
                          });
                        }}
                      />
                    </p>
                    <p>
                      <NoteIcon />
                      {[get(item, 'comment'), get(item, 'note'), 'Keine Notiz']
                        .filter((f) => f && f.length > 0)
                        .slice(0, 2)
                        .join(' | ')}
                    </p>
                    <p>
                      <TechIcon />
                      {get(item, 'technician.name', '')}
                    </p>
                  </CardListItem>
                ))}
              </CardListGroup>
            );
          })}
        </CardList>
      </div>
    </div>
  );
};

export default ToursHotelsList;
