import { Tour } from '../../context/Route';
import React, { Component } from 'react';

import { AuthConsumer, AuthContext } from '../../context/AuthContext';
import { DateTime } from 'luxon';
import { ReactComponent as SendIcon } from '../../assets/icons/check.svg';
import { ReactComponent as CheckedIcon } from '../../assets/icons/check-circle.svg';
import classNames from 'classnames';

interface TourTechnicianPermissionGrantedProps {
  tour: Tour;
  permissionGranted?: any;
  onGrantPermission: () => Promise<void>;
  disabled?: boolean;
}

class TourTechnicianPermissionGranted extends Component<TourTechnicianPermissionGrantedProps> {
  canShare(user: any) {
    // const { tour } = this.props;
    return (
      user.group === 'super'
      //  &&
      // reduce(
      //   tour.jobs,
      //   (acc, job) => {
      //     if (job.isHotel) {
      //       return acc && (job as HotelJobBase).address && job.hotelName;
      //     } else {
      //       return acc && job.address;
      //     }
      //   },
      //   true
      // )
    );
  }

  render() {
    const { disabled, onGrantPermission, permissionGranted } = this.props;
    return (
      <AuthConsumer>
        {({ user }) => (
          <>
            <div className='route-step-icon'>
              <CheckedIcon />
            </div>
            <div className='route-step-content'>
              <div className='row'>
                <div className='col col-7'>
                  <p className='name'>Freigabe</p>
                  <p className='address'>
                    {permissionGranted && permissionGranted.at
                      ? `
                erteilt: ${DateTime.fromISO(permissionGranted.at)
                  .setLocale('de')
                  .toLocaleString({
                    month: '2-digit',
                    day: '2-digit',
                    weekday: 'short',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}`
                      : 'nicht erteilt'}
                  </p>
                </div>
              </div>
            </div>
            <div className='route-step-actions extra'>
              <button
                disabled={
                  (permissionGranted && permissionGranted.at) ||
                  disabled ||
                  !this.canShare(user)
                }
                hidden={
                  (permissionGranted && permissionGranted.at) ||
                  disabled ||
                  !this.canShare(user)
                }
                onClick={() => onGrantPermission()}
                className={classNames('route-step-action', {
                  success: this.canShare(user),
                })}
              >
                <SendIcon />
                Freigeben
              </button>
            </div>
          </>
        )}
      </AuthConsumer>
    );
  }
}

TourTechnicianPermissionGranted.contextType = AuthContext;

export default TourTechnicianPermissionGranted;
