import React, { FunctionComponent, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { Modal, notification } from 'antd';
import { Technician, Tour } from '../../context/Route';
import { get, reduce } from 'lodash';
import { Request } from '../../api/Request';
import styled from 'styled-components';

interface ChangeTechnicianModalProps {
  tour: Tour;
  onClose: () => void;
  onSubmit: (technician: string) => void;
}

const Items = styled.div``;
const Item = styled.div<{ active: boolean }>`
  padding: 1rem;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  color: ${({ active }) => (active ? '#009842' : 'rgba(0, 0, 0, 0.75)')};
  &:hover {
    color: ${({ active }) => (active ? '#009842' : '#000000')};
  }
`;

const Loading = styled.p`
  padding: 1rem;
  text-align: center;
`;
const Title = styled.p``;
const Address = styled.p``;

const ChangeTechnicianModal: FunctionComponent<ChangeTechnicianModalProps> = ({
  tour,
  onClose,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(true);
  const [technicians, setTechnicians] = useState<Technician[]>();
  const [selectedTechnician, selectTechnician] = useState<Technician>();

  useEffect(() => {
    setLoading(true);

    const skills = reduce(
      tour.jobs,
      (r, l) => {
        const tasks = (l as any).tasks;
        if (!tasks) return r;
        return {
          reg: r.reg || !!tasks.reg,
          hub: r.hub || !!tasks.hub,
          not: r.not || !!tasks.not,
          lt: r.lt || !!tasks.lt,
          gb: r.gb || !!tasks.gb,
          ma: r.ma || !!tasks.ma,
          mb: r.mb || !!tasks.mb,
          th: r.th || !!tasks.th,
          ovg: r.ovg || !!tasks.ovg || !!tasks.ovg2 || !!tasks.ovg4,
          ofg: r.ofg || !!tasks.ofg,
          ofa: r.ofa || !!tasks.ofa,
          roll: r.roll || !!tasks.roll,
          ebox: r.ebox || !!tasks.ebox,
          luft: r.luft || !!tasks.luft,
        };
      },
      {
        hub: false,
        not: false,
        reg: false,
        lt: false,
        gb: false,
        ma: false,
        mb: false,
        th: false,
        ovg: false,
        ofg: false,
        ofa: false,
        roll: false,
        ebox: false,
        luft: false,
      }
    );

    try {
      Request.list('planning/technicians/available', {
        sort: 'name',
        desc: false,
        limit: 25,
        page: 0,
        start: DateTime.fromISO(tour.startDate!)
          .set({ weekday: 1 })
          .startOf('day')
          .toISO(),
        end: DateTime.fromISO(tour.endDate!)
          .set({ weekday: 7 })
          .endOf('day')
          .toISO(),
        ...skills,
        locations: [],
      }).then((res) => {
        setTechnicians(res.items);
        setLoading(false);
      });
    } catch (error: any) {
      const msg = get(error, 'response.body.message');
      notification.error({
        message: 'Fehler',
        description: msg || error.message || JSON.stringify(error),
        duration: 10000,
      });
    }
  }, [tour]);

  console.log('loading', loading);

  return (
    <Modal
      className='tour-changeweek-modal'
      title='Techniker ersetzen'
      closable={true}
      keyboard={false}
      closeIcon={undefined}
      visible={true}
      onCancel={() => onClose()}
      onOk={() => {
        if (selectedTechnician) {
          onSubmit(selectedTechnician._id);
        }
      }}
      okButtonProps={{
        disabled: !selectedTechnician,
      }}
      width={286}
    >
      {loading && <Loading>Suche nach passenden Techniker...</Loading>}
      {!loading && technicians && technicians.length === 0 && (
        <Loading>Es konnte kein passender Techniker gefunden werden.</Loading>
      )}
      {!loading && (
        <Items>
          {technicians?.map((technician) => (
            <Item
              key={technician._id}
              active={
                selectedTechnician && technician._id === selectedTechnician._id
              }
              onClick={() => selectTechnician(technician)}
            >
              <Title>{technician.name}</Title>
              <Address>
                {technician.address.street
                  ? `${technician.address.street}, `
                  : null}{' '}
                {technician.address.postalCode} {technician.address.city}
              </Address>
            </Item>
          ))}
        </Items>
      )}
    </Modal>
  );
};

export default ChangeTechnicianModal;
