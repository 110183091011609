import './renderLoadingModal.scss';

import { ClipLoader } from 'react-spinners';
import { Button, Col, Modal, Row } from 'antd';
import React from 'react';

import { ReactComponent as OkIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/fail-circle.svg';
import { ReactComponent as IgnoreIcon } from '../../assets/icons/lock.svg';
import styled from 'styled-components';
import { map } from 'lodash';

export default function renderLoadingModal(text: string) {
  return (
    <Modal
      className='loading-modal'
      closable={false}
      keyboard={false}
      closeIcon={undefined}
      footer={[<span key='0'>{text}</span>]}
      visible={true}
    >
      <div
        className={`col`}
        style={{
          textAlign: 'center',
        }}
      >
        <ClipLoader size={80} color={'#009842'} loading={true} />
      </div>
    </Modal>
  );
}

export enum ListLoadingStatus {
  loading,
  ok,
  error,
  ignore,
}

const R = styled(Row)`
  padding: 0.5rem 0;
  border-bottom: solid 1px gray;
  text-align: left;
`;
const C = styled(Col)`
  float: right;
`;

const OK = styled(OkIcon)`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 3px 0;
  fill: #009842;
  color: #009842;
`;

const Error = styled(ErrorIcon)`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 3px 0;
  fill: #ff4d4d;
  color: #ff4d4d;
`;

const Ignore = styled(IgnoreIcon)`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 3px 0;
  fill: rgb(161, 161, 161);
  color: rgb(161, 161, 161);
`;

export function renderListLoadingModal(
  items: {
    [tag: number]: ListLoadingStatus;
  },
  title: string,
  complete: boolean,
  onClose: () => void
) {
  return (
    <Modal
      className='loading-modal'
      closable={false}
      keyboard={false}
      footer={
        <Button disabled={!complete} onClick={onClose}>
          Schließen
        </Button>
      }
      visible={true}
      title={title}
    >
      <div
        className={`col`}
        style={{
          textAlign: 'center',
        }}
      >
        {map(items, (status, tag) => (
          <R key={tag}>
            <Col flex={1}>Tour {tag}</Col>
            <C>
              {status === ListLoadingStatus.loading && (
                <ClipLoader size={20} color={'#009842'} loading={true} />
              )}
              {status === ListLoadingStatus.ok && <OK />}
              {status === ListLoadingStatus.error && <Error />}
              {status === ListLoadingStatus.ignore && <Ignore />}
            </C>
          </R>
        ))}
        {/* */}
      </div>
    </Modal>
  );
}
