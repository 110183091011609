import React, { Fragment, useEffect, useState } from 'react';

import AddContractModal from './AddContractModal';
import { DateTime } from 'luxon';
import EditableInput from '../Form/EditableInput';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as YesIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as NoIcon } from '../../assets/icons/fail-circle.svg';
import { Popconfirm } from 'antd';
import { Request } from '../../api/Request';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { Switch } from 'antd';
import styled from 'styled-components';

interface ContractsProps {
  location: string;
  _v: number;
}

interface ContractResult {
  contractsRefill: boolean;
  contractsRefillValue: number;
  contracts: ContractData[];
  contractValueSum: number;
  jobValueSum: number;
}

interface ContractData {
  price: number;
  auto: boolean;
  _id: string;
  changedAt: string;
}

const Actions = styled.div`
  display: flex;
  float: right;
  > :not(:last-child) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

const AW = styled.span`
  display: inline-block;
  line-height: 32px;
  font-size: 11px;
  font-weight: bold;
  color: #a1a1a1;
  padding: 4px 22px;
`;

const SetButton = styled.button`
  border: 0;
  outline: 0;
  background: transparent;
  margin: 0 40px;
  padding: 8px 0;
  svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 12px 3px 0;
    fill: rgb(0, 152, 66);
    color: rgb(0, 152, 66);
  }
`;

function prepareContract(contract: ContractData): ContractData {
  return {
    price: contract.price,
    auto: contract.auto,
    _id: contract._id,
    changedAt: DateTime.fromISO(contract.changedAt).toFormat('yyyy-LL-dd'),
  };
}

export default function Contracts(props: ContractsProps) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [state, setState] = useState<ContractResult>();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // console.log('LOAD ');
    setLoading(refreshKey === 0);
    Request.get('locations', props.location, 'contracts', {})
      .then((res: ContractResult) => {
        setState({
          ...res,
          contracts: res.contracts.map((c) => prepareContract(c)),
          contractsRefill: res.contractsRefill,
          contractsRefillValue: res.contractsRefillValue,
        });
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [props.location, refreshKey]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error occured.{JSON.stringify(error)}</div>;
  }

  const saveRefill = async (
    status: boolean | undefined,
    value: number | undefined
  ) => {
    await Request.put('locations', props.location, {
      contractsRefill: status,
      contractsRefillValue: value,
    });
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const addContract = async (contractTargetValue: number) => {
    try {
      // if first value infinity we have to remove it
      if (
        state &&
        state.contracts.length > 0 &&
        state.contracts[0].price === null
      ) {
        await remove(state.contracts[0]);
      }

      
      const contractValue =
        contractTargetValue -
        ((state?.contractValueSum ?? 0) - (state?.jobValueSum ?? 0));

      await Request.post(`/locations/${props.location}/contracts`, {
        contractValue,
      });

      setModalOpen(false);
      setRefreshKey((oldKey) => oldKey + 1);
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  };

  const remove = async (con: ContractData) => {
    try {
      await Request.delete(
        `/locations/${props.location}/contracts`,
        con._id,
        ''
      );
      setModalOpen(false);
      setRefreshKey((oldKey) => oldKey + 1);
    } catch (error: any) {
      setError(error);
    }
  };

  const renderRemoveButton = (con: ContractData) => {
    return (
      <Popconfirm
        placement='bottomRight'
        title='Sind Sie sich sicher?'
        onConfirm={() => remove(con)}
        okText='Ja'
        cancelText='Nein'
      >
        <p className='delete float-right'>
          <TrashIcon />
        </p>
      </Popconfirm>
    );
  };

  const renderContract = (con: ContractData) => {
    return (
      <div key={con._id} className='row tasks-row'>
        <div className='col col-5 col-md-5 col-xl-2'>
          <EditableInput
            title={`Erstellt`}
            type='date'
            value={con.changedAt}
            readOnly={true}
          />
        </div>
        <div className='col col-5 col-md-5 col-xl-2'>
          {con.price && (
            <EditableInput
              title={`Wert`}
              type='currency'
              value={con.price}
              readOnly={true}
            />
          )}
        </div>
        <div className='col col-5 col-md-5 col-xl-2'>
          <EditableInput
            title={`Auto`}
            highlight={true}
            defaultValue={'Nein'}
            value={con.auto ? 'Ja' : 'Nein'}
            readOnly={true}
          />
        </div>
      </div>
    );
  };

  const renderContractTotal = (conre: ContractResult) => {
    return (
      <div className='row tasks-row'>
        <Fragment>
          <div className='col col-5 col-md-5 col-xl-2'>
            <AW>Auftragswert</AW>
          </div>
          {!conre.contractValueSum && (
            <div className='col col-5 col-md-5 col-xl-8'>
              <p>
                Es wurden keine Aufträge angelegt. Dieser Standort hat dadurch
                kein Limit.
              </p>
            </div>
          )}
          {conre.contractValueSum && (
            <>
              <div className='col col-5 col-md-5 col-xl-2'>
                <EditableInput
                  title={`Summe`}
                  type='currency'
                  defaultValue={'0€'}
                  value={conre.contractValueSum}
                  readOnly={true}
                />
              </div>
              <div className='col col-5 col-md-5 col-xl-2'>
                <EditableInput
                  title={`Jobs`}
                  type='currency'
                  defaultValue={'0€'}
                  value={-conre.jobValueSum || 0}
                  readOnly={true}
                />
              </div>
              <div className='col col-5 col-md-5 col-xl-2'>
                <EditableInput
                  title={`Rest`}
                  highlight={true}
                  type='currency'
                  defaultValue={'0€'}
                  value={
                    Math.round(
                      100 * (conre.contractValueSum - conre.jobValueSum)
                    ) / 100
                  }
                  readOnly={true}
                />
              </div>
              <div className='col col-5 col-md-5 col-xl-2'></div>
            </>
          )}
          <div className='col col-5 col-md-5 col-xl-2'>
            <SetButton
              className='action add-contract float-right'
              onClick={() => setModalOpen(true)}
            >
              <EditIcon />
              Auftragswert einstellen
            </SetButton>
          </div>
        </Fragment>
      </div>
    );
  };

  const renderContractRefill = (conre: ContractResult) => {
    return (
      <div className='row tasks-row'>
        <Fragment>
          <div className='col col-5 col-md-5 col-xl-2'>
            <AW>Automatisches Auffüllen</AW>
          </div>
          <div className='col col-5 col-md-5 col-xl-2'>
            <EditableInput
              title={`Aktiv`}
              defaultValue='Nein'
              value={conre.contractsRefill ? 'Ja' : 'Nein'}
              readOnly={true}
            />
          </div>
          <div className='col col-5 col-md-5 col-xl-2'>
            <EditableInput
              title={`Wert`}
              type='currency'
              defaultValue={'0€'}
              value={conre.contractsRefillValue || 0}
              readOnly={false}
              callback={async (value: number) => {
                await saveRefill(undefined, value);
              }}
            />
          </div>
          <div className='col col-5 col-md-5 col-xl-2'></div>
          <div className='col col-5 col-md-5 col-xl-2'></div>
          <div className='col col-5 col-md-5 col-xl-2'>
            <SetButton
              className='action add-contract float-right'
              onClick={async () => {
                await saveRefill(!(state?.contractsRefill ?? false), undefined);
              }}
            >
              {state?.contractsRefill ? <NoIcon /> : <YesIcon />}
              {state?.contractsRefill ? 'Deaktivieren' : 'Aktivieren'}
            </SetButton>
          </div>
        </Fragment>
      </div>
    );
  };

  return (
    <Fragment>
      <div className='row mb-3'>
        <div className='col col-12'>
          <div className='row table-divider'>
            <div className='col'>
              <span>Aufträge</span>
            </div>
            <div className='col'>
              <Actions className=''>
                <div>
                  <Switch
                    checked={visible}
                    title='Bearbeiten'
                    onChange={(checked) => setVisible(checked)}
                  />
                  <span
                    style={{
                      marginLeft: '8px',
                    }}
                  >
                    Alle anzeigen
                  </span>
                </div>
              </Actions>
            </div>
          </div>
          {state && renderContractRefill(state)}
          {state && renderContractTotal(state)}
          {visible
            ? state?.contracts
                .filter((c) => c.price)
                .reverse()
                .map((s) => renderContract(s))
            : null}
        </div>
      </div>
      <AddContractModal
        isOpen={modalOpen}
        close={() => setModalOpen(false)}
        onSubmit={(v) => addContract(v)}
      />
    </Fragment>
  );
}
