import React, { Fragment } from 'react';

import { Location } from '../../../context/Route';
import { RouteInfo } from '../../../context/Route';
import numeral from 'numeral';
import { sumHours } from '../helper/sum';

// switch between locales
numeral.locale('de');

function shortCurrency(value: number, smallFormat: string) {
  const f = value < 10000 ? smallFormat || '0,0' : '0.0a';
  return numeral(value).format(f);
}

export default function SummeryWorkingTime(props: {
  info?: RouteInfo;
  locations: Location[];
  loading?: boolean;
}) {
  const a: string[] = [];
  if (props.loading) {
    a.push('berechne...');
  } else if (props.info && props.locations && props.locations.length > 0) {
    const dri = (props.info.duration || 0) / 3600;
    const wrk = sumHours(props.locations);
    const v = dri * 1.25 + wrk;
    const d = `${shortCurrency(v as number, '0.00')}h`;
    a.push(d);
  } else {
    a.push(`0h`);
  }
  return (
    <Fragment>
      <span>Arbeitszeit:</span>
      <span>{a.join(' ')}</span>
    </Fragment>
  );
}
