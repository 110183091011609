import React, { useEffect, useState } from 'react';

import { Interval } from 'luxon';
import NumberChart from '../Charts/NumberChart';
import { Request } from '../../../api/Request';
import classNames from 'classnames';
import renderLoading from '../../SharesActions/renderLoading';
import RemoveButton from './RemoveButton';
import EditButton from './EditButton';
export const defaultSize = {
  w: 2,
  h: 1,
  minW: 1,
  maxW: 4,
  minH: 1,
  maxH: 3,
};

export const defaultColors = ['#41a441', '#ffc34d'];
export const colorLabels = ['Geplant', 'offene Rückpflege'];

export default function PlaningChart(props: {
  delete?: () => void;
  edit?: () => void;
  dateRange: Interval;
  colors: string[];
}) {
  const usedColors =
    !props.colors || props.colors.length !== defaultColors.length
      ? defaultColors
      : props.colors;

  const [data, setData] = useState<
    | {
        count: number;
        countNotRnshrined: number;
      }
    | undefined
  >();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      Request.list(
        'dashboard-data/tours',
        {
          start: props.dateRange.start.toISO(),
          end: props.dateRange.end.toISO(),
        },
        false
      )
        .then(({ count, countNotRnshrined }) => {
          // handle success
          setData({ count, countNotRnshrined });
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(() => setLoading(false));
    };
    fetchData();
  }, [props.dateRange.end, props.dateRange.start]);

  const headerClass = classNames({
    'box-header': true,
    row: true,
    'justify-content-between': true,
    'has-title': true,
  });

  const editClass = classNames({
    box: true,
    'box-editing': false,
  });

  return (
    <div className={editClass}>
      <div className={headerClass}>
        <div className='col col-8'>
          <div className='box-title'>Planung</div>
          {props.edit != undefined ? (
            <EditButton onClick={() => props.edit!()} />
          ) : null}
          {props.delete != undefined ? (
            <RemoveButton onClick={() => props.delete!()} />
          ) : null}
        </div>
      </div>
      <div className='box-content'>
        {loading && renderLoading()}
        {!loading && (
          <div className='row'>
            <div
              className='col col-12 col-md-6'
              style={{
                fontSize: '16px',
                fill: usedColors[0],
                color: usedColors[0],
              }}
            >
              <NumberChart
                precise={false}
                value={data?.count || 0}
              ></NumberChart>
              <p
                style={{
                  display: 'block',
                  textAlign: 'center',
                  position: 'relative',
                  top: '-8px',
                }}
              >
                geplant
              </p>
            </div>
            <div
              className='col col-12 col-md-6'
              style={{
                fontSize: '16px',
                fill: usedColors[1],
                color: usedColors[1],
              }}
            >
              <NumberChart
                precise={false}
                value={data?.countNotRnshrined || 0}
              ></NumberChart>
              <p
                style={{
                  display: 'block',
                  textAlign: 'center',
                  position: 'relative',
                  top: '-8px',
                }}
              >
                offene Rückpflege
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
