import { get } from 'lodash';
import showEMailSelector from '../../../EMailSelector';
import { Request } from '../../../../api/Request';
import { message } from 'antd';
import { Job, Tour } from '../../../../context/Route';

export async function sendInfoToCustomer(
  job: Job,
  tour: Tour,
  userEmail: string
) {
  try {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const jobID = job._id;
    const tourID = tour._id;
    const location = job.location;

    const emails = [
      {
        email: location.email,
        source: 'Standort',
      },
      {
        email: location.email2,
        source: 'Standort 2',
      },
      {
        email: get(location, 'customer.email'),
        source: 'Kunde',
      },
      { email: userEmail, source: 'Meine E-Mail' },
      { email: 'info@allessafe.de', source: 'Alles Safe' },
      { email: 'doku@allessafe.de', source: 'Dokumentation' },
    ].map((e) => {
      return {
        ...e,
        disabled: !e.email || !emailRegex.test(e.email),
      };
    });

    await new Promise((resolve) => setTimeout(resolve, 50));

    const mails = await showEMailSelector(emails);

    if (mails) {
      await Request.put(
        'tours',
        tourID,
        { emails: mails },
        `share/customer/${jobID}`,
        false
      ).catch(() => {
        const msg = `Emailversand an den Kunden  war nicht erfolgreich!`;
        message.error(msg, 30);
      });
    }
  } catch (error: any) {
    console.error(error);
  }
}
