import './Errors.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as PlugIcon } from '../../assets/icons/plug.svg';

class Page404 extends Component {
  render() {
    return (
      <div className='container-flow error erorr-404'>
        <div className='row justify-content-center'>
          <div className='col mt-2'>
            <h1 className='text-truncate text-center'>SORRY!</h1>
            <div>
              <PlugIcon className='plug'></PlugIcon>
              <div className='plug-gd'></div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-6 mt-4'>
            <p className='text-center'>
              Diese Seite konnte nicht gefunden werden.
            </p>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12 mt-4 text-center'>
            <NavLink className='button' to='/'>
              Zum Dashboard
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Page404;
