import './Planning.scss';

import { Location, Route, RouteInfo, Technician } from '../../context/Route';
import {
  PlanningConsumer,
  PlanningProvider,
} from '../../context/PlanningContext';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get, last, reduce, uniq } from 'lodash';
import { sumHours, sumUmsatz } from './helper/sum';

import CheckTime from './WrapperInfo/CheckTime';
import { DateTime } from 'luxon';
import Distance from './WrapperInfo/Distance';
import DrivingTime from './WrapperInfo/DrivingTime';
import LocationsSelected from './WrapperInfo/LocationsSelected';
import PlanningStepComplete from './Steps/PlanningStepComplete';
import PlanningStepLocations from './Steps/PlanningStepLocations';
import PlanningStepTechnicians from './Steps/PlanningStepTechnicians';
import PlanningStepTour from './Steps/PlanningStepTour';
import PlanningStepperHeader from './PlanningStepperHeader';
import SummerySalesSum from './WrapperInfo/SummerySalesSum';
import SummeryWorkingTime from './WrapperInfo/SummeryWorkingTime';
import WorkingTime from './WrapperInfo/WorkingTime';
import planedInHolidays from './helper/jobPlanedInHolidays';

enum Step {
  'locations' = 0,
  'technicians' = 1,
  'route' = 2,
  'complete' = 3,
}

function getStepFromString(step: string): Step {
  if (step === 'locations') return Step.locations;
  if (step === 'technicians') return Step.technicians;
  if (step === 'route') return Step.route;
  if (step === 'complete') return Step.complete;
  return Step.locations;
}

type Tour = any;

type StepHeaderFunction<T> = (
  locations: Location[],
  technicians: Technician[],
  route: Route | undefined,
  planningWeek: Date | undefined,
  info: RouteInfo | undefined,
  loadingInfo: boolean
) => T;

export interface StepHeader {
  content: JSX.Element;
  text: string;
  subtext: StepHeaderFunction<JSX.Element>;
  info: StepHeaderFunction<JSX.Element>;
  canNext: StepHeaderFunction<boolean>;
  hasAlert: StepHeaderFunction<boolean>;
  url?: string;
}

interface MatchParams {
  step: string;
}

interface PlanningWrapperProps extends RouteComponentProps<MatchParams> {}

const locationSelectionAlert = (locations: Location[], duration: number) => {
  const umsatz = sumUmsatz(locations);
  const stunden = sumHours(locations) + duration;
  const umsatzZiel = Math.ceil(umsatz / 3000);
  const stundenZiel = Math.ceil(stunden / 40);
  if (umsatzZiel > 1 && umsatzZiel > stundenZiel)
    return 'Es wurde wurde das Umsatz-, aber nicht das Arbeitsstundenziel erreicht!';
  if (stundenZiel > 1 && stundenZiel > umsatzZiel)
    return 'Es wurde wurde das Arbeitsstunden-, aber nicht das Umsatzziel erreicht!';
  return null;
};

const techniciansSelectionAlert = (
  locations: Location[],
  technicians: Technician[]
) => {
  const stunden = sumHours(locations);
  const stundenZiel = Math.ceil(stunden / 40);
  if (stundenZiel > technicians.length)
    return 'Die Anzahl der Wochenstunden (40 pro Techniker) wird wohlmöglich überschritten!';
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkjobPlanedOnFixed = (tour: Tour, planningWeek: Date) => {
  return true;
  // return reduce(
  //   tour.jobs,
  //   (sj, job) => {
  //     const r = jobPlanedOnFixed(job);
  //     return sj || r;
  //   },
  //   false
  // );
};

const checkTimetable = (tour: Tour, planningWeek: Date) => {
  let dateBefore = DateTime.fromJSDate(planningWeek)
    .set({ weekday: 1 })
    .startOf('day');
  return reduce(
    tour.jobs,
    (sj, job) => {
      const d = DateTime.fromISO(job.planedAt);
      const compD = job.planedAtTimeByUser
        ? d
        : d.set({ millisecond: 0, second: 0, minute: 1, hour: 0 });
      const compB = job.planedAtTimeByUser
        ? dateBefore
        : dateBefore.set({
            millisecond: 0,
            second: 0,
            minute: 0,
            hour: 0,
          });
      const r = sj && (job.type === 0 || (d.isValid && compD > compB));
      if (d.isValid && job.type > 0) dateBefore = d;
      return (
        r &&
        !planedInHolidays(job, job.holidays) &&
        !planedInHolidays(job, tour.technician.holidays)
      );
    },
    true
  );
};

const checkLastDateisInPlaningWeek = (tour: Tour, planningWeek: Date) => {
  if (!tour) return true;
  const lastStop: { planedAt: string } | undefined = last(tour.jobs);
  if (!lastStop) return false;
  const d = DateTime.fromISO(lastStop.planedAt);
  const lastDate = DateTime.fromJSDate(planningWeek)
    .set({ weekday: 7 })
    .endOf('day');
  return d < lastDate;
};

const checkOfftime = (tour: Tour) => {
  if (!tour) return true;

  const overlappingOfftime = uniq(
    tour.technician.overlappingOfftime.map((d: string) =>
      DateTime.fromISO(d).startOf('day').toISO()
    )
  );

  const jobsPlanedAt = uniq(
    tour.jobs.map(({ planedAt }: any) =>
      DateTime.fromISO(planedAt).startOf('day').toISO()
    )
  );

  const l = [...overlappingOfftime, ...jobsPlanedAt];
  const q = uniq(l);

  return l.length === q.length;

  // const test = (item: JobWithLeg | HotelJobWithLeg) => {
  //   const a = overlappingOfftime.filter((o: DateTime) => {
  //     const b = DateTime.fromISO(item.planedAt as string).startOf('day');
  //     return (o as any).ts === (b as any).ts;
  //   });
  //   return a.length > 0;
  // };

  return false;
};

interface PlanningWrapperState {
  currentStep: Step;
}

class PlanningWrapper extends Component<
  PlanningWrapperProps,
  PlanningWrapperState
> {
  state = {
    currentStep: Step.locations,
  };

  steps: StepHeader[] = [
    {
      text: 'Standorte auswählen',
      subtext: (
        locations: Location[],
        technicians: Technician[],
        tour: Tour | undefined,
        planningWeek: Date | undefined,
        info: RouteInfo | undefined,
        loadingInfo: boolean
      ) => {
        return (
          <div className='two-lines'>
            <LocationsSelected locations={locations} loading={loadingInfo} />
            <Distance info={info} extra={false} loading={loadingInfo} />
            <DrivingTime info={info} extra={false} loading={loadingInfo} />
            <CheckTime locations={locations} loading={loadingInfo} />
          </div>
        );
      },
      info: (
        locations: Location[],
        technicians: Technician[],
        tour: Tour | undefined,
        planningWeek: Date | undefined,
        info: RouteInfo | undefined,
        loadingInfo: boolean
      ) => {
        return (
          <PlanningConsumer>
            {({ info }) => {
              if (!info) return <span></span>;
              const duration = Math.floor(info.duration / 3600);
              const alert = locationSelectionAlert(locations, duration);
              return (
                <div className={`${alert ? 'steps-info-alert' : ''}`}>
                  {alert !== null ? (
                    <div className='alert-inner'>
                      {/* <WarningIcon /> */}
                      <div>
                        <p>
                          <b>Achtung</b>
                        </p>
                        <p>{alert}</p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div>
                    <p>
                      <SummerySalesSum
                        locations={locations}
                        loading={loadingInfo || false}
                      />
                    </p>
                    <p>
                      <SummeryWorkingTime
                        info={info}
                        locations={locations}
                        loading={loadingInfo || false}
                      />
                    </p>
                  </div>
                </div>
              );
            }}
          </PlanningConsumer>
        );
      },
      content: <PlanningStepLocations />,
      canNext: (locations: Location[]) => locations.length > 0,
      hasAlert: (locations: Location[]) =>
        locationSelectionAlert(locations, 0) !== null,
      url: 'locations',
    },
    {
      text: 'Techniker auswählen',
      subtext: () => {
        return (
          <div className='two-lines'>
            {/* <Distance info={info} extra={false} loading={loadingInfo} />
            <DrivingTime info={info} extra={false} loading={loadingInfo} />
            <CheckTime locations={locations} loading={loadingInfo} /> */}
          </div>
        );
      },
      info: (
        locations: Location[],
        technicians: Technician[],
        tour: Tour | undefined,
        planningWeek: Date | undefined,
        info: RouteInfo | undefined,
        loadingInfo: boolean
      ) => {
        const alert = techniciansSelectionAlert(locations, technicians);
        return (
          <div
            className={`${
              technicians.length > 0 && alert ? 'steps-info-alert' : ''
            }`}
          >
            {technicians.length > 0 && alert !== null ? (
              <div className='alert-inner'>
                {/* <WarningIcon /> */}
                <div>
                  <p>
                    <b>Achtung</b>
                  </p>
                  <p>{alert}</p>
                </div>
              </div>
            ) : (
              ''
            )}
            <div>
              <p>
                <SummerySalesSum
                  locations={locations}
                  loading={loadingInfo || false}
                />
              </p>
              <p>
                <SummeryWorkingTime
                  info={info}
                  locations={locations}
                  loading={loadingInfo || false}
                />
              </p>
            </div>
          </div>
        );
      },
      content: <PlanningStepTechnicians />,
      canNext: (locations: Location[], technicians: Technician[]) => {
        const stunden = sumHours(locations);
        const stundenZiel = Math.max(1, Math.ceil(stunden / 40));
        return (
          technicians.length > 0 &&
          technicians.length <= stundenZiel &&
          technicians.length <= 1
        );
      },
      hasAlert: (locations: Location[], technicians: Technician[]) =>
        technicians.length > 0 &&
        techniciansSelectionAlert(locations, technicians) !== null,
      url: 'technicians',
    },
    {
      text: 'Tour planen',
      subtext: (
        locations: Location[],
        technicians: Technician[],
        tour: Tour | undefined,
        planningWeek: Date | undefined,
        info: RouteInfo | undefined,
        loadingInfo: boolean
      ) => {
        return (
          <PlanningConsumer>
            {({ route }) => {
              const info = route ? route : { distance: 0, duration: 0 };
              return (
                <div className='two-lines'>
                  <Distance
                    info={info}
                    extra={true}
                    loading={loadingInfo || false}
                  />
                  <DrivingTime
                    info={info}
                    extra={true}
                    loading={loadingInfo || false}
                  />
                  <WorkingTime
                    info={info}
                    locations={locations}
                    loading={loadingInfo || false}
                  />
                </div>
              );
            }}
          </PlanningConsumer>
        );
      },
      content: <PlanningStepTour />,
      info: (
        locations: Location[],
        technicians: Technician[],
        tour: Tour | undefined,
        planningWeek: Date | undefined
      ) => {
        if (!tour || !planningWeek) return <span></span>;
        const errorTexts: string[] = [];
        if (!checkjobPlanedOnFixed(tour, planningWeek)) {
          errorTexts.push('Termine prüfen!');
        }
        if (!checkTimetable(tour, planningWeek)) {
          errorTexts.push('Zeitplan prüfen!');
        }
        if (!checkLastDateisInPlaningWeek(tour, planningWeek)) {
          errorTexts.push('Enddatum liegt außerhalb der KW');
        }
        if (errorTexts.length === 0) {
          return <span></span>;
        }
        return (
          <div className='steps-info-alert'>
            <div className='alert-inner'>
              {/* <WarningIcon /> */}
              <div>
                <p>
                  <b>Achtung</b>
                </p>
                {errorTexts.map((errorText, i) => (
                  <p key={i}>{errorText}</p>
                ))}
              </div>
            </div>
          </div>
        );
      },
      canNext: (
        locations: Location[],
        technicians: Technician[],
        tour: Tour | undefined,
        planningWeek: Date | undefined
      ) => {
        if (!tour || !planningWeek) return false;
        const _checkjobPlanedOnFixed = checkjobPlanedOnFixed(
          tour,
          planningWeek
        );
        const _checkTimetable = checkTimetable(tour, planningWeek);
        const _checkLastDateisInPlaningWeek = checkLastDateisInPlaningWeek(
          tour,
          planningWeek
        );
        const _checkOfftime = checkOfftime(tour);

        return (
          tour &&
          _checkjobPlanedOnFixed &&
          _checkTimetable &&
          _checkLastDateisInPlaningWeek &&
          _checkOfftime
        );
      },
      hasAlert: (
        locations: Location[],
        technicians: Technician[],
        tour: Tour | undefined,
        planningWeek: Date | undefined
      ) => {
        if (!tour || !planningWeek) return false;
        return !(
          tour &&
          checkjobPlanedOnFixed(tour, planningWeek) &&
          checkTimetable(tour, planningWeek) &&
          checkLastDateisInPlaningWeek(tour, planningWeek)
        );
      },
      url: 'route',
    },
    {
      text: 'Abschließen',
      subtext: () => <span></span>,
      info: () => <span></span>,
      content: <PlanningStepComplete />,
      canNext: () => true,
      hasAlert: () => false,
      url: 'complete',
    },
  ];

  constructor(props: PlanningWrapperProps) {
    super(props);
    this.state.currentStep = getStepFromString(props.match.params.step);
  }

  componentDidUpdate(prevProps: PlanningWrapperProps) {
    const newStep = get(this.props, 'match.params.step');
    const prevStep = get(prevProps, 'match.params.step');
    if (newStep !== prevStep) {
      // console.log('componentDidUpdate');
      const currentStep = getStepFromString(newStep);
      this.setState({ currentStep });
    }
  }

  currentContent() {
    return this.steps[this.state.currentStep].content;
  }

  render() {
    const { currentStep } = this.state;
    const lastStep = currentStep + 1 === this.steps.length;
    return (
      <PlanningProvider>
        <div className='planning-wrapper'>
          <div className={`planning-stepper-content ${lastStep ? 'mr-0' : ''}`}>
            {this.currentContent()}
          </div>
          {!lastStep && (
            <PlanningStepperHeader
              currentStep={currentStep}
              steps={this.steps}
            />
          )}
        </div>
      </PlanningProvider>
    );
  }
}

export default withRouter(PlanningWrapper);
