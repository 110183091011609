import { MenuItemWithTo } from './Header';
import { NavLink } from 'react-router-dom';
import ProtectedLink from '../../Routes/ProtectedLink';
import React from 'react';
import { checkActive } from './util';
import classNames from 'classnames';

interface LinkItemProps {
  item: MenuItemWithTo;
  styleName?: string;
  level: number;
}

export default function LinkItem(props: LinkItemProps) {
  const { text, to, Icon, permissions } = props.item;
  const Component = Icon as React.ElementType;
  return (
    <ProtectedLink requiredPermission={permissions}>
      <li
        className={classNames(
          'nav-item',
          `nav-item-level-${props.level}`,
          props.styleName
        )}
      >
        <NavLink
          className='nav-link'
          activeClassName='active'
          isActive={checkActive}
          exact={true}
          to={to}
        >
          <Component className='nav-icon' />
          <span>{text}</span>
        </NavLink>
      </li>
    </ProtectedLink>
  );
}
