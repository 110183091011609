import './NavLinkInput.scss';

import React, { Component } from 'react';

import { FormConsumer } from '../../context/FormContext';
import { NavLink } from 'react-router-dom';

class NavLinkInput extends Component {
  render() {
    const { title, link } = this.props;
    return (
      <FormConsumer>
        {() => (
          <div className='nav-link-input'>
            <NavLink to={link} className=''>
              {this.props.children}
              {title}
            </NavLink>
          </div>
        )}
      </FormConsumer>
    );
  }
}

export default NavLinkInput;
