import React, { FunctionComponent } from 'react';
import Filter from '../../../Tours/Filter';

interface MapBoundsFilterProps {
  useBounds: boolean;
  onChange: (useBounds: boolean) => void;
  disabled?: boolean;
}

const MapBoundsFilter: FunctionComponent<MapBoundsFilterProps> = ({
  useBounds,
  onChange,
  disabled,
}) => {
  return (
    <Filter
      size='14rem'
      disabled={disabled}
      name='useBounds'
      options={{
        useBounds: useBounds ? 'true' : 'false',
      }}
      multiple={false}
      required={true}
      isActive={(options) =>
        options.useBounds === 'true' || options.useBounds === true
      }
      values={[
        {
          key: 'true',
          value: 'true',
          text: 'Kartenausschnitt verwenden',
        },
        {
          key: 'false',
          value: 'false',
          text: 'Kartenausschnitt ignorieren',
        },
      ]}
      onChange={(options) => {
        onChange(options.useBounds === 'true');
      }}
    >
      {useBounds ? 'Kartenausschnitt verwenden' : 'Kartenausschnitt ignorieren'}
    </Filter>
  );
};

export default MapBoundsFilter;
