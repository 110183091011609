import { useFormikContext } from 'formik';
import { reduce } from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';
import { IFormikValues } from './Response';

interface WatchWorkingHoursProps {}

const WatchWorkingHours: FunctionComponent<WatchWorkingHoursProps> = ({}) => {
  const { values, setFieldValue } = useFormikContext<IFormikValues>();

  const validNumberOrFallback = (
    value: number | undefined | string,
    fallback: number
  ): number => {
    // console.log('validNumberOrFallback', value, fallback);
    if (value === undefined || value === null || value === '' || value === 0) {
      return fallback;
    }
    if (typeof value === 'string') {
      return parseFloat(value);
    }
    return value;
  };

  useEffect(() => {
    console.log('WatchWorkingHours');
    // initial value so that the form is not dirty
    if (values.timestamp === 0) return;
    debugger;
    //
    const totalActualWorkingHours = reduce(
      values.jobs,
      (acc, job) =>
        acc +
        (job.status === 'completed' || job.status === 'mixed'
          ? validNumberOrFallback(job.actualWorkingHours, job.planHours)
          : 0),
      0
    );
    setFieldValue('actualWorkingHours', totalActualWorkingHours);
  }, [values]);

  return <></>;
};

export default WatchWorkingHours;
