import './Header.scss';

import React, { Component } from 'react';

import { ReactComponent as ControlIcon } from '../../../assets/icons/control.svg';
import { ReactComponent as CustomerIcon } from '../../../assets/icons/customer.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/icons/dashboard.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as NotificationIcon } from '../../../assets/icons/notification.svg';
import DropdownItem from './DropdownItem';
import { ReactComponent as HolidayIcon } from '../../../assets/icons/holiday.svg';
import { ReactComponent as HotelIcon } from '../../../assets/icons/sleep.svg';
import LinkItem from './LinkItem';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import NavDivider from './NavDivider';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PartnerIcon } from '../../../assets/icons/partner.svg';
import { ReactComponent as PlanIcon } from '../../../assets/icons/plan.svg';
// import { ReactComponent as SettingsIcon } from '../../../assets/icons/technician.svg';
import { ReactComponent as TechIcon } from '../../../assets/icons/technician2.svg';
import { ReactComponent as ToursIcon } from '../../../assets/icons/tours.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import User from './User/User';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import isStaging from '../../helper/DevDetect';
import GlobalSearch from '../../Search/GlobalSearch';

export interface MenuItemWithChildren {
  type: 'dropdown';
  text: string;
  children: MenuItem[];
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  permissions?: string[];
  isActivePrefix: string;
}

export interface MenuItemWithTo {
  type: 'link';
  text: string;
  to: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  permissions?: string[];
}

export interface MenuItemDivider {
  type: 'divider';
}

export type MenuItem = MenuItemWithChildren | MenuItemWithTo | MenuItemDivider;

const data: MenuItem[] = [
  {
    type: 'link',
    text: 'Dashboard',
    to: '',
    Icon: DashboardIcon,
  },
  {
    type: 'link',
    text: 'Planung',
    to: '/planning/locations',
    Icon: PlanIcon,
    permissions: ['tour:write'],
  },
  {
    type: 'link',
    text: 'Auswertung',
    to: '/analysis/locations',
    Icon: DashboardIcon,
    permissions: ['tour:read'],
  },
  {
    type: 'dropdown',
    text: 'Touren',
    Icon: ToursIcon,
    isActivePrefix: '/tours',
    children: [
      {
        type: 'link',
        text: 'Übersicht',
        to: '/tours',
        Icon: ToursIcon,
        permissions: ['tour:read'],
      },
      {
        type: 'link',
        text: 'Standorte',
        to: '/locations',
        Icon: LocationIcon,
        permissions: ['tour:read'],
      },
      {
        type: 'link',
        text: 'Hotels',
        to: '/hotels',
        Icon: HotelIcon,
        permissions: ['tour:read'],
      },
    ],
  },
  {
    type: 'dropdown',
    text: 'Verwaltung',
    Icon: ControlIcon,
    isActivePrefix: '/administration',
    children: [
      {
        type: 'link',
        text: 'Standorte',
        to: '/administration/locations',
        Icon: LocationIcon,
        permissions: ['location:read', 'location:read:own'],
      },
      {
        type: 'link',
        text: 'Kunden',
        to: '/administration/customers',
        Icon: CustomerIcon,
        permissions: ['customer:read', 'customer:read:own'],
      },
      {
        type: 'link',
        text: 'Kundengruppen',
        to: '/administration/customer-groups',
        Icon: CustomerIcon,
        permissions: ['customer-group:read', 'customer-group:read:own'],
      },
      {
        type: 'link',
        text: 'Partner',
        to: '/administration/partners',
        Icon: PartnerIcon,
        permissions: ['partner:read', 'partner:read:own'],
      },
      {
        type: 'divider',
      },
      {
        type: 'link',
        text: 'Techniker',
        to: '/administration/technicians',
        Icon: TechIcon,
        permissions: ['technician:read'],
      },
      {
        type: 'link',
        text: 'Urlaub',
        to: '/administration/offtime',
        Icon: HolidayIcon,
        permissions: ['holidays:read'],
      },
      {
        type: 'divider',
      },
      {
        type: 'link',
        text: 'Import',
        to: '/administration/import',
        Icon: UploadIcon,
        permissions: ['import'],
      },
      {
        type: 'link',
        text: 'Export',
        to: '/export',
        Icon: DownloadIcon,
        permissions: ['import'],
      },
      {
        type: 'link',
        text: 'REL',
        to: '/rel',
        Icon: DownloadIcon,
        permissions: ['import'],
      },
      {
        type: 'divider',
      },
      {
        type: 'link',
        text: 'Benachrichtigungen',
        to: '/administration/email-templates',
        Icon: NotificationIcon,
        permissions: ['email-templates:read'],
      },
      {
        type: 'link',
        text: 'User',
        to: '/administration/users',
        Icon: UserIcon,
        permissions: ['user:read'],
      },
    ],
  },
];

class Header extends Component {
  renderLink(index: number, item: MenuItemWithTo) {
    return <LinkItem level={0} key={index} item={item}></LinkItem>;
  }

  renderDropdown(index: number, item: MenuItemWithChildren) {
    return <DropdownItem level={0} key={index} item={item}></DropdownItem>;
  }

  renderDivider(index: number) {
    return <NavDivider key={index}></NavDivider>;
  }

  renderMenuItem(index: number, item: MenuItem) {
    if (item.type === 'dropdown') return this.renderDropdown(index, item);
    if (item.type === 'link') return this.renderLink(index, item);
    if (item.type === 'divider') return this.renderDivider(index);
  }

  render() {
    return (
      <header>
        <nav
          className='navbar navbar-expand-lg fixed-top'
          style={{
            backgroundColor: isStaging() ? '#66ffa8' : '#ffffff',
          }}
        >
          <NavLink className='navbar-brand' to=''>
            <Logo />
          </NavLink>
          <button
            className='navbar-toggler'
            data-toggle='collapse'
            data-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <GlobalSearch />
          <div className='collapse navbar-collapse' id='navbarCollapse'>
            <ul className='navbar-nav'>
              {data.map((item, index) => this.renderMenuItem(index, item))}
            </ul>
          </div>
          <User />
        </nav>
      </header>
    );
  }
}

export default Header;
