import './Search.scss';

import { Input, Popover } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Request } from '../../api/Request';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as TechIcon } from '../../assets/icons/technician2.svg';
import { ReactComponent as CustomerIcon } from '../../assets/icons/customer.svg';
import { ReactComponent as ToursIcon } from '../../assets/icons/tours.svg';

import styled from 'styled-components';

import {
  BehaviorSubject,
  distinctUntilChanged,
  debounceTime,
  switchMap,
  of,
  catchError,
  map,
  merge,
} from 'rxjs';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';

interface SearchProps {}

const Wrapper = styled.div`
  position: relative;
`;

const Results = styled.div<{
  open: boolean;
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  left: 0px;
  top: 36px;
  min-height: 100px;
  min-width: 440px;
  z-index: 10000;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.5rem;
  color: #000;
  outline: 0;
  padding: 0.5rem 0;
  overflow-y: auto;
  > div {
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
    :last-child {
      border-bottom: 0;
    }
  }
`;

const GroupWrapper = styled.div`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
`;

const GroupTitle = styled.div`
  color: rgba(0, 0, 0, 0.375);
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

const GroupItem = styled.div`
  color: #000;
  font-size: 0.75rem;

  padding: 0.25rem;
  max-width: 100%;
  overflow: hidden;

  svg {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }

  > a {
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    max-width: 100%;
    overflow: hidden;
  }

  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000;
    font-size: 0.75rem;
    text-decoration: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    max-width: 100%;
    overflow: hidden;
  }

  &:hover {
    color: #009842;
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #009842;
    }
  }
`;

export interface SearchState {
  items: [];
  loading: boolean;
  errorMessage: string | undefined;
  noResults: boolean;
  active: boolean;
}

const Group: FunctionComponent<{
  query: string;
  title: string;
  url: string;
  render: (value: any) => React.ReactNode;
}> = ({ query, title, url, render }) => {
  const [searchSubject, setSearchSubject] =
    useState<BehaviorSubject<string> | null>(null);

  const [searchState, setSearchState] = useState<SearchState>({
    items: [],
    loading: false,
    errorMessage: undefined,
    noResults: false,
    active: false,
  });

  useEffect(() => {
    if (searchSubject && !searchSubject.closed) {
      return searchSubject.next(query);
    }
  }, [query]);

  //
  const onSearch = async (term: string) => {
    const result: {
      search: boolean;
      items: any[];
      total: number;
    } = await Request.list(url, { q: term, take: 3 }, false).catch((e) => {
      console.error(e);
      return { search: false, items: [], total: 0 };
    });

    //
    return {
      items: result.items.slice(0, 3),
    };
  };

  /**
   * Initialize search subject
   */
  useEffect(() => {
    if (searchSubject === null) {
      const sub = new BehaviorSubject('');
      setSearchSubject(sub);
    } else {
      const observable = searchSubject
        .pipe(
          map((s) => s.toLowerCase()),
          map((s) => {
            return s;
          }),
          map((s) => s.trim()),
          distinctUntilChanged(),
          debounceTime(250),
          switchMap((term) => {
            if (!term || term.length === 0) {
              return of({
                items: [],
                loading: false,
                errorMessage: undefined,
                noResults: false,
                active: false,
              });
            }

            return merge(
              of({
                items: [],
                loading: true,
                errorMessage: undefined,
                noResults: false,
                active: true,
              }),
              onSearch(term).then(({ items }) => ({
                items,
                loading: false,
                errorMessage: undefined,
                noResults: true,
                active: true,
              }))
            );
          }),
          catchError((e: any) =>
            of({
              items: [],
              loading: false,
              errorMessage: e.message,
              noResults: true,
              active: true,
            })
          )
        )
        .subscribe((newState) => {
          setSearchState((s) => Object.assign({}, s, newState));
        });

      return () => {
        if (observable) observable.unsubscribe();
        if (searchSubject) searchSubject.unsubscribe();
        setSearchSubject(null);
      };
    }
  }, [searchSubject]);

  if (searchState.items.length === 0) {
    return <></>;
  }

  return (
    <GroupWrapper>
      <GroupTitle>{title}</GroupTitle>
      {searchState.loading ? <GroupItem>Suchen...</GroupItem> : null}
      {searchState.items.map((item, i) => (
        <GroupItem key={i}>
          {item !== undefined ? render(item) : false}
        </GroupItem>
      ))}
    </GroupWrapper>
  );
};

const GlobalSearch: FunctionComponent<SearchProps> = ({}) => {
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
  };

  return (
    <Wrapper>
      <Results open={value.length > 0 && focus}>
        <Group
          query={value}
          title={'Touren'}
          url={'/search-tours'}
          render={(item) => {
            return (
              <NavLink to={`/tours/${item._id}`}>
                <ToursIcon />
                <div>
                  <h5>
                    Tour {item.tag} |{' '}
                    {DateTime.fromISO(item.start).toFormat("'KW' WW '-' yyyy")}
                  </h5>
                  <p>{item.technician.name}</p>
                </div>
              </NavLink>
            );
          }}
        />
        <Group
          query={value}
          title={'Standort'}
          url={'/locations'}
          render={(item) => {
            return (
              <NavLink to={`/administration/locations/${item._id}`}>
                <LocationIcon />
                <div>
                  <h5>
                    {item.name} [{item.tag}]
                  </h5>
                  <p>
                    {item.address.street}, {item.address.postalCode}{' '}
                    {item.address.city}
                  </p>
                </div>
              </NavLink>
            );
          }}
        />
        <Group
          query={value}
          title={'Techniker'}
          url={'/technicians'}
          render={(item) => {
            return (
              <NavLink to={`/administration/technicians/${item._id}`}>
                <TechIcon />
                <div>
                  <h5>{item.name}</h5>
                  <p>
                    {item.address.street}, {item.address.postalCode}{' '}
                    {item.address.city}
                  </p>
                </div>
              </NavLink>
            );
          }}
        />
        <Group
          query={value}
          title={'Kunden'}
          url={'/customers'}
          render={(item) => {
            return (
              <NavLink to={`/administration/customers/${item._id}`}>
                <CustomerIcon />
                <div>
                  <h5>{item.name}</h5>
                </div>
              </NavLink>
            );
          }}
        />
      </Results>
      <Input.Group compact className='search'>
        <SearchIcon style={{ margin: '8px 4px 8px 0' }} />
        <Input
          onFocus={() => setFocus(true)}
          onBlur={() => {
            setTimeout(() => {
              // setFocus(false);
              setValue('');
            }, 150);
          }}
          placeholder={'Suche'}
          allowClear
          value={value}
          onChange={handleInputChange}
        />
      </Input.Group>
    </Wrapper>
  );
};

export default GlobalSearch;
