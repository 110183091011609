import React, { FunctionComponent } from 'react';
import RELSettingLine from './RelSettingLine';
import { Field, Line } from './fieldsDefault';

interface SortableListProps {
  lines: Line[];
  fields: { [group: string]: Field[] };
  loading: boolean;
  onChange: (index: number, line: Line | undefined) => void;
}

export const SortableList: FunctionComponent<SortableListProps> = ({
  lines,
  fields,
  loading,
  onChange,
}) => (
  <ul style={{ padding: 0 }}>
    {lines.map((line, index) => (
      <RELSettingLine
        key={line._id}
        onChange={(l) => onChange(index, l)}
        onDelete={() => onChange(index, undefined)}
        loading={loading}
        line={line}
        fields={fields}
      />
    ))}
  </ul>
);
