import './Modal.scss';

import React, { useState } from 'react';
import {
  validateDue,
  validateOperatingExpenses,
  validatePrice,
} from './validation';

import CheckInput from '../Form/CheckInput';
import { DateTime } from 'luxon';
import DirectInput from '../Form/DirectInput';
import Modal from 'react-modal';
import SelectInput from '../Form/SelectInput';
import classNames from 'classnames';
import customStyles from '../helper/modalCustomStyles';

interface onSubmitData {
  task: string;
  message?: string;
  due: string;
  dueFixed: boolean;
  planHours: number;
  estimatedPrice: number;
  mb_for_vds: boolean;
}

interface AddTaskModalProps {
  isOpen: boolean;
  close: () => void;
  onSubmit: (data: onSubmitData) => Promise<void>;
  taskTypes: {
    value: string;
    text: string;
  }[];
  getPriceForTask: (task?: string) => number;
}

export default function AddTaskModal(props: AddTaskModalProps) {
  Modal.setAppElement('#root');

  const { isOpen, close, onSubmit, taskTypes, getPriceForTask } = props;
  const [type, setType] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const [dueDate, setDueDate] = useState<Date | undefined>(undefined);
  const [isFixed, setFixed] = useState<boolean>(false);

  const [az, setAZ] = useState<number | undefined>(undefined);

  const getType = () => {
    return (
      type ||
      (taskTypes && taskTypes.length > 0 ? taskTypes[0].value : undefined)
    );
  };

  const p = getPriceForTask(getType());
  const [estimatedPrice, setEstimatedPrice] = useState(p);

  const valid = () => {
    if (!getType()) return false;
    if (!validateDue(dueDate)) return false;
    if (!validateOperatingExpenses(az)) return false;
    if (!validatePrice(estimatedPrice)) return false;
    return true;
  };

  const submitClasses = classNames('btn', 'btn-primary', {
    disabled: !valid(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => close && close()}
      style={customStyles}
      contentLabel=''
    >
      <div className='modal' tabIndex={-1} role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Aufgabe oder zusätzliche Prüfung anlegen
              </h5>
            </div>
            <div className='modal-body'>
              <div className='row table-row input-row'>
                <div className='col'>
                  <SelectInput
                    direct={true}
                    title='Aufgabe'
                    value={getType()}
                    options={taskTypes}
                    callback={(value: string) => {
                      setType(value);
                      setEstimatedPrice(getPriceForTask(value));
                    }}
                  />
                </div>
              </div>
              <div className='row table-row input-row'>
                <div className='col'>
                  <DirectInput
                    title='Techniker Info'
                    value={message}
                    callback={setMessage}
                  />
                </div>
              </div>
              <div className='row table-row input-row'>
                <div className='col'>
                  <CheckInput
                    titleLeft={true}
                    short='Fester Termin'
                    value={isFixed}
                    callback={(value: boolean) => {
                      setFixed(value);
                    }}
                  />
                </div>
              </div>
              <div className='row table-row input-row'>
                <div className='col'>
                  <DirectInput
                    title={isFixed ? 'Termin' : 'Fälligkeit'}
                    type={isFixed ? 'datetime' : 'date'}
                    value={dueDate}
                    validate={(value: Date | undefined) =>
                      validateDue(value, false)
                    }
                    callback={(value: Date) => setDueDate(value)}
                  />
                </div>
              </div>
              {/* {isFixed ? (
                <Fragment>
                  <div className='row table-row input-row'>
                    <div className='col'>
                      <DirectInput
                        title='Tag'
                        type='date'
                        value={fixedDate}
                        validate={(value: Date | undefined) =>
                          validateDue(value, false)
                        }
                        callback={(value: string) => {
                          if (!value || value.length === 0) return;
                          const time = fixedDate
                            ? fixedDate.split('T')[1]
                            : '00:00:00.000';
                          setFixedDate(`${value}T${time}`);
                        }}
                      />
                    </div>
                  </div>
                  <div className='row table-row input-row'>
                    <div className='col'>
                      <DirectInput
                        title={'Uhrzeit'}
                        type='time'
                        value={fixedDate}
                        validate={(value: Date | undefined) =>
                          validateDue(value, false)
                        }
                        callback={(value: string) => {
                          if (!value || value.length === 0) return;
                          const date = fixedDate
                            ? fixedDate.split('T')[0]
                            : '2020-01-01';
                          setFixedDate(`${date}T${value}`);
                        }}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : (
                
              )} */}
              <div className='row table-row input-row'>
                <div className='col'>
                  <DirectInput
                    title='AZ'
                    type='hours'
                    value={az}
                    validate={(value: number) =>
                      validateOperatingExpenses(value)
                    }
                    callback={(value: number) => {
                      setAZ(value);
                    }}
                  />
                </div>
              </div>
              <div className='row table-row input-row'>
                <div className='col'>
                  <DirectInput
                    title='Preis'
                    type='currency'
                    value={estimatedPrice || p}
                    validate={(value: number) => validatePrice(value)}
                    callback={(value: number) => {
                      setEstimatedPrice(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => close && close()}
              >
                Schließen
              </button>
              <button
                type='button'
                className={submitClasses}
                onClick={async () => {
                  if (onSubmit && valid()) {
                    if (!type) return;

                    const due = DateTime.fromJSDate(dueDate!).toISO();

                    const data: onSubmitData = {
                      message,
                      due,
                      dueFixed: isFixed,
                      estimatedPrice,
                      planHours: az!,
                      task: type === 'mb_for_vds' ? 'mb' : type,
                      mb_for_vds: type === 'mb_for_vds',
                    };

                    await onSubmit(data);
                    setType(taskTypes[0].value);
                    setFixed(false);
                    setDueDate(undefined);
                    setMessage(undefined);
                  }
                }}
              >
                Erstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
