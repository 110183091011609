import { Technician, Tour } from '../../context/Route';
import React, { Component } from 'react';
import { message, notification } from 'antd';

import { AuthContext } from '../../context/AuthContext';
import { DateTime } from 'luxon';
import { ReactComponent as Icon } from '../../assets/icons/001-file.svg';
import { Request } from '../../api/Request';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import classNames from 'classnames';
import isStaging from '../helper/DevDetect';
import showEMailSelector from '../EMailSelector';
import canShare from './canShare';

interface TourTechnicianPlanProps {
  tour: Tour;
  technician: Technician;
  sharedAt: any;
  onRELShared?: () => Promise<void>;
  disabled?: boolean;
}

class TourTechnicianPlan extends Component<TourTechnicianPlanProps> {
  async share() {
    const { tour, onRELShared } = this.props;
    if (canShare(tour)) {
      const emails = [
        { email: tour.technician.email, source: 'Techniker' },
        { email: this.context.user.email, source: 'Meine E-Mail' },
        { email: 'info@allessafe.de', source: 'Alles Safe' },
        { email: 'doku@allessafe.de', source: 'Dokumentation' },
      ];
      const mail = await showEMailSelector(emails);
      if (mail) {
        const hide = message.loading('E-Mail wird versendet..', 0);
        try {
          await Request.put('tours', tour._id, { email: mail }, 'share/rel');
          onRELShared && (await onRELShared());
          hide();
          message.success(`Emailversand war erfolgreich!`, 15);
        } catch (error: any) {
          hide();
          notification.error({
            message: 'Fehler beim Senden',
            description: 'Der Versand war nicht möglich!',
          });
        }
      }
    }
  }

  render() {
    const { sharedAt, disabled, tour } = this.props;
    return (
      <React.Fragment>
        <div className='route-step-icon'>
          <Icon />
        </div>
        <div className='route-step-content'>
          <div className='row'>
            <div className='col col-7'>
              <p className='name'>Tourenplan / REL</p>
              <p className='address'>
                {sharedAt &&
                  sharedAt.rel &&
                  `
                gesendet: ${DateTime.fromISO(sharedAt.rel)
                  .setLocale('de')
                  .toLocaleString({
                    month: '2-digit',
                    day: '2-digit',
                    weekday: 'short',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}`}
              </p>
            </div>
          </div>
        </div>
        <div className='route-step-actions extra'>
          <button
            disabled={disabled || !canShare(tour)}
            onClick={() => this.share()}
            className={classNames('route-step-action', {
              success: sharedAt && sharedAt.rel,
              danger: !(sharedAt && sharedAt.rel) && canShare(tour),
            })}
          >
            <SendIcon />
            {sharedAt && sharedAt.rel ? 'Erneut senden' : 'Senden'}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

TourTechnicianPlan.contextType = AuthContext;

export default TourTechnicianPlan;
