import CheckInput from '../Form/CheckInput';
import { LineWithEmbbededTarget } from './Import';
import React from 'react';
import classNames from 'classnames';

interface ImportLineProps {
  line: LineWithEmbbededTarget;
  // onChange: (line: Line) => void;
}

export default function ImportLine(props: ImportLineProps) {
  console.log(props.line.required);
  return (
    <div
      id={props.line._id || props.line.target.key}
      className={classNames('row', 'import-row', {
        // invalid: !props.loading && !valid,
      })}
      style={{
        padding: '16px 0',
        margin: '9px 0',
        // background: props.line.required ? 'rgb(220, 220, 220)' : '#ffffff',
        background: '#ffffff',
        borderRadius: ' 3px',
      }}
    >
      <div className='col col-1'>
        <CheckInput
          title=''
          readOnly={props.line.required}
          value={props.line.active}
          callback={() => {
            // onChange(field.key, value);
          }}
        />
      </div>

      <div className='col col-2'>
        <span style={{ lineHeight: '44px' }}>{props.line.source}</span>
      </div>
      {props.line.target && (
        <div className='col col-4'>
          <span style={{ lineHeight: '44px' }}>
            {props.line.target.group} - {props.line.target.name}
          </span>
        </div>
      )}
      {/* {props.line.required && (
        <div className='col col-3'>
          <span>Feld muss für jeden Standort vorhanden sein.</span>
        </div>
      )} */}
    </div>
  );
}
