import React, { FunctionComponent } from 'react';

import { Result } from 'antd';

interface ExportResultProps {
  error?: Error;
}

export const ExportResult: FunctionComponent<ExportResultProps> = ({}) => {
  return (
    <div className='row'>
      <div className='col col-12'>
        <Result
          status='success'
          title='Export wird erstellt...'
          subTitle='Sie können dieses Fenster nun verlassen. Sobald der Export vollständig ist, wird dieser per E-Mail zugestellt.'
        />
      </div>
    </div>
  );
};
