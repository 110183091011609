export const withWidth = (width: string, maxWidth?: string) => ({
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width,
    maxWidth: maxWidth || width,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
});

const customStyles = withWidth('95%');

export default customStyles;
