import NumberFormat, { NumberFormatValues } from 'react-number-format';
import React, { Component } from 'react';

import classNames from 'classnames';
import { isNumber } from 'lodash';
import { v4 as uuid } from 'uuid';

interface ComparisonInputProps {
  overwrite?: number; // current value calculated set by user
  actual: number; // current value calculated by system
  estimated?: number; // Value precalculated as target
  hideEstimated?: boolean;
  suffix?: string;
  dangerForLower?: boolean;
  dangerForHigher?: boolean;
  readOnly?: boolean;
  istText?: string;
  onChange?: (value: number) => void;
  onReset?: () => void;
}

class ComparisonInput extends Component<ComparisonInputProps> {
  elementID = uuid();

  constructor(props: ComparisonInputProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps: ComparisonInputProps) {
    if (nextProps.actual !== this.props.actual) return true;
    if (nextProps.estimated !== this.props.estimated) return true;
    if (nextProps.overwrite !== this.props.overwrite) return true;
    return false;
  }

  // linkOnKeyPress(ref) {
  //   if (ref) {
  //     this.focusRef = ref;
  //     ref.onkeypress = (e) => {
  //       if (e.keyCode === 13) {
  //         e.preventDefault();
  //         this.props.pressedEnter && this.props.pressedEnter();
  //       }
  //     };
  //   }
  // }

  // focus() {
  //   this.focusRef && this.focusRef.focus();
  // }

  handleChange(values: NumberFormatValues) {
    if (this.props.actual !== values.floatValue) {
      this.props.onChange && this.props.onChange(values.floatValue || 0);
    }
  }

  render() {
    const {
      estimated,
      suffix,
      dangerForLower,
      dangerForHigher,
      readOnly,
      istText,
      onReset,
    } = this.props;

    const isOverwritte = !!this.props.overwrite;
    const value = this.props.overwrite || this.props.actual || 0;

    let diff = 0;

    if (value !== 0 && estimated !== 0) {
      diff =
        (estimated && isNumber(estimated) && Number(estimated) > 0
          ? Math.round((100 / estimated) * value * 100) / 100
          : 0) - 100;
    }

    const classesForDiff = classNames('comaparison-input', {
      danger: (dangerForHigher && diff > 0) || (dangerForLower && diff < 0),
      success: (dangerForLower && diff > 0) || (dangerForHigher && diff < 0),
    });

    return (
      <div className={classesForDiff}>
        <div className='row'>
          <div className='col col-12 col-md-4 pr-0'>
            {!this.props.hideEstimated && (
              <>
                <NumberFormat
                  id={`comaparison-input-${this.elementID}-est`}
                  thousandSeparator='.'
                  decimalSeparator=','
                  value={estimated ? estimated : 0}
                  prefix=''
                  suffix={suffix}
                  decimalScale={2}
                  disabled
                />
                <label>Plan</label>
              </>
            )}
          </div>
          <div className='col col-12 col-md-4 pl-0 pr-2'>
            <NumberFormat
              id={`comaparison-input-${this.elementID}`}
              thousandSeparator='.'
              decimalSeparator=','
              value={value}
              prefix=''
              suffix={suffix}
              decimalScale={2}
              disabled={readOnly}
              onValueChange={(values) => {
                this.handleChange(values);
              }}
            />
            <label>
              {istText || 'Ist'}
              {isOverwritte && onReset && (
                <span
                  onClick={() => {
                    onReset && onReset();
                  }}
                >
                  (überschrieben)
                </span>
              )}
            </label>
          </div>
          <div className='col col-12 col-md-4 pl-0 diff-col'>
            {!this.props.hideEstimated && (
              <>
                <NumberFormat
                  id={`comaparison-input-${this.elementID}-diff`}
                  thousandSeparator='.'
                  decimalSeparator=','
                  value={diff}
                  prefix=''
                  suffix=' %'
                  decimalScale={2}
                  disabled
                />
                <label>Differenz</label>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ComparisonInput;
