import classNames from 'classnames';
import { reduce } from 'lodash';
import React from 'react';

import getColumnGridWidth from './getColumnGridWidth';

const TableSumFooter = ({ width, header, items, selectable }) => {
  const style = { width: `${width}px` };
  const showSum = reduce(
    header,
    (acc, h) => acc || h.sum === true || h.counter === true,
    false
  );
  if (showSum) {
    return (
      <div className='table-footer table-footer-bg' style={style}>
        <div className='row table-row'>
          {selectable ? (
            <div className='col col-0-5' width={(width / 12) * 0.5} />
          ) : null}
          {header.map((col) => {
            const w = getColumnGridWidth(col);
            const colWidth = (width / 12) * w;
            const classes = classNames('col', `col-${col.width}`, {
              highlight: col.highlight,
            });
            let value = null;
            let valueSec = null;

            if (col.sum) {
              value = reduce(
                items,
                (acc, curr) => {
                  const a = curr[col.key];
                  const an =
                    a != undefined &&
                    a != null &&
                    isNaN(Number(a)) &&
                    a != Infinity
                      ? 0
                      : Number(a);
                  acc += an;
                  return acc;
                },
                0
              );
            }

            if (col.sumSec) {
              valueSec = reduce(
                items,
                (acc, curr) => {
                  const a = col.sumSec(curr);
                  const an = isNaN(Number(a)) ? 0 : Number(a);
                  acc += an;
                  return acc;
                },
                0
              );
            }

            if (col.counter) {
              value = items.length;
            }

            if (value) {
              const formatter =
                col.formatSum || col.format || ((value) => value);
              value = formatter(value, null) || '';
            }

            if (valueSec) {
              const formatter =
                col.formatSum || col.format || ((valueSec) => valueSec);
              valueSec = formatter(valueSec, null) || '';
            }

            return (
              <div key={col.key} className={classes} width={colWidth}>
                {col.counter ? 'Anzahl: ' : ''}
                <p>{value}</p>
                <p>{valueSec}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};
export default TableSumFooter;
