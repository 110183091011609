import './style.scss';

import { debounce, filter, pick } from 'lodash';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Table, { TableOptions } from '../Table/Table';

import qs from 'query-string';
import { Request } from '../../api/Request';
import { AuthConsumer } from '../../context/AuthContext';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';

const defaultOptions = {
  sort: 'address.postalCode',
  desc: false,
  page: 0,
  limit: 25,
  fuzzy: true,
};

type EmailTemplate = any;

interface MatchParams {
  id: string;
}

interface EmailTemplateListProps extends RouteComponentProps<MatchParams> {}

interface EmailTemplateListOptions extends TableOptions {
  q?: string | null;
  fuzzy?: boolean;
}

interface EmailTemplateListState {
  error?: Error;
  loading: boolean;
  searching: boolean;
  options: EmailTemplateListOptions;
  items: EmailTemplate[];
  selectedItems: EmailTemplate[];
  maxItems?: number;
}

class EmailTemplateList extends Component<
  EmailTemplateListProps,
  EmailTemplateListState
> {
  state: EmailTemplateListState = {
    loading: true,
    searching: false,
    options: defaultOptions,
    maxItems: 0,
    items: [],
    selectedItems: [],
  };

  handleSearchDebounced = debounce(this.handleSearch.bind(this), 440);

  header() {
    return [
      {
        width: 50,
        key: 'title',
        sort: false,
        title: 'Bezeichnung',
        emptySymbol: ' ',
      },
    ];
  }

  constructor(props: EmailTemplateListProps) {
    super(props);
    this.handleOptionsChanged = this.handleOptionsChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
  }

  async componentDidMount() {
    await this.loadOptionsFromURL(this.props.location);
    this.loadData();
  }

  loadOptionsFromURL(location: { search: string }) {
    const data = qs.parse(location.search);
    return new Promise<void>((resolve) => {
      const s: EmailTemplateListOptions = {};
      s.sort = (data.sort as string) || defaultOptions.sort;
      s.page = Number(data.page) || defaultOptions.page;
      s.desc = Boolean(data.desc) || defaultOptions.desc;
      s.limit = Number(data.limit) || defaultOptions.limit;
      s.q = data.q as string;
      s.fuzzy = data.fuzzy ? data.fuzzy === 'true' : defaultOptions.fuzzy;
      this.setState({ options: s }, async () => resolve());
    });
  }

  storeOptionsAtURL() {
    const s = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'q',
      'fuzzy',
    ]);
    const stringified = qs.stringify(s);
    window.history.replaceState(s, 'safePlan', `?${stringified}`);
  }

  async handleSearch(query: string | undefined) {
    this.setState(
      {
        loading: true,
        options: {
          limit: undefined,
          page: undefined,
          desc: undefined,
          sort: 'score',
          q: query
        },
      },
      () => {
        this.storeOptionsAtURL();
        this.loadData();
      }
    );
  }

  async loadData() {
    const s = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'q',
      'fuzzy',
    ]);
    const result: {
      search: boolean;
      items: any[];
      total: number;
    } = await Request.list('email-layouts', s);
    this.setState({
      loading: false,
      searching: result.search,
      items: result.items,
      maxItems: result.search ? 0 : result.total,
    });
  }

  handleOptionsChanged(opts: EmailTemplateListOptions) {
    this.setState({ loading: true, options: opts }, () => {
      this.storeOptionsAtURL();
      this.loadData();
    });
  }

  handleSelectionChanged(
    emailtemplatesToSet: EmailTemplate[],
    selection: boolean
  ) {
    const { selectedItems } = this.state;
    if (selection) {
      this.setState({
        selectedItems: selectedItems.concat(emailtemplatesToSet),
      });
    } else {
      const ids = emailtemplatesToSet.map((l) => l._id);
      const value = filter(selectedItems, (l) => !ids.includes(l._id));
      this.setState({ selectedItems: value });
    }
  }

  render() {
    const { items, options, maxItems, selectedItems, loading } = this.state;
    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='email-templates container-inner container-inner-list'>
            <div className='page-header row justify-content-between'>
              <div className='col col-12 col-md-6'>
                <h1 className='page-title'>Benachrichtigungen verwalten</h1>
              </div>
            </div>
            <div className='row page-content'>
              <div className='col col-12'>
                <Table
                  loading={loading}
                  filterable={false}
                  options={options}
                  header={this.header()}
                  items={items}
                  maxItems={maxItems}
                  handleOptionsChanged={this.handleOptionsChanged}
                  selection={selectedItems}
                  handleSelectionChanged={this.handleSelectionChanged}
                  link={'/administration/email-templates'}
                />
              </div>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(EmailTemplateList);
