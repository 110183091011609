import { Job } from '../../../context/Route';

export default function jobPlanedOnFixed({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  planedAt,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fixedDateFromAdditionalTasks,
}: Job & { fixedDateFromAdditionalTasks?: string }) {
  return false;
  // if (!fixedDateFromAdditionalTasks) return false;
  // if (!planedAt) return false;
  // const d1 = DateTime.fromISO(fixedDateFromAdditionalTasks).toUTC();
  // const d2 = p(planedAt).toUTC();
  // return d1 !== d2;
}
