import React, { Component, Fragment } from 'react';

import { AuthContext } from '../../context/AuthContext';
import { ReactComponent as CalenderCheckIcon } from '../../assets/icons/date.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/calender.svg';
import DatePicker, { DatePickerType, parseDate } from '../Picker/DatePicker';
import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import { Request } from '../../api/Request';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { ReactComponent as SentIcon } from '../../assets/icons/check-circle.svg';
import TourItemDelete from './TourItemDelete';
import TourItemToggleFixed from './TourItemToggleFixed';
import classNames from 'classnames';
import getLocalFormat from './getLocalFormat';
import isStaging from '../helper/DevDetect';
import { message } from 'antd';
import numeral from 'numeral';
import showEMailSelector from '../EMailSelector';
import styled from 'styled-components';
import renderLoading from '../SharesActions/renderLoading';
import { get } from 'lodash';

// switch between locales
numeral.locale('de');

function shortCurrency(value: number, n = '0.[00]') {
  return numeral(value).format(n);
}

const Annotation = styled.div`
  color: #009842;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 12px;
  padding: 0;
  text-align: center;
  span {
    display: block;
  }
  svg {
    margin: 0 auto 2px auto;
    height: 18px;
    width: 18px;
    display: block;
    fill: #009842;
  }
`;

interface TourLocationProps {
  editing: boolean;
  tourId: string;
  indexTag: number;
  value: {
    _id: string;
    location?: any;
    planedAtTimeByUser?: any;
    until?: string;
    planHours?: number;
    estimatedPrice?: number;
    address?: any;
    planedAt?: string;
    doneAt?: string;
    failed: boolean;
    fixed: boolean;
    sharedAt?: {
      info?: string;
    };
    customerNotification: any[];
    comment?: string;
    sort: number;
  };
  onChangeAddress?: (id: string, data: any) => void;
  onChangePlanedAt?: (
    id: string,
    planedAt: Date,
    planedAtTimeByUser: boolean,
    until?: string
  ) => void;
  onChangeHotelname?: (id: string, data: any) => void;
  onDelete?: (id: string) => void;
  onSetFixed: (id: string, fixed: boolean) => Promise<void>;
  onSentCustomerNotification: (id: string) => void;
}

interface TourLocationState {
  sendingInfoToCustomer: boolean;
  invalidString?: string;
  date?: string;
}

class TourLocation extends Component<TourLocationProps, TourLocationState> {
  state: TourLocationState = {
    sendingInfoToCustomer: false,
  };

  constructor(props: TourLocationProps) {
    super(props);
    if (props.value.planedAt) this.state.date = props.value.planedAt;
    this.sendInfoToCustomer = this.sendInfoToCustomer.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps: TourLocationProps) {
    // console.log('UNSAFE_componentWillReceiveProps', typeof newProps.value.planedAt);
    this.setState({
      date: newProps.value.planedAt,
    });
  }

  async sendInfoToCustomer(
    jobID: string,
    tourID: string,
    location: any,
    stopIndex: number
  ) {
    try {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      const emails = [
        {
          email: location.email,
          source: 'Standort',
        },
        {
          email: location.email2,
          source: 'Standort 2',
        },
        {
          email: get(location, 'customer.email'),
          source: 'Kunde',
        },
        { email: this.context.user.email, source: 'Meine E-Mail' },
        { email: 'info@allessafe.de', source: 'Alles Safe' },
        { email: 'doku@allessafe.de', source: 'Dokumentation' },
      ].map((e) => {
        return {
          ...e,
          disabled: !e.email || !emailRegex.test(e.email),
        };
      });

      await new Promise((resolve) => setTimeout(resolve, 50));

      const mails = await showEMailSelector(emails);

      if (mails) {
        this.setState({
          sendingInfoToCustomer: true,
        });

        await Request.put(
          'tours',
          tourID,
          { emails: mails },
          `share/customer/${jobID}`,
          false
        )
          .then(() => {
            this.props.onSentCustomerNotification &&
              this.props.onSentCustomerNotification(jobID);
          })
          .catch(() => {
            const msg = `Emailversand an den Kunden für Stop ${stopIndex} war nicht erfolgreich!`;
            message.error(msg, 30);
          });
      }
    } catch (error: any) {
    } finally {
      this.setState({
        sendingInfoToCustomer: false,
      });
    }
  }

  /**
   * Check if the job is shared or has a customer notification
   * @returns true if the job is shared or has a customer notification
   */
  infoShared() {
    const { value } = this.props;
    const o = value && value.sharedAt && value.sharedAt.info;
    const n =
      value !== undefined &&
      value.customerNotification !== undefined &&
      value.customerNotification.length > 0;
    return n || o;
  }

  /**
   * Check if the planned date is older than today morning
   * @returns true if the planned date is older than today morning
   */
  infoOld() {
    const { value } = this.props;
    const planedAt = DateTime.fromISO(value.planedAt || '');
    const threshold = DateTime.local().startOf('day');
    return planedAt < threshold;
  }

  /**
   * Check if the job is done or failed
   * @returns true if the job is done or failed
   */
  infoDoneOrFailed() {
    const { value } = this.props;
    return value && (value.doneAt || value.failed);
  }

  render() {
    const { value, tourId } = this.props;
    return (
      <React.Fragment>
        <div className='route-step-icon'>
          {/* <WrenchIcon /> */}
          <p>{value.sort + 1}</p>
        </div>
        <div className='route-step-content'>
          <div className='row'>
            <div className='col col-6'>
              <NavLink
                to={
                  value && value.location
                    ? `/administration/locations/${value.location._id}`
                    : '#'
                }
                target='_blank'
              >
                <p className='name'>
                  {!value.location && 'Standort gelöscht'}
                  {value.location &&
                    value.location.tag &&
                    `[${value.location.tag}] `}
                  {value.location && `${value.location.name}`}
                </p>
              </NavLink>
              {value.address ? (
                <p className='address'>
                  {value.address.street}, {value.address.postalCode}{' '}
                  {value.address.city}
                </p>
              ) : (
                <p className='address missing'>Bitte Adresse eingeben..</p>
              )}
            </div>
            <div className='col col-2'>
              {value.planedAt ? (
                <p className='date'>
                  <ClockIcon />
                  {DateTime.fromISO(value.planedAt)
                    .setLocale('de')
                    .toLocaleString(getLocalFormat(value.planedAtTimeByUser))}
                </p>
              ) : (
                <p className='date missing'>Plandatum eingeben</p>
              )}
              <p className='operatingExpense'>
                {`${shortCurrency(value.planHours!)}h | ${shortCurrency(
                  value.estimatedPrice!,
                  '0,0'
                )}€`}
              </p>
            </div>
            <div className='col col-2'>
              {value.until && value.until.length > 0 && (
                <Annotation>
                  <span>Bis</span>
                  <span>{value.until}</span>
                </Annotation>
              )}
            </div>
            <div className='col col-2'>
              {value.fixed && (
                <Annotation>
                  <CalenderCheckIcon />
                  <span>Termin fix</span>
                </Annotation>
              )}
            </div>
          </div>
        </div>
        <div className='route-step-actions extra'>
          {!this.props.editing && this.infoShared() && (
            <button
              onClick={() => {
                this.sendInfoToCustomer(
                  value._id,
                  tourId,
                  this.props.value.location,
                  value.sort + 1
                );
              }}
              className={classNames(
                'route-step-action route-step-action-100 success'
              )}
            >
              <SentIcon />
              Kunde Benachrichtigt
            </button>
          )}
          {!this.props.editing && this.infoOld() && !this.infoShared() && (
            <span></span>
          )}
          {!this.props.editing &&
            !this.infoDoneOrFailed() &&
            !this.infoOld() &&
            !this.infoShared() && (
              <button
                onClick={() => {
                  this.sendInfoToCustomer(
                    value._id,
                    tourId,
                    this.props.value.location,
                    value.sort + 1
                  );
                }}
                className={classNames(
                  'route-step-action route-step-action-100',
                  {
                    danger: true,
                  }
                )}
              >
                <SendIcon />
                {this.state.sendingInfoToCustomer
                  ? 'Kundeninfo wird gesendet...'
                  : 'Kundeninfo senden'}
              </button>
            )}

          {/* {!this.props.editing && !(!this.infoShared() && !this.infoOld()) && (
            <button
              onClick={() => {
                this.sendInfoToCustomer(value._id, tourId);
              }}
              className={classNames('route-step-action route-step-action-100', {
                success: this.infoShared(),
                danger: !this.infoShared(),
              })}
            >
              {this.infoShared() ? <SentIcon /> : <SendIcon />}
              {this.infoShared() ? 'Kunde Benachrichtigt' : ''}
              {!this.infoShared() && !this.infoOld() ? 'Kundeninfo senden' : ''}
            </button>
          )} */}
          {/* {!this.props.editing && (
            <div className='route-step-action'>
              <TourLocationComment value={value} />
            </div>
          )} */}
          {this.props.editing && (
            <Fragment>
              {!value.fixed && (
                <TourItemDelete
                  text=''
                  value={value}
                  onDelete={(id) => {
                    this.props.onDelete && this.props.onDelete(id);
                  }}
                />
              )}
              {value.planedAt && !value.fixed && (
                <DatePicker
                  className='route-step-action'
                  format=''
                  useUntil={true}
                  canRemoveUntil={true}
                  types={[DatePickerType.weekday]}
                  value={{
                    startDate: value.planedAt,
                    time: value.planedAtTimeByUser,
                    until: value.until,
                  }}
                  rangeFirst={DateTime.fromISO(value.planedAt)
                    .setLocale('de')
                    .startOf('week')
                    .toJSDate()}
                  rangeLast={DateTime.fromISO(value.planedAt)
                    .setLocale('de')
                    .endOf('week')
                    .toJSDate()}
                  onChange={({ startDate, time, until }) => {
                    this.props.onChangePlanedAt &&
                      this.props.onChangePlanedAt(
                        value._id,
                        parseDate(startDate).toJSDate(),
                        time ?? false,
                        until
                      );
                  }}
                ></DatePicker>
              )}
              <TourItemToggleFixed
                text=''
                value={value}
                onChange={(id, value) => this.props.onSetFixed(id, value)}
              />
            </Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

TourLocation.contextType = AuthContext;

export default TourLocation;
