import React, { useEffect, useState } from 'react';

import { Interval } from 'luxon';
import NumberChart from '../Charts/NumberChart';
import { Request } from '../../../api/Request';
import classNames from 'classnames';
import renderLoading from '../../SharesActions/renderLoading';
import RemoveButton from './RemoveButton';
import EditButton from './EditButton';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
export const defaultSize = {
  w: 2,
  h: 1,
  minW: 1,
  maxW: 4,
  minH: 1,
  maxH: 3,
};

export const defaultColors = ['#41a441', '#41a441'];
export const colorLabels = ['Anzahl', 'Kürzel'];

export default function TechsChart(props: {
  delete?: () => void;
  edit?: () => void;
  dateRange: Interval;
  colors: string[];
}) {
  const usedColors =
    !props.colors || props.colors.length !== defaultColors.length
      ? defaultColors
      : props.colors;

  const [data, setData] = useState<
    | {
        initials: string;
        name: string;
        tag: string;
        _id: string;
      }[]
    | undefined
  >();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      Request.list(
        'dashboard-data/techs',
        {
          start: props.dateRange.start.toISO(),
          end: props.dateRange.end.toISO(),
        },
        false
      )
        .then((d) => {
          // handle success
          setData(d);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(() => setLoading(false));
    };
    fetchData();
  }, [props.dateRange.end, props.dateRange.start]);

  const headerClass = classNames({
    'box-header': true,
    row: true,
    'justify-content-between': true,
    'has-title': true,
  });

  const editClass = classNames({
    box: true,
    'box-editing': false,
  });

  return (
    <div className={editClass}>
      <div className={headerClass}>
        <div className='col col-8'>
          <div className='box-title'>Techniker</div>
          {props.edit != undefined ? (
            <EditButton onClick={() => props.edit!()} />
          ) : null}
          {props.delete != undefined ? (
            <RemoveButton onClick={() => props.delete!()} />
          ) : null}
        </div>
      </div>
      <div className='box-content'>
        {loading && renderLoading()}
        {!loading && (
          <div className='row'>
            <div
              className='col col-12 col-md-4'
              style={{
                fontSize: '16px',
                fill: usedColors[0],
                color: usedColors[0],
              }}
            >
              <NumberChart
                precise={false}
                value={data?.length || 0}
              ></NumberChart>
              <p
                style={{
                  display: 'block',
                  textAlign: 'center',
                  position: 'relative',
                  top: '-8px',
                }}
              >
                geplant
              </p>
            </div>
            <div
              className='col col-12 col-md-8'
              style={{
                fontSize: '16px',
                fill: usedColors[1],
                color: usedColors[1],
              }}
            >
              <UL>
                {data?.map((tech) => (
                  <LI key={tech.initials}>
                    <NavLink
                      to={`/tours/${tech._id}?p=1`}
                      className=''
                      target='blank'
                    >
                      {tech.initials && tech.initials.length > 0
                        ? tech.initials
                        : 'N/A'}
                    </NavLink>
                  </LI>
                ))}
              </UL>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const UL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
`;

const LI = styled.li`
  display: flex;
  align-items: center;
  margin: 0.3rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: #41a441;
  svg {
    margin-right: 0.5rem;
  }
`;
