import React, { FunctionComponent } from 'react';
import { HomeAccommodationJob, HotelJob, Job } from '../../../context/Route';

import { ReactComponent as CarIcon } from '../../../assets/icons/delivery-truck.svg';

import DistanceExtra from './DistanceExtra';
import ExtraWrapper from './Extra';

interface ExtraDistanceProps {
  job: Job | HotelJob | HomeAccommodationJob;
  next: Job | HotelJob | HomeAccommodationJob;
}

const getAddresses = (job: any) => {
  if (
    job.location &&
    job.location.addressRouting &&
    job.location.addressRouting.location
  ) {
    return job.location.addressRouting;
  }

  if (job.location && job.location.address && job.location.address.location) {
    return job.location.address;
  }

  return job.address;
};

const ExtraDistance: FunctionComponent<ExtraDistanceProps> = ({
  job,
  next,
}) => {
  return (
    <ExtraWrapper>
      <div>
        <CarIcon />
        <DistanceExtra p1={getAddresses(job)} p2={getAddresses(next)} />
      </div>
    </ExtraWrapper>
  );
};

export default ExtraDistance;
