import React from 'react';
import { RouteInfo } from '../../../context/Route';

export default function Distance(props: {
  info?: RouteInfo;
  extra: boolean;
  loading?: boolean;
}) {
  const a = ['Strecke:'];
  if (props.loading) {
    a.push('berechne...');
  } else if (props.info && props.info.distance) {
    const d = Math.floor(props.info.distance / 1000);
    a.push(`${d}km`);
    if (props.extra) {
      const e = Math.floor((0.25 * props.info.distance) / 1000);
      a.push(`(zzgl. ${e}km)`);
    }
  } else {
    a.push(`0km`);
  }
  return <span style={{ display: 'block' }}>{a.join(' ')}</span>;
}
