import '../Tours.scss';
import '../Filter.scss';

import React, { Fragment, FunctionComponent } from 'react';

import MetaTag from '../ListItem/MetaTag';
import { ReactComponent as ToursIcon } from '../../../assets/icons/info1.svg';
import { isNumber } from 'lodash';

interface TourMetaProps {
  item: any;
}

const asNumber = (item: any, key: string, d = 0) => {
  return item && item[key] && isNumber(item[key])
    ? Math.round(item[key] * 100) / 100
    : d;
};

const TourMeta: FunctionComponent<TourMetaProps> = (props) => {
  return (
    <Fragment>
      <div
        style={{
          marginTop: '18px',
        }}
      >
        <ToursIcon className='small' />
        <p className='header'>Info</p>
        <ul>
          <MetaTag
            item={props.item}
            text='KM'
            unit='km'
            sollKey='planDrivingDistance'
            istKey='actualDrivingDistance'
            alert={(v) => (v && v > 750 ? true : false)}
          />
          <MetaTag
            item={props.item}
            text='AZ'
            unit='h'
            i={
              asNumber(props.item, 'actualRuWHours') +
              asNumber(props.item, 'actualWorkingHours') +
              asNumber(props.item, 'actualDrivingHours')
            }
            s={
              asNumber(props.item, 'planWorkingHours') +
              asNumber(props.item, 'planDrivingHours')
            }
            a={` (${asNumber(props.item, 'actualRuWHours')}h RUW)`}
            alert={() => false}
          />
          <MetaTag
            item={props.item}
            text='UM'
            unit='€'
            sollKey='estimatedPrice'
            istKey='actualPrice'
            alert={(v) => (v && v < 3000 ? true : false)}
          />
          <MetaTag
            item={props.item}
            text='TS'
            unit='€'
            sollKey='tagessatzPlan'
            istKey='tagessatzIst'
            alert={(v) => (v && v < 600 ? true : false)}
          />
          <MetaTag
            item={props.item}
            text='RN'
            unit='€'
            sollKey='rentaPlan'
            istKey='rentaIst'
            alert={(v) => (v && v < 1550 ? true : false)}
          />
        </ul>
      </div>
    </Fragment>
  );
};

export default TourMeta;
