import { get, reduce } from 'lodash';
import { HotelJobBase, Tour } from '../../context/Route';

const canShare = (tour: Tour) => {
  const permission = get(tour, 'permissionGranted.at') != undefined;

  const jobs = reduce(
    tour.jobs,
    (acc, job) => {
      if (job.isHotel) {
        return (
          acc &&
          (job as HotelJobBase).address != undefined &&
          job.hotelName != undefined
        );
      } else {
        return acc && job.address != undefined;
      }
    },
    true
  );

  return permission && jobs;
};

export default canShare;
