import { Chart, AxisOptions } from 'react-charts';

import React from 'react';
import numeral from 'numeral';

numeral.locale('de');

// function shortCurrency(value, smallFormat) {
//   const f = value < 10000 ? smallFormat || '0,0' : '0.0a';
//   return numeral(value).format(f);
// }

// function invertColor(hex, bw) {
//   if (hex.indexOf('#') === 0) {
//     hex = hex.slice(1);
//   }
//   // convert 3-digit hex to 6-digits.
//   if (hex.length === 3) {
//     hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
//   }
//   if (hex.length !== 6) {
//     throw new Error('Invalid HEX color.');
//   }
//   const r = parseInt(hex.slice(0, 2), 16),
//     g = parseInt(hex.slice(2, 4), 16),
//     b = parseInt(hex.slice(4, 6), 16);
//   if (bw) {
//     // https://stackoverflow.com/a/3943023/112731
//     return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
//   }
//   // invert color components
//   r = (255 - r).toString(16);
//   g = (255 - g).toString(16);
//   b = (255 - b).toString(16);
//   // pad each with zeros and return
//   return '#' + padZero(r) + padZero(g) + padZero(b);
// }

export default function GeplantIstChart(props) {
  if (!props.data || props.data.length === 0) {
    return null;
  }

  // ist and geplant will be stacked
  // both will be positive or negative
  // there are four possible cases
  // 1) both values are positive and ist > geplant
  // 2) both values are positive and geplant > ist
  // 3) both values are negative and ist > geplant
  // 4) both values are negative and geplant > ist

  const getValueForGeplant = (ist: number, geplant: number) => {
    if (ist === null || ist === 0) {
      return geplant;
    }

    // case 1
    if (geplant > 0 && ist > 0 && ist > geplant) {
      return geplant;
    }
    // case 2
    if (geplant > 0 && ist > 0 && geplant > ist) {
      return geplant - ist;
    }
    // case 3 & 4
    if (geplant < 0 || ist < 0) {
      return geplant;
    }
  };

  const getValueForIst = (ist: number, geplant: number) => {
    if (geplant === null || geplant === 0) {
      return ist;
    }

    // case 1
    if (geplant > 0 && ist > 0 && ist > geplant) {
      return ist - geplant;
    }
    // case 2
    if (geplant > 0 && ist > 0 && geplant > ist) {
      return ist;
    }
    // case 3 & 4
    if (geplant < 0 || ist < 0) {
      return ist;
    }
  };

  const data = React.useMemo(
    () => [
      {
        label: 'Geplant',
        data: props.data.map((d) => ({
          primary: d.name,
          secondary: d.geplant, //getValueForGeplant(d.ist, d.geplant) ?? 0,
          geplant: d.geplant,
          ist: d.ist,
          _id: d._id,
        })),
      },
      {
        label: 'Ist',
        data: props.data.map((d) => ({
          primary: d.name,
          secondary: d.ist, //getValueForIst(d.ist, d.geplant) ?? 0,
          ist: d.ist,
          geplant: d.geplant,
          _id: d._id,
        })),
      },
    ],
    [props.data]
  );

  // console.log(data);

  const primaryAxis = React.useMemo<
    AxisOptions<(typeof data)[number]['data'][number]>
  >(
    () => ({
      position: 'left',
      getValue: (datum) => datum.primary,
      innerBandPadding: 0.2,
      outerBandPadding: 0.1,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<(typeof data)[number]['data'][number]>[]
  >(
    () => [
      {
        position: 'bottom',
        getValue: (datum) => datum.secondary,
        stacked: false,
      },
    ],
    []
  );

  const handleClick = (_id: string) => {
    console.log(_id);
    const win = window.open(`/${props.link}/${_id}`, '_blank');
    win?.focus();
  };

  return (
    <Chart
      options={{
        data,
        primaryAxis,
        secondaryAxes,
        onClickDatum: (datum) => {
          console.log(datum);
          if (datum && datum.originalDatum) {
            handleClick((datum.originalDatum as any)._id);
          }
        },
        // tooltip: false,
      }}
    />
  );
}
