import React, { useEffect, useState } from 'react';

import { Request } from '../../../api/Request';

interface LocationBlockedInfoProps {
  userId: string;
}

export default function LocationBlockedInfo(props: LocationBlockedInfoProps) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('');

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        if (props.userId) {
          const { name } = await Request.get(
            'users',
            props.userId,
            '',
            {},
            false
          );
          setUser(name);
        }
      } catch (error: any) {
        setUser('Fehler');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [props.userId]);

  if (loading) {
    return <span>Laden...</span>;
  } else {
    return <span>{user}</span>;
  }
}
