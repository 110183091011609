import './Picker.scss';

import React, { Component } from 'react';
import { DatePickerInnerProps } from './interface';

import CheckInput from '../Form/CheckInput';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { parseDate } from './DatePicker';

interface _DatepickerInnerWeekdayProps {
  rangeFirst: Date;
  rangeLast: Date;
  useUntil: boolean;
  canRemoveUntil: boolean;
  until: string | undefined;
  displayTime?: boolean;
  onChangeDisplayTime: (value: boolean) => void;
  onChangeUntil: (value: string) => void;
}

type DatepickerInnerWeekdayProps = DatePickerInnerProps &
  _DatepickerInnerWeekdayProps;

interface DatepickerInnerWeekdayState {
  until: string;
  selected: DateTime;
  displayTime: boolean;
  rangeFirst: DateTime;
  rangeLast: DateTime;
  startDate: DateTime;
  monthValue: DateTime;
}

class DatepickerInnerWeekday extends Component<
  DatepickerInnerWeekdayProps,
  DatepickerInnerWeekdayState
> {
  static format(startDate: DateTime, format?: string) {
    return startDate.toFormat(format ?? 'ccc');
  }

  state: DatepickerInnerWeekdayState = {
    selected: DateTime.local(),
    monthValue: DateTime.local(),
    displayTime: false,
    until: '',
    rangeFirst: DateTime.local(),
    rangeLast: DateTime.local(),
    startDate: DateTime.local(),
  };

  currentWeekNumber = DateTime.local().setLocale('de').weekNumber;
  currentYear = DateTime.local().setLocale('de').year;

  constructor(props: DatepickerInnerWeekdayProps) {
    super(props);
    const { startDate, rangeFirst, rangeLast, until } = props;
    this.state.until = until ?? '';
    this.state.rangeFirst = DateTime.fromJSDate(rangeFirst).setLocale('de');
    this.state.rangeLast = DateTime.fromJSDate(rangeLast).setLocale('de');
    this.state.startDate =
      typeof startDate === 'string'
        ? DateTime.fromISO(startDate)
        : DateTime.fromJSDate(startDate ?? new Date()).setLocale('de');
    this.state.monthValue = DateTime.fromJSDate(
      startDate ?? new Date()
    ).setLocale('de');
  }

  UNSAFE_componentWillReceiveProps(props: DatepickerInnerWeekdayProps) {
    const { displayTime, startDate, rangeFirst, rangeLast } = props;
    this.setState({
      displayTime: displayTime ?? false,
      rangeFirst: DateTime.fromJSDate(rangeFirst).setLocale('de'),
      rangeLast: DateTime.fromJSDate(rangeLast).setLocale('de'),
      startDate:
        typeof startDate === 'string'
          ? DateTime.fromISO(startDate)
          : DateTime.fromJSDate(startDate ?? new Date()).setLocale('de'),
      monthValue: DateTime.fromJSDate(startDate ?? new Date()).setLocale('de'),
    });
  }

  daysForWeek() {
    return ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
  }

  render() {
    const {
      onChangeValue,
      onChangeUntil,
      onChangeDisplayTime,
      canRemoveUntil,
      useUntil,
    } = this.props;
    const { startDate, rangeFirst, displayTime, until } = this.state;
    const daysForWeek = this.daysForWeek();
    return (
      <React.Fragment>
        <div className='row justify-content-center'>
          {daysForWeek.map((day, index) => {
            const v = rangeFirst.set({ weekday: index + 1 }).set({
              hour: startDate.isValid ? startDate.get('hour') : 8,
              minute: startDate.isValid ? startDate.get('minute') : 0,
            }); //.startOf("day");
            const classes = classNames(
              'col',
              'select-date',
              'col-1',
              // `col-${Math.floor(12 / daysForWeek.length)}`,
              {
                active: startDate.weekday === v.weekday,
              }
            );
            return (
              <div
                key={day}
                onClick={() => {
                  const _startDate = v.toJSDate();
                  const endDate = v
                    .set({
                      hour: startDate.isValid ? startDate.get('hour') : 8,
                      minute: startDate.isValid ? startDate.get('minute') : 0,
                    })
                    .toJSDate();
                  onChangeValue &&
                    onChangeValue(
                      _startDate,
                      endDate,
                      DatepickerInnerWeekday.format(parseDate(_startDate)),
                      'weekday',
                      until.length > 0 ? until : undefined
                    );
                }}
                className={classes}
              >
                {day}
              </div>
            );
          })}
        </div>
        <div className='row justify-content-start time'>
          <div className='col-12 row'>
            <div className='col-6 p-0'>
              <CheckInput
                title='Uhrzeit:'
                value={displayTime}
                callback={(value: boolean) =>
                  onChangeDisplayTime && onChangeDisplayTime(value)
                }
              />
            </div>
            <div
              className='col-6'
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              <input
                style={{
                  border: 0,
                  borderBottom: '1px solid #aaa',
                  width: '24px',
                }}
                pattern='[0-9]{2}'
                value={(startDate.get('hour') ?? '')
                  .toString()
                  .padStart(2, '0')}
                onChange={(e) => {
                  const v = Number(e.target.value);
                  if (v >= 0 && v < 24) {
                    const value = startDate.set({ hour: v }).toJSDate();
                    onChangeValue &&
                      onChangeValue(
                        value,
                        value,
                        DatepickerInnerWeekday.format(parseDate(value)),
                        'weekday',
                        until.length > 0 ? until : undefined
                      );
                  }
                }}
              />
              <span>:</span>
              <input
                style={{
                  border: 0,
                  borderBottom: '1px solid #aaa',
                  width: '24px',
                }}
                pattern='[0-9]{2}'
                value={(startDate.get('minute') ?? '')
                  .toString()
                  .padStart(2, '0')}
                onChange={(e) => {
                  const v = Number(e.target.value);
                  if (v >= 0 && v < 60) {
                    const value = startDate.set({ minute: v }).toJSDate();
                    onChangeValue &&
                      onChangeValue(
                        value,
                        value,
                        DatepickerInnerWeekday.format(parseDate(value)),
                        'weekday',
                        until.length > 0 ? until : undefined
                      );
                  }
                }}
              />
            </div>
          </div>
          {useUntil && (
            <div className='col-12 row'>
              <div className='col-6 p-0'>
                <CheckInput
                  readOnly={canRemoveUntil !== true}
                  title='Bis:'
                  value={until.length > 0}
                  callback={() => {
                    this.setState({ until: '' });
                    onChangeUntil && onChangeUntil('');
                  }}
                />
              </div>
              <div
                className='col-6'
                style={{
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
              >
                <input
                  style={{
                    border: 0,
                    borderBottom: '1px solid #aaa',
                    width: '88px',
                  }}
                  value={until}
                  onChange={(e) => {
                    this.setState({ until: e.currentTarget.value });
                    onChangeUntil && onChangeUntil(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DatepickerInnerWeekday;
