import './Pagination.scss';

import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import qs from 'query-string';

interface MatchParams {
  id: string;
}

interface PaginationProps extends RouteComponentProps<MatchParams> {
  page: number;
  pageCount: number;
  onChange: (page: number) => void;
}

class Pagination extends Component<PaginationProps> {
  isHidden() {
    return this.props.pageCount === 1;
  }

  getQueryForPage(page: number) {
    const { location } = this.props;
    const queryParams = qs.parse(location.search);
    queryParams.page = page.toFixed();
    return `?${qs.stringify(queryParams)}`;
  }

  renderDot(cp: number) {
    return (
      <NavLink to={this.getQueryForPage(cp)} key={cp} data-page={cp}>
        <span
          className={`dot ${cp === this.props.page ? 'active' : ''}`}
          onClick={() => this.props.onChange(cp)}
        ></span>
      </NavLink>
    );
  }

  renderLeft() {
    return (
      <NavLink to={this.getQueryForPage(this.props.page - 1)}>
        <span
          className='float-left'
          onClick={() => this.props.onChange(this.props.page - 1)}
        >
          <ArrowLeft />
        </span>
      </NavLink>
    );
  }

  renderRight() {
    return (
      <NavLink to={this.getQueryForPage(this.props.page + 1)}>
        <span
          className='float-right'
          onClick={() => this.props.onChange(this.props.page + 1)}
        >
          <ArrowRight />
        </span>
      </NavLink>
    );
  }

  render() {
    const { page, pageCount } = this.props;
    const dotsFirst = Math.max(0, page - 5);
    const dotsLast = Math.min(pageCount, dotsFirst + 10);
    const allDots = Array.from(Array(pageCount).keys());
    const dots = allDots.slice(dotsFirst, dotsLast);

    if (this.isHidden()) {
      return <div className='pagination center-block row'></div>;
    }

    return (
      <div className='pagination center-block row'>
        <React.Fragment>
          <div className='col-4'>{page > 0 && this.renderLeft()}</div>
          <div className='col-4'>{dots.map((cp) => this.renderDot(cp))}</div>
          <div className='col-4 '>
            {page < pageCount - 1 && this.renderRight()}
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(Pagination);
