import { Button, Modal } from 'antd';
import { Fragment, useState } from 'react';

import React from 'react';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';

interface RemoveButtonProps {
  hidden?: boolean;
  itemTypeText: string;
  callback: () => Promise<void>;
}

export default function RemoveButton(props: RemoveButtonProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const action = async () => {
    try {
      setLoading(true);
      await props.callback();
    } catch (error: any) {
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        hidden={!!props.hidden}
        type='link'
        className='delete float-right'
        loading={loading}
        onClick={() => !loading && setOpen(true)}
      >
        <TrashIcon />
        Löschen
      </Button>
      <Modal
        title={`${props.itemTypeText} löschen`}
        visible={open}
        onOk={() => action()}
        onCancel={() => setOpen(false)}
        okText='Löschen'
        cancelText='Zurück'
      >
        <p>Sind Sie sich sicher?</p>
      </Modal>
    </Fragment>
  );
}
