import './Picker.scss';

import React, { Component } from 'react';

import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { DateTime } from 'luxon';
import classNames from 'classnames';

// import CheckInput from "../Form/CheckInput";
// import DirectInput from "../Form/DirectInput";

function parseDate(startDate) {
  if (typeof startDate === 'string')
    return DateTime.fromISO(startDate).setLocale('de');
  if (startDate.isLuxonDateTime) return startDate;
  return DateTime.fromJSDate(startDate).setLocale('de');
}

const quarters = [
  {
    title: 'Q1',
    startDate: DateTime.fromObject({ day: 1, month: 1 }),
    endDate: DateTime.fromObject({ day: 31, month: 3 }),
  },
  {
    title: 'Q2',
    startDate: DateTime.fromObject({ day: 1, month: 4 }),
    endDate: DateTime.fromObject({ day: 30, month: 6 }),
  },
  {
    title: 'Q3',
    startDate: DateTime.fromObject({ day: 1, month: 7 }),
    endDate: DateTime.fromObject({ day: 30, month: 9 }),
  },
  {
    title: 'Q4',
    startDate: DateTime.fromObject({ day: 1, month: 10 }),
    endDate: DateTime.fromObject({ day: 31, month: 12 }),
  },
];

class DatepickerInnerQuarter extends Component {
  static format(startDate, format) {
    if (!startDate) return startDate;
    return startDate
      .set({ weekday: 0 })
      .plus({ weeks: 1 })
      .endOf('day')
      .toFormat(format ?? "'Q'q '-' yyyy");
  }

  state = {
    selected: null, // quarter selected by user
    yearValue: null, // year selected by user
    displayTime: false,
  };

  currentWeekNumber = DateTime.local().setLocale('de').weekNumber;
  currentYear = DateTime.local().setLocale('de').year;

  constructor({ startDate }) {
    super();
    const parsed = parseDate(startDate);
    this.state.yearValue = parsed;
    this.state.startDate = parsed;
  }

  UNSAFE_componentWillReceiveProps({ startDate }) {
    const parsed = parseDate(startDate);
    this.setState({
      startDate: parsed,
      yearValue: parsed,
    });
  }

  prevYear() {
    this.setState({
      yearValue: this.state.yearValue.minus({ year: 1 }),
    });
  }

  today() {
    this.setState({
      yearValue: DateTime.local().setLocale('de'),
    });
  }

  nextYear() {
    this.setState({
      yearValue: this.state.yearValue.plus({ year: 1 }),
    });
  }

  renderQuarter({ title, startDate, endDate }, onChangeValue) {
    const selected = this.state.startDate;
    const yearValue = this.state.yearValue;
    const value = parseDate(selected);
    const active = startDate <= value && value <= endDate;
    const classes = classNames('col', 'select-date', `col-3`, { active });

    return (
      <div
        key={title}
        onClick={() => {
          const formatted = startDate.toFormat("'Q'q '-' yyyy");
          onChangeValue(
            startDate.set({ year: yearValue.year }).toJSDate(),
            endDate.set({ year: yearValue.year }).toJSDate(),
            formatted,
            'quarter'
          );
        }}
        className={classes}
      >
        {title}
      </div>
    );
  }

  render() {
    const { onChangeValue } = this.props;
    const { yearValue } = this.state;
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col center-block select-month'>
            <span onClick={() => this.prevYear()}>
              <ArrowLeft />
            </span>
            <span
              className='calender-toolbar-month'
              onClick={() => this.today()}
            >
              {yearValue && yearValue.isValid
                ? yearValue.toLocaleString({
                    quarter: 'long',
                    year: 'numeric',
                  })
                : null}
            </span>
            <span onClick={() => this.nextYear()}>
              <ArrowRight />
            </span>
          </div>
        </div>
        <div className='row justify-content-center'>
          {quarters.map((quarter) =>
            this.renderQuarter(quarter, onChangeValue)
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DatepickerInnerQuarter;
