import './EditableInput.scss';

import { DatePicker, TimePicker } from 'antd';
import React, { Component, Fragment } from 'react';

import NumberFormat, { NumberFormatValues } from 'react-number-format';
import PasswordStrengthBar from 'react-password-strength-bar';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import AddressInput from './AddressInput';

const zxcvbnConfig = {
  minLength: 6,
};

interface DirectInputProps {
  validate?: (value: any) => boolean;
  callback?: (value: any) => void;
  value: any;
  defaultValue?: any;
  required?: boolean;
  readOnly?: boolean;
  type?: string;
  title: string;
}

interface DirectInputState {
  value: any;
  valid: boolean;
}

class DirectInput extends Component<DirectInputProps, DirectInputState> {
  elementID = uuid();
  state = {
    value: '',
    valid: true,
  };

  constructor(props: DirectInputProps) {
    super(props);
    if (props.value) {
      this.state.valid = this.props.validate
        ? this.props.validate(props.value) === true
        : true;
      this.state.value = props.value;
    } else {
      this.state.valid = !!!props.required;
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNumberFormat = this.handleChangeNumberFormat.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps: DirectInputProps) {
    if (this.state.value !== newProps.value) {
      this.setState({
        valid: this.props.validate
          ? this.props.validate(newProps.value) === true
          : true,
        value: newProps.value,
      });
    }
  }

  handleChangeNumberFormat(event: NumberFormatValues) {
    const valid = this.props.validate
      ? this.props.validate(event.floatValue) === true
      : true;
    this.setState({ value: event.floatValue, valid }, () => {
      this.props.callback && this.props.callback(this.state.value);
    });
  }

  // handleChangeTimeFormat(event) {
  //   const { value } = event.target;
  //   if (value) {
  //     const [h, m] = value.split(':');
  //     const a = this.state.value || '1970-01-01T00:00:00.000+00:00';
  //     const d = (
  //       typeof this.state.value === 'string'
  //         ? DateTime.fromISO(a)
  //         : DateTime.fromJSDate(a)
  //     ).set({ hour: h, minute: m });
  //     this.setState({ value: d.toJSDate(), valid: d.isValid }, () => {
  //       this.props.callback && this.props.callback(this.state.value);
  //     });
  //   } else {
  //     this.setState({ value, valid: false }, () => {
  //       this.props.callback && this.props.callback(this.state.value);
  //     });
  //   }
  // }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const v = (event.target.value as any)._isAMomentObject
      ? (event.target.value as any as moment.Moment).toDate()
      : event.target.value;
    const valid = this.props.validate ? this.props.validate(v) === true : true;
    this.setState({ value: event.target.value, valid }, () => {
      this.props.callback && this.props.callback(this.state.value);
    });
  }

  renderCurrency() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'currency') {
      return (
        <NumberFormat
          id={`direct-input-${this.elementID}`}
          thousandSeparator='.'
          decimalSeparator=','
          value={value}
          prefix=''
          suffix='€'
          onValueChange={(values) => this.handleChangeNumberFormat(values)}
        />
      );
    }
  }

  renderPercentage() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'percentage') {
      return (
        <NumberFormat
          id={`direct-input-${this.elementID}`}
          thousandSeparator='.'
          decimalSeparator=','
          value={value}
          prefix=''
          suffix='%'
          decimalScale={0}
          onValueChange={(values) => this.handleChangeNumberFormat(values)}
        />
      );
    }
  }

  renderMonths() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'months') {
      return (
        <NumberFormat
          id={`direct-input-${this.elementID}`}
          thousandSeparator='.'
          decimalSeparator=','
          value={value}
          prefix=''
          suffix=' Monate'
          decimalScale={0}
          onValueChange={(values) => this.handleChangeNumberFormat(values)}
        />
      );
    }
  }

  renderHours() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'hours') {
      return (
        <NumberFormat
          id={`direct-input-${this.elementID}`}
          thousandSeparator='.'
          decimalSeparator=','
          value={value}
          prefix=''
          suffix=' Stunden'
          decimalScale={2}
          onValueChange={(values) => this.handleChangeNumberFormat(values)}
        />
      );
    }
  }

  renderNumber() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'number') {
      return (
        <NumberFormat
          id={`direct-input-${this.elementID}`}
          thousandSeparator='.'
          decimalSeparator=','
          value={value}
          prefix=''
          suffix=''
          decimalScale={0}
          onValueChange={(values) => this.handleChangeNumberFormat(values)}
        />
      );
    }
  }

  renderDate() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'date' || type === 'datetime') {
      const valueMoment: moment.Moment =
        value && (value as any)._isAMomentObject
          ? (value as any as moment.Moment)
          : moment(value);
      return (
        <DatePicker
          id={`direct-input-${this.elementID}`}
          style={{
            width: '100%',
            maxWidth: '286px',
          }}
          format={type === 'datetime' ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
          showTime={type === 'datetime'}
          value={valueMoment.isValid() ? valueMoment : undefined}
          minuteStep={15}
          // disabledSeconds={true}
          onChange={(value) => {
            const valueAsDate = value ? value.toDate() : null;

            let valid = false;

            if (valueAsDate) {
              valid = this.props.validate
                ? this.props.validate(valueAsDate) === true
                : true;
            }

            this.setState({ value: valueAsDate, valid }, () => {
              this.props.callback && this.props.callback(valueAsDate);
            });
          }}
        />
      );
    }
  }

  renderTime() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'time') {
      const valueMoment: moment.Moment =
        value && (value as any)._isAMomentObject
          ? (value as any as moment.Moment)
          : moment(value);
      return (
        <TimePicker
          id={`direct-input-${this.elementID}`}
          style={{
            width: '100%',
            maxWidth: '286px',
          }}
          format={'HH:mm'}
          value={valueMoment.isValid() ? valueMoment : undefined}
          // minuteStep={15}
          // disabledSeconds={true}
          onChange={(value) => {
            const valueAsDate = value ? value.toDate() : null;

            let valid = false;

            if (valueAsDate) {
              valid = this.props.validate
                ? this.props.validate(valueAsDate) === true
                : true;
            }
            this.setState({ value: valueAsDate, valid }, () => {
              this.props.callback && this.props.callback(valueAsDate);
            });
          }}
        />
      );
    }
  }

  renderPass() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'password') {
      return (
        <PasswordStrengthBar
          style={{ maxWidth: '50%', left: '50%' }}
          minLength={zxcvbnConfig.minLength}
          scoreWords={['schwach', 'schwach', 'ok', 'gut', 'stark']}
          shortScoreWord='zu kurz'
          password={value}
        />
      );
    }
  }

  renderAddress() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'address') {
      return (
        <AddressInput
          direct={true}
          id={`direct-input-${this.elementID}`}
          value={value || ''}
          callback={(e) => {
            this.props.callback && this.props.callback(e);
          }}
        />
      );
    }
  }

  renderElse() {
    const { type } = this.props;
    const { value } = this.state;
    if (type === 'currency') return;
    if (type === 'number') return;
    if (type === 'months') return;
    if (type === 'hours') return;
    if (type === 'percentage') return;
    if (type === 'date') return;
    if (type === 'datetime') return;
    if (type === 'time') return;
    if (type === 'address') return;
    return (
      <input
        id={`direct-input-${this.elementID}`}
        placeholder={this.props.defaultValue}
        type={type || 'text'}
        name='name'
        value={value || ''}
        onChange={(e) => this.handleChange(e)}
      />
    );
  }

  render() {
    const { title } = this.props;
    const { valid } = this.state;
    return (
      <Fragment>
        <div className={`direct-input`}>
          <label
            htmlFor={`direct-input-${this.elementID}`}
            className={`${valid ? 'valid' : 'invalid'}`}
          >
            {title}
          </label>
          {this.renderCurrency()}
          {this.renderPercentage()}
          {this.renderMonths()}
          {this.renderHours()}
          {this.renderDate()}
          {this.renderTime()}
          {this.renderAddress()}
          {this.renderElse()}
        </div>
        {this.renderPass()}
      </Fragment>
    );
  }
}

export default DirectInput;
