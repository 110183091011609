import './Modal.scss';

import React, { useState } from 'react';

import CheckInput from '../Form/CheckInput';
import Modal from 'react-modal';
import SelectInput from '../Form/SelectInput';
import classNames from 'classnames';
import customStyles from '../helper/modalCustomStyles';
import { first } from 'lodash';

interface TransferModalProps {
  isOpen: boolean;
  close: () => void;
  onSubmit: (
    type: string,
    selection: {
      [key: string]: boolean;
    }
  ) => Promise<void>;
  transferable: { value: string; text: string }[];
  transferTarget: { value: string; text: string }[];
}

export default function TransferModal(props: TransferModalProps) {
  Modal.setAppElement('#root');

  const { isOpen, close, onSubmit, transferable, transferTarget } = props;

  const [type, setType] = useState<string>();
  const [selection, setSelection] = useState<{
    [key: string]: boolean;
  }>({});

  const valid = () => {
    return type;
  };

  const submitClasses = classNames('btn', 'btn-primary', {
    disabled: !valid(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => close && close()}
      style={customStyles}
      onAfterOpen={() => {
        const defaultTransferTarget = first(transferTarget);
        if (defaultTransferTarget && defaultTransferTarget.value) {
          setType(defaultTransferTarget.value);
        } else {
          setType(undefined);
        }
      }}
      contentLabel=''
    >
      <div className='modal' tabIndex={-1} role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Einstellungen auf alle Standorte übertagen
              </h5>
            </div>
            <div className='modal-body'>
              <div className='row table-row input-row'>
                <div className='col'>
                  <SelectInput
                    direct={true}
                    title='Ziel'
                    value={type}
                    callback={setType}
                    options={transferTarget}
                  />
                </div>
              </div>
              <div className='row table-row input-row'>
                {transferable.map((t, i) => (
                  <div key={i} className='col col-6'>
                    <CheckInput
                      title={t.text}
                      value={() => selection[t.value]}
                      callback={(value: boolean) => {
                        selection[t.value] = value;
                        setSelection(selection);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => close && close()}
              >
                Schließen
              </button>
              <button
                type='button'
                className={submitClasses}
                onClick={async () => {
                  if (onSubmit && valid()) {
                    await onSubmit(type!, selection);
                    setSelection({});
                    setType('customergroup');
                  }
                }}
              >
                Übertragen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
