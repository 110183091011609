import { MenuItem, MenuItemWithChildren, MenuItemWithTo } from './Header';
import React, { useState } from 'react';

import LinkItem from './LinkItem';
import NavDivider from './NavDivider';
import { NavLink } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { checkActive } from './util';
import classNames from 'classnames';

interface DropdownItemProps {
  item: MenuItemWithChildren;
  styleName?: string;
  level: number;
}

export default function DropdownItem(props: DropdownItemProps) {
  const [open, setOpen] = useState<boolean>(false);

  const { text, Icon, children, isActivePrefix } = props.item;
  const Component = Icon as React.ElementType;
  const classes = classNames('dropdown-menu', {
    show: open,
  });
  const classesLink = classNames('nav-link', 'no-click', {
    'no-border': open,
  });

  const renderLink = (index: number, item: MenuItemWithTo, level: number) => {
    return <LinkItem level={level} key={index} item={item}></LinkItem>;
  };

  const renderDropdown = (
    index: number,
    item: MenuItemWithChildren,
    level: number
  ) => {
    return <DropdownItem level={level} key={index} item={item}></DropdownItem>;
  };

  const renderDivider = (index: number) => {
    return <NavDivider key={index}></NavDivider>;
  };

  const renderMenuItem = (index: number, item: MenuItem) => {
    if (item.type === 'dropdown')
      return renderDropdown(index, item, props.level + 1);
    if (item.type === 'link') return renderLink(index, item, props.level + 1);
    if (item.type === 'divider') return renderDivider(index);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <li
        className={classNames(
          'nav-item',
          `nav-item-level-${props.level}`,
          'dropdown',
          props.styleName
        )}
      >
        <NavLink
          className={classesLink}
          to='#'
          role='button'
          aria-haspopup='true'
          aria-expanded='false'
          activeClassName='active'
          isActive={(m, l) => checkActive(m, l, isActivePrefix)}
          onClick={() => setOpen(!open)}
        >
          <Component className='nav-icon' />
          <span>{text}</span>
        </NavLink>

        {children && children.length > 0 && (
          <ul className={classes}>
            {children.map(
              (item, index) => renderMenuItem(index, item)
              // <LinkItem
              //   key={index}
              //   level={props.level + 1}
              //   styleName='dropdown-item'
              //   item={item as MenuItemWithTo}
              // />
            )}
          </ul>
        )}
      </li>
    </OutsideClickHandler>
  );
}
