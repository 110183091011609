import './Table.scss';
import './Filter.scss';

import {
  AutoSizer,
  CellMeasurerCache,
  Column,
  Index,
  Table as RVTable,
  SortDirection,
  TableCellProps,
  TableHeaderProps,
  TableRowProps,
} from 'react-virtualized';
import { Comparator, FILTER_OPERATOR_EQ_BOOL } from './Filter';
import React, { Component, Fragment } from 'react';
import {
  TabledHeaderField,
  TabledHeaderFieldWithFilter,
} from '../Planing/Steps/createLocationTableHeader';
import { find, get, isNil, reduce } from 'lodash';

import { ReactComponent as CheckBoldIcon } from '../../assets/icons/check-bold.svg';
import { ClipLoader } from 'react-spinners';
import FilterModal from './FilterModal';
import { NavLink } from 'react-router-dom';
import TableFooter from './TableFooter';
import TableSumFooter from './TableSumFooter';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import getColumnGridWidth from './getColumnGridWidth';

export interface TableOptions {
  sort?: string;
  desc?: boolean;
  page?: number;
  limit?: number;
  filters?: { [col: string]: { value: string; comparator: string }[] };
}

export enum SELECTION_TYPE {
  disabled,
  selectAll,
  Filter,
}

type SelectionCellRendererFunction = (
  { rowData }: TableCellProps,
  isItemSelected: (id: string) => boolean,
  isItemDisabled?: (id: string) => boolean,
  isItemDisabledInfo?: (item: any) => React.ReactNode,
  handleSelectionChanged?: (item: any, value: boolean) => void
) => JSX.Element;

type ExtensionRowRendererFunction = (
  item: any,
  index: number,
  columns: any[],
  isItemSelected: (id: string) => boolean,
  isItemDisabled?: (id: string) => boolean,
  isItemDisabledInfo?: (item: any) => React.ReactNode,
  handleSelectionChanged?: (item: any, value: boolean) => void
) => JSX.Element;

export interface TableProps {
  header: TabledHeaderField[];
  link?: string | ((item: any) => string | undefined);
  linkTarget?: string;
  selectable?: SELECTION_TYPE;
  filterable?: boolean;
  loading: boolean;
  options?: TableOptions;
  maxItems?: number;
  items?: any[];
  // unfilteredValues?: { [col: string]: string[] };
  selection?: { _id: string }[];
  handleOptionsChanged?: (options: TableOptions) => void;
  isItemDisabled?: (item: any) => boolean;
  isItemDisabledInfo?: (item: any) => React.ReactNode;
  handleSelectionChanged?: (item: any, value: boolean) => void;
  extensionRow?: ExtensionRowRendererFunction;
  extensionGetter?: (item: any) => any;
  selectionCellRenderer?: SelectionCellRendererFunction;
}

export interface TableState {
  modalIsOpen: boolean;
  options: TableOptions;
  items?: any[];
  // unfilteredValues?: { [col: string]: string[] };
  maxItems?: number;
  selection: { _id: string }[];
  selectionFilter: boolean | undefined;
  selectable: SELECTION_TYPE;
  loading: boolean;
  // filters?: { [col: string]: { value: string; comparator: string }[] };
}

const DEFAULT_OPTIONS: TableOptions = {
  filters: {},
  sort: 'name',
  desc: false,
  page: 0,
  limit: 0,
};

class Table extends Component<TableProps, TableState> {
  rvTableRef = React.createRef<Table>();
  cache: CellMeasurerCache;

  state: TableState = {
    loading: true,
    options: DEFAULT_OPTIONS,
    selectable: SELECTION_TYPE.disabled,
    items: [],
    selection: [],
    selectionFilter: undefined,
    modalIsOpen: false,
    // filters: {},
  };

  constructor(props: TableProps) {
    super(props);
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 94,
    });

    this.state.options = props.options || DEFAULT_OPTIONS;

    this.state.items = props.items || [];
    // this.state.unfilteredValues = props.unfilteredValues || {};
    this.state.selection = props.selection || [];
    this.state.selectable = props.selectable || SELECTION_TYPE.disabled;

    this._cellRenderer = this._cellRenderer.bind(this);
    this._selectionCellRenderer = this._selectionCellRenderer.bind(this);
    this._headerRenderer = this._headerRenderer.bind(this);
    this._headerSelectionRenderer = this._headerSelectionRenderer.bind(this);
    this._rowRenderer = this._rowRenderer.bind(this);
    this._rowClassName = this._rowClassName.bind(this);
    this._sort = this._sort.bind(this);
    this.onPagination = this.onPagination.bind(this);
  }

  componentDidUpdate(prevProps: TableProps) {
    let p = {};
    if (this.props.options !== prevProps.options) {
      p = { ...p, options: this.props.options || DEFAULT_OPTIONS };
    }
    if (this.props.maxItems !== prevProps.maxItems) {
      p = { ...p, maxItems: Number(this.props.maxItems || 0) };
    }
    if (this.props.items !== prevProps.items) {
      p = { ...p, items: this.props.items || [] };
    }
    // if (this.props.unfilteredValues !== prevProps.unfilteredValues) {
    //   p = { ...p, unfilteredValues: this.props.unfilteredValues || {} };
    // }
    if (this.props.selection !== prevProps.selection) {
      p = { ...p, selection: this.props.selection || {} };
    }
    if (this.props.loading !== prevProps.loading) {
      p = { ...p, loading: this.props.loading };
    }
    if (this.props.selectable !== prevProps.selectable) {
      p = { ...p, selectable: this.props.selectable };
    }
    if (Object.keys(p).length > 0) {
      // console.log('Update State for ', Object.keys(p));
      this.setState(p);
    }
  }

  isItemSelected(id: string) {
    const r = find(this.state.selection, function (l) {
      return id === l._id;
    });
    return !isNil(r);
  }

  onPagination(page: number) {
    const opts: TableOptions = {
      ...this.state.options,
      page,
    };
    this.setState({ options: opts }, () => {
      this.props.handleOptionsChanged && this.props.handleOptionsChanged(opts);
    });
  }

  _rowClassName(info: Index) {
    if (info.index < 0) {
      return 'table-header';
    } else {
      return 'table-row';
    }
  }

  _rowRenderer(cell: TableRowProps) {
    const { columns, className, key, style, rowData } = cell;
    let extensions: any[] = [];

    if (this.props.extensionGetter) {
      extensions = this.props.extensionGetter(rowData);
    }

    const classes = classNames('table-row-inner', {
      'has-extensions': extensions.length > 0,
    });

    return (
      <div className={className} key={key} role='row' style={style}>
        <div className={classes}>{columns}</div>
        {extensions.map(
          (extension, index) =>
            this.props.extensionRow &&
            this.props.extensionRow(
              extension,
              index,
              columns,
              this.isItemSelected.bind(this),
              this.props.isItemDisabled
                ? this.props.isItemDisabled.bind(this)
                : undefined,
              this.props.isItemDisabledInfo
                ? this.props.isItemDisabledInfo.bind(this)
                : undefined,
              this.props.handleSelectionChanged
                ? this.props.handleSelectionChanged.bind(this)
                : undefined
            )
        )}
      </div>
    );
  }

  _sort(sort: string, sortDirection: string) {
    const opts: TableOptions = {
      ...this.state.options,
      sort,
      desc: sortDirection === SortDirection.DESC,
    };
    this.setState({ options: opts }, () => {
      this.props.handleOptionsChanged && this.props.handleOptionsChanged(opts);
    });
  }

  filterActive(dataKey: string) {
    const value = get(this.state.options.filters, dataKey);
    return !isNil(value) && value.length > 0;
  }

  getFiltersForKey(key: string) {
    if (!this.state.options) return [];
    if (!this.state.options.filters) return [];
    if (!this.state.options.filters![key]) return [];
    return this.state.options.filters![key];
  }

  _renderFilter(col: TabledHeaderFieldWithFilter) {
    // let unfilteredValues: string[] = [];

    // if (col.key === 'selection') {
    //   unfilteredValues = ['ausgewählt'];
    // } else if (
    //   this.props.unfilteredValues &&
    //   this.props.unfilteredValues[col.key]
    // ) {
    //   unfilteredValues = this.props.unfilteredValues[col.key];
    // }

    return (
      <FilterModal
        filters={this.getFiltersForKey(col.key)}
        field={col}
        onSubmitFilters={(f) => this.onSubmitFilters(col.key, f)}
        // values={unfilteredValues}
      />
    );
  }

  sortClicked(dataKey: string, options?: TableOptions) {
    this._sort(dataKey, options?.desc ? SortDirection.ASC : SortDirection.DESC);
  }

  _headerRenderer(
    col: TabledHeaderField,
    { dataKey, label, disableSort }: TableHeaderProps
  ) {
    const { filterable, options } = this.props;
    const classes = classNames('table-header-col', {
      'can-sort': !disableSort,
      'is-sort': options?.sort === dataKey,
      'is-desc': options?.desc,
      'filter-active': this.filterActive(dataKey),
    });
    return (
      <div className={classes}>
        <span
          onClick={() => !disableSort && this.sortClicked(dataKey, options)}
        >
          {label}
        </span>
        <span
          className='sort-arrow'
          onClick={() => !disableSort && this.sortClicked(dataKey, options)}
        ></span>
        {(col as TabledHeaderFieldWithFilter).filterable &&
          filterable &&
          this._renderFilter(col as TabledHeaderFieldWithFilter)}
      </div>
    );
  }

  _headerSelectionRenderer(
    { dataKey, disableSort }: TableHeaderProps,
    _items: any
  ) {
    const { filterable } = this.props;

    const items = _items.filter((i: any) => {
      return !(this.props.isItemDisabled && this.props.isItemDisabled(i));
    });

    const classes = classNames('table-header-col', {
      'can-sort': !disableSort,
      'filter-active': this.filterActive(dataKey),
    });
    const col: TabledHeaderFieldWithFilter = {
      key: 'selection',
      title: 'Auswahl',
      width: '0-5',
      filterable: true,
      suggestion: true,
      filterComparators: FILTER_OPERATOR_EQ_BOOL,
      equalComparator: Comparator['='],
      notEqualComparator: Comparator['!='],
    };
    const checked = reduce(
      items,
      (acc, item) => acc && this.isItemSelected(item._id),
      true
    );
    return (
      <div className={classes}>
        {filterable ? (
          <Fragment>
            <div className={`table-selection ${checked ? 'check' : ''}`}>
              <input
                type='checkbox'
                id='table-selection-header'
                checked={checked}
                onChange={(event) => {
                  this.setSelection(items || [], event.target.checked);
                }}
              />
              <label className='check' htmlFor='table-selection-header'>
                <CheckBoldIcon />
              </label>
              {col.filterable && filterable && this._renderFilter(col)}
            </div>
          </Fragment>
        ) : null}
      </div>
    );
  }

  _cellRenderer(
    { format, emptySymbol, noLink }: TabledHeaderField,
    { dataKey, rowData }: TableCellProps
  ) {
    const { link, linkTarget } = this.props;
    const formatter = format || ((value) => value);
    const value =
      formatter(get(rowData, dataKey), rowData) || emptySymbol || '-';
    const target = linkTarget || '_self';

    let to: string | undefined;

    if (link && typeof link === 'function') {
      to = link(rowData);
    } else {
      if (link) {
        to = `${link}/${rowData._id}`;
      }
      if (link && (target === '_blank' || target === 'blank')) {
        to += '?p=1';
      }
    }

    if (to && noLink !== true) {
      return (
        <NavLink target={target} to={to}>
          {value}
        </NavLink>
      );
    } else {
      return <React.Fragment>{value}</React.Fragment>;
    }
  }

  getSelectionCellRenderer(cell: TableCellProps) {
    if (this.props.selectionCellRenderer) {
      return this.props.selectionCellRenderer(
        cell,
        this.isItemSelected.bind(this),
        this.props.isItemDisabled
          ? this.props.isItemDisabled.bind(this)
          : undefined,
        this.props.isItemDisabledInfo
          ? this.props.isItemDisabledInfo.bind(this)
          : undefined,
        this.props.handleSelectionChanged
          ? this.props.handleSelectionChanged.bind(this)
          : undefined
      );
    } else {
      return this._selectionCellRenderer(cell);
    }
  }

  _selectionCellRenderer({ rowData }: TableCellProps) {
    const { _id } = rowData;
    const value = this.isItemSelected(_id);
    const disabled =
      this.props.isItemDisabled && this.props.isItemDisabled(rowData);
    return (
      <div className={`table-selection ${value || false ? 'check' : ''}`}>
        <input
          type='checkbox'
          id={`table-selection-${_id}`}
          checked={value || false}
          disabled={disabled}
          onChange={(event) => {
            !disabled &&
              this.props.handleSelectionChanged &&
              this.props.handleSelectionChanged(
                [rowData],
                event.target.checked
              );
          }}
        />
        {disabled && this.props.isItemDisabledInfo && (
          <Tooltip
            placement='right'
            title={this.props.isItemDisabledInfo(rowData)}
          >
            <label
              className={`check ${disabled ? 'disabled' : ''}`}
              htmlFor={`table-selection-${_id}`}
            >
              <CheckBoldIcon />
            </label>
          </Tooltip>
        )}
        {!disabled && (
          <label
            className={`check ${disabled ? 'disabled' : ''}`}
            htmlFor={`table-selection-${_id}`}
          >
            <CheckBoldIcon />
          </label>
        )}
      </div>
    );
  }

  setSelection(locations: any[], value: boolean) {
    this.props.handleSelectionChanged &&
      this.props.handleSelectionChanged(locations, value);
  }

  async onSubmitFilters(
    key: string,
    newFilters: { value: string; comparator: string }[]
  ) {
    if (key !== 'selection') {
      const filters = {
        ...(this.state.options.filters || {}),
      };

      filters[key] = newFilters;

      const opts: TableOptions = {
        ...this.state.options,
        filters,
      };

      this.setState({ options: opts }, () => {
        this.props.handleOptionsChanged &&
          this.props.handleOptionsChanged(opts);
      });
    } else {
      let sf: boolean | undefined = undefined;

      if (
        newFilters &&
        newFilters.length > 0 &&
        newFilters[0].comparator === '=B'
      )
        sf = true;
      if (
        newFilters &&
        newFilters.length > 0 &&
        newFilters[0].comparator === '!=B'
      )
        sf = false;

      this.setState({
        selectionFilter: sf,
      });
    }
  }

  render() {
    const { header, loading } = this.props;
    const { selectable } = this.state;

    if (loading) {
      return (
        <div className='center-loader'>
          <ClipLoader size={80} color={'#009842'} loading={true} />
        </div>
      );
    }

    const { items, maxItems, selectionFilter, options } = this.state;

    let _items: any[] = [];

    if (selectionFilter === true && items) {
      _items = items.filter((item) => this.isItemSelected(item._id));
    } else if (selectionFilter === false && items) {
      _items = items.filter((item) => !this.isItemSelected(item._id));
    } else if (items) {
      _items = items;
    }

    return (
      <React.Fragment>
        <AutoSizer>
          {({ width, height }) => (
            <div
              style={{
                position: 'relative',
              }}
            >
              <RVTable
                className='table'
                headerHeight={22}
                height={height - 80}
                overscanRowCount={10}
                rowClassName={this._rowClassName}
                rowHeight={({ index }) => {
                  const item = _items[index];
                  const exH = 38;
                  let h = 60; //85;
                  if (this.props.extensionGetter) {
                    const ex: any[] = this.props.extensionGetter(item);
                    h = h + ex.length * exH;
                    h += 9;
                  }
                  return h;
                }}
                rowCount={_items.length}
                rowGetter={({ index }) => _items[index]}
                rowRenderer={this._rowRenderer}
                width={width}
              >
                {selectable !== SELECTION_TYPE.disabled && (
                  <Column
                    flexGrow={0}
                    flexShrink={0}
                    className='col col-0-5'
                    headerClassName='col col-0-5'
                    dataKey='selected'
                    label=''
                    disableSort={false}
                    headerRenderer={(p) =>
                      this._headerSelectionRenderer(p, _items)
                    }
                    cellRenderer={(cell) => this.getSelectionCellRenderer(cell)}
                    width={(width / 12) * 0.5}
                  />
                )}
                {header.map((col) => {
                  const w = getColumnGridWidth(col);
                  const colWidth = (width / 12) * w;
                  const classes = classNames(
                    'col',
                    `col-${w}`,
                    `col-${col.key}`,
                    {
                      highlight: col.highlight,
                    }
                  );
                  const headerClasses = classNames('col', `col-${w}`);
                  return (
                    <Column
                      flexGrow={0}
                      flexShrink={0}
                      key={col.key}
                      className={classes}
                      headerClassName={headerClasses}
                      dataKey={col.key}
                      label={col.title}
                      disableSort={!col.sort}
                      headerRenderer={(cell) => this._headerRenderer(col, cell)}
                      cellRenderer={(cell) => this._cellRenderer(col, cell)}
                      width={colWidth}
                    />
                  );
                })}
              </RVTable>
              <TableSumFooter
                width={width}
                header={header}
                selectable={selectable !== SELECTION_TYPE.disabled}
                items={_items}
              />
              <TableFooter
                width={width}
                page={options.page}
                maxItems={maxItems}
                limit={options.limit}
                onPagination={this.onPagination}
              />
            </div>
          )}
        </AutoSizer>
      </React.Fragment>
    );
  }
}

export default Table;
