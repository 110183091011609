import React from 'react';
import numeral from 'numeral';

// switch between locales
numeral.locale('de');

interface NumberChartProps {
  value: number;
  unit?: string;
  precise: boolean;
}

export default function NumberChart({
  unit,
  value,
  precise,
}: NumberChartProps) {
  // if (!value) return <></>;
  const fa = precise ? '0.00' : '0';
  const format = value < 10000 ? fa : `${fa}a`;
  const formatted = numeral(value).format(format);
  return (
    <svg width='100%' viewBox='0 0 150 40'>
      <g>
        <text
          fontSize='30px'
          fontWeight='bold'
          x='50%'
          y='34px'
          dominantBaseline='top'
          textAnchor='middle'
        >
          {formatted}{' '}
          <tspan dx='-8px' fontSize='10px'>
            {unit}
          </tspan>
        </text>
      </g>
    </svg>
  );
}
