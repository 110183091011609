import {
  PlanningConsumer,
  PlanningContext,
} from '../../../context/PlanningContext';
import React, { Component, Fragment } from 'react';
import { chain, get, mapValues, sumBy } from 'lodash';

import { ReactComponent as CheckCircleIcon } from '../../../assets/icons/check-circle.svg';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as FailCircleIcon } from '../../../assets/icons/fail-circle.svg';
import { JOB_TYPE } from '../../../context/Route';
import { Progress } from 'antd';
import { Redirect } from 'react-router-dom';
import { Request } from '../../../api/Request';

interface PlanningStepCompleteState {
  error?: Error;
  loading: boolean;
  infoProgress: number;
  result?: Result;
  errorID?: string;
}

interface Result {
  complete: boolean;
  tour: any;
  technician: any;
  jobs: any[];
}

class PlanningStepComplete extends Component {
  state: PlanningStepCompleteState = {
    loading: true,
    infoProgress: 0,
  };

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  componentDidMount() {
    this.saveRoute();
  }

  async saveRoute() {
    const { route, reset } = this.context;
    if (!route) return;
    const _jobs = chain(route.jobs)
      .filter((f) => f.type > 0)
      .map(
        (
          {
            _id,
            locID,
            name,
            address,
            nearByAddress,
            checkDepth,
            refIndex,
            type,
            splited,
            operatingExpense,
            _dues,
            duePrice,
            comment,
            freezeComment,
            planedAt,
            planedAtTimeByUser,
            until,
            leg,
          },
          index
        ) => {
          return {
            sort: index,
            location: locID ? locID : _id,
            additionalTask: locID ? _id : undefined,
            name,
            locationName: name,
            address,
            comment,
            freezeComment,
            nearByAddress,
            estimatedPrice: type === 2 ? 0 : duePrice,
            checkDepth,
            refIndex,
            type,
            tasks: mapValues(_dues, (d) => !!d),
            splited,
            planHours: type === 2 ? 0 : operatingExpense,
            planedAt: planedAt,
            planedAtTimeByUser,
            until,
            leg,
            isHotel: type === JOB_TYPE.HotelAccommodation,
            isHome: type === JOB_TYPE.HomeAccommodation,
          };
        }
      )
      .value();
    const data = {
      planWorkingHours: sumBy(_jobs, 'planHours'),
      planDrivingHours: Math.floor((route.duration / 3600) * 100) / 100,
      planDrivingDistance: Math.floor(route.distance / 1000),
      estimatedPrice: sumBy(_jobs, 'estimatedPrice'),
      technician: route.technician,
      jobs: _jobs,
    };
    if (data) {
      try {
        const result = await Request.post('planning/routes/create', data);
        await this.setPromisifiedState({ isLoaded: true, result });
        await reset();
        await this.setPromisifiedState({ infoProgress: 25 });

        // await this.sendInfoToTechnician(result.tour._id, [
        //   route.technician.email,
        //   result.createdByEMail,
        // ]);
      } catch (error: any) {
        const errorID = get(error, 'response.headers.x-request-id');
        await this.setPromisifiedState({ isLoaded: true, error, errorID });
      }
    }
  }

  // async sendInfoToTechnician(tourID: string, emails: string[]) {
  //   await this.setPromisifiedState({ infoProgress: 50 });
  //   const mail = await showEMailSelector([...emails, 'info@allessafe.de']);
  //   if (!mail) return;
  //   await Request.put(
  //     'tours',
  //     tourID,
  //     { email: mail, temporarily: true },
  //     'share/temporarily'
  //   );
  //   await this.setPromisifiedState({ infoProgress: 100 });
  // }

  isValid(
    locations: any[] | undefined,
    technicians: any[] | undefined,
    route: any | undefined
  ) {
    return (
      locations &&
      locations.length > 0 &&
      technicians &&
      technicians.length > 0 &&
      route
    );
  }

  renderError(error: Error, errorID?: string) {
    return (
      <div className={`jumbo ${error ? 'error' : ''}`}>
        <div className='icon d-flex justify-content-center'>
          <FailCircleIcon className='' />
        </div>
        <h2 className='text'>
          {error ? 'Da ist etwas schief gelaufen!' : ''}
          <p>{error.message}</p>
          <p>Fehler-ID: {errorID}</p>
        </h2>
      </div>
    );
  }

  renderResult(result: any) {
    return (
      <div className='jumbo'>
        <div className='icon d-flex justify-content-center'>
          <CheckCircleIcon className='' />
        </div>
        <h2 className='text'>
          {result && !result.complete
            ? `Tour ${result.tour.tag} gespeichert`
            : ''}
          {result && result.complete
            ? `Tour ${result.tour.tag} erfolgreich geplant`
            : ''}
        </h2>
      </div>
    );
  }

  renderSending(infoProgress: number) {
    return (
      <div
        style={{
          textAlign: 'center',
          margin: '0 auto',
        }}
      >
        <div
          style={{
            display: 'block',
          }}
        >
          <Progress
            percent={infoProgress}
            steps={5}
            size='small'
            strokeColor='#52c41a'
          />
        </div>
        <span>Info an den Techniker senden</span>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className='jumbo'>
        <div className='icon d-flex justify-content-center'>
          <ClipLoader size={80} color={'#009842'} loading={true} />
        </div>
        <h2 className='text'>Tour wird erzeugt...</h2>
      </div>
    );
  }

  render() {
    const { result, error, errorID } = this.state;
    return (
      <div className='complete container-inner'>
        <div className='row justify-content-center'>
          <div className='col col-sm-12'>
            <PlanningConsumer>
              {({ locations, technicians, route }) => (
                <Fragment>
                  {error && this.renderError(error, errorID)}
                  {!error &&
                    !result &&
                    !this.isValid(locations, technicians, route) && (
                      <Redirect to='/planning/locations' />
                    )}
                  {!error && result && this.renderResult(result)}
                  {!error && !result && this.renderLoading()}
                </Fragment>
              )}
            </PlanningConsumer>
          </div>
        </div>
      </div>
    );
  }
}

PlanningStepComplete.contextType = PlanningContext;

export default PlanningStepComplete;
