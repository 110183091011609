import { InputNumber } from 'antd';
import { useField } from 'formik';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Title = styled.p`
  color: #009842;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  padding: 1px 0 0;
  overflow: hidden;
  line-height: 28px;
  margin-right: 12px !important;
`;

const Wrapper = styled.div`
  align-self: flex-end;
  display: flex;
`;

interface WorkingHoursProps {
  name: string;
  title?: string;
}

const WorkingHours: FunctionComponent<WorkingHoursProps> = ({
  name,
  title,
}) => {
  const fieldName = `${name}.planHours`;
  const [field, meta] = useField(fieldName);
  const status = meta.error;
  return (
    <Wrapper>
      <Title>{title ?? 'gepl. AH'}</Title>
      <InputNumber
        status={status !== undefined ? 'error' : undefined}
        style={{ width: 66, marginLeft: 0 }}
        value={field.value ? Math.round(field.value * 100) / 100 : 0}
        readOnly={true}
        disabled={true}
        decimalSeparator=','
      />
    </Wrapper>
  );
};

export default WorkingHours;
