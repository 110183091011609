import './Location.scss';

import { Col, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import { Request } from '../../api/Request';
import { orderBy } from 'lodash';
import renderLoading from '../SharesActions/renderLoading';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// class LocationHistory extends Component<RouteComponentProps, LocationNewState> {

const Changed = styled.div`
  background: #fff;
  border-radius: 3px;
  padding: 16px;
  margin: 8px 0;
  h6 {
    margin: 0 12px;
    color: #aaa;
  }
  p {
    margin: 0 12px;
  }
`;

const itemToString = (data: any): string => {
  if (typeof data === 'string') {
    const dt = DateTime.fromISO(data);
    if (dt.isValid) {
      return dt.toLocaleString({
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } else {
      return data;
    }
  }
  if (typeof data === 'number') {
    return data.toString();
  }
  if (typeof data === 'object' && data) {
    return `Datensatz (${data._id})`;
  }
  if (typeof data === 'boolean') {
    return data ? 'Ja' : 'Nein';
  }
  if (Array.isArray(data)) {
    return data.map((i) => itemToString(i)).join('\n');
  }
  return '';
};

const LocationHistory: FunctionComponent = () => {
  const params = useParams<{ id: string }>();

  const [location, setLocation] = useState<any>();
  const [history, setHistory] = useState<any>(undefined);

  useEffect(() => {
    const load = async () => {
      const l = await Request.get('locations', params.id, undefined, {
        displayAllJobs: false,
      });
      setLocation(l);
    };
    load();
  }, [params]);

  useEffect(() => {
    const load = async () => {
      const l = await Request.get('locations', params.id, 'history', {});
      setHistory(l);
    };
    load();
  }, [params]);

  return (
    <div className='location container-inner'>
      <InPageNavigation
        to={`/administration/locations/${params.id}`}
        item={location}
      />
      <div className='page-header page-header-line row justify-content-between'>
        <div className='col-6 col'>
          <h1 className='page-title'>
            Verlauf von{' '}
            {location && location.address ? location.address.street + ' |' : ''}{' '}
            {location && location.address ? location.address.city + ' |' : ''}{' '}
            {location && location.customer ? location.customer.name : ''}
          </h1>
        </div>
        <div className='col-6 col'></div>
      </div>
      <div className='page-content'>
        {!history && renderLoading()}
        {history !== undefined &&
          orderBy(history, 't', 'desc').map((p: any, j: number) => (
            <div data-id={p._id} key={j}>
              <div className='row table-divider'>
                <div className='col'>
                  <span>
                    {DateTime.fromISO(p.t).toLocaleString({
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                    {p.changedBy ? ` | ${p.changedBy.name}` : ''}
                  </span>
                </div>
              </div>
              {p.res.map((r: any, i: number) => (
                <div key={i} className='row table-row input-row'>
                  <div className='col'>
                    <Changed>
                      <Row>
                        <Col xs={4}>
                          <h6>Aktion</h6>
                          {(r.item ? r.item.kind : r.kind) === 'N' && (
                            <p>Neu</p>
                          )}
                          {(r.item ? r.item.kind : r.kind) === 'D' && (
                            <p>Gelöscht</p>
                          )}
                          {(r.item ? r.item.kind : r.kind) === 'E' && (
                            <p>Geändert</p>
                          )}
                          {(r.item ? r.item.kind : r.kind) === 'A' && (
                            <p>Liste geändert</p>
                          )}
                        </Col>
                        <Col xs={6}>
                          <h6>Pfad</h6>
                          <p>
                            {[...r.path, r.index]
                              .filter((t) => t != undefined && t != null)
                              .join(' / ')}
                          </p>
                        </Col>
                        <Col xs={6}>
                          <h6>Neuer Wert</h6>
                          <p>{itemToString(r.item ? r.item.rhs : r.rhs)}</p>
                        </Col>
                        <Col xs={6}>
                          <h6>Alter Wert</h6>
                          <p>{itemToString(r.item ? r.item.lhs : r.lhs)}</p>
                        </Col>
                      </Row>
                    </Changed>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default LocationHistory;
